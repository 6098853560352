import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';

export class EPAL1Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    console.log('EuroPaletteMesh 1 constructor called', other);
    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = 145;
    const plankH = 22;

    let currentY = 0;
    const r = 17;

    //bottom planks
    const allRounded = [r, r, r, r] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    this.plank(
      new Vector3(x0, currentY, z0),
      this.length,
      100,
      plankH,
      1,
      ...allRounded
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2),
      this.length,
      plankW,
      plankH,
      1,
      ...allRounded
    );
    this.plank(
      new Vector3(x0, currentY, z1 - 100),
      this.length,
      100,
      plankH,
      1,
      ...allRounded
    );

    //boxes
    currentY += plankH;
    this.plank(
      new Vector3(x0, currentY, z0),
      plankW,
      100,
      78,
      2,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2),
      plankW,
      plankW,
      78,
      2,
      ...leftExternalRadiuses
    );

    this.plank(
      new Vector3(x0, currentY, z1 - 100),
      plankW,
      100,
      78,
      2,
      ...leftExternalRadiuses
    );

    this.plank(new Vector3(xc - plankW / 2, currentY, z0), plankW, 100, 78, 2);
    this.plank(
      new Vector3(xc - plankW / 2, currentY, zc - plankW / 2),
      plankW,
      plankW,
      78,
      2
    );
    this.plank(
      new Vector3(xc - plankW / 2, currentY, z1 - 100),
      plankW,
      100,
      78,
      2
    );

    this.plank(
      new Vector3(x1 - plankW, currentY, z0),
      plankW,
      100,
      78,
      2,
      ...rightExternalRadiuses
    );
    this.plank(
      new Vector3(x1 - plankW, currentY, zc - plankW / 2),
      plankW,
      145,
      78,
      2,
      ...rightExternalRadiuses
    );
    this.plank(
      new Vector3(x1 - plankW, currentY, z1 - 100),
      plankW,
      100,
      78,
      2,
      ...rightExternalRadiuses
    );

    //before top planks
    currentY += 78;
    this.plank(
      new Vector3(x0, currentY, z0),
      plankW,
      this.width,
      plankH,
      1,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(xc - plankW / 2, currentY, z0),
      plankW,
      this.width,
      plankH,
      1
    );
    this.plank(
      new Vector3(x1 - plankW, currentY, z0),
      plankW,
      this.width,
      plankH,
      1,
      ...rightExternalRadiuses
    );

    //top planks
    currentY += plankH;
    this.plank(
      new Vector3(x0, currentY, z0),
      this.length,
      plankW,
      plankH,
      2,
      ...backExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2),
      this.length,
      plankW,
      plankH,
      2
    );
    this.plank(
      new Vector3(x0, currentY, z1 - plankW),
      this.length,
      plankW,
      plankH,
      2,
      ...frontExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2 - 100 - 43),
      this.length,
      100,
      plankH,
      2
    );
    this.plank(
      new Vector3(x0, currentY, zc + plankW / 2 + 43),
      this.length,
      100,
      22,
      2
    );

    currentY += plankH;
    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(x0, currentY);
  }
}
