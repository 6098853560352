import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Order } from '../lib/order';
import { dateValidator } from './lib/date.validator';
import { LoadService } from '../../load/lib/load.service';
import { Load } from '../../load/lib/load';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { OrderService } from '../order.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-order-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.less']
})
export class CreateComponent implements OnInit, OnDestroy {
  @Input() order: Order;
  protected form: FormGroup;

  protected loads: Load[] = [];
  protected filteredLoads: Load[] = [];
  protected isLoading = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private loadService: LoadService,
    private loadComponentService: LoadComponentService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.loadService.loads$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.loads = list;
        this.filterLoads('');
        if (this.order) {
          const load = list.find((l) => l.uuid === this.order.ItemId);
          if (load) {
            this.form.get('Load').patchValue(load);
          }
        }
      });
    this.filteredLoads = this.loads.slice();
    this.form.get('Load')!.valueChanges.subscribe((value) => {
      console.log('autocomplete', value);
      this.filteredLoads = this.filterLoads(value);
    });

    if (this.order) {
      this.form.patchValue(this.order);
      if (this.order.ShippingDateConfirmed) {
        this.form
          .get('ShippingDateConfirmed')
          .patchValue(new Date(this.order.ShippingDateConfirmed));
      }
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      Number: ['', Validators.required],
      DeliveryName: ['', Validators.required],
      DeliveryCity: ['', Validators.required],
      ZipCode: ['', Validators.required],
      Country: ['', Validators.required],
      Load: [null, Validators.required],
      Qty: [null, [Validators.required, Validators.pattern(/^-?\d+$/)]],
      ShippingDateConfirmed: [null, [Validators.required]],
      Reference: [''],
      CustAccount: [''],
      State: [''],
      ItemGrp: [''],
      AvailableStock: [''],
      DlvMode: [''],
      Volume: [''],
      InventRefType: [''],
      InventRefNo: [''],
      ProdStatus: [''],
      LatestSchedDate: [''],
      ShipmentId: [''],
      TruckArrival: [''],
      Note: [''],
      Site: [''],
      Pool: [''],
      ItemGrpId: [''],
      BuyerGroup: [''],
      Warehouse: ['']
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const orderData = this.form.value;
      if (orderData.Load) {
        console.log(orderData.Load);
        orderData.ItemId = orderData.Load.uuid;
        orderData.ItemName = orderData.Load.name;
        delete orderData.Load;
      }
      orderData.Uuid = uuid();
      const order = new Order(orderData);
      console.log(order);
      this.isLoading = true;
      this.orderService
        .saveOrder(order)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((list) => {
          this.isLoading = false;
          this.close();
        });
      // save order and reload list
    } else {
      this.form.markAllAsTouched();
    }
  }

  protected filterLoads(value: string | Load): Load[] {
    const name = value instanceof Load ? value.name : value || '';
    const filterValue = name.toLowerCase();
    return this.loads.filter((load) =>
      load.name.toLowerCase().includes(filterValue)
    );
  }

  protected displayLoad(load: Load): string {
    return load && load.name ? load.name : '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.loadComponentService.clear('order/import/import.service.ts');
  }

  protected get load() {
    return this.form.get('Load');
  }
}
