import { Load } from 'src/app/load/lib/load';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Project } from 'src/app/projects/lib/project';
import { Vehicle } from '../../vehicle/lib/vehicle';
import { VehicleFactory } from '../../vehicle/lib/vehicle-factory';
import { Settings } from './settings';

interface LatestLoad {
  name: string;
  timestamp: number;
}

export interface ProfileInterface {
  userId?: string;
  name?: string;
  email?: string;
  phone?: string;
  settings: Settings;
  customLoadsList?: Load[];
  customVehiclesList?: Vehicle[];
  loadsCounter?: number;
  calculationsCounter?: number;
  createdAt?: Date;
  updatedAt?: Date;
  expireOn?: Date;
  isExpired?: boolean;
  isPlusMember?: boolean;
  accountType?: string;
  latestProject?: Project;
  latestProjectUuid?: string;
  extraAttributes: string[];
  lastLoads?: LatestLoad[];
  roles?: string[];

  recreate(loadFactory: LoadFactory, vehicleFactory: VehicleFactory): void;
  hasAttribute(name: string): boolean;
  hasRole(name: string): boolean;
}

export class Profile implements ProfileInterface {
  userId?: string;
  name?: string;
  email?: string;
  phone?: string;
  settings: Settings;
  customLoadsList?: Load[];
  customVehiclesList?: Vehicle[];
  loadsCounter?: number;
  calculationsCounter?: number;
  expireOn?: Date;
  isExpired?: boolean;
  isPlusMember?: boolean;
  accountType?: string;
  latestProject?: Project;
  latestProjectUuid?: string;
  extraAttributes: string[];
  lastLoads?: LatestLoad[];
  roles?: string[] = [];
  createdAt?: Date;
  updatedAt?: Date;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.customLoadsList = [];
    this.customVehiclesList = [];
    this.settings = new Settings(this.settings ?? {});
    if (this.latestProject) {
      this.latestProject = new Project(this.latestProject);
    }
    if (!this.lastLoads) {
      this.lastLoads = [];
    }
    if (!this.roles) {
      this.roles = [];
    }
  }

  public recreate(loadFactory: LoadFactory, vehicleFactory: VehicleFactory) {
    (this.customLoadsList || []).map((l) => loadFactory.recreateLoad(l));
    (this.customVehiclesList || []).map((v) => vehicleFactory.recreate(v));
    if (!this.extraAttributes) {
      this.extraAttributes = [];
    }
  }

  public hasAttribute(name: string) {
    return (this.extraAttributes || []).includes(name);
  }

  public hasRole(name: string) {
    return (this.roles || []).includes(name);
  }
}
