import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';

export class EPALCP4Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = 120;
    const plankW2 = 80;
    const plankW3 = 100;
    const plankH = 18;
    const plankH2 = 22;
    const plankL = this.length;

    let currentY = 0;
    const r = 14;

    const allRounded = [r, r, r, r] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    //bottom planks
    const bottomPlankCutoff = 100;
    const bottomPlanks = [
      new Vector3(x0 + bottomPlankCutoff / 2, currentY, z0),
      new Vector3(x0 + bottomPlankCutoff / 2, currentY, zc - plankW / 2),
      new Vector3(x0 + bottomPlankCutoff / 2, currentY, z1 - plankW)
    ];
    bottomPlanks.forEach((p) =>
      this.plank(p, plankL - bottomPlankCutoff, plankW, plankH, 1)
    );
    //boxes
    currentY += plankH;
    const boxH = 80;
    const boxOffset = 110;
    const boxSize = [plankW2, plankW, boxH] as const;
    const leftBoxes = [
      new Vector3(x0 + boxOffset, currentY, z0),
      new Vector3(x0 + boxOffset, currentY, zc - plankW / 2),
      new Vector3(x0 + boxOffset, currentY, z1 - plankW)
    ];
    leftBoxes.map((p) => {
      this.plank(p, ...boxSize, 2, ...leftExternalRadiuses);
    });
    const centerBoxes = [
      new Vector3(xc - plankW2 / 2, currentY, z0),
      new Vector3(xc - plankW2 / 2, currentY, zc - plankW / 2),
      new Vector3(xc - plankW2 / 2, currentY, z1 - plankW)
    ];
    centerBoxes.map((p) => {
      this.plank(p, ...boxSize, 2);
    });
    const rightBoxes = [
      new Vector3(x1 - plankW2 - boxOffset, currentY, z0),
      new Vector3(x1 - plankW2 - boxOffset, currentY, zc - plankW / 2),
      new Vector3(x1 - plankW2 - boxOffset, currentY, z1 - plankW)
    ];
    rightBoxes.map((p) => {
      this.plank(p, ...boxSize, 2, ...rightExternalRadiuses);
    });

    //before top planks
    currentY += boxH;
    this.plank(
      new Vector3(x0 + boxOffset - (plankW3 / 2 - plankW2 / 2), currentY, z0),
      plankW3,
      this.width,
      plankH2,
      1,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(xc - plankW3 / 2, currentY, z0),
      plankW3,
      this.width,
      plankH2,
      1
    );
    this.plank(
      new Vector3(
        x1 - boxOffset - plankW3 + (plankW3 / 2 - plankW2 / 2),
        currentY,
        z0
      ),
      plankW3,
      this.width,
      plankH2,
      1,
      ...rightExternalRadiuses
    );

    //top planks
    currentY += plankH2;
    this.plank(
      new Vector3(x0, currentY, z0),
      plankL,
      plankW,
      plankH,
      2,
      ...backExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, z1 - plankW),
      plankL,
      plankW,
      plankH,
      2,
      ...frontExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2),
      plankL,
      plankW,
      plankH,
      2
    );
    const centerPlanks = [
      new Vector3(x0, currentY, z0 + plankW),
      new Vector3(x0, currentY, z0 + plankW + plankW2 + 44),
      new Vector3(x0, currentY, z0 + plankW + plankW2 * 2 + 44 * 2),
      new Vector3(x0, currentY, z1 - plankW - plankW2),
      new Vector3(x0, currentY, z1 - plankW - plankW2 * 2 - 44),
      new Vector3(x0, currentY, z1 - plankW - plankW2 * 3 - 44 * 2)
    ];
    centerPlanks.forEach((p) => {
      this.plank(p, plankL, plankW2, plankH, 2);
    });

    currentY += plankH;

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(0, currentY);
  }
}
