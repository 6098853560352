<div class="chart-container">
  <canvas
    #chart
    baseChart
    [data]="chartData"
    [options]="chartOptions"
    [type]="chartType"
    (chartHover)="chartHovered($event)"
    (chartClick)="chartClicked($event)"
  >
  </canvas>
  <button type="button" class="download" (click)="downloadChartPdf()">PDF</button>
</div>
