import { Euler, Vector3 } from 'three';
import { ModelDetails } from './model-details.interface';

export class ModelDetails3023GT implements ModelDetails {
  getPartDimensions(name: string): Vector3 {
    switch (name) {
      case 'wheel-hub-fr-obb':
      case 'wheel-hub-fl-obb':
      case 'wheel-hub-rl-obb':
      case 'wheel-hub-rr-obb':
        return new Vector3(undefined, 60, undefined);
    }
    return new Vector3(undefined, undefined, 65);
  }
  getPartRotation(name: string): Euler {
    switch (name) {
      case 'shaft-left-obb':
        return new Euler(0, -Math.PI / 10, 0);
      case 'shaft-right-obb':
        return new Euler(0, Math.PI / 10, 0);
      case 'wheel-hub-fr-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-fl-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rr-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rl-obb':
        return new Euler(0, 0, Math.PI / 7);
    }
    throw new Error(`3023GT OBB layer for ${name} not supported`);
  }
}
