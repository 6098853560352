import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { AmountComponent } from './amount/amount.component';
import { ColorComponent } from './color/color.component';
import { WeightComponent } from './weight/weight.component';
import { FlooringComponent } from './flooring/flooring.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { ColorBlockModule } from 'ngx-color/block';
import { ColorCompactModule } from 'ngx-color/compact'; // <color-compact></color-compact>
import { ColorAlphaModule } from 'ngx-color/alpha'; // <color-alpha-picker></color-alpha-picker>
import { ColorChromeModule } from 'ngx-color/chrome'; // <color-chrome></color-chrome>
import { ColorCircleModule } from 'ngx-color/circle'; // <color-circle></color-circle>
import { ColorGithubModule } from 'ngx-color/github'; // <color-github></color-github>
import { ColorHueModule } from 'ngx-color/hue'; // <color-hue-picker></color-hue-picker>
import { ColorMaterialModule } from 'ngx-color/material'; // <color-material></color-material>
import { ColorPhotoshopModule } from 'ngx-color/photoshop'; // <color-photoshop></color-photoshop>
import { ColorSketchModule } from 'ngx-color/sketch'; // <color-sketch></color-sketch>
import { ColorSliderModule } from 'ngx-color/slider'; // <color-slider></color-slider>
import { ColorSwatchesModule } from 'ngx-color/swatches'; // <color-swatches></color-swatches>
import { ColorTwitterModule } from 'ngx-color/twitter'; // <color-twitter></color-twitter>
import { ColorShadeModule } from 'ngx-color/shade'; // <color-shade-picker></color-shade-picker>
import { CuboidModule } from '../type/cuboid/cuboid.module';
import { CarTrailerModule } from '../type/car-trailer/car-trailer.module';
import { CylinderModule } from '../type/cylinder/cylinder.module';
import { LShapeModule } from '../type/l-shape/l-shape.module';
import { TShapeModule } from '../type/t-shape/t-shape.module';
import { HShapeModule } from '../type/h-shape/h-shape.module';
import { HashShapeModule } from '../type/hash-shape/hash-shape.module';
import { AutoComponent } from './auto/auto.component';
import { ExpertComponent } from './expert/expert.component';
import { NameComponent } from './name/name.component';
import { CreateComponent } from './create/create.component';
import { ProtrusionComponent } from './protrusion/protrusion.component';
import { CommonModule as LoadCommonModule } from '../common/common.module';
import { RotationComponent } from './rotation/rotation.component';
import { SettingsModule } from 'src/app/settings-modal/settings.module';
import { EditComponent } from './edit/edit.component';
import { DisassembleComponent } from './disassemble/disassemble.component';

@NgModule({
  declarations: [
    FormComponent,
    AmountComponent,
    ColorComponent,
    WeightComponent,
    FlooringComponent,
    AutoComponent,
    ExpertComponent,
    NameComponent,
    CreateComponent,
    ProtrusionComponent,
    RotationComponent,
    EditComponent,
    DisassembleComponent
  ],
  imports: [
    CommonModule,
    LoadCommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ColorBlockModule,
    ColorCompactModule,
    ColorAlphaModule,
    ColorChromeModule,
    ColorCircleModule,
    ColorGithubModule,
    ColorHueModule,
    ColorMaterialModule,
    ColorPhotoshopModule,
    ColorSketchModule,
    ColorSliderModule,
    ColorSwatchesModule,
    ColorTwitterModule,
    ColorShadeModule,
    CuboidModule,
    CarTrailerModule,
    CylinderModule,
    LShapeModule,
    TShapeModule,
    HShapeModule,
    HashShapeModule,
    SettingsModule
  ],
  exports: [FormComponent]
})
export class FormModule {}
