import { Load } from 'src/app/load/lib/load';
import { LoadGroup, LoadGroupType } from './load-group';
import { LoadFactory } from 'src/app/load/lib/load-factory';

export class LoadGroupList {
  public loads: LoadGroup[];

  constructor(private typeRef: LoadGroupType) {
    this.loads = [];
  }

  public addLoad(load: Load): LoadGroupList {
    const found = this.loads.find((group) => group.has(load));
    if (found != null) {
      if (!found.hasExact(load)) {
        found.addLoad(load);
      }
    } else {
      const newGroup = new this.typeRef();
      newGroup.addLoad(load);
      this.loads.push(newGroup);
    }
    return this;
  }

  public removeLoad(load: Load): LoadGroupList {
    this.loads = this.loads.filter((group) => !group.has(load));
    return this;
  }

  public removeSpecificLoads(loads: Load[]): LoadGroupList {
    loads.forEach((toRemove) => {
      this.loads.forEach((group) => {
        group.removeSingleLoad(toRemove);
      });
    });
    this.loads = this.loads.filter((group) => group.cnt > 0);
    return this;
  }

  public isEmpty() {
    return this.loads.length === 0;
  }

  public countLoads(): number {
    return this.loads.reduce((total, group) => total + group.cnt, 0) || 0;
  }

  public clear() {
    this.loads = [];
  }

  public hasLoadExact(aLoad: Load) {
    return this.loads.some((group) => group.hasExact(aLoad));
  }
}
