<div class="modal-box order-create-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Nowe zamówienie</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <mat-form-field appearance="fill" class="small">
            <mat-label i18n>Numer</mat-label>
            <input
              matInput
              type="text"
              required
              placeholder="Numer"
              formControlName="Number"
            />
            <mat-error
              *ngIf="
                form.get('Number').invalid &&
                (form.get('Number').dirty || form.get('Number').touched)
              "
            >
              <span *ngIf="form.get('Number').errors.required"
                >Podaj Numer</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field class="wide">
            <mat-label i18n>Przyczepa</mat-label>

            <input
              matInput
              type="text"
              required
              placeholder="Przyczepa"
              formControlName="Load"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayLoad"
            >
              <mat-option *ngFor="let item of filteredLoads" [value]="item">
                {{ item.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="load.invalid && (load.dirty || load.touched)">
              <span *ngIf="load.errors.required">Wybierz przyczepę</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="small">
            <mat-label i18n>Ilość</mat-label>

            <input
              matInput
              type="text"
              required
              placeholder="Ilość"
              formControlName="Qty"
            />
            <mat-error
              *ngIf="
                form.get('Qty').invalid &&
                (form.get('Qty').dirty || form.get('Qty').touched)
              "
            >
              <span *ngIf="form.get('Qty').errors.required">Podaj ilość</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="fill">
            <mat-label i18n>Odbiorca</mat-label>
            <input
              matInput
              type="text"
              required
              placeholder="Odbiorca"
              formControlName="DeliveryName"
            />
            <mat-error
              *ngIf="
                form.get('DeliveryName').invalid &&
                (form.get('DeliveryName').dirty ||
                  form.get('DeliveryName').touched)
              "
            >
              <span *ngIf="form.get('DeliveryName').errors.required"
                >Podaj odbiorcę</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="small">
            <mat-label i18n>Data dostawy</mat-label>

            <input
              matInput
              [matDatepicker]="picker"
              required
              placeholder="Data dostawy"
              formControlName="ShippingDateConfirmed"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="
                form.get('ShippingDateConfirmed').invalid &&
                (form.get('ShippingDateConfirmed').dirty ||
                  form.get('ShippingDateConfirmed').touched)
              "
            >
              <span *ngIf="form.get('ShippingDateConfirmed').errors.required"
                >Podaj datę dostawy</span
              >
              <span *ngIf="form.get('ShippingDateConfirmed').errors.invalidDate"
                >Data dostawy powinna mieć format YYYY-MM-DD.</span
              >
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="fill">
            <mat-label i18n>Miasto dostawy</mat-label>
            <input
              matInput
              type="text"
              required
              placeholder="Miasto dostawy"
              formControlName="DeliveryCity"
            />
            <mat-error
              *ngIf="
                form.get('DeliveryCity').invalid &&
                (form.get('DeliveryCity').dirty ||
                  form.get('DeliveryCity').touched)
              "
            >
              <span *ngIf="form.get('DeliveryCity').errors.required"
                >Podaj miasto dostawy</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>Kod pocztowy dostawy</mat-label>

            <input
              matInput
              type="text"
              required
              placeholder="Kod pocztowy dostawy"
              formControlName="ZipCode"
            />
            <mat-error
              *ngIf="
                form.get('ZipCode').invalid &&
                (form.get('ZipCode').dirty || form.get('ZipCode').touched)
              "
            >
              <span *ngIf="form.get('ZipCode').errors.required"
                >Podaj kod pocztowy dostawy</span
              >
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>Państwo dostawy (kod 2-literowy)</mat-label>

            <input
              matInput
              type="text"
              required
              maxlength="2"
              placeholder="Państwo dostawy"
              formControlName="Country"
            />
            <mat-error
              *ngIf="
                form.get('Country').invalid &&
                (form.get('Country').dirty || form.get('Country').touched)
              "
            >
              <span *ngIf="form.get('Country').errors.required"
                >Podaj państwo dostawy</span
              >
            </mat-error>
          </mat-form-field>
        </div>

        <hr />

        <div class="row">
          <mat-form-field appearance="fill">
            <mat-label i18n>Reference</mat-label>

            <input
              matInput
              type="text"
              placeholder="Reference"
              formControlName="Reference"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>CustAccount</mat-label>

            <input
              matInput
              type="text"
              placeholder="CustAccount"
              formControlName="CustAccount"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>State</mat-label>
            <input
              matInput
              type="text"
              placeholder="State"
              formControlName="State"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>ItemGrp</mat-label>
            <input
              matInput
              type="text"
              placeholder="ItemGrp"
              formControlName="ItemGrp"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field appearance="fill">
            <mat-label i18n>AvailableStock</mat-label>
            <input
              matInput
              type="text"
              placeholder="AvailableStock"
              formControlName="AvailableStock"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>DlvMode</mat-label>
            <input
              matInput
              type="text"
              placeholder="DlvMode"
              formControlName="DlvMode"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>Volume</mat-label>
            <input
              matInput
              type="text"
              placeholder="Volume"
              formControlName="Volume"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>InventRefType</mat-label>
            <input
              matInput
              type="text"
              placeholder="InventRefType"
              formControlName="InventRefType"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="fill">
            <mat-label i18n>InventRefNo</mat-label>
            <input
              matInput
              type="text"
              placeholder="InventRefNo"
              formControlName="InventRefNo"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>ProdStatus</mat-label>
            <input
              matInput
              type="text"
              placeholder="ProdStatus"
              formControlName="ProdStatus"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>LatestSchedDate</mat-label>
            <input
              matInput
              type="date"
              placeholder="LatestSchedDate"
              formControlName="LatestSchedDate"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>ShipmentId</mat-label>
            <input
              matInput
              type="text"
              placeholder="ShipmentId"
              formControlName="ShipmentId"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="fill">
            <mat-label i18n>TruckArrival</mat-label>
            <input
              matInput
              type="text"
              placeholder="TruckArrival"
              formControlName="TruckArrival"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>Note</mat-label>
            <input
              matInput
              type="text"
              placeholder="Note"
              formControlName="Note"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>Site</mat-label>
            <input
              matInput
              type="text"
              placeholder="Site"
              formControlName="Site"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>Pool</mat-label>
            <input
              matInput
              type="text"
              placeholder="Pool"
              formControlName="Pool"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field appearance="fill">
            <mat-label i18n>ItemGrpId</mat-label>
            <input
              matInput
              type="text"
              placeholder="ItemGrpId"
              formControlName="ItemGrpId"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>BuyerGroup</mat-label>
            <input
              matInput
              type="text"
              placeholder="BuyerGroup"
              formControlName="BuyerGroup"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>Warehouse</mat-label>
            <input
              matInput
              type="text"
              placeholder="Warehouse"
              formControlName="Warehouse"
            />
          </mat-form-field>
        </div>

        <div class="buttons">
          <button mat-raised-button type="submit" [disabled]="!form.valid">
            <mat-icon>save</mat-icon>
            <ng-container i18n>Zapisz zamówienie</ng-container>
            <mat-icon *ngIf="isLoading">
              <mat-spinner class="progress" color="accent" diameter="24">
              </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
