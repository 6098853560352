import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FileResponse } from '../../lib/communication/file.response';
import { HttpService } from '../../services/http.service';
import { LoadComponentService } from '../../services/load-component.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService extends HttpService {
  constructor(
    private loadComponentService: LoadComponentService,
    protected http: HttpClient
  ) {
    super(http);
  }

  close() {
    this.loadComponentService.clear('order/import/import.service.ts');
  }

  uploadFile(
    file: File,
    projectId: string,
    actionType: string
  ): Observable<FileResponse> {
    const formData = new FormData();
    formData.append('orders', file);
    formData.append('actionType', actionType);
    formData.append('projectId', projectId);
    return this.http
      .post<FileResponse>(
        environment.apiUrl + '/database/order/upload-orders',
        formData,
        {}
      )
      .pipe(map((response) => new FileResponse(response)));
  }
}
