import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  take,
  map,
  Subject,
  catchError,
  mergeMap,
  shareReplay,
  merge
} from 'rxjs';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { Vehicle } from './vehicle';
import { VehicleFactory } from './vehicle-factory';
import { VehicleType } from './vehicle-type';
import { SpaceFactory } from '../space/lib/space-factory';
import { ProfileService } from 'src/app/services/profile.service';
import { UiService } from 'src/app/services/ui.service';
import { Vector3 } from 'three';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends HttpService {
  // private url = environment.apiUrl + '/database/container/vehicle/list';
  protected url = environment.apiUrl + '/database/vehicle';
  protected listUrl = this.url + '/list';

  protected sortByProp = 'volume';
  private cache$: Observable<Vehicle[]>;
  private update$ = new Subject<void>();
  private updates$: Observable<Vehicle[]>;

  constructor(
    protected http: HttpClient,
    protected vehicleFactory: VehicleFactory,
    protected profileService: ProfileService,
    protected uiService: UiService
  ) {
    super(http);
    this.listUrl = this.url + '/list';
    this.updates$ = this.update$.pipe(mergeMap(() => this.fetchVehicles()));
  }

  get vehicles$(): Observable<Vehicle[]> {
    if (!this.cache$) {
      this.cache$ = this.fetchVehicles().pipe(shareReplay(1));
    }
    return merge(this.cache$, this.updates$).pipe(
      map((vehicles) => this.sortedBy(vehicles, this.sortByProp))
    );
  }

  getByType(type: VehicleType): Observable<Vehicle[]> {
    return this.vehicles$.pipe(
      map((list) =>
        list.filter((x) => {
          const fiteringType = type ? type.type : '-';
          if (fiteringType === 'car') {
            return x.type === 'bus' || x.type === 'truck';
          }
          return x.type === fiteringType;
        })
      )
    );
  }

  public update() {
    this.cache$ = null;
    this.update$.next();
  }

  protected refresh() {
    this.cache$ = null;
  }

  private fetchVehicles(): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(this.listUrl).pipe(
      take(1),
      map((results) => results.map((r) => this.vehicleFactory.recreate(r))),
      // tap((cuboids) => // console.log('cuboids fetched ' + cuboids.length)),
      catchError(this.handleError('fetchVehicles', []))
    );
  }

  public getLargestVehicle(
    notIn: Vehicle[] = [],
    minSpaceDimension: Vector3 = null
  ): Observable<Vehicle> {
    console.log(
      `get largest vehicle, skip ${notIn.length}, minDimension`,
      minSpaceDimension
    );
    return this.vehicles$.pipe(
      map((list) =>
        list
          .filter((v) => notIn.findIndex((vv) => vv.uuid === v.uuid) < 0)
          .filter((v) => {
            if (!minSpaceDimension) {
              return true;
            }
            for (const space of v.enabledSpaces) {
              if (
                space.length >= minSpaceDimension.x &&
                space.width >= minSpaceDimension.z &&
                space.height >= minSpaceDimension.y
              ) {
                return true;
              }
            }
            return false;
          })
          .sort((a, b) => (a.volume > b.volume ? 1 : -1))
          .slice()
          .pop()
      )
    );
  }

  public getVehicleTypes(filter: boolean = true): VehicleType[] {
    const types: VehicleType[] = [];
    const settings = this.profileService.currentSettings;
    if (!filter || settings.useVehicles()) {
      types.push(
        new VehicleType({
          type: 'car',
          name: $localize`Pojazd`,
          icon: 'ic_cargo2.png',
          canCreate: true
        })
      );
    }
    if (!filter || settings.useContainers()) {
      types.push(
        new VehicleType({
          type: 'container',
          name: $localize`Kontener`,
          icon: 'ic_cargo2.png',
          canCreate: true
        })
      );
    }
    /*if (!filter || settings.usePallets()) {
      types.push(
        new VehicleType({
          type: 'other',
          name: $localize`Paleta`,
          icon: 'ic_cargo2.png',
          canCreate: true
        })
      );
    }*/

    if (
      this.uiService.warehousesEnabled &&
      (!filter || settings.useWarehouses())
    ) {
      types.push(
        new VehicleType({
          type: 'warehouse',
          name: $localize`Magazyn`,
          icon: 'ic_cargo2.png',
          canCreate: false
        })
      );
    }
    console.log('vehicle types', types);
    return types;
  }

  private sortedBy(items: Vehicle[], prop: string): Vehicle[] {
    return items.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }
}
