import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';

import { ProjectListMode, UiService } from '../services/ui.service';
import { ProfileService } from '../services/profile.service';
import { ProfileInterface } from '../lib/model/profile';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.less']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  showHelp = true;

  private readonly helpCookieName = 'prl-help';
  private unsubscribe$ = new Subject<void>();
  protected ProjectListMode = ProjectListMode;
  protected activeTab$: Observable<ProjectListMode>;
  protected profile$: Observable<ProfileInterface>;

  constructor(protected ui: UiService, private profileService: ProfileService) {
    this.activeTab$ = ui.projectListMode$;
    this.profile$ = profileService.getProfile();
  }

  ngOnInit(): void {
    // console.log('ProjectsComponent onInit');

    if (localStorage.getItem(this.helpCookieName)) {
      this.showHelp = false;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hideHelp() {
    localStorage.setItem(this.helpCookieName, new Date().getTime() + '');
    this.showHelp = false;
  }

  protected showProjects() {
    this.ui.setProjectListMode(ProjectListMode.PROJECTS);
  }

  protected showOrders() {
    this.ui.setProjectListMode(ProjectListMode.ORDERS);
  }

  protected showOfficialOrders() {
    this.ui.setProjectListMode(ProjectListMode.OFFICIAL_ORDERS);
  }

  protected showReports() {
    this.ui.setProjectListMode(ProjectListMode.REPORTS);
  }
}
