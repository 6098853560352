<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <ng-container *ngIf="!load">
        <h1 i18n>Zdefiniuj własny ładunek</h1>
      </ng-container>
      <ng-container *ngIf="load">
        <h1 i18n>Edytuj {{ load.name }}</h1>
      </ng-container>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <app-name
        #name
        [events]="eventsSubject.asObservable()"
        [default]="defaultName"
      ></app-name>
      <app-cuboid-form
        #dimensions
        [events]="eventsSubject.asObservable()"
        (addLoadEvent)="create($event)"
        [mode]="formMode"
        [type]="load ? 'edit' : 'create'"
        [item]="load"
      ></app-cuboid-form>
      <app-weight
        #weight
        [mode]="formMode"
        [default]="load ? ui.getWeightInCurrentUnit(load.weight) : null"
      ></app-weight>
      <app-flooring
        #flooring
        [mode]="load ? 'edit' : 'create'"
        [item]="load"
      ></app-flooring>
      <app-load-rotation
        #rotation
        [mode]="load ? 'edit' : 'create'"
        [item]="load"
      ></app-load-rotation>
      <!--<app-color #color></app-color> -->

      <div class="buttons">
        <button mat-raised-button (click)="onSubmit()" cdkFocusInitial>
          <mat-icon>save</mat-icon>
          <ng-container i18n>Zapisz</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
