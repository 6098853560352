import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { LoadGroup } from 'src/app/lib/model/load-group/load-group';
import { LoadGroupList } from 'src/app/lib/model/load-group/load-group-list';
import { SceneService } from 'src/app/scene/scene.service';
import { PendingLoadsService } from '../lib/pending-loads.service';
import { LoadEventObject } from 'src/app/load/lib/load-event-object';
import { ProfileService } from 'src/app/services/profile.service';
import { ProjectsService } from 'src/app/projects';
import { MessageService } from 'src/app/messenger/message.service';
import { Load } from 'src/app/load/lib/load';
import { TrailerPositioner } from 'src/app/raycasting/lib/trailer-positioner';
import { UiService } from 'src/app/services/ui.service';
import { ContextService } from 'src/app/vehicle/context/context.service';

@Component({
  selector: 'app-loadings-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  loads: LoadGroupList;
  pending$: Observable<LoadGroupList>;
  showPendingList$: Observable<boolean>;

  protected reorderMode = false;
  protected canLoadToNewVehicle = false;

  constructor(
    private sceneService: SceneService,
    private profileService: ProfileService,
    private pendingLoads: PendingLoadsService,
    private projectsService: ProjectsService,
    private messageService: MessageService,
    private trailerPositioner: TrailerPositioner,
    private uiService: UiService,
    private contextService: ContextService
  ) {
    this.loads = new LoadGroupList(LoadGroup);
    this.pending$ = pendingLoads.loads$().pipe(takeUntil(this.unsubscribe$));
    this.showPendingList$ = pendingLoads.showList$();
  }

  ngOnInit(): void {
    this.sceneService
      .getVehicleContext()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loads = this.sceneService.getGroupedLoads();
      });

    this.pending$.subscribe((list) => {
      console.log('pending list updated', list);
    });

    this.sceneService
      .getVehicleContext()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        this.canLoadToNewVehicle = context?.isVehicleChosen();
      });
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

  protected toggleReorderMode() {
    this.reorderMode = !this.reorderMode;
    if (this.reorderMode) {
      this.loads.loads.forEach((group) => (group.expanded = false));
    } else {
      this.uiService.setLoading(true);
      this.sceneService
        .saveContextChanges(this.sceneService.context)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.uiService.setLoading(false);
        });
    }
  }

  protected orderChanged(event: CdkDragDrop<LoadGroup[]>) {
    moveItemInArray(this.loads.loads, event.previousIndex, event.currentIndex);
    this.loads.loads.forEach((group, index) => {
      group.list.forEach((load) => (load.displayOrder = index * 10));
    });
  }

  protected loadPending() {
    const error = this.trailerPositioner.preLoadChecks(
      this.pendingLoads.getCurrentLoads().loads.flatMap((g) => g.list)
    );
    if (error) {
      this.messageService.systemMessage('default', {
        title: error.title,
        body: error.body
      });
      //return;
    }
    this.uiService.setLoadingNow(true);
    this.sceneService
      .addPendingLoads(this.pendingLoads.getCurrentLoads())
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.profileService.fetchProfile()),
        switchMap((profile) => {
          this.profileService.updateProfile(profile);
          const newPending = this.pendingLoads
            .getCurrentLoads()
            .removeSpecificLoads(this.sceneService.context.getLoadedLoads());
          return this.pendingLoads.replaceList(newPending);
        })
      )
      .subscribe((project) => {
        this.uiService.setLoadingNow(false);
      });
  }

  protected loadPendingToNewVehicle() {
    const error = this.trailerPositioner.preLoadChecks(
      this.pendingLoads.getCurrentLoads().loads.flatMap((g) => g.list)
    );
    if (error) {
      this.messageService.systemMessage('default', {
        title: error.title,
        body: error.body
      });
      //return;
    }
    this.uiService.setLoadingNow(true);
    const emptyContext = this.contextService.createEmptyContext(false);
    this.contextService.addAndActivate(emptyContext);
    this.sceneService
      .addPendingLoads(this.pendingLoads.getCurrentLoads(), emptyContext)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.profileService.fetchProfile()),
        switchMap((profile) => {
          this.profileService.updateProfile(profile);
          return of(profile);
        }),
        switchMap(() => {
          const newPending = this.pendingLoads
            .getCurrentLoads()
            .removeSpecificLoads(emptyContext.getLoadedLoads());
          return this.pendingLoads.replaceList(newPending);
        })
      )
      .subscribe((project) => {
        this.uiService.setLoadingNow(false);
        this.sceneService.refreshView();
      });
  }

  private unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
