<div class="canvas-wrapper">
  <canvas
    #canvas
    id="canvas"
    style="width: 100%; border: 1px solid red"
  ></canvas>
  <div class="list" [class.show]="showList">
    <button class="show-toggle" (click)="showList = !showList">
      <ng-container *ngIf="showList">‹</ng-container>
      <ng-container *ngIf="!showList">›</ng-container>
    </button>
    <div class="elements">
      <div class="divider"></div>
      <button (click)="addModelFromName('oven')">Add oven</button>
      <button (click)="addModelFromName('tomograph')">Add tomograph</button>
      <div class="divider"></div>
      <button (click)="add1150S(1)">Add1150S</button>
      <button (click)="add1150S(10)">10 x Add1150S</button>
      <button (click)="add1150S(50)">50 x Add1150S</button>
      <button (click)="add1150S(100)">100 x Add1150S</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('1150S')">1150S</button>
      <button (click)="addModelFromName('1205XLUB')">1205XLUB</button>
      <button (click)="addModelFromName('1205STILT')">1205STILT</button>
      <button (click)="addModelFromName('1205STILT', '1203S B')">
        1203S B
      </button>

      <div class="divider"></div>
      <button (click)="addModelFromName('2205B')">2205B</button>
      <button (click)="addModelFromName('2205UB')">2205UB</button>
      <button (click)="addModelFromName('2260UB')">2260UB</button>
      <button (click)="addModelFromName('2260ST')">2260ST</button>
      <button (click)="addModelFromName('2260x153UB')">2260x153UB</button>
      <button (click)="addModelFromName('2270S')">2270S</button>
      <button (click)="addModelFromName('2270SXL', '2270S XL')">
        2270S XL
      </button>
      <button (click)="addModelFromName('2300SB')">2300SB</button>
      <button (click)="addModelFromName('2300STB')">2300STB</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('3150SUB')">3150SUB</button>
      <button (click)="addModelFromName('3205S')">3205S</button>
      <button (click)="addModelFromName('3251SB')">3251SB</button>
      <button (click)="addModelFromName('3251STB')">3251STB</button>
      <button (click)="addModelFromName('3253S')">3253S</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('4260SB')">4260SB</button>
      <button (click)="addModelFromName('4260SB', '4260A ')">4260A</button>
      <button (click)="addModelFromName('4260STB')">4260STB</button>
      <button (click)="addModelFromName('4310STB')">4310STB</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('5325')">5325</button>
      <button (click)="addModelFromName('5375')">5375</button>
      <button (click)="addModelFromName('5420')">5420</button>
      <button (click)="addModelFromName('5420W')">5420W</button>
      <button (click)="addModelFromName('5520W')">5520W</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('6420')">6420</button>
      <button (click)="addModelFromName('6520')">6520</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('ATHB')">ATHB</button>
      <button (click)="addModelFromName('2513GT')">2513GT</button>
      <button (click)="addModelFromName('3023GT')">3023GT</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('BOAT16')">BOAT16</button>
      <button (click)="addModelFromName('BOAT18')">BOAT18</button>
      <button (click)="addModelFromName('BOAT20')">BOAT20</button>
      <button (click)="addModelFromName('BOAT20-6m')">BOAT20-6m</button>
      <button (click)="addModelFromName('BOAT22')">BOAT22</button>
      <button (click)="addModelFromName('BOAT24')">BOAT24</button>
      <button (click)="addModelFromName('BOAT26')">BOAT26</button>
      <button (click)="addModelFromName('BOAT30')">BOAT30</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('CARGO7300')">CARGO 7300</button>
      <button (click)="addModelFromName('CARGO7260x153')">
        CARGO 7260x153
      </button>
      <button (click)="addModelFromName('CARGO7260x128')">
        CARGO 7260x128
      </button>
      <button (click)="addModelFromName('CARGO7350')">CARGO 7350</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('MT2600')">MT2600</button>
      <button (click)="addModelFromName('MT3080')">MT3080</button>
      <button (click)="addModelFromName('MT3651')">MT3651</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('TT5325ATB')">TT5325ATB</button>
      <button (click)="addModelFromName('TT5375ATB')">TT5375ATB</button>
      <button (click)="addModelFromName('BT4260SB')">BT4260SB</button>
      <button (click)="addModelFromName('BT4260STB')">BT4260STB</button>
      <button (click)="addModelFromName('BT4310STB')">BT4310STB</button>

      <div class="divider"></div>
      <button (click)="addModelFromName('UNI2503UB')">UNI2503UB</button>
      <button (click)="addModelFromName('UNI2503UC')">UNI2503UC</button>

      <div class="divider"></div>

      <button (click)="addCube()">AddCube</button>
      <button (click)="addCylinder()">AddCylinder</button>
      <button (click)="addSphere()">AddSphere</button>
      <button (click)="addCone()">AddCone</button>
      <button (click)="addIcosahedron()">AddIcosahedron</button>

      <div class="divider"></div>
      <button (click)="reset()">reset</button>
    </div>
  </div>
</div>
