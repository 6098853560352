import { Component } from '@angular/core';
import { Order } from '../lib/order';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { SceneService } from 'src/app/scene/scene.service';
import { ProfileService } from 'src/app/services/profile.service';
import { OrderService } from '../order.service';
import { Project, ProjectsService } from 'src/app/projects';

@Component({
  selector: 'app-pending-orders-list',
  templateUrl: './pending-list.component.html',
  styleUrls: ['./pending-list.component.less']
})
export class PendingListComponent {
  pending$: Observable<Order[]>;
  showPendingList$: Observable<boolean>;
  private pendingOrders: Order[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private sceneService: SceneService,
    private orderService: OrderService,
    private profileService: ProfileService,
    private projectService: ProjectsService
  ) {
    this.pending$ = orderService.pendingOrders$;
    this.showPendingList$ = orderService.showPendingList$();
  }

  ngOnInit(): void {
    this.pending$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => (this.pendingOrders = list));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected loadPending() {
    console.log('order/pending-list.component.ts loadPending');
    if (this.pendingOrders.length === 0) {
      return;
    }
    const profile = this.profileService.currentProfile;
    const newProject = new Project({
      userId: profile.userId,
      name: this.pendingOrders[0].Number
      //orderUuid: this.pendingOrders[0].Uuid
    });
    this.projectService
      .addProject(newProject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((project) => {
        this.sceneService.requestPlacementGenerationForOrders(
          this.pendingOrders
        );
        /*this.pendingOrders.forEach((order) => {
          order.ProjectId = project.uuid;
        });
        this.orderService
          .addOrdersToProject(project.uuid, this.pendingOrders)
          .subscribe();*/
        this.projectService.currentProject = project;
      });
  }

  protected remove(order: Order) {
    this.orderService.removeOrderFromPendingList(order);
  }

  protected close() {
    this.orderService.updatePendingOrdersList([]);
  }
}
