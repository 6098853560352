import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./../../load.component.less', './amount.component.less']
})
export class AmountComponent implements OnInit {
  public form: UntypedFormGroup;

  @Input() cnt: number = 1;
  @Input() type: string = 'inline';

  constructor(private formBuilder: UntypedFormBuilder, private ui: UiService) {}

  ngOnInit(): void {
    this.createForm();
  }

  public getValue(): number {
    return this.form.get('cnt').value;
  }

  public setValue(val: number) {
    this.form.get('cnt').setValue(val);
  }

  public isValid() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  protected get label(): string | boolean {
    if (this.type === 'stacked') {
      return $localize`Ile sztuk`;
    }
    return false;
  }

  private createForm() {
    this.form = this.formBuilder.group({
      cnt: [
        this.cnt,
        Validators.compose([Validators.required, Validators.max(3000)])
      ]
    });
  }
}
