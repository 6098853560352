import {
	EventDispatcher,
	Matrix4,
	Plane,
	Raycaster,
	Vector2,
	Vector3
} from 'three';

const _plane = new Plane();
const _raycaster = new Raycaster();

const _pointer = new Vector2();
const _offset = new Vector3();
const _diff = new Vector2();
const _previousPointer = new Vector2();
const _intersection = new Vector3();
const _worldPosition = new Vector3();
const _inverseMatrix = new Matrix4();

const _up = new Vector3();
const _right = new Vector3();


class DragControls extends EventDispatcher {



	constructor(_objects, _camera, _domElement) {

		super();

		_domElement.style.touchAction = 'none'; // disable touch scroll

		let _selected = null, _hovered = null;
		let moveEnabled = true;
		let multiModeEnabled = true;

		const _intersections = [];



		const scope = this;

		function activate() {
			console.log('drag controls activated');
			_domElement.addEventListener('pointermove', onPointerMove);
			_domElement.addEventListener('pointerdown', onPointerDown);
			_domElement.addEventListener('pointerup', onPointerCancel);
			_domElement.addEventListener('pointerleave', onPointerCancel);
			document.addEventListener('keydown', onDocumentKeyDown);
			document.addEventListener('keyup', onDocumentKeyUp);
		}

		function deactivate() {

			_domElement.removeEventListener('pointermove', onPointerMove);
			_domElement.removeEventListener('pointerdown', onPointerDown);
			_domElement.removeEventListener('pointerup', onPointerCancel);
			_domElement.removeEventListener('pointerleave', onPointerCancel);
			document.removeEventListener('keydown', onDocumentKeyDown);
			document.removeEventListener('keyup', onDocumentKeyUp);
			setCursor('');

		}

		function dispose() {

			deactivate();

		}

		function setCursor(type) {
			['pointer', 'move', 'magnet', 'magnet-minus'].forEach((t) => {
				type !== t && _domElement.classList.remove('cursor-' + t);
			});
			_domElement.classList.add('cursor-' + type);
		}

		function getObjects() {

			return _objects;

		}

		function getRaycaster() {

			return _raycaster;

		}

		function onPointerMove(event) {
			if (scope.enabled === false) return;
			updatePointer(event);

			_raycaster.setFromCamera(_pointer, _camera);

			if (_selected && scope.enabled && moveEnabled) {
				if (scope.mode === 'translate') {
					if (_raycaster.ray.intersectPlane(_plane, _intersection)) {
						_selected.position.copy(_intersection.sub(_offset).applyMatrix4(_inverseMatrix));
					}
				} else if (scope.mode === 'rotate') {

					_diff.subVectors(_pointer, _previousPointer).multiplyScalar(scope.rotateSpeed);
					if (scope.rotateAxis === 'ALL') {
						_selected.rotateOnWorldAxis(_up, _diff.x);
						//_selected.rotateOnWorldAxis(_right.normalize(), - _diff.y);
					} else if (scope.rotateAxis === 'Y') {
						//_selected.rotateOnWorldAxis(_up, _diff.x);
						_selected.rotateOnWorldAxis(_right.normalize(), - _diff.y);
					}

				}

				scope.dispatchEvent({ type: 'drag', object: _selected });
				_previousPointer.copy(_pointer);

				return;

			}

			// hover support

			if (event.pointerType === 'mouse' || event.pointerType === 'pen') {

				_intersections.length = 0;

				_raycaster.setFromCamera(_pointer, _camera);
				_raycaster.intersectObjects(_objects, scope.recursive, _intersections);

				if (_intersections.length > 0) {
					//console.log('hover intersections', _intersections.length);
					let object = _intersections[0].object;
					let stepUp = 5;
					//console.log('selected', object?.name);
					while (stepUp > 0 && object.name !== 'load') {
						object = object.parent;
						stepUp--;
					}
					if (object && object.parent && object.parent.name === 'load') { // jeszcze raz, bo na zwykłej scenie mesh to też "load"
						object = object.parent;
					}
					if (stepUp == 0 && object.name !== 'load') {
						console.error('No parent named "load" found for hovered object.');
					}

					_plane.setFromNormalAndCoplanarPoint(_camera.getWorldDirection(_plane.normal), _worldPosition.setFromMatrixPosition(object.matrixWorld));

					if (_hovered !== object && _hovered !== null) {

						scope.dispatchEvent({ type: 'hoveroff', object: _hovered });
						//console.log('hoveroff');

						setCursor('auto');
						_hovered = null;

					}

					if (_hovered !== object) {
						//console.log('hoveron');
						scope.dispatchEvent({ type: 'hoveron', object: object });
						setCursor('pointer');
						_hovered = object;

					}

				} else {

					if (_hovered !== null) {
						//console.log('hoveroff 2');
						scope.dispatchEvent({ type: 'hoveroff', object: _hovered });
						setCursor('auto');
						_hovered = null;

					}

				}

			}
			_previousPointer.copy(_pointer);

		}

		function onDocumentKeyDown(event) {
			if (multiModeEnabled && event.ctrlKey) {
				//if (event.shiftKey) {
				//  setCursor('magnet-minus');
				//} else {
				setCursor('magnet');
				//}
			} else {
				setCursor('auto');
			}
			if (event.key == scope.switchModeKey) {
				scope.mode = (scope.mode === 'translate' ? 'rotate' : 'translate');
				console.log('switch drag mode to ', scope.mode);
			}
			if (scope.mode === 'rotate' && event.key === 'Y') {
				scope.rotateAxis = (scope.rotateAxis === 'ALL' ? 'Y' : 'ALL');
				console.log('rotation mode changed to', scope.rotateAxis)
			}
		}

		function onDocumentKeyUp(event) {
			setCursor('auto');
		}

		function getIntersectionsForMouseEvent(event) {
			_intersections.length = 0;
			_raycaster.setFromCamera(_pointer, _camera);
			_raycaster.intersectObjects(_objects, true, _intersections);
			//console.log('intersection check', _objects.length,);
			if (_intersections.length > 0) {
				//console.log('intersection');
				_selected =
					scope.transformRoot === true
						? _objects[0]
						: _intersections[0].object;
				let stepUp = 5;
				//console.log('selected', _selected?.name);
				while (stepUp > 0 && _selected.name !== 'load') {
					_selected = _selected.parent;
					stepUp--;
				}
				if (_selected && _selected.parent && _selected.parent.name === 'load') { // jeszcze raz, bo na zwykłej scenie mesh to też "load"
					_selected = _selected.parent;
				}
				if (stepUp == 0 && _selected.name !== 'load') {
					console.error('No parent named "load" found for selected object.');
				}
				_plane.setFromNormalAndCoplanarPoint(_camera.getWorldDirection(_plane.normal), _worldPosition.setFromMatrixPosition(_selected.matrixWorld));

				if (_raycaster.ray.intersectPlane(_plane, _intersection)) {
					if (scope.mode == 'translate') {
						_inverseMatrix.copy(_selected.parent.matrixWorld).invert();
						_offset.copy(_intersection).sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
					} else if (scope.mode === 'rotate') {

						// the controls only support Y+ up
						_up.set(0, 1, 0);//.applyQuaternion(_camera.quaternion).normalize();
						_right.set(1, 0, 0);//.applyQuaternion(_camera.quaternion).normalize();


					}

				}
				return true;
			}
			return false;
		}


		function onPointerDown(event) {

			if (scope.enabled === false) return;

			updatePointer(event);
			if (event.button === 0) { // left
				const intersects = getIntersectionsForMouseEvent(event);
				//console.log('mouse left down', intersects);

				if (intersects) {
					setCursor('move');
					scope.dispatchEvent({ type: 'dragstart', object: _selected });
				}
				scope.dispatchEvent({
					type: 'mouseLeftButtonDown',
					mouseEvent: event,
					object: _selected
				});
			} else if (event.button == 2) { // right
				const intersects = getIntersectionsForMouseEvent(event);

				if (intersects) {
					setCursor('move');

					scope.dispatchEvent({
						type: 'mouseRightButtonDown',
						mouseEvent: event,
						object: _selected
					});
					_selected = undefined;
				}
			}
			_previousPointer.copy(_pointer);
		}

		function onPointerCancel(event) {

			if (scope.enabled === false) return;

			if (_selected) {
				scope.dispatchEvent({ type: 'mouseup', object: _selected, mouseEvent: event });
				scope.dispatchEvent({ type: 'dragend', object: _selected });

				_selected = null;

			}
			if (!event.ctrlKey) {
				setCursor(_hovered ? 'pointer' : 'auto');
			}

		}

		function updatePointer(event) {

			const rect = _domElement.getBoundingClientRect();

			_pointer.x = (event.clientX - rect.left) / rect.width * 2 - 1;
			_pointer.y = - (event.clientY - rect.top) / rect.height * 2 + 1;

		}

		function findGroup(obj, group = null) {

			if (obj.isGroup) group = obj;

			if (obj.parent === null) return group;

			return findGroup(obj.parent, group);

		}

		function enableMove(val) {
			moveEnabled = val;
		}

		function enableMultiMode(val) {
			multiModeEnabled = val;
		}


		activate();

		// API

		this.enabled = true;
		this.recursive = true;
		this.transformGroup = false;
		this.mode = 'translate';
		this.rotateSpeed = 5;
		this.switchModeKey = 'R';
		this.rotateAxis = 'ALL';

		this.activate = activate;
		this.deactivate = deactivate;
		this.dispose = dispose;
		this.getObjects = getObjects;
		this.getRaycaster = getRaycaster;
		this.enableMove = enableMove;
		this.enableMultiMode = enableMultiMode;
	}

}

export { DragControls };
