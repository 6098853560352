import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  delay,
  filter,
  interval,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timer
} from 'rxjs';
import { HttpCancelService } from '../lib/communication/http-cancel.service';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();
  private readonly longWorkAfterMs = 5 * 1000;
  private readonly timeoutTime = 110 * 1000;

  protected showLongWorkInfo = false;
  protected timeoutCounter: number;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private httpCancelService: HttpCancelService,
    private uiService: UiService
  ) {}

  ngOnInit(): void {
    console.log('Loader component init');
    this.showLongWorkInfo = false;

    this.uiService.showLoader$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((show) => {
          if (show) {
            return of(true).pipe(
              switchMap(() => this.createLongWorkNotification())
            );
          } else {
            this.timeoutCounter = this.timeoutTime;
            this.showLongWorkInfo = false;
            return of(null);
          }
        })
      )
      .subscribe((timeout) => {});
  }

  private createLongWorkNotification() {
    return this.uiService.showLoader$.pipe(
      takeUntil(this.unsubscribe$),
      filter((show) => show === true),
      delay(this.longWorkAfterMs),
      filter((show) => show === true),
      tap(() => {
        this.timeoutCounter = this.timeoutTime;
        this.showLongWorkInfo = true;
      }),
      switchMap(() => this.startCountdown())
    );
  }

  private startCountdown(): Observable<number> {
    return interval(1000).pipe(
      startWith(0),
      tap((val) => {
        this.timeoutCounter = Math.max(0, this.timeoutCounter - 1000);
      }),
      takeWhile(() => this.timeoutCounter > 0),
      tap(() => {
        if (this.timeoutCounter === this.timeoutTime) {
          this.showLongWorkInfo = true;
        }
      })
    );
  }

  ngOnDestroy(): void {
    console.log('Loader component destroy');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  cancel(): void {
    this.httpCancelService.cancelPendingRequests();
    this.close.emit();
  }
}
