import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, map, Observable, Subject } from 'rxjs';
import { PositionerResponse } from '../../lib/communication/positioner.response';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { ContextExport } from './lib/context-export';
import { MessageService } from '../../messenger/message.service';
import { Project } from '../../projects/lib/project';
import { CalculationService } from '../../api/calculation.service';
import { ContextFactory } from './lib/context-factory';
import { Constants } from 'src/app/config/constants';
import { StatusResponse } from 'src/app/lib/communication/status.response';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  context$ = new BehaviorSubject<VehicleContext>(
    this.contextFactory.createForVehicle(null)
  );
  contexts = new BehaviorSubject<VehicleContext[]>([]);
  private project: Project;
  private afterContextDrawn$ = new Subject<VehicleContext>();
  private exportProcess$ = new Subject<VehicleContext[]>();
  private exportEnded$ = new Subject<ContextExport[]>();

  constructor(
    private calculationService: CalculationService,
    private messenger: MessageService,
    private contextFactory: ContextFactory
  ) {
    // this.context.value = new VehicleContext();
    // console.log('context.service.ts: constructor');
  }

  get currentContext$(): Observable<VehicleContext> {
    return this.context$.asObservable();
  }

  init(res: PositionerResponse[]) {
    // // console.log('context.service.ts init called', res, vehicles);
    if (res && res.length > 0) {
      const contexts: VehicleContext[] = res.map((calc) =>
        this.reindexLoads(this.contextFactory.fromPositionerResponse(calc))
      );
      console.log('context.service.ts: context list initialized', contexts);
      this.updateContextList(contexts);
      this.setContext(contexts[0]);
    } else {
      this.updateContextList([]);
      this.createEmptyContext();
    }
  }

  public updateProject(project: Project) {
    // console.log('context.service.ts: current project changed', project);
    this.project = project;
  }

  public createEmptyContext(add = true) {
    const context = this.contextFactory.createForVehicle(null);

    context.setProjectId(this.project?.uuid);
    if (add) {
      this.setContext(context);
    }
    return context;
  }

  public setContext(context: VehicleContext) {
    this.context$.next(context);
  }

  public getCurrentContext(): VehicleContext {
    return this.context$.value;
  }

  public contextChanged(): Observable<VehicleContext> {
    return this.context$.asObservable();
  }

  public getAvailableVehicles(): Observable<Array<VehicleContext>> {
    return this.contexts.asObservable();
  }

  public addContext(context: VehicleContext) {
    this.updateContextList(this.contexts.value.concat([context]));
  }

  public updateContextList(contexts: VehicleContext[]) {
    this.contexts.next(
      contexts.sort((x, y) =>
        x.getInitialTimestamp() > y.getInitialTimestamp() ? 1 : -1
      )
    );
  }

  public createContext(context: VehicleContext): Observable<VehicleContext> {
    if (
      context === null ||
      !context.getVehicle() ||
      context.getVehicle().type === 'empty'
    ) {
      this.createEmptyContext();
      return EMPTY;
    } else {
      if (this.contexts.value.length >= Constants.MAX_CONTEXTS) {
        this.messenger.info({
          title: $localize`Limit rozmieszczeń`,
          body:
            $localize`Nie można dodać więcej rozmieszczeń w tym projekcie. ` +
            $localize`Aby rozmieszczać dalej, dodaj nowy projekt lub usuń starsze rozmieszczenia.`
        });
        return EMPTY;
      }
      context.setProjectId(this.project?.uuid);
      this.addAndActivate(context);
      return this.calculationService
        .saveContext(context)
        .pipe(map(() => context));
    }
  }

  public cloneContext(other: VehicleContext): Observable<VehicleContext> {
    const contextClone = this.contextFactory.clone(other);

    return this.calculationService
      .saveContext(contextClone)
      .pipe(map(() => contextClone));
  }

  public addAndActivate(context: VehicleContext) {
    this.addContext(context);
    this.setContext(context);
  }

  public removeAllContexts(): Observable<StatusResponse<void>> {
    if (this.project?.uuid) {
      return this.calculationService.removeAllContextsInProject(
        this.project?.uuid
      );
    } else {
      return EMPTY;
    }
  }

  public removeCurrentContext(): Observable<VehicleContext> {
    return this.removeContext(this.context$.value);
  }

  public removeContext(context: VehicleContext): Observable<VehicleContext> {
    return this.calculationService
      .removeContext(context.getUuid())
      .pipe(map(() => context));
  }

  public removeContextFromView(context: VehicleContext) {
    const idx = this.contexts.value.indexOf(context);
    const uuid = context.getUuid();
    const contexts = this.contexts.value;
    this.updateContextList(contexts.filter((x) => x.getUuid() !== uuid));
    // // console.log('Available Contexts', this.contexts.value.length);
    if (this.contexts.value.length === 0) {
      // // console.log('set context null');
      // this.setContext(null);
      this.createEmptyContext();
    } else {
      const ctx =
        this.contexts.value[Math.min(idx, this.contexts.value.length - 1)];

      this.setContext(ctx);
    }
  }

  public removeAllContextsFromView() {
    this.updateContextList([]);
    this.createEmptyContext();
  }

  public contextDrawn(context: VehicleContext) {
    this.afterContextDrawn$.next(context);
  }

  public getAfterContextDrawn() {
    return this.afterContextDrawn$.asObservable();
  }

  public startExport(contexts: VehicleContext[]) {
    this.exportProcess$.next(contexts);
  }

  public getExportProcess() {
    return this.exportProcess$.asObservable();
  }

  public endExport(exportData: ContextExport[]) {
    this.exportEnded$.next(exportData);
  }

  public onExportEnd(): Observable<ContextExport[]> {
    return this.exportEnded$.asObservable();
  }

  private handleResponseMessages(messages: string[], context: VehicleContext) {
    if (messages && messages.length > 0) {
      if (messages.indexOf('moved_outside_vehicle') >= 0) {
      } else if (messages.indexOf('space_found') >= 0) {
      } else if (messages.indexOf('space_not_found') >= 0) {
        context.setMessage('space_not_found');
      }
      if (
        context.getMessage() !== 'space_not_found' &&
        messages.indexOf('weight_limit') >= 0
      ) {
        context.setMessage('weight_limit');
      }
    }
  }

  public replaceContext(
    context: VehicleContext,
    switchToReplaced = true
  ): VehicleContext | null {
    let idx = this.contexts.value.findIndex(
      (x) => x.getUuid() === context.getUuid()
    );
    if (idx >= 0) {
      const updatedContext = this.contextFactory.copyInto(
        context,
        this.contexts.value[idx]
      );
      if (switchToReplaced) {
        this.setContext(updatedContext);
      }
      return updatedContext;
    }
    return null;
  }

  public replaceAndSave(
    old: VehicleContext,
    newContext: VehicleContext
  ): Observable<VehicleContext> {
    const updated = this.contextFactory.copyInto(newContext, old);
    return this.calculationService.saveContext(old).pipe(map(() => updated));
  }

  public handleResponse(
    response: PositionerResponse,
    clearMessages: boolean = false,
    disableSwitchToContext = false
  ): VehicleContext {
    console.log('Handle Response', response);
    const contextUuid = response.uuid;
    let context = this.contexts.value.find((x) => x.getUuid() === contextUuid);
    let addContextToList = false;
    if (context === undefined) {
      context = this.contextFactory.createForVehicle(response.vehicle);
      addContextToList = true;
    }

    context.setMessage('');

    this.handleResponseMessages(response.messages, context);

    if (clearMessages) {
      context.setMessage('');
    }

    let updatedContext = this.contextFactory.fromPositionerResponse(
      response,
      context
    );

    updatedContext = this.reindexLoads(updatedContext);

    // console.log('context.service.ts - updated context', updatedContext);

    if (addContextToList) {
      this.addContext(updatedContext);
    }
    if (!disableSwitchToContext) {
      this.setContext(updatedContext);
    }
    return updatedContext;
  }

  public canLoadAnyContextOnVehicle(): boolean {
    return this.contexts.value.some((context) =>
      context.canBeLoadedOnVehicle()
    );
  }

  public currentContextList(): VehicleContext[] {
    return this.contexts.value;
  }

  private reindexLoads(context?: VehicleContext): VehicleContext {
    if (context !== undefined && context !== null) {
      const loads = context
        .getExistingLoads()
        .sort((x, y) => (x.initialTimestamp > y.initialTimestamp ? 1 : -1));
      let idx = 1;
      for (const load of loads) {
        load.idx = idx;
        if (load.mesh?.obj) {
          load.mesh.obj.userData.idx = idx;
        }
        idx++;
      }
    }
    return context;
  }
}
