import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { VehicleContext } from '../lib/model/vehicle-context';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf/dist/jspdf.es.min';
import html2canvas from 'html2canvas/dist/html2canvas.esm';
import { User } from '../lib/model/user';
import { v4 as uuidv4 } from 'uuid';
import { CalculationService } from '../api/calculation.service';
import { IClipboardResponse } from 'ngx-clipboard';
import { MessageService } from '../messenger/message.service';
import { AuthService } from '../services/auth.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UiService } from '../services/ui.service';
import { ExcelExportService } from './excel-export.service';
import { ShareProject } from '../lib/model/share-project';
import { ProfileService } from '../services/profile.service';
import { Settings } from '../lib/model/settings';
import { ContextExport } from '../vehicle/context/lib/context-export';
import { ContextService } from '../vehicle/context/context.service';
import { ProjectsService } from '../projects/projects.service';
import { Project } from '../projects/lib/project';
import { FormComponent as ProjectCommentFormComponent } from '../project-comment/form/form.component';
import { ColorService } from '../services/color.service';
import { OrderService } from '../order/order.service';
import { MenuService } from '../menu/menu.service';

class Marker {
  public constructor(public color: string, public selected: boolean = false) {}
}

@Component({
  selector: 'app-pdf-export',
  templateUrl: './pdf-export.component.html',
  styleUrls: ['./pdf-export.component.less']
})
export class PdfExportComponent implements OnInit, OnDestroy {
  @ViewChild('pdf_container') pdfContainer: ElementRef;
  @ViewChild('cursor') cursor: ElementRef;
  @ViewChild(ProjectCommentFormComponent)
  commentFormComponent: ProjectCommentFormComponent;
  data: ContextExport[];
  dateObj: Date;
  date: string;
  year: number;
  shareLoading = false;
  shareUrl: string;
  showShareRow = false;
  user: Observable<User>;
  userObj: User;
  settings: Settings;
  project: Project;

  protected commentVisible = false;

  // scene views
  protected topView = false;
  protected sideFrontView = false;
  protected sideBackView = false;
  protected frontView = false;
  protected rearView = false;
  protected currentView = true;
  protected viewScale = 2.0;
  protected textScale = 3.0;

  protected markersVisible = false;
  protected markers: Marker[] = [
    new Marker('red'),
    new Marker('skyblue'),
    new Marker('yellow'),
    new Marker('green')
  ];

  protected get selectedMarker(): Marker {
    return this.markers.find((m) => m.selected);
  }

  private inExport = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private calculationService: CalculationService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<PdfExportComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private messenger: MessageService,
    auth: AuthService,
    public ui: UiService,
    private excelExport: ExcelExportService,
    private profileService: ProfileService,
    protected colors: ColorService,
    private projectsService: ProjectsService,
    private orderService: OrderService,
    private menuService: MenuService
  ) {
    this.data = data.data;
    const date = new Date();
    this.dateObj = date;
    this.date = datePipe.transform(date, 'dd.MM.yyyy');
    this.year = date.getFullYear();
    this.user = auth.getUser();
    this.project = projectsService.currentProject;
    this.commentVisible = this.project?.comment?.length > 0;
  }

  ngOnInit(): void {
    this.showShareRow = false;
    this.shareUrl = null;
    this.shareLoading = false;
    this.user.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.userObj = user;
    });
    this.profileService
      .getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.settings = profile.settings;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  private exportStarted() {
    this.ui.setLoading(true);
    this.inExport = true;
  }

  private exportEnded() {
    this.ui.setLoading(false);
    this.inExport = false;
  }

  private async addElementToPdf(
    pdf: jsPDF,
    element: HTMLElement,
    sizes: any
  ): Promise<void> {
    const canvas = await html2canvas(element as HTMLElement);
    const imgData = canvas.toDataURL('image/jpeg', 1.0);
    const imgProps = pdf.getImageProperties(imgData);
    const pageHeight = pdf.internal.pageSize.height;
    const width = pdf.internal.pageSize.getWidth() - 2 * sizes.sideMargin;
    const pdfHeight = (imgProps.height * width) / imgProps.width;
    if (sizes.currentY + pdfHeight > pageHeight) {
      pdf.addPage();
      sizes.currentY = sizes.topMargin;
    }
    pdf.addImage(
      imgData,
      'JPEG',
      sizes.sideMargin,
      sizes.currentY,
      width,
      pdfHeight
    );
    sizes.currentY += pdfHeight + sizes.sectionMargin;
  }

  async downloadPdf() {
    this.exportStarted();
    this.saveComment();
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: [210 * this.viewScale, 297 * this.viewScale]
    });
    const html: HTMLElement = this.pdfContainer.nativeElement;
    const htmlParts = html.querySelectorAll('.pdf-part');
    const sizes = {
      currentY: 10,
      topMargin: 10,
      sectionMargin: 10,
      sideMargin: 10
    };
    const htmlArray = Array.from(htmlParts);

    for (const element of htmlArray) {
      await this.addElementToPdf(pdf, element as HTMLElement, sizes);
    }

    pdf.save(this.getFilename('.pdf'), { returnPromise: true }).then(() => {
      this.exportEnded();
      this.close();
    });
  }

  downloadJPG() {
    this.exportStarted();
    this.saveComment();
    const html = this.pdfContainer.nativeElement;
    html2canvas(html).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const aLink = document.createElement('a');
      aLink.download = this.getFilename('.jpg');
      aLink.href = 'data:' + imgData;
      aLink.click();
      this.exportEnded();
      this.close();
    });
  }

  async saveToOrder() {
    this.exportStarted();
    this.saveComment();
    const html = this.pdfContainer.nativeElement;
    html2canvas(html).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      this.orderService
        .saveImage(this.project.orderUuid, imgData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.exportEnded();
          this.close();
          this.menuService.backToProjects();
        });
    });
  }

  async downloadXLS() {
    // console.log('download XLS', sheetName);
    this.exportStarted();
    this.saveComment();
    const excel = this.excelExport.createNewDocument();
    for (let index = 0; index < this.data.length; index++) {
      const contextData = this.data[index];
      const html: HTMLElement = this.pdfContainer.nativeElement;
      const htmlParts = html.querySelectorAll(
        '.view-vehicle-' + contextData.context.getVehicle().uuid
      );
      const htmlArray = Array.from(htmlParts);
      const images: string[] = [];
      for (const element of htmlArray) {
        const canvas = await html2canvas(element as HTMLElement);
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        images.push(imgData);
      }
      const sheetName = this.sheetName(contextData.context);
      const title =
        this.project?.toString() +
        ' - ' +
        $localize`Projekt załadunku z dnia` +
        ' ' +
        this.date +
        ' - ' +
        contextData.context.getVehicle().fullName;
      this.excelExport.addSheet(
        excel,
        sheetName,
        title,
        this.project,
        contextData.context,
        contextData.context.groupLoads(),
        images,
        this.userObj,
        this.settings,
        this.date
      );
    }
    const blob = await this.excelExport.getDocumentData(excel);
    const aLink = document.createElement('a');
    aLink.href = window.URL.createObjectURL(blob);
    aLink.download = this.getFilename('.xlsx');
    aLink.click();
    this.exportEnded();
    this.close();
  }

  share() {
    this.shareLoading = true;
    this.showShareRow = true;
    this.exportStarted();
    this.saveComment();
    const html = this.pdfContainer.nativeElement;
    html2canvas(html).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const request: ShareProject = {
        data: imgData,
        contentType: 'image/jpeg',
        uuid: uuidv4()
      };
      this.calculationService
        .shareProject(request)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.shareLoading = false;
          this.shareUrl = response.url;
          this.exportEnded();
        });
    });
  }

  urlCopied(event: IClipboardResponse) {
    this.messenger.info({
      title: $localize`Skopiowano URL`,
      body: $localize`Link do projektu znajduje się w schowku`
    });
    // console.log('event', event);
  }

  myAccount() {
    window.location.assign(environment.profileUrl);
  }

  get vehicleNames() {
    return this.data
      .map((data) => data.context.getVehicle().fullName)
      .join(', ');
  }

  private saveComment() {
    this.commentFormComponent?.save();
  }

  protected toggleComment() {
    this.commentVisible = !this.commentVisible;
    if (this.commentVisible) {
    }
  }

  protected commentSaved() {
    console.log('comment saved');
    if (this.inExport) {
      this.ui.setLoading(true);
    }
  }

  protected toggleMarkers() {
    if (!this.markersVisible) {
      this.markers.forEach((marker, index) => (marker.selected = index === 0));
    } else {
      this.markers.forEach((marker, index) => (marker.selected = false));
    }
    this.markersVisible = !this.markersVisible;
  }

  protected toggleSelectMarker(marker: Marker) {
    this.markers.forEach((marker) => (marker.selected = false));
    marker.selected = !marker.selected;
  }

  @HostListener('mousemove', ['$event'])
  markerAsCursor($event: MouseEvent) {
    if (!this.markersVisible) {
      return;
    }
    const x = $event.clientX;
    const y = $event.clientY;
    this.cursor.nativeElement.style.top = `${y}px`;
    this.cursor.nativeElement.style.left = `${x}px`;
  }

  markerClick($event: MouseEvent) {
    console.log('mouse click', $event.target);
    if (!this.markersVisible) {
      return;
    }
    if (
      $event.target &&
      ($event.target as HTMLElement).classList.contains('marker-point')
    ) {
      ($event.target as HTMLElement).remove();
      return;
    }
    const rect = ($event.target as HTMLElement).getBoundingClientRect();
    var x = $event.clientX - rect.left;
    var y = $event.clientY - rect.top;
    const point = this.createMarkerPoint(x, y);
    ($event.target as HTMLElement).appendChild(point);
  }

  private createMarkerPoint(x: number, y: number) {
    const point = document.createElement('div');
    point.classList.add('marker-point', this.selectedMarker.color);
    point.style.top = `${y}px`;
    point.style.left = `${x}px`;
    return point;
  }

  private sheetName(context: VehicleContext) {
    const vehicleName = context.getVehicle().fullName;
    const safeVehicleName = vehicleName
      .replace(/[ +]/gi, '_')
      .replace(/[^a-z0-9_]/gi, '');
    const dateStr = this.datePipe.transform(this.dateObj, 'ddMMyyyy');
    const currentEP = context
      .getStatistics()
      .reduce((total, next) => total + next.cnt, 0);
    return `${dateStr}_${safeVehicleName}_${currentEP}EP`;
  }

  private getFilename(ext: string) {
    const vehicleName = this.vehicleNames;
    const safeVehicleName = vehicleName
      .replace(/[ +]/gi, '_')
      .replace(/[^a-z0-9_]/gi, '');
    const dateStr = this.datePipe.transform(this.dateObj, 'ddMMyyyy');
    const currentEP = this.data
      .map((data) => data.context.getStatistics())
      .flat()
      .reduce((total, next) => total + next.cnt, 0);
    return `${dateStr}_${safeVehicleName}_${currentEP}EP${ext}`;
  }
}
