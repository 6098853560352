import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  map,
  switchMap,
  take,
  tap
} from 'rxjs';
import { environment } from '../../environments/environment';
import { Order } from './lib/order';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../services/http.service';
import { DateRange } from '../dates-filter';
import { OrderList } from './lib/order-list';
import { OrderGroup } from './lib/order-group';
import { GroupedOrderList } from './lib/grouped-order-list';
import { OfficialOrderList } from './lib/official-order-list';
import { OfficialOrder } from './lib/official-order';

type Location = { value: string; label: string };
type SortOption = { value: string; label: string };

@Injectable({
  providedIn: 'root'
})
export class OrderService extends HttpService {
  lastSearchQuery: string;
  lastOfficialSearchQuery: string;

  searchQuery = new Subject<string>();
  officialSearchQuery = new Subject<string>();

  private ordersUrl = environment.apiUrl + '/database/order';
  private orders = new ReplaySubject<OrderList | GroupedOrderList>(1);
  private officialOrders = new ReplaySubject<OfficialOrderList>(1);

  private pendingOrders = new BehaviorSubject<Order[]>([]);

  lastDateRange: DateRange;
  lastOfficialDateRange: DateRange;
  currentPage = 0;
  currentOfficialPage = 0;
  lastGroupingFlag = false;
  lastGroupingDistance = 50000;
  lastLocation = '';
  lastSorting: string;
  lastOfficialSorting: string;
  lastFtl?: number = -1;
  lastOfficialLocation = '';
  pageSize = 25;
  officialPageSize = 25;

  private supportedCountries: Location[] = [
    { value: '', label: $localize`Wszystkie` },
    { value: 'AT', label: $localize`Austria` },
    { value: 'BG', label: $localize`Belgia` },
    { value: 'CZ', label: $localize`Czechy` },
    { value: 'DK', label: $localize`Dania` },
    { value: 'FR', label: $localize`Francja` },
    { value: 'ES', label: $localize`Hiszpania` },
    { value: 'NL', label: $localize`Holandia` },
    { value: 'IS', label: $localize`Islandia` },
    { value: 'DE', label: $localize`Niemcy` },
    { value: 'NO', label: $localize`Norwegia` },
    { value: 'PL', label: $localize`Polska` },
    { value: 'SK', label: $localize`Słowacja` },
    { value: 'CH', label: $localize`Szwajcaria` },
    { value: 'SE', label: $localize`Szwecja` },
    { value: 'GB', label: $localize`Wielka Brytania` },
    { value: 'IT', label: $localize`Włochy` },
    { value: 'FO', label: $localize`Wyspy owcze` }
  ];

  public readonly sortOptions: SortOption[] = [
    {
      value: 'ShippingDateConfirmed|1',
      label: $localize`Data dostawy - rosnąco`
    },
    {
      value: 'ShippingDateConfirmed|-1',
      label: $localize`Data dostawy - malejąco`
    }
  ];

  public readonly officialSortOptions: SortOption[] = [
    {
      value: 'createdAt|-1',
      label: $localize`Data utworzenia - malejąco`
    },
    {
      value: 'ShippingDateConfirmed|1',
      label: $localize`Data dostawy - rosnąco`
    },
    {
      value: 'ShippingDateConfirmed|-1',
      label: $localize`Data dostawy - malejąco`
    }
  ];

  constructor(protected http: HttpClient) {
    super(http);
    this.lastSorting = this.sortOptions[0].value;
    this.lastOfficialSorting = this.officialSortOptions[0].value;
  }

  saveOrder(order: Order): Observable<OrderList | GroupedOrderList> {
    const httpCall = this.http.post<Order>(this.ordersUrl, order).pipe(
      catchError(this.handleError('fetchOrders', [])),
      take(1),
      map((response: Order) => {
        const created = new Order(response);
        return created;
      })
    );
    return httpCall.pipe(switchMap((_) => this.fetchOrders()));
  }

  addOrdersToProject(
    projectId: string,
    orders: Order[]
  ): Observable<OrderList | GroupedOrderList> {
    const httpCall = this.http
      .post<Order>(this.ordersUrl + '/add-to-project', {
        projectId,
        orderIds: orders.map((order) => order.Uuid)
      })
      .pipe(
        catchError(this.handleError('addOrdersToProject', [])),
        take(1),
        map((response: any) => {
          return response.status === 'ok';
        })
      );
    return httpCall.pipe(switchMap((_) => this.fetchOrders()));
  }

  saveImage(officialOrderUuid: string, imgData: string): Observable<any> {
    const httpCall = this.http
      .post<string>(
        `${this.ordersUrl}/official/${officialOrderUuid}/save-image`,
        {
          data: imgData
        }
      )
      .pipe(catchError(this.handleError('saveImage', [])), take(1));
    return httpCall;
  }

  getDetails(uuid: string): Observable<Order> {
    const httpCall = this.http.get<any>(this.ordersUrl + '/' + uuid).pipe(
      catchError(this.handleError('order.service getDetails', [])),
      take(1),

      map((results) => {
        const order = new Order(results.order);
        return order;
      })
    );
    return httpCall;
  }

  getOfficialOrderDetails(uuid: string): Observable<OfficialOrder> {
    const httpCall = this.http
      .get<any>(this.ordersUrl + '/official/' + uuid)
      .pipe(
        catchError(
          this.handleError('order.service getOfficialOrderDetails', [])
        ),
        take(1),

        map((results) => {
          const order = new OfficialOrder(results.order);
          order.ImageBlob = results.image;
          return order;
        })
      );
    return httpCall;
  }

  removeOfficialOrder(uuid: string): Observable<OfficialOrderList> {
    const httpCall = this.http
      .delete<any>(this.ordersUrl + '/official/' + uuid)
      .pipe(
        catchError(this.handleError('order.service removeOfficialOrder', [])),
        take(1),
        switchMap(() => this.fetchOfficialOrders())
      );
    return httpCall;
  }

  createOrderTemplate(
    uuid: string
  ): Observable<{ order: OfficialOrder; newOrders: number; error?: string }> {
    const httpCall = this.http
      .post<any>(this.ordersUrl + '/official/' + uuid + '/template', {})
      .pipe(
        catchError(this.handleError('order.service createOrderTemplate', [])),
        take(1)
      );
    return httpCall;
  }

  fetchOrders(
    range?: DateRange,
    search?: string,
    page?: number,
    group?: boolean,
    groupDistance?: number,
    location?: string,
    ftl?: number,
    sorting?: string
  ): Observable<OrderList | GroupedOrderList> {
    if (range) {
      this.lastDateRange = range;
    }
    if (search !== undefined) {
      this.lastSearchQuery = search;
    }
    if (page !== undefined) {
      this.currentPage = page;
    }
    if (group !== undefined) {
      this.lastGroupingFlag = group;
    }
    if (groupDistance !== undefined) {
      this.lastGroupingDistance = groupDistance;
    }
    if (location !== undefined) {
      this.lastLocation = location;
    }
    if (ftl !== undefined) {
      this.lastFtl = ftl;
    }
    if (sorting !== undefined) {
      this.lastSorting = sorting;
    }
    const httpCall = this.http
      .get<any>(this.ordersUrl, {
        params: {
          from: this.lastDateRange?.from?.toISOString(),
          to: this.lastDateRange?.to?.toISOString(),
          page: this.currentPage,
          pageSize: this.pageSize,
          search: this.lastSearchQuery ?? '',
          group: this.lastGroupingFlag ? 1 : 0,
          groupDistance: this.lastGroupingDistance ?? '',
          location: this.lastLocation ?? '',
          ftl: this.lastFtl,
          sorting: this.lastSorting
        }
      })
      .pipe(
        catchError(this.handleError('fetchOrders', [])),
        take(1),

        map((results) => {
          this.currentPage = results.page;
          this.pageSize = results.pageSize;
          if (results.type === 'single') {
            const orders: Order[] = results.list.map(
              (order: any) => new Order(order)
            );
            return new OrderList(
              orders,
              results.total || 0,
              results.page || 0,
              results.pageSize || this.pageSize
            );
          } else {
            const groups: OrderGroup[] = results.list.grouped.map((group) => {
              const members = group.members.map((order) => new Order(order));
              const head = new Order(group.head);
              return new OrderGroup(group.id, head, members);
            });
            const single = results.list.single.map((order) => new Order(order));
            return new GroupedOrderList(
              groups,
              single,
              results.total,
              results.page || 0,
              results.pageSize || this.pageSize
            );
          }
        }),
        tap((list) => {
          this.updateOrderList(list);
        })
      );
    return httpCall;
  }

  fetchOfficialOrders(
    range?: DateRange,
    search?: string,
    page?: number,
    location?: string,
    sorting?: string
  ): Observable<OfficialOrderList> {
    if (range) {
      this.lastOfficialDateRange = range;
    }
    if (search !== undefined) {
      this.lastOfficialSearchQuery = search;
    }
    if (page !== undefined) {
      this.currentOfficialPage = page;
    }
    if (location !== undefined) {
      this.lastOfficialLocation = location;
    }
    if (sorting !== undefined) {
      this.lastOfficialSorting = sorting;
    }
    const httpCall = this.http
      .get<any>(this.ordersUrl + '/official', {
        params: {
          from: this.lastOfficialDateRange?.from?.toISOString(),
          to: this.lastOfficialDateRange?.to?.toISOString(),
          page: this.currentOfficialPage,
          pageSize: this.officialPageSize,
          search: this.lastOfficialSearchQuery ?? '',
          location: this.lastOfficialLocation ?? '',
          sorting: this.lastOfficialSorting
        }
      })
      .pipe(
        catchError(this.handleError('fetchOfficialOrders', [])),
        take(1),

        map((results) => {
          this.currentOfficialPage = results.page;
          this.officialPageSize = results.pageSize;
          const orders: OfficialOrder[] = results.list.map(
            (order: any) => new OfficialOrder(order)
          );
          return new OfficialOrderList(
            orders,
            results.total || 0,
            results.page || 0,
            results.pageSize || this.officialPageSize
          );
        }),
        tap((list) => {
          this.updateOfficialOrderList(list);
        })
      );
    return httpCall;
  }

  public moveOrdersToOfficial(projectId: string): Observable<OfficialOrder> {
    const httpCall = this.http
      .post<any>(this.ordersUrl + '/official', {
        projectId
      })
      .pipe(
        catchError(this.handleError('moveOrdersToOfficial', [])),
        take(1),

        map((results) =>
          results.order ? new OfficialOrder(results.order) : null
        ),
        tap((created) => {
          console.log('created official order', created);
        })
      );
    return httpCall;
  }

  get searchQuery$(): Observable<string> {
    return this.searchQuery.asObservable();
  }

  get officialSearchQuery$(): Observable<string> {
    return this.officialSearchQuery.asObservable();
  }

  get orders$(): Observable<OrderList | GroupedOrderList> {
    return this.orders.asObservable();
  }

  get officialOrders$(): Observable<OfficialOrderList> {
    return this.officialOrders.asObservable();
  }

  get countries(): Location[] {
    return this.supportedCountries;
  }

  get pendingOrders$(): Observable<Order[]> {
    return this.pendingOrders.asObservable();
  }

  updatePendingOrdersList(val: Order[]) {
    this.pendingOrders.next(val);
  }

  addOrderToPendingList(val: Order) {
    const list = this.pendingOrders.value;
    list.push(val);
    this.pendingOrders.next(list);
  }

  removeOrderFromPendingList(val: Order) {
    const list = this.pendingOrders.value.filter((l) => l.Uuid !== val.Uuid);
    this.pendingOrders.next(list);
  }

  showPendingList$(): Observable<boolean> {
    return this.pendingOrders.pipe(map((orders) => orders.length > 0));
  }

  updateOrderList(list: OrderList | GroupedOrderList) {
    this.orders.next(list);
  }

  updateOfficialOrderList(list: OfficialOrderList) {
    this.officialOrders.next(list);
  }

  updateSearchQuery(value: string) {
    this.searchQuery.next(value);
  }

  updateOfficialSearchQuery(value: string) {
    this.officialSearchQuery.next(value);
  }
}
