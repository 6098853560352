<div class="row date-filters-container">
  <div class="date-filters">
    <app-dates-filter
      (datesChangeEvent)="changeFilters($event)"
      [currentType]="selectedDatesFilter"
      [withWeek]="true"
    >
    <span allText i18n>Wszystkie projekty</span>
  </app-dates-filter>
  </div>
  <div class="text-right">
    <div class="new-directory text-success" (click)="newDirectory()">
      <span i18n>Nowy folder</span> <mat-icon>create_new_folder</mat-icon>
    </div>
    <div class="new-project text-success" (click)="newProject()">
      <span i18n>Nowy projekt</span> <mat-icon>add_circle_outline</mat-icon>
    </div>
  </div>
</div>
<div>
  <mat-form-field class="search" appearance="fill">
    <mat-label i18n>Wyszukaj projekt</mat-label>
    <input
      type="text"
      matInput
      [formControl]="search"
      [matAutocomplete]="autocompleteProject"
    />
    <mat-autocomplete #autocompleteProject="matAutocomplete">
      <mat-option *ngFor="let option of projectNames$ | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<div class="panel">
  <table class="table projects-table">
    <thead>
      <tr>
        <th style="width: 56px"></th>
        <th i18n>Nazwa</th>
        <th i18n>Data utworzenia</th>
        <th i18n>Data modyfikacji</th>
        <th i18n>Numer</th>
        <th style="width: 120px" i18n>Akcje</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let listItem of (list$ | async)?.directories">
        <tr>
          <td class="text-right">
            <span
              class="open-close"
              (click)="listItem.expanded = !listItem.expanded"
            >
              <mat-icon *ngIf="!listItem.expanded">expand_more</mat-icon>
              <mat-icon *ngIf="listItem.expanded">expand_less</mat-icon>
            </span>
          </td>
          <td>
            <span class="inline-flex-center"
              ><mat-icon>folder</mat-icon> {{ listItem.directory.name }}</span
            >
          </td>
          <td>{{ listItem.directory.createdAt | date: 'dd.MM.YYYY' }}</td>
          <td>
            {{ listItem.directory.updatedAt | date: 'dd.MM.YYYY HH:mm' }}
          </td>
          <td></td>
          <td>
            <div class="actions">
              <mat-icon
                class="action text-primary"
                (click)="editDirectory(listItem)"
                >edit</mat-icon
              >
              <mat-icon
                class="action text-accent"
                (click)="deleteDirectory(listItem)"
                >delete_forever</mat-icon
              >
            </div>
          </td>
        </tr>
        <tr
          *ngFor="let project of listItem.directory.projects"
          [hidden]="!listItem.expanded"
          class="in-folder"
        >
          <td class="text-right"><span class="vertical-line"></span></td>
          <td class="action text-primary" (click)="openProject(project)">
            {{ project.name ?? '(brak)' }}
          </td>
          <td>{{ project.createdAt | date: 'dd.MM.YYYY' }}</td>
          <td>{{ project.updatedAt | date: 'dd.MM.YYYY HH:mm' }}</td>
          <td class="action text-primary" (click)="openProject(project)">
            {{ project.seqNumber }}
          </td>
          <td>
            <div class="actions">
              <mat-icon
                class="action text-primary"
                (click)="openProject(project)"
                >find_in_page</mat-icon
              >
              <mat-icon
                class="action text-primary"
                (click)="editProject(project)"
                >edit</mat-icon
              >
              <mat-icon
                class="action text-accent"
                (click)="deleteProject(project)"
                >delete_forever</mat-icon
              >
            </div>
          </td>
        </tr>
      </ng-container>

      <tr *ngFor="let project of (list$ | async)?.projects">
        <td></td>
        <td class="action text-primary" (click)="openProject(project)">
          {{ project.name ?? '(brak)' }}
        </td>

        <td>{{ project.createdAt | date: 'dd.MM.YYYY' }}</td>
        <td>{{ project.updatedAt | date: 'dd.MM.YYYY HH:mm' }}</td>
        <td class="action text-primary" (click)="openProject(project)">
          {{ project.seqNumber }}
        </td>
        <td>
          <div class="actions">
            <mat-icon class="action text-primary" (click)="openProject(project)"
              >find_in_page</mat-icon
            >
            <mat-icon class="action text-primary" (click)="editProject(project)"
              >edit</mat-icon
            >
            <mat-icon
              class="action text-accent"
              (click)="deleteProject(project)"
              >delete_forever</mat-icon
            >
          </div>
        </td>
      </tr>
      <tr *ngIf="!(isLoading$ | async) && (list$ | async)?.isEmpty()">
        <td colspan="6" class="text-center" i18n>
          Brak projektów spełniających kryteria
        </td>
      </tr>
      <tr *ngIf="isLoading$ | async">
        <td colspan="6" class="text-center" i18n>
          Ładowanie listy projektów...
        </td>
      </tr>
    </tbody>
  </table>
</div>
