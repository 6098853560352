import { environment } from 'src/environments/environment';
import { Order } from './order';
import { v4 as uuid } from 'uuid';

export class OfficialOrder {
  Uuid: string;
  UserId: string;
  Number?: string;
  ShippingDateConfirmed?: Date;
  ProjectId?: string;
  VehicleName?: string;
  TotalLDM?: number; // LDM pojazdu
  FreeLDM?: number;
  TotalCapacity?: number; // Objętość pojazdu - mm^3
  FreeCapacity?: number; // pozostała Objętość pojazdu

  Qty?: number;
  HasImage?: boolean;
  ImageBlob?: string;
  Orders: Order[] = [];
  DistanceMatching?: number;
  Ftl?: boolean;
  TemplateId?: string;

  public constructor(obj?: Partial<OfficialOrder>) {
    Object.assign(this, obj || {});
    this.Orders = (this.Orders || []).map((order) => new Order(order));
    if (!this.Uuid) {
      this.Uuid = uuid();
    }
  }

  get DeliveryName() {
    return Array.from(
      new Set(this.Orders.map((o) => o.DeliveryName).filter((name) => !!name))
    ).join('; ');
  }

  get shortPositionNumbers(): string {
    const numbers = [
      ...new Set(this.Orders.map((order) => order.Number.split('-')[0]))
    ].join('_');
    return numbers;
  }

  public getImageSharedUrl() {
    return `${environment.apiUrl}/shared/order/${this.Uuid}`;
  }
}
