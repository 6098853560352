<div class="modal-box suggest-larger-vehicle-box">
  <div class="modal-box__container" cdkDrag>
    <div *ngIf="(status$ | async) === 'new'; else suggestions">
      <div class="modal-box__header" cdkDragHandle>
        <span i18n>Szukamy innej przestrzeni</span>
        <button class="close" (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="modal-box__content">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>

<ng-template #suggestions>
  <div class="modal-box__header empty" cdkDragHandle>
    <span>&nbsp;</span>
    <button class="close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-box__content">
    <ng-container [ngSwitch]="type">
      <ng-container
        *ngSwitchCase="'size'"
        [ngTemplateOutlet]="suggestLarger"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'get-smaller'"
        [ngTemplateOutlet]="suggestSmaller"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'weight'"
        [ngTemplateOutlet]="suggestByWeight"
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #suggestLarger>
  <ng-container [ngTemplateOutlet]="larger"></ng-container>
  <ng-container [ngTemplateOutlet]="largerActions"></ng-container>
</ng-template>

<ng-template #suggestByWeight>
  <ng-container [ngTemplateOutlet]="weightSummary"></ng-container>
  <ng-container [ngTemplateOutlet]="largerActions"></ng-container>
</ng-template>

<ng-template #suggestSmaller>
  <div class="description" i18n *ngIf="(status$ | async) !== 'not-found'">
    Możesz przeładować ładunek na mniejszą przestrzeń:
  </div>
  <div class="actions-row">
    <div>
      <ng-container
        *ngIf="(status$ | async) === 'found'"
        [ngTemplateOutlet]="vehicleList"
      ></ng-container>

      <ng-container *ngIf="(status$ | async) === 'not-found'">
        <div>
          <span i18n
            >Nie znaleźliśmy mniejszej przestrzeni dla wybranego ładunku.</span
          >
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #largerActions>
  <div class="description" i18n>
    Możesz powielić tę samą przestrzeń lub zamienić obecną na większą:
  </div>
  <div class="actions-row">
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="multiply()"
        class="multiply-btn"
      >
        <ng-container i18n>Użyj jednego typu</ng-container> <br />
        <ng-container i18n>przestrzeni ładunkowej:</ng-container> <br />
        {{ currentVehicle.name }}
      </button>
    </div>
    <div>
      <ng-container
        *ngIf="(status$ | async) === 'found'"
        [ngTemplateOutlet]="vehicleList"
      ></ng-container>

      <ng-container *ngIf="(status$ | async) === 'not-found'">
        <div>
          <span i18n
            >Ładunek nie zmieścił się na największą dostępną przestrzeń.</span
          >
        </div>
        <button
          *ngIf="currentVehicle?.isCar; else addNewSpace"
          mat-raised-button
          color="primary"
          (click)="autoPlacement()"
          class="auto-placement-btn"
          i18n
        >
          Zaproponuj pojazdy
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #larger>
  <div class="summary">
    <div>
      <div class="title" i18n>Zmieściło się:</div>
      <ng-container
        *ngTemplateOutlet="loadsTemplate; context: { $implicit: loadedCnt }"
      ></ng-container>
      <div class="percentage">
        (<span class="text-green">
          <ng-container *ngIf="loadedPercentage == 0"> &lt; 1</ng-container>
          <ng-container *ngIf="notLoadedPercentage == 0"> &gt; 99</ng-container>
          <ng-container
            *ngIf="notLoadedPercentage > 0 && loadedPercentage > 0"
            >{{ loadedPercentage }}</ng-container
          >%
        </span>
        <ng-container i18n>załadunku</ng-container>)
      </div>
    </div>
    <div>
      <div class="title" i18n>Zostało:</div>
      <ng-container
        *ngTemplateOutlet="loadsTemplate; context: { $implicit: notLoadedCnt }"
      ></ng-container>
      <div class="percentage">
        (<span class="text-red"
          ><ng-container *ngIf="notLoadedPercentage == 0"> &lt; 1</ng-container>
          <ng-container *ngIf="notLoadedPercentage > 0">{{
            notLoadedPercentage
          }}</ng-container
          >%
        </span>
        <ng-container i18n>załadunku</ng-container>)
      </div>
    </div>
  </div>
</ng-template>

<ng-template #weightSummary>
  <div class="summary by-weight">
    <div class="loads" i18n>
      Łączna masa ładunków to:
      {{
        ui.getWeightInCurrentUnit(loadsWeight) + ' ' + ui.getCurrentWeightUnit()
      }}
    </div>
    <div class="loads" i18n>
      Przekroczono ładowność przestrzeni o:
      <span class="text-red">{{
        ui.getWeightInCurrentUnit(weightLimitExceededBy) +
          ' ' +
          ui.getCurrentWeightUnit()
      }}</span>
    </div>
  </div>
</ng-template>

<ng-template #vehicleList>
  <button
    *ngFor="let vehicle of vehicles$ | async; index as i; first as isFirst"
    mat-flat-button
    color="primary"
    class="list-item"
    (click)="vehicleChosen(vehicle)"
  >
    <strong>{{ vehicle.fullName }}</strong>
    {{ vehicle | vehicleDimensions | async }} |
    {{ vehicle.maxLoadingWeight | weightUnit | async | number: '0.0-2' }}
    {{ ui.getWeightUnit() | async }}
  </button>
</ng-template>

<ng-template #addNewSpace>
  <ng-container i18n
    >Dodaj kolejną przestrzeń z listy lub zdefiniuj własną.</ng-container
  >
</ng-template>

<ng-template #loadsTemplate let-cnt>
  <div class="loads">
    <span [ngSwitch]="cnt">
      <ng-container *ngSwitchCase="1"
        >1 <ng-container i18n>ładunek</ng-container></ng-container
      >
      <ng-container *ngSwitchCase="2 || 3 || 4"
        >{{ cnt }} <ng-container i18n>ładunki</ng-container></ng-container
      >
      <ng-container *ngSwitchDefault
        >{{ cnt }} <ng-container i18n>ładunków</ng-container></ng-container
      >
    </span>
  </div>
</ng-template>
