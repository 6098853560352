import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, take, tap } from 'rxjs';
import { DateRange } from 'src/app/dates-filter';
import { Load } from 'src/app/load/lib/load';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends HttpService {
  protected url = environment.apiUrl + '/database/order/chart/data';

  private fromCountry?: string = '';
  private toCountry?: string = '';
  private fillType?: string = 'LDM';
  private target?: number;

  private load: Load;

  private update = new Subject<void>();

  constructor(protected http: HttpClient) {
    super(http);
  }

  public changeToCountry(val: string) {
    this.toCountry = val;
    this.updateCharts();
  }

  public changeLoad(val: Load) {
    this.load = val;
    this.updateCharts();
  }

  public changeFillType(val: string) {
    this.fillType = val;
    this.updateCharts();
  }

  public changeTarget(val: number) {
    this.target = val;
    this.updateCharts();
  }

  public getToCountry() {
    return this.toCountry;
  }

  public getFillType() {
    return this.fillType;
  }

  public getTarget() {
    return this.target;
  }

  public getLoad() {
    return this.load;
  }

  public updateCharts() {
    this.update.next();
  }

  public get update$() {
    return this.update.asObservable();
  }

  fetchChartData(range: DateRange): Observable<any> {
    const httpCall = this.http
      .get<any>(this.url, {
        params: {
          from: range.from?.toISOString(),
          to: range.to?.toISOString(),
          fromCountry: this.fromCountry ?? '',
          toCountry: this.toCountry ?? '',
          loadId: this.load?.uuid ?? '',
          fillType: this.fillType ?? ''
        }
      })
      .pipe(
        catchError(this.handleError('fetchChartData', [])),
        take(1),

        map((results) => {
          return results;
        }),
        tap((data) => {
          console.log('chart data', data);
        })
      );
    return httpCall;
  }
}
