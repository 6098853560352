import { TextLabel } from './TextLabel';
import { LabelComponentModel } from 'src/app/label/label.component.model';
import { UiService } from 'src/app/services/ui.service';
import { Vector3 } from 'three';
import { Load } from 'src/app/load/lib/load';

export class LoadCenterLabel extends TextLabel {
  public constructor(
    load: Load,
    private settings: LabelComponentModel,
    private uiService: UiService,
    scale = 1.0
  ) {
    super('', new Vector3(), 0, 'cuboid-label fs14', scale);
    const txt = this.getLoadCenterLabelsText(load).join('\n');
    const position = new Vector3();
    if (load.mesh) {
      load.mesh.getWorldPosition(position);
      //const position = load.mesh.position.clone();
      this.setText(txt);
      this.setPosition(position);
    }
  }

  private getLoadCenterLabelsText(load: Load): string[] {
    const labels = [];
    if (this.settings.showNumber) {
      labels.push(load.idx);
    }
    if (this.settings.showName) {
      labels.push(load.name);
    }
    if (this.settings.showWeight) {
      labels.push(
        '(' +
          this.uiService.getWeightInCurrentUnit(load.weight) +
          ' ' +
          this.uiService.getCurrentWeightUnit() +
          ')'
      );
    }
    return labels;
  }
}
