import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';

import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { Cuboid } from '../lib/cuboid';
import { UiService } from 'src/app/services/ui.service';
import { LoadService as LoadUiService } from '../../../load.service';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { LoadListItem } from 'src/app/load/lib/load-list-item';

@Component({
  selector: 'app-cuboid-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less']
})
export class FormComponent implements OnInit, OnDestroy {
  @Output() addLoadEvent = new EventEmitter<Cuboid>();

  @Input() mode = 'mat-expansion-panel';
  @Input() type: 'create' | 'add' | 'edit' = 'add';
  @Input() events: Observable<void>;

  @Input() set item(item: Cuboid) {
    this._item = item;
    this.fillForm(item);
  }

  get item(): Cuboid {
    return this._item;
  }

  private _item: Cuboid;

  protected dataPanelOpenState = false;
  protected form: UntypedFormGroup;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private loadUiService: LoadUiService,
    private fb: UntypedFormBuilder,
    protected ui: UiService,
    private loadFactory: LoadFactory
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onSubmit());

    if (!this.item && this.type === 'add') {
      this.getSelectedLoadData();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getSelectedLoadData() {
    this.loadUiService.selected$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((items) => items.length > 0)
      )
      .subscribe((items: LoadListItem[]) => {
        this.fillForm(items[0].load as Cuboid);
      });
  }

  fillForm(item: Cuboid): void {
    if (!item) {
      return;
    }
    this._item = this.loadFactory.recreateLoad(item) as Cuboid;
    (this.form.controls.width as UntypedFormControl)?.setValue(
      this.ui.getLengthInCurrentUnit(this._item.width)
    );
    (this.form.controls.length as UntypedFormControl)?.setValue(
      this.ui.getLengthInCurrentUnit(this._item.length)
    );
    (this.form.controls.height as UntypedFormControl)?.setValue(
      this.ui.getLengthInCurrentUnit(this._item.height)
    );
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      const errors = this.getValidationErrors();
      // console.log('errors', errors);
      // console.log('form not valid');
    } else {
      // console.log('form valid');
      const item = this.loadFactory.recreateLoad({
        ...this._item,
        type: 'cuboid'
      }) as Cuboid;
      item.length = this.ui.getLengthInDefaultUnit(
        +this.form.controls.length.value
      );
      item.width = this.ui.getLengthInDefaultUnit(
        +this.form.controls.width.value
      );
      item.height = this.ui.getLengthInDefaultUnit(
        +this.form.controls.height.value
      );
      this.addLoadEvent.emit(item);
    }
  }

  private getValidationErrors() {
    const errors: any = {};
    Object.keys(this.form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        errors[key] = {};
        Object.keys(controlErrors).forEach((keyError) => {
          errors[key][keyError] = controlErrors[keyError];
        });
      }
    });
    return errors;
  }

  private createForm() {
    const requiredPositiveValidators = Validators.compose([
      Validators.required,
      Validators.min(0.00001)
    ]);
    this.form = this.fb.group({
      length: [null, requiredPositiveValidators],
      width: [null, requiredPositiveValidators],
      height: [null, requiredPositiveValidators]
    });
  }
}
