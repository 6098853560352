import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subject, of, switchMap, takeUntil } from 'rxjs';
import { Order } from '../lib/order';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { OrderService } from '../order.service';
import { Project, ProjectsService } from 'src/app/projects';
import { ProfileService } from 'src/app/services/profile.service';
import { SceneService } from 'src/app/scene/scene.service';
import { PendingLoadsService } from 'src/app/loadings/lib/pending-loads.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.less']
})
export class DetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() order: Order;
  @ViewChild('box') box: ElementRef;

  protected isLoading = true;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private loadComponentService: LoadComponentService,
    private orderService: OrderService,
    private projectService: ProjectsService,
    private profileService: ProfileService,
    private sceneService: SceneService,
    private pendingLoads: PendingLoadsService
  ) {}

  ngOnInit(): void {
    this.orderService
      .getDetails(this.order.Uuid)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((order) => {
        this.order = order;
        this.isLoading = false;
      });
  }

  ngAfterViewInit(): void {
    this.box.nativeElement.style.top = window.scrollY + 'px';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    console.log('order/details.component.ts destroyed');
  }

  close() {
    this.loadComponentService.clear('order/details/details.component.ts');
  }
}
