import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { CSS2DToCanvasConverterService } from 'src/app/label/css2d-to-canvas-converter.service';
import { LabelComponentModel } from 'src/app/label/label.component.model';
import { LabelService } from 'src/app/label/label.service';
import { OrbitControlsService } from 'src/app/orbit-controls/orbit-controls.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UiService } from 'src/app/services/ui.service';
import { Vector3 } from 'three';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { SceneDirector } from '../lib/SceneDirector';
import { ModelLoaderService } from 'src/app/raycasting/lib/model-loader.service';

@Component({
  selector: 'app-scene-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less']
})
export class ViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() side: string = '';
  @Input() autoZoom = true;
  @Input() context: VehicleContext;
  @Input() scale: number = 1.0;
  @Input() textScale: number = 1.0;

  @ViewChild('canvas')
  private canvasRef: ElementRef;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private director: SceneDirector;

  private unsubscribe$ = new Subject<void>();

  private labelService: LabelService;
  constructor(
    private profileService: ProfileService,
    private labelConverter: CSS2DToCanvasConverterService,
    private uiService: UiService,
    private orbitControlsService: OrbitControlsService,
    modelLoader: ModelLoaderService
  ) {
    this.director = new SceneDirector(modelLoader);
    this.labelService = new LabelService(uiService);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.director.setupMainScene(
      new Vector3(undefined, 1250, 16000),
      this.canvas,
      true,
      true,
      this.scale
    );
    this.labelService.init(
      this.canvas,
      this.director.scene,
      this.director.getCurrentCamera(),
      this.textScale
    );
    this.labelService.initModel(
      this.profileService.currentSettings.labelConfig ??
        new LabelComponentModel()
    );
    this.updateContext(this.context);
    switch (this.side) {
      case 'side':
        this.director.sideView(this.context);
        break;
      case 'sideBack':
        this.director.sideView(this.context, false);
        break;
      case 'rear':
        this.director.rearView(this.context);
        break;
      case 'front':
        this.director.rearView(this.context, true);
        break;
      case 'top':
        this.director.topView(this.context);
        break;
    }
    this.renderOnRequest();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getCanvasAsImg() {
    return this.canvas.toDataURL('image/jpeg', 1.0);
  }

  protected onResize() {
    this.resizeCanvasToDisplaySize();
    this.renderOnRequest();
  }

  private resizeCanvasToDisplaySize() {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;

    if (this.canvas.width !== width || this.canvas.height !== height) {
      this.director.updateSize(width, height);
    }
  }

  private updateContext(context: VehicleContext) {
    if (!this.director.hasScene()) {
      console.log('export scene not loaded');
      return;
    }
    this.director.scene
      .setContext(context, this.profileService.currentSettings, null, null)
      .then(async () => {
        this.labelService.setData(
          context,
          context.getAllLoads(),
          this.orbitControlsService.getControls().getSpherical().radius
        );
        await new Promise((f) => setTimeout(f, 10));
        const labelCanvas = this.labelConverter.addLabelsToCanvas(
          this.labelService.getAllLabels(),
          this.canvas,
          this.director.getCurrentCamera(),
          this.uiService.getCurrentLabelFontModifierAsNumber(),
          this.textScale
        );
        //this.context = context;
        //this.sceneService.vehicleContextDrawn(context);
        this.renderOnRequest();
      });
  }

  private renderOnRequest() {
    //this.director.render();
    this.director.render2d();
    //this.sceneService.renderLabels();
  }
}
