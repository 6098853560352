import { Euler, Vector3 } from 'three';
import { ModelDetails } from './model-details.interface';

export class ModelDetailsTomograph implements ModelDetails {
  getPartDimensions(name: string): Vector3 {
    return new Vector3(undefined, undefined, undefined);
  }
  getPartRotation(name: string): Euler {
    throw new Error(`Tomograph OBB layer for ${name} not supported`);
  }
}
