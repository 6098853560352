import { Euler, Vector3 } from 'three';
import { ModelDetails } from './model-details.interface';

export class ModelDetailsBOAT20_6m implements ModelDetails {
  getPartDimensions(name: string): Vector3 {
    return new Vector3(undefined, undefined, 60);
  }
  getPartRotation(name: string): Euler {
    switch (name) {
      case 'shaft-left-obb':
        return new Euler(0, -Math.PI / 11, 0);
      case 'shaft-right-obb':
        return new Euler(0, Math.PI / 11, 0);
    }
    throw new Error(`BOAT20-6m OBB layer for ${name} not supported`);
  }
}
