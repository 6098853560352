//import THREE = require("three");

import { AxleMesh } from '../axle-mesh';

import {
  BufferGeometry,
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments
} from 'three';
import { Axle } from '../axle';

export class AxleTriangularMesh extends AxleMesh {
  private indices = [];
  private vertices = [];

  constructor(axle: Axle) {
    super('triangle', axle);

    this.axle = axle;
    this.init();
  }

  public getName(): string {
    return '(' + this.axle.offset + ')';
  }

  private init() {
    this.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const groupStart = 0;
    let groupCount = 0;
    const width = 50;
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;

    this.vertices.push(x0 - width, y0, z0);
    this.vertices.push(x0 + width, y0, z0);
    this.vertices.push(x0, y0 + width * Math.sqrt(3), z0);

    this.vertices.push(x0 - width, y0, z0 + this.axle.width);
    this.vertices.push(x0 + width, y0, z0 + this.axle.width);
    this.vertices.push(x0, y0 + width * Math.sqrt(3), z0 + this.axle.width);

    // loading space
    this.indices.push(0, 1, 2);

    this.indices.push(0, 2, 3);
    this.indices.push(3, 2, 5);

    this.indices.push(1, 2, 4);
    this.indices.push(5, 4, 2);

    this.indices.push(0, 1, 3);
    this.indices.push(4, 3, 1);

    this.indices.push(3, 4, 5);

    groupCount = 5 * 6;
    this.geometry.addGroup(groupStart, groupCount, 0);

    this.geometry.setIndex(this.indices);
    this.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.add(wireframe);

    // this.mesh.position.y = Constants.SCENE_FLOOR_Y + y0;
  }
}
