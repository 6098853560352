import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { Vehicle } from '../../lib/vehicle';
import { CreateComponent as CreateSpaceComponent } from 'src/app/vehicle/space/form/create/create.component';
import { Space } from '../../space/lib/space';
import { SpaceFactory } from '../../space/lib/space-factory';
import { VehicleType } from '../../lib/vehicle-type';
import { VehicleService } from '../../lib/vehicle.service';
import { VehicleService as VehicleUiService } from '../../vehicle.service';
import { NameComponent } from '../../form/name/name.component';
import { VehicleFactory } from '../../lib/vehicle-factory';
import { CustomVehicleService } from '../../lib/custom-vehicle.service';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { ContextService } from '../context.service';
import { SceneService } from 'src/app/scene/scene.service';
import { ContextFactory } from '../lib/context-factory';
import { Vector } from 'src/app/lib/communication/vector';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-context-vehicle-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EditComponent implements OnInit, OnDestroy {
  @ViewChild('spaces', { read: ViewContainerRef, static: true })
  spacesFormsContainer: ViewContainerRef;
  @ViewChild('name') nameComponent: NameComponent;
  @Input() context: VehicleContext;

  private componentList: CreateSpaceComponent[] = [];
  public spaces: Space[] = [];

  private $unsubscribe = new Subject<void>();

  constructor(
    private contextService: ContextService,
    private sceneService: SceneService,
    private loadComponentService: LoadComponentService,
    private spaceFactory: SpaceFactory,
    private contextFactory: ContextFactory,
    private uiService: UiService
  ) {}

  ngOnInit(): void {
    this.context.getVehicle().enabledSpaces.forEach((space) => {
      const stats = this.context.getSpaceStatistics(space.uuid);
      const minSize = new Vector({
        x: stats.oxLDM || 1,
        y: stats.oyLDM || 1,
        z: stats.ozLDM || 1
      });
      this.addSpaceForm(space, minSize);
    });
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  close(): void {
    this.loadComponentService.clear('vehicle/context/edit/edit.component.ts');
  }

  onSubmit() {
    const nameValid = this.nameComponent.validateForm();
    let spacesValid = this.spaces.length > 0;
    this.componentList.forEach((space, idx) => {
      const spaceValid = this.componentList[idx].validateForm();
      spacesValid = spacesValid && spaceValid;
    });
    const valid = nameValid && spacesValid;
    if (valid) {
      const context = this.getObject();
      this.updateContextState(context);
    }
  }

  getObject(): VehicleContext {
    const newContext = this.contextFactory.cloneAsNewState(this.context);
    const vehicle = newContext.getVehicle();
    vehicle.name = this.nameComponent.getValue();
    this.componentList.forEach((spaceComponent) => {
      const space = spaceComponent.getObject();
      const existingIndex = vehicle.spaces.findIndex(
        (s) => s.uuid === space.uuid
      );
      if (existingIndex < 0) {
        vehicle.addSpace(space);
      } else {
        const oldSize = new Vector({
          xLength: vehicle.spaces[existingIndex].length,
          zLength: vehicle.spaces[existingIndex].width,
          yLength: vehicle.spaces[existingIndex].height
        });
        space.updateMatrixToFitSize(oldSize);
        vehicle.spaces[existingIndex] = space;
      }
    });
    return newContext;
  }

  private updateContextState(context: VehicleContext) {
    this.uiService.setLoading(true);
    this.sceneService
      .saveContextChanges(context)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(() => {
        this.uiService.setLoading(false);
        // redraw
        this.sceneService.redrawContextWithLabels();
        this.close();
      });
  }

  private addSpaceForm(obj: Space, minSize: Vector) {
    const space = this.spaceFactory.recreate(obj || { type: 'trailer' });
    this.spaces.push(space);

    const newComponent =
      this.spacesFormsContainer.createComponent(CreateSpaceComponent);
    newComponent.instance.space = space;
    newComponent.instance.minSize = minSize;
    newComponent.instance.order = this.componentList.length;
    this.componentList.push(newComponent.instance);
  }
}
