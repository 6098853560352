import { Vector3 } from 'three';
import { VectorDimensionLabel } from './VectorDimensionLabel';

export class VectorHeightLabel extends VectorDimensionLabel {
  protected updateLabelPosition(): void {
    const position = new Vector3();
    this.vector.mesh.getWorldPosition(position);
    position.x -= this.vector.length / 2 - 100;
    position.z += this.vector.width / 2 - 20;
    this.setPosition(position);
  }
}
