import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  ReplaySubject,
  Subject
} from 'rxjs';
import { take, share, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Project } from './lib/project';
import { Directory } from './lib/directory';
import { ProjectList } from './lib/project-list';
import { HttpService } from '../services/http.service';
import { DateRange } from '../dates-filter/lib/date-range';
import { StatusResponse } from '../lib/communication/status.response';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends HttpService {
  lastSearchQuery: string;

  searchQuery = new Subject<string>();

  private projectsUrl = environment.apiUrl + '/database/projects';
  private directoriesUrl = environment.apiUrl + '/database/projects/directory';
  private projectNamesUrl = environment.apiUrl + '/database/projects/names';
  private projects = new ReplaySubject<Project[]>(1);
  private project = new ReplaySubject<Project>(1);

  private list = new BehaviorSubject<ProjectList>(new ProjectList([], [], []));

  private projectNames: Observable<string[]>;

  private allDirectories = new ReplaySubject<Directory[]>(1);

  private lastDateRange: DateRange;
  private selectedProject: Project;

  constructor(protected http: HttpClient) {
    super(http);
  }

  findByOrder(uuid: string): Observable<Project> {
    const httpCall = this.http
      .get<any>(this.projectsUrl + '/find-by-order/' + uuid)
      .pipe(
        catchError(this.handleError('fetchProjects', [])),
        take(1),
        map((result) => {
          if (result.project) {
            return new Project(result.project);
          }
          return null;
        })
      );
    return httpCall;
  }

  find(uuid: string): Observable<Project> {
    const httpCall = this.http.get<any>(this.projectsUrl + '/' + uuid).pipe(
      catchError(this.handleError('find', [])),
      take(1),
      map((result) => {
        if (result.project) {
          return new Project(result.project);
        }
        return null;
      })
    );
    return httpCall;
  }

  fetchProjects(range?: DateRange, search?: string): Observable<ProjectList> {
    if (range) {
      this.lastDateRange = range;
    }
    if (search !== undefined) {
      this.lastSearchQuery = search;
    }
    const httpCall = this.http
      .get<any>(this.projectsUrl, {
        params: {
          from: this.lastDateRange?.from?.toISOString(),
          to: this.lastDateRange?.to?.toISOString(),
          search: this.lastSearchQuery ?? ''
        }
      })
      .pipe(
        catchError(this.handleError('fetchProjects', [])),
        take(1),
        withLatestFrom(this.list),
        map(([results, latest]) => {
          const projects = (results?.list || [])
            .map((project: any) => new Project(project))
            .sort((a: Project, b: Project) =>
              a.createdAt > b.createdAt ? -1 : 1
            );
          const directories = (results?.directories || []).map(
            (d: any) => new Directory(d)
          );
          return new ProjectList(
            directories,
            projects,
            latest?.getExpandedDirectoryUuids() || []
          );
        })
      );
    return httpCall;
  }

  fetchProjectNames() {
    if (!this.projectNames) {
      this.projectNames = this.http.get<any>(this.projectNamesUrl).pipe(
        catchError(this.handleError('fetchProjectNames', [])),
        shareReplay(1),
        map((results: string[]) =>
          results
            .filter((name) => !!name)
            .sort((a: string, b: string) =>
              a.toLowerCase() > b.toLowerCase() ? 1 : -1
            )
        )
      );
    }
    return this.projectNames;
  }

  fetchAllDirectories(): Observable<Directory[]> {
    const httpCall = this.http.get<any>(this.directoriesUrl).pipe(
      take(1),
      catchError(this.handleError('fetchAllDirectories', [])),
      map((response: any) => response.list.map((d: any) => new Directory(d))),
      share(),
      tap((response: Directory[]) => this.allDirectories.next(response))
    );
    return httpCall;
  }

  addProject(project: Project): Observable<Project> {
    const httpCall = this.http.post(this.projectsUrl, project).pipe(
      take(1),
      catchError(this.handleError('addProject', [])),
      map((response: any) => new Project(response)),
      share()
    );
    return httpCall;
  }

  updateProject(
    project: Project,
    switchToUpdated: boolean = true
  ): Observable<Project> {
    const httpCall = this.http
      .put(this.projectsUrl + '/' + project.uuid, project)
      .pipe(
        take(1),
        catchError(this.handleError('updateProject', [])),
        map((response: any) => new Project(response)),
        tap((updated) => {
          if (switchToUpdated) {
            this.currentProject = updated;
          }
        }),
        share()
      );
    return httpCall;
  }

  deleteProject(project: Project): Observable<StatusResponse<void>> {
    const httpCall = this.http
      .delete(this.projectsUrl + '/' + project.uuid)
      .pipe(
        take(1),
        catchError(this.handleError('deleteProject', [])),
        map((response: any) => new StatusResponse<void>(response.status, null)),
        share()
      );
    return httpCall;
  }

  addDirectory(directory: Directory): Observable<Directory> {
    const httpCall = this.http.post(this.directoriesUrl, directory).pipe(
      take(1),
      catchError(this.handleError('addDirectory', [])),
      map((response: any) => new Directory(response)),
      share()
    );
    return httpCall;
  }

  updateDirectory(directory: Directory): Observable<Directory> {
    const httpCall = this.http
      .put(this.directoriesUrl + '/' + directory.uuid, directory)
      .pipe(
        take(1),
        catchError(this.handleError('updateDirectory', [])),
        map((response: any) => new Directory(response)),
        share()
      );
    return httpCall;
  }

  deleteDirectory(directory: Directory): Observable<StatusResponse<void>> {
    const httpCall = this.http
      .delete(this.directoriesUrl + '/' + directory.uuid)
      .pipe(
        take(1),
        catchError(this.handleError('deleteDirectory', [])),
        map((response: any) => new StatusResponse<void>(response.status, null)),
        share()
      );
    return httpCall;
  }

  clearNamesCache() {
    this.projectNames = null;
  }

  get searchQuery$(): Observable<string> {
    return this.searchQuery.asObservable();
  }

  get projects$(): Observable<Project[]> {
    return this.projects.asObservable();
  }

  get currentProject$(): Observable<Project> {
    return this.project.asObservable();
  }

  get list$(): Observable<ProjectList> {
    return this.list.asObservable();
  }

  get allDirectories$(): Observable<Directory[]> {
    return this.allDirectories.asObservable();
  }

  set currentProject(project: Project) {
    this.project.next(project);
    this.selectedProject = project;
  }

  get currentProject(): Project {
    return this.selectedProject;
  }

  updateProjectList(list: ProjectList) {
    this.list.next(list);
  }

  updateSearchQuery(value: string) {
    this.searchQuery.next(value);
  }
}
