import { LoadDimensionLabel } from './LoadDimensionLabel';

export class LoadLengthLabel extends LoadDimensionLabel {
  protected updateLabelPosition(): void {
    const position = this.load.mesh.position.clone();
    position.y -= this.load.cuboidHull.height / 2;
    position.z += this.load.cuboidHull.width / 2 - 20;
    this.setPosition(position);
  }
}
