import { Profile, ProfileInterface } from '../model/profile';

export class FileResponse {
  uuid: string; //uuidv4
  message: string;
  profile: ProfileInterface;

  constructor(obj: any) {
    Object.assign(this, obj);
    if (obj?.profile) {
      this.profile = new Profile(obj.profile);
    }
  }
}
