import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { MenuState } from './lib/menu-state';
import { ProjectsService } from '../projects';
import { ProfileService } from '../services/profile.service';
import { ContextService } from '../vehicle/context/context.service';
import { SceneService } from '../scene/scene.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  // private menuType = new Subject<string>();
  private menuState = new Subject<MenuState>();

  constructor(
    private projectsService: ProjectsService,
    private profileService: ProfileService,
    private contextService: ContextService,
    private sceneService: SceneService
  ) {}

  get menuState$(): Observable<MenuState> {
    return this.menuState.asObservable();
  }

  openLoadings() {
    this.menuState.next(MenuState.loadingsOpen);
  }

  openVehicles() {
    this.menuState.next(MenuState.vehiclesOpen);
  }
  close() {
    this.menuState.next(MenuState.closed);
  }

  backToProjects() {
    const profile = this.profileService.currentProfile;
    profile.latestProject = null;
    profile.latestProjectUuid = null;
    this.profileService.updateProfile(profile);
    this.profileService
      .saveProfile(profile)
      .pipe(take(1))
      //.pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {});

    this.projectsService.currentProject = null;
    this.contextService.setContext(null);
    this.sceneService.requestPlacementGenerationForOrders(null);
  }
}
