<div class="load">
  <div>
    <mat-menu #menu>
      <button
        mat-menu-item
        *ngFor="let shape of shapes; index as i"
        (click)="selectedLoadShape(shape)"
      >
        <ng-container
          *ngTemplateOutlet="
            shapeTemplate;
            context: { name: shape.name, icon: shape.icon }
          "
        ></ng-container>
      </button>
    </mat-menu>
  </div>

  <ng-template #shapeTemplate let-name="name" let-icon="icon">
    <span class="icon">
      <img src="../../assets/images/{{ icon }}" alt="" />
    </span>
    <span>{{ name }}</span>
  </ng-template>

  <app-load-form
    [shape]="shape"
    [events]="eventsSubject.asObservable()"
    (addLoadEvent)="addLoad($event)"
  >
  </app-load-form>
</div>
