<mat-form-field appearance="fill" hideRequiredMarker="true" class="search-box">
  <input
    type="text"
    placeholder="Wyszukaj"
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let item of filteredItems" [value]="item">
      {{ item.load.fullName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<div *ngIf="lastLoads.length === 0" i18n class="text-center mt-2">
  Tutaj wyświetlą się ostatnio używane przyczepy
</div>
<div *ngIf="lastLoads.length > 0" i18n class="mt-2">Ostatnio używane</div>

<mat-list class="condensed">
  <mat-list-item
    *ngFor="let item of lastLoads.slice(0, 10); let i = index"
    [ngClass]="{ selected: item.selected }"
    [@flashBackground]="item.highlight ? 'show' : ''"
    (@flashBackground.done)="item.highlight = false"
  >
    <div class="load-list-item">
      <span class="name-container" (click)="selectSingle(item)">
        <span class="name">{{ item.load.fullName }}</span>
        <span>
          {{ item.load | dimensions | async }}
          <span *ngIf="item.load.weight">
            |
            {{ item.load.weight | weightUnit | async | number: '0.0-2' }}
            {{ ui.getWeightUnit() | async }}
          </span>
        </span>
      </span>
    </div>
  </mat-list-item>
</mat-list>
