import { CuboidHull } from 'src/app/load/lib/cuboid-hull';
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { Load } from '../../../lib/load';
import { CylinderMesh } from './cylinder-mesh';

export class Cylinder extends Load {
  shape = 'cylinder';
  radius: number;
  height: number;

  get cuboidHull(): CuboidHull {
    return new CuboidHull({
      width: 2 * this.radius,
      length: 2 * this.radius,
      height: this.height
    });
  }

  get volume(): number {
    return this.area * this.height;
  }
  get area(): number {
    return this.radius * this.radius * Math.PI;
  }

  get fullName(): string {
    return `Cylinder ${this.name}`;
  }
  get fullDescription(): string {
    return `radius ${this.radius}x${this.height}`;
  }

  get descriptiveDimensions(): number[] {
    return [this.radius, this.height];
  }
  async createMesh(settings: LoadDisplaySettings): Promise<LoadMesh> {
    this.mesh = new CylinderMesh(this, settings);
    return this.mesh;
  }
}
