import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Other } from './other';
import {
  BoxGeometry,
  BufferGeometry,
  CylinderGeometry,
  Float32BufferAttribute,
  Mesh,
  MeshBasicMaterial,
  Vector3
} from 'three';

export class OtherMesh extends VehicleMesh {
  private other: Other;

  protected indices = [];
  protected vertices = [];

  protected length: number;
  protected height: number;
  protected width: number;

  public constructor(other: Other) {
    super('other', other);
    console.log('OTHER constructor');
    this.length = other.length || 0;
    this.height = other.height || 0;
    this.width = other.width || 0;
    this.init();
  }

  protected angleBracket(
    p: Vector3,
    length: number,
    width: number,
    height: number,
    materialIndex: number,
    rotateY: number
  ) {
    const x0 = length / 2;
    const y0 = height / 2;
    const z0 = width / 2;

    const topPlank = new BoxGeometry(length, 4, width);
    const backPlank = new BoxGeometry(4, height - 8, width);
    const bottomPlank = new BoxGeometry(length, 4, width);

    const topObj = new Mesh(topPlank, this.mesh.material[materialIndex]);
    const backObj = new Mesh(backPlank, this.mesh.material[materialIndex]);
    const bottomObj = new Mesh(bottomPlank, this.mesh.material[materialIndex]);

    const geometry = new BufferGeometry();
    const object = new Mesh(geometry);
    backObj.position.copy(new Vector3(-2, 0, 0));
    topObj.position.copy(new Vector3(-x0, y0 - 2, 0));
    bottomObj.position.copy(new Vector3(-x0, -y0 + 2, 0));
    object.add(topObj, backObj, bottomObj); //, bottomObj);

    object.position.copy(p);
    object.rotateY(rotateY);
    this.mesh.add(object);

    /*const indices = new Array();
    const vertices = new Array();
    const geometry = new BufferGeometry();

    const x0 = length / 2;
    const y0 = height / 2;
    const z0 = width / 2;

    vertices.push(-100, -100, 100);

    vertices.push(100, -100, 100); // 1
    vertices.push(-100, 100, 100); // 2
    vertices.push(100, 100, 100); // 3
    vertices.push(-100, -100, -100); // 4
    vertices.push(100, -100, -100); // 5
    vertices.push(-100, 100, -100); // 6
    vertices.push(100, 100, -100); // 7

    indices.push(
      // front
      0,
      3,
      2,
      0,
      1,
      3,
      // right
      1,
      7,
      3,
      1,
      5,
      7,
      // back
      5,
      6,
      7,
      5,
      4,
      6,
      // left
      4,
      2,
      6,
      4,
      0,
      2,
      // top
      2,
      7,
      6,
      2,
      3,
      7,
      // bottom
      4,
      1,
      0,
      4,
      5,
      1
    );

    geometry.setIndex(indices);

    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

    geometry.computeVertexNormals();

    // geometry.addGroup(0, indices.length, materialIndex);

    const object = new Mesh(geometry, this.material[materialIndex]);
    object.position.copy(p);
    this.add(object);*/
  }

  protected cylinder(
    p: Vector3,
    length: number,
    width: number,
    height: number,
    materialIndex: number
  ) {
    const geometry = new CylinderGeometry(length, width, height, 32);

    const cylinder = new Mesh(geometry, this.mesh.material[materialIndex]);
    cylinder.position.copy(p);
    this.mesh.add(cylinder);
  }

  //hexahedron <-> decahedron
  protected plank(
    p: Vector3,
    length: number,
    width: number,
    height: number,
    materialIndex: number,
    leftTopRadius = 0,
    rightTopRadius = 0,
    rightBottomRadius = 0,
    leftBottomRadius = 0
  ) {
    const v = this.vertices.length / 3;
    const indicesStart = this.indices.length;

    this.vertices.push(p.x + leftTopRadius, p.y, p.z); //0
    this.vertices.push(p.x + length - rightTopRadius, p.y, p.z); //1
    this.vertices.push(p.x + length, p.y, p.z + rightTopRadius); //2
    this.vertices.push(p.x + length, p.y, p.z + width - rightBottomRadius); //3
    this.vertices.push(p.x + length - rightBottomRadius, p.y, p.z + width);
    this.vertices.push(p.x + leftBottomRadius, p.y, p.z + width); //5
    this.vertices.push(p.x, p.y, p.z + width - leftBottomRadius); //6
    this.vertices.push(p.x, p.y, p.z + leftTopRadius); //7

    this.vertices.push(p.x + leftTopRadius, p.y + height, p.z);
    this.vertices.push(p.x + length - rightTopRadius, p.y + height, p.z);
    this.vertices.push(p.x + length, p.y + height, p.z + rightTopRadius);
    this.vertices.push(
      p.x + length,
      p.y + height,
      p.z + width - rightBottomRadius
    );
    this.vertices.push(
      p.x + length - rightBottomRadius,
      p.y + height,
      p.z + width
    );
    this.vertices.push(p.x + leftBottomRadius, p.y + height, p.z + width);
    this.vertices.push(p.x, p.y + height, p.z + width - leftBottomRadius);
    this.vertices.push(p.x, p.y + height, p.z + leftTopRadius);

    this.indices.push(v, v + 1, v + 2);
    this.indices.push(v, v + 2, v + 3);
    this.indices.push(v, v + 3, v + 4);
    this.indices.push(v, v + 4, v + 5);
    this.indices.push(v, v + 5, v + 6);
    this.indices.push(v, v + 6, v + 7);

    const z = v + 8;
    this.indices.push(z, z + 1, z + 2);
    this.indices.push(z, z + 2, z + 3);
    this.indices.push(z, z + 3, z + 4);
    this.indices.push(z, z + 4, z + 5);
    this.indices.push(z, z + 5, z + 6);
    this.indices.push(z, z + 6, z + 7);

    this.indices.push(v, v + 1, z + 1);
    this.indices.push(v, z + 1, z);

    this.indices.push(v + 1, v + 2, z + 2);
    this.indices.push(v + 1, z + 2, z + 1);

    this.indices.push(v + 2, v + 3, z + 3);
    this.indices.push(v + 2, z + 3, z + 2);

    this.indices.push(v + 3, v + 4, z + 4);
    this.indices.push(v + 3, z + 4, z + 3);

    this.indices.push(v + 4, v + 5, z + 5);
    this.indices.push(v + 4, z + 5, z + 4);

    this.indices.push(v + 5, v + 6, z + 6);
    this.indices.push(v + 5, z + 6, z + 5);

    this.indices.push(v + 6, v + 7, z + 7);
    this.indices.push(v + 6, z + 7, z + 6);
    this.indices.push(v + 7, v, z);
    this.indices.push(v + 7, z, z + 7);
    this.mesh.geometry.addGroup(
      indicesStart,
      this.indices.length,
      materialIndex
    );
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getCapacity(): number {
    return this.width * this.length;
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  protected getBufferGeometry() {
    //tutaj cokolwiek ?
    //gdy nie pobiera rozmiarow z bazy
    //this.length = 1000;
    //this.height = 2000;
    //this.width = 1500;

    const x0 = this.length / 2;
    const y0 = this.height / 2;
    const z0 = this.width / 2;
    this.vertices.push(0, 0, -z0); // bez dodania czegokolwiek nie działa poprawnie określanie pozycji

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();

    this.addSpaces(x0, y0);
  }
}
