import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { FilterType, DateRange, FilterChangeEvent } from '../dates-filter';
import { Order } from './lib/order';
import { OrderService } from './order.service';
import { LoadComponentService } from '../services/load-component.service';
import { ImportComponent } from './import/import.component';
import { PageEvent } from '@angular/material/paginator';
import { OrderList } from './lib/order-list';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GroupedOrderList } from './lib/grouped-order-list';
import { CreateComponent } from './create/create.component';
import { DetailsComponent } from './details/details.component';
import { ProfileService } from '../services/profile.service';
import { Project, ProjectsService } from '../projects';
import { SceneService } from '../scene/scene.service';
import { OrderGroup } from './lib/order-group';

@Component({
  selector: 'app-order-list',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.less']
})
export class OrderComponent implements OnInit, OnDestroy {
  protected list$: Observable<OrderList | GroupedOrderList>;
  protected isLoading$: Observable<boolean>;
  protected groupingEnabled = false;
  protected groupingDistance: number = 50;

  protected currentLocation?: string = '';
  protected currentSorting?: string = '';
  protected ftl?: number = -1;
  protected showDetails = false;

  search: UntypedFormControl;
  selectedDatesFilter = FilterType.month;

  private unsubscribe$ = new Subject<void>();

  constructor(
    protected service: OrderService,
    private loadComponentService: LoadComponentService,
    private profileService: ProfileService,
    private projectService: ProjectsService,
    private sceneService: SceneService
  ) {
    this.list$ = service.orders$;
    this.isLoading$ = this.list$.pipe(
      map(() => false),
      startWith(true)
    );
    this.search = new UntypedFormControl(service.lastSearchQuery, []);
    this.groupingEnabled = service.lastGroupingFlag;
    this.groupingDistance = Math.floor(service.lastGroupingDistance / 1000);
    this.currentLocation = service.lastLocation;
    this.ftl = service.lastFtl;
    this.currentSorting = service.lastSorting;
  }

  ngOnInit(): void {
    // update order list
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((searchQuery) => {
        this.service
          .fetchOrders(undefined, searchQuery)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeFilters(event: FilterChangeEvent) {
    console.log('order.component.ts: changed filters', event);
    this.selectedDatesFilter = event.filterType;
    this.updateOrderList(event.range, this.search.value, 0);
  }

  private updateOrderList(
    range?: DateRange,
    search?: string,
    page?: number,
    group?: boolean,
    groupDistance?: number,
    location?: string,
    ftl?: number,
    sorting?: string
  ) {
    this.service
      .fetchOrders(
        range,
        search,
        page,
        group,
        groupDistance ? groupDistance * 1000 : undefined,
        location,
        ftl,
        sorting
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        console.debug('order.component.ts: updateOrderList', list);
      });
  }

  protected importFromFile() {
    this.loadComponentService.clear('order.component.ts importFromFile');
    this.loadComponentService.add(ImportComponent);
  }

  protected showCreateOrderForm() {
    this.loadComponentService.clear('order.component.ts showCreateOrderForm');
    this.loadComponentService.add(CreateComponent);
  }

  protected copyOrder(order: Order) {
    this.loadComponentService.clear('order.component.ts copyOrder');
    const component = this.loadComponentService.add(CreateComponent);
    component.instance.order = order;
  }

  protected goToDetails(order: Order) {
    this.loadComponentService.clear('order.component.ts goToDetails');
    const component = this.loadComponentService.add(DetailsComponent);
    component.instance.order = order;
  }

  protected onPageChange(e: PageEvent) {
    this.updateOrderList(undefined, undefined, e.pageIndex);
  }

  protected changeGrouping(event: MatCheckboxChange) {
    const checked = event.checked;
    this.updateOrderList(undefined, undefined, 0, checked);
  }

  protected changeGroupingDistance() {
    this.updateOrderList(
      undefined,
      undefined,
      0,
      undefined,
      this.groupingDistance
    );
  }

  protected changeLocation(event: any) {
    console.log('change location', this.currentLocation);
    this.updateOrderList(
      undefined,
      undefined,
      0,
      undefined,
      this.groupingDistance,
      this.currentLocation
    );
  }

  protected changeSorting(event: any) {
    console.log('change sorting', this.currentSorting);
    this.updateOrderList(
      undefined,
      undefined,
      0,
      undefined,
      this.groupingDistance,
      this.currentLocation,
      this.ftl,
      this.currentSorting
    );
  }

  protected changeFtl(event: any) {
    console.log('change FTL / LTL', this.ftl);
    this.updateOrderList(
      undefined,
      undefined,
      0,
      undefined,
      this.groupingDistance,
      this.currentLocation,
      this.ftl
    );
  }

  protected generatePlacement(order: Order) {
    console.log('order/details.component.ts generatePlacement');
    this.service.addOrderToPendingList(order);
  }

  protected generatePlacementForGroup(group: OrderGroup) {
    const orders: Order[] = [];
    orders.push(group.head);
    orders.push(...group.members);
    const profile = this.profileService.currentProfile;
    const newProject = new Project({
      userId: profile.userId,
      name: group.head.Number,
      workOrders: orders.map((o) => o.Uuid),
      distanceMatching:
        (this.service.lastGroupingFlag
          ? this.service.lastGroupingDistance
          : null) || null
    });
    this.projectService
      .addProject(newProject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((project) => {
        this.sceneService.requestPlacementGenerationForOrders(orders);
        this.projectService.currentProject = project;
      });
  }

  /*protected openProject(order: Order) {
    this.projectService
      .findByOrder(order.Uuid)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((project) => {
        this.projectService.currentProject = project;
      });
  }*/
}
