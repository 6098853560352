import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { catchError, map, share, take } from 'rxjs/operators';

import { Profile, ProfileInterface } from '../lib/model/profile';
import { environment } from '../../environments/environment';

import { HttpService } from './http.service';
import { LabelComponentModel } from '../label/label.component.model';
import { Settings } from '../lib/model/settings';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends HttpService {
  private profileUrl = environment.apiUrl + '/database/profile';
  private settingsUrl = this.profileUrl + '/settings';
  private labelConfigUrl = this.settingsUrl + '/label';

  private profile = new ReplaySubject<ProfileInterface>(1);

  private profileObject: ProfileInterface;

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getProfile(): Observable<ProfileInterface> {
    return this.profile.asObservable();
  }

  get settings$(): Observable<Settings> {
    return this.profile.asObservable().pipe(map((profile) => profile.settings));
  }

  public updateProfile(profile: ProfileInterface) {
    this.profileObject = profile;
    this.profile.next(profile);
  }

  public updateLabelConfig(config: LabelComponentModel) {
    this.profileObject.settings.labelConfig = config;
    this.updateProfile(this.profileObject);
  }

  public updateSettings(settings: Settings) {
    this.profileObject.settings = new Settings({
      ...this.profileObject.settings,
      ...settings
    });
    this.updateProfile(this.profileObject);
  }

  public saveProfile(profile: ProfileInterface): Observable<ProfileInterface> {
    const httpCall = this.http.post<any>(this.profileUrl, profile).pipe(
      catchError(this.handleError('saveProfile', [])),
      map((result: any): ProfileInterface => new Profile(result.profile))
    );
    return httpCall;
  }

  public saveSettings(settings: Settings): Observable<Settings> {
    const httpCall = this.http.put<Settings>(this.settingsUrl, settings).pipe(
      catchError(this.handleError('saveSettings', [])),
      map((result: Settings): Settings => new Settings(result))
    );
    return httpCall;
  }

  get currentSettings() {
    return this.profileObject.settings;
  }

  get currentProfile() {
    return this.profileObject;
  }

  /**
   * Pobiera dane profilu użytkownika.
   * Tu nie używamy shareReplay, żeby pierwszy fetch był tylko na starcie aplikacji, a dane profilu zwracane już wszędzie.
   *
   * @returns Observable<Prfile>
   */
  public fetchProfile(): Observable<Profile> {
    const httpCall = this.http.get(this.profileUrl).pipe(
      take(1),
      catchError(this.handleError('getProfile', [])),
      map((result: any): ProfileInterface => new Profile(result.profile))
    );
    return httpCall;
  }

  public saveLabelConfig(
    model: LabelComponentModel
  ): Observable<LabelComponentModel> {
    // // console.log('saveLabelConfig called', this.labelConfigUrl, model);
    const httpCall = this.http.post<any>(this.labelConfigUrl, model).pipe(
      catchError(this.handleError('saveLabelConfig', [])),
      map((response) => new LabelComponentModel(response)),
      share()
    );
    return httpCall;
  }
}
