<ng-template
  *ngIf="
    (uiMode$ | async) === 'standard';
    then standardTopHeader;
    else advancedTopHeader
  "
></ng-template>
<ng-template #standardTopHeader>
  <button
    mat-button
    class="scene-header__item"
    (click)="newContext()"
    *ngIf="contextExists()"
  >
    <mat-icon>add</mat-icon>
    <div class="scene-header__item__title" i18n>Dodaj pojazd</div>
  </button>

  <button
    mat-button
    class="scene-header__item"
    (click)="copyContext()"
    *ngIf="isNotEmptyContext()"
  >
    <mat-icon>content_copy</mat-icon>
    <div class="scene-header__item__title" i18n>Powiel</div>
  </button>
  <div class="scene-header__item labels-view">
    <button mat-button (click)="toggleLabelView()" *ngIf="isNotEmptyContext()">
      <mat-icon>rule</mat-icon>
      <div class="scene-header__item__title" i18n>Widok ładunku</div>
    </button>
    <app-label *ngIf="labelViewVisible"></app-label>
  </div>
</ng-template>

<ng-template #advancedTopHeader>
  <div class="scene-header__item" *ngIf="isNotEmptyContext()">
    <button mat-button (click)="showVehicleSettings()">
      <mat-icon>settings</mat-icon>
      <div class="scene-header__item__title" i18n>Ustawienia</div>
    </button>
  </div>
  <div class="scene-header__item" *ngIf="isNotEmptyContext()">
    <button mat-button (click)="showFlooringSettings()">
      <mat-icon>layers</mat-icon>
      <div class="scene-header__item__title" i18n>Piętrowanie</div>
    </button>
  </div>
  <div class="scene-header__item" *ngIf="isContextAVehicle()">
    <button mat-button (click)="showAxlesSettings()">
      <mat-icon>settings_input_composite</mat-icon>
      <div class="scene-header__item__title" i18n>Osie</div>
    </button>
  </div>
  <div class="scene-header__item" *ngIf="isNotEmptyContext()">
    <button mat-button (click)="showGridSettings()">
      <mat-icon>grid_on</mat-icon>
      <div class="scene-header__item__title" i18n>Siatka</div>
    </button>
  </div>
  <div class="scene-header__item labels-view" *ngIf="isNotEmptyContext()">
    <button mat-button (click)="toggleLabelView()">
      <mat-icon>rule</mat-icon>
      <div class="scene-header__item__title" i18n>Widok ładunku</div>
    </button>
    <app-label *ngIf="labelViewVisible"></app-label>
  </div>
</ng-template>
