import { Mesh } from 'three';
import { Constants } from '../../lib/constants';

import { Axle } from './axle';

export abstract class AxleMesh extends Mesh {
  protected description: string;
  public axle: Axle;
  private aType: string;

  constructor(type: string, obj: Axle) {
    super();
    this.axle = obj;
    this.aType = type;
    this.name = 'axle-mesh';
    this.material = [
      Constants.NEUTRAL_AXLE_MATERIAL,
      Constants.SUCCESS_AXLE_MATERIAL,
      Constants.WRONG_AXLE_MATERIAL
    ];
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(value: string) {
    this.description = value;
  }

  public getType() {
    return this.aType;
  }
}
