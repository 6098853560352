import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { CustomLoadService } from '../../../lib/custom-load.service';
import { LoadService } from '../../../lib/load.service';
import { LoadService as LoadUiService } from '../../../load.service';
import { UiService } from '../../../../services/ui.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LoadListItem } from '../../../lib/load-list-item';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from '../../../../confirmation-dialog/confirmation-dialog.component';
import { LoadComponentService } from '../../../../services/load-component.service';
import { CreateComponent } from '../../../../load/form/create/create.component';
import { flashBackground } from '../../../../../../animations/animations';

@Component({
  selector: 'app-car-trailer-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.less'],
  animations: [flashBackground]
})
export class SelectListComponent implements OnInit, OnDestroy {
  @Input() listType: string;
  public items: LoadListItem[] = [];

  get selected(): LoadListItem[] {
    return this.items.filter((item) => item.selected);
  }
  public searchText: string;
  protected loadShape = 'car-trailer';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: LoadService,
    private customLoadService: CustomLoadService,
    private loadUiService: LoadUiService,
    private loadComponentService: LoadComponentService,
    public ui: UiService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log('car-trailer/select-list/select-list.component.ts: init');
    //this.select(null);

    if (this.listType === 'system') {
      this.service
        .getByShape(this.loadShape)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((list) => {
          console.debug('car trailer list', this.loadShape, list);
          const newList = list.map((load) => new LoadListItem(load));
          this.loadUiService.updateList(newList);
        });
    } else if (this.listType === 'user') {
      this.customLoadService
        .getByShape(this.loadShape)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((list) => {
          const newList = list.map((load) => new LoadListItem(load));
          newList.forEach((item) => {
            const old = this.loadUiService.currentSelected.find(
              (l) => l.load.uuid === item.load.uuid
            );
            if (old) {
              item.selected = true;
            }
          });
          this.loadUiService.updateList(newList);
        });
    }
    this.loadUiService.list$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.items = list;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public selectSingle(item: LoadListItem): void {
    this.items.forEach(
      (l) => (l.selected = item && item.load.uuid === l.load.uuid)
    );
    this.loadUiService.updateList(this.items);
    this.loadUiService.updateSelected(this.selected);
    this.loadUiService.setSingleItemForScroll(item);
  }

  public delete(item: LoadListItem): void {
    this.customLoadService
      .delete(item.load)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.items = this.items.filter((l) => l.load.uuid !== item.load.uuid);
        this.loadUiService.updateList(this.items);
        this.loadUiService.updateSelected(this.selected);
        //this.select(null);
      });
  }

  public deleteAll(): void {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: { action: ConfirmationDialogAction.deleteAllCustomLoads }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() =>
          this.customLoadService.deleteMany(this.items.map((l) => l.load))
        )
      )
      .subscribe(() => {
        this.items = [];
        this.loadUiService.updateList(this.items);
        this.loadUiService.updateSelected(this.selected);
        //this.select(null);
      });
  }

  protected cntChanged(item: LoadListItem) {
    if (item.cnt > 1) {
      item.selected = true;
      this.loadUiService.updateList(this.items);
      this.loadUiService.updateSelected(this.selected);
    }
  }

  protected deselect(item: LoadListItem) {
    item.selected = false;
    this.loadUiService.updateList(this.items);
    this.loadUiService.updateSelected(this.selected);
  }

  protected updateSelection(item: LoadListItem, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.loadUiService.updateList(this.items);
    this.loadUiService.updateSelected(this.selected);
  }

  protected selectAll() {
    const selected = !this.areAllSelected();
    this.items.forEach((item) => (item.selected = selected));
    this.loadUiService.updateList(this.items);
    this.loadUiService.updateSelected(this.selected);
  }

  protected areAllSelected() {
    const selectedCnt = this.items.filter((x) => x.selected).length;
    return selectedCnt > 0 && selectedCnt === this.items.length;
  }

  protected noop() {}

  protected edit(item: LoadListItem) {
    this.loadComponentService.clear('expert.component.ts createNew');
    const component = this.loadComponentService.add(CreateComponent);
    component.instance.setEdit(item.load);
  }
}
