<div class="inputs" [ngSwitch]="selectedFilter">
  <div class="week" *ngSwitchCase="filterType.week">
    <div class="row">
      <mat-form-field>
        <mat-label i18n>Tydzień</mat-label>
        <select matNativeControl [(ngModel)]="currentWeek">
          <option
            *ngFor="let week of getWeekNumbers(currentDate.getFullYear())"
            [value]="week"
          >
            {{ week }}
          </option>
        </select>
      </mat-form-field>
    </div>
    <div class="row">
      <button
        mat-flat-button
        color="accent"
        (click)="changeWeekYear(currentDate.getFullYear() - 1)"
      >
        &lt;
      </button>
      <button
        mat-flat-button
        color="accent"
        class="name"
        (click)="changeWeekYear(now.getFullYear())"
      >
        {{ currentDate | date : 'yyyy' }}
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="changeWeekYear(currentDate.getFullYear() + 1)"
      >
        &gt;
      </button>
    </div>
  </div>
  <div class="month" *ngSwitchCase="filterType.month">
    <div class="row">
      <button
        mat-flat-button
        class="prev-month"
        color="accent"
        (click)="
          changeMonth(currentDate.getMonth() - 1, currentDate.getFullYear())
        "
      >
        &lt;
      </button>
      <button
        mat-flat-button
        color="accent"
        class="name"
        (click)="changeMonth(now.getMonth(), now.getFullYear())"
      >
        {{ currentDate | date : 'LLLL' }}
      </button>
      <button
        mat-flat-button
        class="next-month"
        color="accent"
        (click)="
          changeMonth(currentDate.getMonth() + 1, currentDate.getFullYear())
        "
      >
        &gt;
      </button>
    </div>
    <div class="row">
      <button
        mat-flat-button
        color="accent"
        (click)="changeMonthYear(currentDate.getFullYear() - 1)"
      >
        &lt;
      </button>
      <button
        mat-flat-button
        color="accent"
        class="name"
        (click)="changeMonthYear(now.getFullYear())"
      >
        {{ currentDate | date : 'yyyy' }}
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="changeMonthYear(currentDate.getFullYear() + 1)"
      >
        &gt;
      </button>
    </div>
  </div>
  <div class="day" *ngSwitchCase="filterType.day">
    <mat-form-field appearance="fill">
      <mat-label i18n>Wybierz dzień</mat-label>
      <input
        matInput
        [value]="currentDate"
        [matDatepicker]="picker"
        (dateChange)="changeDay($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="range" *ngSwitchCase="filterType.range">
    <mat-form-field appearance="fill">
      <mat-label i18n>Wybierz zakres</mat-label>
      <mat-date-range-input [rangePicker]="rangePicker">
        <input
          matStartDate
          [value]="rangeFrom"
          i18n-placeholder
          placeholder="Od"
          (dateChange)="changeRangeFrom($event)"
        />
        <input
          matEndDate
          [value]="rangeTo"
          i18n-placeholder
          placeholder="Do"
          (dateChange)="changeRangeTo($event)"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matSuffix
        [for]="rangePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #rangePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="year" *ngSwitchCase="filterType.year">
    <div class="row">
      <button
        mat-flat-button
        color="accent"
        (click)="changeYear(currentDate.getFullYear() - 1)"
      >
        &lt;
      </button>
      <button
        mat-flat-button
        color="accent"
        class="name"
        (click)="changeYear(now.getFullYear())"
      >
        {{ currentDate | date : 'yyyy' }}
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="changeYear(currentDate.getFullYear() + 1)"
      >
        &gt;
      </button>
    </div>
  </div>
  <div class="default text-center" *ngSwitchDefault>
    <div #allText>
      <ng-content select="[allText]"></ng-content>
    </div>
    <ng-container *ngIf="!allText?.childElementCount" i18n
      >Wszystkie projekty</ng-container
    >
  </div>
</div>
<div class="types">
  <div>
    <button
      *ngIf="withWeek"
      mat-flat-button
      color="accent"
      [class.current]="filterType.week === selectedFilter"
      (click)="changeFilterType(filterType.week)"
      i18n
    >
      Filtr tygodnia
    </button>
  </div>
  <div>
    <button
      mat-flat-button
      color="accent"
      [class.current]="filterType.month === selectedFilter"
      (click)="changeFilterType(filterType.month)"
      i18n
    >
      Filtr miesiąca
    </button>
  </div>
  <div>
    <button
      mat-flat-button
      color="accent"
      [class.current]="filterType.day === selectedFilter"
      (click)="changeFilterType(filterType.day)"
      i18n
    >
      Filtr dnia
    </button>
  </div>
  <div>
    <button
      mat-flat-button
      color="accent"
      [class.current]="filterType.range === selectedFilter"
      (click)="changeFilterType(filterType.range)"
      i18n
    >
      Filtr zakresu
    </button>
  </div>
  <div>
    <button
      mat-flat-button
      color="accent"
      [class.current]="filterType.year === selectedFilter"
      (click)="changeFilterType(filterType.year)"
      i18n
    >
      Filtr roku
    </button>
  </div>
  <div>
    <button
      mat-flat-button
      color="accent"
      class="filter-none"
      [class.current]="filterType.none === selectedFilter"
      (click)="changeFilterType(filterType.none)"
      i18n
    >
      Brak filtra
    </button>
  </div>
</div>
