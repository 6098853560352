import { Box3, Color, LineBasicMaterial, Mesh, Object3D, Vector3 } from 'three';
import { CarTrailerType1 } from './car-trailer-type-1';
import { LoadMesh } from '../../../lib/load-mesh';
import { LoadDisplaySettings } from '../../../lib/load-display-settings';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { ModelLoaderService } from 'src/app/raycasting/lib/model-loader.service';

export class BrenderupMesh extends LoadMesh {
  private model: GLTF;
  private modelName: string;

  public constructor(
    trailer: CarTrailerType1,
    settings: LoadDisplaySettings,
    protected modelLoader: ModelLoaderService
  ) {
    super('rectangular', trailer.color, settings, trailer.uuid, modelLoader);
    this.modelName = trailer.modelName!;
    this.init(trailer);
  }

  public getName(): string {
    return '(Brenderup default)';
  }

  public updateColor(color: number) {
    this.obj.traverse((child) => {
      if (child instanceof Mesh) {
        child.material.color = new Color(color);
      }
    });
  }

  private init(trailer: CarTrailerType1) {
    const mat = new LineBasicMaterial(this.getLineSettings());
    const centerOffset = new Vector3(
      trailer.length / 2,
      trailer.height / 2,
      trailer.width / 2
    );
  }

  public async loadModel(): Promise<Object3D> {
    return this.modelLoader
      .loadGlbModel(this.modelName)
      .then((model) => {
        this.obj.add(model);
        this.obj.userData.size = model.userData.size.clone();
        this.obj.userData.halfSize = model.userData.halfSize.clone();
        this.modelLoaded = true;
        return model;
      })
      .catch((reason) => {
        console.error(
          `Brenderup ${this.modelName} model loading failed`,
          reason
        );
        throw reason;
      });
  }

  public getMaxX() {
    return this.obj.userData.size.x;
  }

  public getMaxY() {
    return this.obj.userData.size.y;
  }

  public getMaxZ() {
    return this.obj.userData.size.z;
  }

  protected prepareMesh() {
    // this.mesh = new THREE.Mesh(this.object, super.getMaterial());
    //const wireframe = new THREE.WireframeGeometry(this.mesh.geometry);
    //const wireframe = new EdgesGeometry(this.obj.geometry);
    //const mat = new LineBasicMaterial(this.getLineSettings());
    //const line = new LineSegments(wireframe, mat);
    //line.name = 'border';
    //this.obj.add(line);
  }

  public calculateModelVolume() {
    let volume = 0;
    this.obj.traverse((child) => {
      if (!child.name.endsWith('-bb')) {
        return;
      }
      const box = new Box3().setFromObject(child);
      const size = box.getSize(new Vector3());
      volume += size.x * size.y * size.z;
    });
    return volume;
  }
}
