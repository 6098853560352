<div *ngIf="showPendingList$ | async" class="pending-orders-list">
  <div class="loads-list-header">
    <span i18n>Zamówienia do rozmieszczenia:</span>
    <button
      class="close"
      (click)="close()"
      title="Wyczyść listę zamówień"
      i18n-title
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="loaded">
    <div
      *ngFor="let order of pending$ | async"
      class="loaded-element"
      [class.nomodel]="!order.HasModel"
      [attr.title]="!order.HasModel ? 'Brak modelu 3D' : ''"
      i18n-title
    >
      <span>[{{ order.Number }}]</span
      ><span>{{ order.Qty }} x {{ order.ItemName }} ({{ order.ItemId }})</span>
      <span (click)="remove(order)" class="remove">
        <mat-icon>delete_forever</mat-icon>
      </span>
    </div>
  </div>
  <div class="pending-add">
    <button
      mat-raised-button
      class="btn-primary"
      (click)="loadPending()"
      type="button"
      i18n
    >
      Generuj rozmieszczenie
    </button>
  </div>
</div>
