import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from './reports.service';
import { OrderService } from '../order.service';
import { LoadService } from '../../load/lib/load.service';
import { Load } from '../../load/lib/load';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-order-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less']
})
export class ReportsComponent implements OnInit, OnDestroy {
  protected loads: Load[] = [];
  protected filteredLoads: Load[] = [];

  protected form: FormGroup;

  protected year1: number;
  protected year2: number;
  protected year3: number;
  protected year4: number;

  protected yearHalf1: number;
  protected yearHalf2: number;
  protected yearHalf3: number;
  protected yearHalf4: number;

  private unsubscribe$ = new Subject<void>();

  public constructor(
    private fb: FormBuilder,
    protected orderService: OrderService,
    protected reportService: ReportsService,
    private loadService: LoadService
  ) {
    const month = new Date().getMonth() + 1;
    this.year1 = new Date().getFullYear();
    if (month <= 6) {
      this.yearHalf1 = 1;
      this.yearHalf2 = 2;
      this.yearHalf3 = 1;
      this.yearHalf4 = 2;

      this.year2 = this.year1 - 1;
      this.year3 = this.year1 - 1;
      this.year4 = this.year1 - 2;
    } else {
      this.yearHalf1 = 2;
      this.yearHalf2 = 1;
      this.yearHalf3 = 2;
      this.yearHalf4 = 1;

      this.year2 = this.year1;
      this.year3 = this.year1 - 1;
      this.year4 = this.year1 - 1;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.loadService.loads$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.loads = list;
        this.filterLoads('');
      });
    this.filteredLoads = this.loads.slice();
    this.form.get('load')!.valueChanges.subscribe((value) => {
      this.filteredLoads = this.filterLoads(value);
      if (!value || value instanceof Load) {
        this.reportService.changeLoad(value);
      }
    });
    this.form.get('toCountry')!.valueChanges.subscribe((value) => {
      console.log('change to country', value);
      this.reportService.changeToCountry(value);
    });
    this.form.get('fillType')!.valueChanges.subscribe((value) => {
      console.log('change fill type', value);
      this.reportService.changeFillType(value);
    });

    this.form.get('target')!.valueChanges.subscribe((value) => {
      console.log('change target', value);
      this.reportService.changeTarget(value);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createForm(): void {
    this.form = this.fb.group({
      toCountry: ['', []],
      load: [null, []],
      target: [null, []],
      fillType: ['LDM', []]
    });
    this.form.get('toCountry')?.patchValue(this.reportService.getToCountry());
    this.form.get('load')?.patchValue(this.reportService.getLoad());
    this.form.get('fillType')?.patchValue(this.reportService.getFillType());
    this.form.get('target')?.patchValue(this.reportService.getTarget());
  }

  protected filterLoads(value: string | Load): Load[] {
    const name = value instanceof Load ? value.name : value || '';
    const filterValue = name.toLowerCase();
    return this.loads.filter((load) =>
      load.name.toLowerCase().includes(filterValue)
    );
  }

  protected displayLoad(load: Load): string {
    return load && load.name ? load.name : '';
  }

  protected get load() {
    return this.form.get('load');
  }
}
