<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Import własnych ładunków</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <!--<mat-progress-bar mode="query"></mat-progress-bar>-->
      <div class="file-template text-center">
        <div i18n>
          Proszę załączyć plik w formacie *.csv, *.xlsx lub *.xls o poniższej
          strukturze
        </div>
        <img
          src="../../assets/images/wzor_importu_ladunkow.png"
          class="file-template"
        />

        <button mat-button (click)="downloadTemplate()" color="primary">
          <mat-icon>download</mat-icon>
          <ng-container i18n>Pobierz wzór</ng-container>
        </button>
      </div>

      <div *ngIf="invalidFormat" i18n>
        Nieprawidłowy format - dopuszczalne formaty to *.xlsx oraz *.csv
      </div>

      <div class="upload-template">
        <div class="advanced">
          <mat-checkbox [(ngModel)]="advanced" i18n
            >Opcje zaawansowane</mat-checkbox
          >
          <section class="options-section" *ngIf="advanced">
            <label id="action-type-description" i18n
              >Co zrobić z istniejącymi ładunkami?</label
            >

            <mat-radio-group
              aria-label="Select an option"
              aria-labelledby="action-type-description"
              [(ngModel)]="actionType"
              class="action-type"
            >
              <mat-radio-button value="append" class="action-type-button" i18n
                >Nic</mat-radio-button
              >
              <mat-radio-button value="keep" class="action-type-button" i18n
                >Zachowaj duplikaty wg nazwy</mat-radio-button
              >
              <mat-radio-button value="override" class="action-type-button" i18n
                >Zastąp duplikaty wg nazwy</mat-radio-button
              >
              <mat-radio-button value="clear" class="action-type-button" i18n
                >Usuń istniejące</mat-radio-button
              >
            </mat-radio-group>
          </section>
        </div>
        <input
          type="file"
          class="file-input"
          [accept]="requiredFileType"
          (change)="onFileSelected($event)"
          #fileUpload
        />

        <div class="file-upload">
          {{ fileName }}

          <button
            mat-mini-fab
            color="primary"
            class="upload-btn"
            (click)="fileUpload.click()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>

        <div class="progress">
          <mat-progress-bar
            class="progress-bar"
            mode="determinate"
            [value]="uploadProgress"
            *ngIf="uploadProgress"
          >
          </mat-progress-bar>

          <mat-icon
            class="cancel-upload"
            (click)="cancelUpload()"
            *ngIf="uploadProgress"
            >delete_forever</mat-icon
          >
        </div>

        <div class="summary">
          {{ summary }}
        </div>
      </div>
      <div class="buttons">
        <button mat-raised-button (click)="close()" cdkFocusInitial i18n>
          Zamknij
        </button>
      </div>
    </div>
  </div>
</div>
