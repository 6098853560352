import { Load } from 'src/app/load/lib/load';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
// import { LoadingSpace } from 'src/app/loading-space/lib/loading-space';
import { OrbitControlsState } from '../model/orbit-controls-state';

import { MatrixHistory } from './matrix.history';
import { Vector } from './vector';

export class PositionerRequest {
  uuid: string;
  historyUuid: string;
  message: string;
  notLoadedLoads: Load[];
  existingLoads: Load[]; //should not be used
  addedLoads: Load[]; //should not be used
  vehicle: Vehicle; //will be deprecated
  // loadingSpace: LoadingSpace;
  matrix: any;
  // boundingBox: BoundingBox;
  maxLoadWidth: number;
  rotateDirection: string;
  load: Load;
  action: string;
  vector: Vector;
  color: number;
  history: MatrixHistory[];
  historyCurrentStep: number;
  initialTimestamp: number;
  orbitControlState: OrbitControlsState;
  projectId: string;
  //history: PositionHistory[];

  // vehiclePosition: Vector; //wykorystywane przy moveRequest
  loadPosition: Vector; // wykorzystywane przy moveRequest - końcowa pozycja środka przenoszonego ładunku

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
