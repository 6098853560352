<mat-expansion-panel (opened)="opened = true" (closed)="opened = false">
  <mat-expansion-panel-header>
    <mat-panel-title i18n>Demontuj elementy</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="disassemble-container">
    <div [formGroup]="form" class="disassemble-options">
      <ng-container *ngFor="let item of form.controls | keyvalue">
        <mat-checkbox
          [formControlName]="item.key"
          (change)="change(item.key, $event)"
        >
          <ng-container [ngSwitch]="item.key">
            <ng-container *ngSwitchCase="'wheels'" i18n>Koła</ng-container>
            <ng-container *ngSwitchCase="'shaft'" i18n>Dyszel</ng-container>
            <ng-container *ngSwitchCase="'fenders'" i18n>Błotniki</ng-container>
            <ng-container *ngSwitchCase="'ramp'" i18n>Rampy</ng-container>
            <ng-container *ngSwitchCase="'lights'" i18n>Światła</ng-container>
            <ng-container *ngSwitchCase="'jockeywheel'" i18n
              >Koło podporowe</ng-container
            >
            <ng-container *ngSwitchDefault i18n
              >Nieznany komponent</ng-container
            >
          </ng-container>
        </mat-checkbox>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel>
