import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ReportsService } from '../reports.service';
import { Subject, takeUntil } from 'rxjs';
import { DateRange } from 'src/app/dates-filter';
import jsPDF from 'jspdf/dist/jspdf.es.min';
import html2canvas from 'html2canvas/dist/html2canvas.esm';

@Component({
  selector: 'app-order-reports-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.less']
})
export class ChartComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() year: number;
  @Input() yearHalf: number;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective<'bar'> | undefined;
  @ViewChild('chart') chartElement: ElementRef;

  private unsubscribe$ = new Subject<void>();

  protected chartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        min: 0,
        max: 100
      }
    },
    plugins: {
      legend: {
        display: true
      },
      title: {
        display: true,
        text: ''
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + '%';
            }
            return label;
          }
        }
      }
    }
  };
  protected chartType = 'bar' as const;

  protected chartData: ChartData<'bar'> = {
    labels: [],
    datasets: []
  };

  public constructor(private service: ReportsService) {}

  ngOnInit(): void {
    this.service.update$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateData();
    });
  }

  ngAfterViewInit(): void {
    this.chartData.labels = this.generateLabels();
    //this.chartData.datasets = this.randomData();
    this.chartOptions.plugins.title.text =
      `${this.year} - ${this.yearHalf} ` + $localize`półrocze`;
    this.chartOptions = { ...this.chartOptions };
    //this.chart?.update();
    this.updateData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateData() {
    const start = new Date();
    const end = new Date();
    start.setUTCFullYear(this.year);
    end.setUTCFullYear(this.year);
    start.setUTCDate(1);
    start.setUTCHours(0);
    start.setUTCMinutes(0);
    start.setUTCSeconds(0);
    start.setUTCMilliseconds(0);
    end.setUTCHours(23);
    end.setUTCMinutes(59);
    end.setUTCSeconds(59);
    end.setUTCMilliseconds(0);
    if (this.yearHalf == 1) {
      start.setUTCMonth(0);
      end.setUTCMonth(5);
      end.setUTCDate(30);
    } else {
      start.setUTCMonth(6);
      end.setUTCMonth(11);
      end.setUTCDate(31);
    }
    const range = new DateRange(start, end);
    this.service
      .fetchChartData(range)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data.status == 'ok') {
          console.log('chart data component', data.data.filled);
          const dataset: any[] = [
            {
              label: $localize`Wypełnienie`,
              data: data.data.filled,
              backgroundColor: 'rgb(109, 225, 160)',
              order: 1
            },
            {
              label: $localize`Pusta przestrzeń`,
              data: data.data.empty,
              backgroundColor: 'rgb(255, 99, 132)',
              order: 1
            }
          ];
          if (this.service.getTarget()) {
            dataset.push({
              label: $localize`Cel`,
              data: Array.from({ length: 6 }, () => this.service.getTarget()),
              borderColor: 'rgb(54, 162, 235)',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              type: 'line',
              order: 0
            });
          }
          this.chartData.datasets = dataset;
        }
        this.chart?.update();
      });

    //this.chartData.datasets = this.randomData();
  }

  private generateLabels() {
    const monthStart = this.yearHalf == 1 ? 1 : 7;
    const labels: string[] = [];
    for (let index = 0; index < 6; index++) {
      const month = ('' + (monthStart + index)).padStart(2, '0');
      labels.push(`${month} - ${this.year}`);
    }
    console.log('labels', labels);
    return labels;
  }

  private randomData() {
    const fill: number[] = [];
    for (let index = 0; index < 6; index++) {
      fill.push(50 + Math.floor(Math.random() * 50));
    }
    const empty = fill.map((f) => 100 - f);
    const data: any[] = [
      {
        label: $localize`Wypełnienie`,
        data: fill,
        backgroundColor: 'rgb(109, 225, 160)'
      },
      {
        label: $localize`Pusta przestrzeń`,
        data: empty,
        backgroundColor: 'rgb(255, 99, 132)'
      }
    ];
    console.log('data', data);
    return data;
  }

  protected downloadChartPdf = () => {
    const html = this.chartElement.nativeElement;
    html2canvas(html).then((canvas) => {
      const doc = new jsPDF();
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const imgProps = doc.getImageProperties(imgData);
      const width = doc.internal.pageSize.getWidth() - 2 * 10;
      const pdfHeight = (imgProps.height * width) / imgProps.width;
      doc.addImage(imgData, 'JPEG', 10, 10, width, pdfHeight);
      doc.save(`Report-${this.year} - ${this.yearHalf}.pdf`);
    });
  };

  // events
  public chartClicked({
    event,
    active
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    //console.log(event, active);
  }

  public chartHovered({
    event,
    active
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    //console.log(event, active);
  }
}
