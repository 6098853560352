<div *ngIf="showPendingList$ | async">
  <div class="loads-list-header">
    <span i18n>Do załadunku ({{ (pending$ | async)?.countLoads() }}):</span>
  </div>
  <div class="loaded">
    <div
      *ngFor="let group of (pending$ | async)?.loads"
      class="pending-element"
    >
      <app-pending-load [group]="group" [cnt]="group.cnt"></app-pending-load>
    </div>
  </div>
  <div class="buttons">
    <div class="pending-add">
      <button
        mat-raised-button
        class="btn-primary"
        (click)="loadPending()"
        type="button"
        i18n
      >
        Załaduj
      </button>
    </div>

    <div class="pending-add" *ngIf="canLoadToNewVehicle">
      <button
        mat-mini-fab
        color="secondary"
        (click)="loadPendingToNewVehicle()"
        i18n-matTooltip
        matTooltip="Załaduj na nowy pojazd"
        [matTooltipPosition]="'right'"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="loads-list-header">
  <button
    class="change-order"
    (click)="toggleReorderMode()"
    [class.enabled]="reorderMode"
    i18n-matTooltip
    matTooltip="Zmień kolejność ładunków na liście"
    [matTooltipPosition]="'right'"
  >
    <mat-icon>sort</mat-icon>
  </button>
  <span i18n>Dodane ładunki ({{ loads.countLoads() }}):</span>
</div>
<div
  class="loaded"
  cdkDropList
  [cdkDropListDisabled]="!reorderMode"
  (cdkDropListDropped)="orderChanged($event)"
>
  <div
    *ngFor="let group of loads.loads"
    class="loaded-element"
    cdkDrag
    cdkDragLockAxis="y"
  >
    <app-loaded-element
      [group]="group"
      [drag]="reorderMode"
    ></app-loaded-element>
    <div class="drag-placeholder" *cdkDragPlaceholder></div>
  </div>
</div>
