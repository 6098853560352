import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, share, take, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { VehicleContext } from '../lib/model/vehicle-context';
import { environment } from '../../environments/environment';

import { ShareProject } from '../lib/model/share-project';
import { ShareProjectResponse } from '../lib/communication/share-project.response';
import { HistoryResponse } from '../lib/communication/history.response';
import { LoadEventObject } from '../load/lib/load-event-object';
import { Load } from '../load/lib/load';
import { HttpService } from '../services/http.service';
import { StatusResponse } from '../lib/communication/status.response';
import {
  RemoveLoadsRequest,
  UpdateLoadRequest,
  HistoryRequest,
  AddLoadsRequest,
  DuplicateVehicleRequest,
  ClearLoadsRequest,
  ReloadLoadsRequest
} from './requests';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { DebugPointsService } from '../lib/debug/debug-points.service';
import { LoadFactory } from '../load/lib/load-factory';
import { LoadGroupList } from '../lib/model/load-group/load-group-list';
import { AddLoadListRequest } from './requests/add-load-list-request';

type SaveContextResponse = {
  calculation: PositionerResponse;
  updatedContexts: string[];
};

@Injectable({
  providedIn: 'root'
})
export class CalculationService extends HttpService {
  private positionerUrl = environment.apiUrl + '/positioner';
  private calculationsUrl = environment.apiUrl + '/positioner/calculations';
  private requestedContextUpdate = new Subject<string[]>();

  constructor(
    protected http: HttpClient,
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory,
    private debugPointsService: DebugPointsService
  ) {
    super(http);
  }

  get requestedContextUpdate$(): Observable<string[]> {
    return this.requestedContextUpdate.asObservable();
  }

  public fetchCurrentCalculations(
    projectUuid: string
  ): Observable<PositionerResponse[]> {
    const httpCall = this.http
      .get<any>(this.calculationsUrl + '/by-project/' + projectUuid)
      .pipe(
        take(1),
        map((response) =>
          response.list.map(
            (calc: any) =>
              new PositionerResponse(
                calc,
                this.vehicleFactory,
                this.loadFactory
              )
          )
        )
      );
    return httpCall;
  }

  public removeContext(uuid: string): Observable<StatusResponse<void>> {
    return this.http
      .delete(this.calculationsUrl + '/' + uuid)
      .pipe(
        map((response: any) => new StatusResponse<void>(response.status, null))
      );
  }

  public removeAllContextsInProject(
    projectId: string
  ): Observable<StatusResponse<void>> {
    return this.http
      .delete(this.calculationsUrl + '/by-project/' + projectId)
      .pipe(
        map((response: any) => new StatusResponse<void>(response.status, null))
      );
  }

  public saveContext(context: VehicleContext): Observable<PositionerResponse> {
    return this.http.post(this.calculationsUrl, context).pipe(
      take(1),
      tap((response: SaveContextResponse) => {
        if ((response.updatedContexts || []).length > 0) {
          this.requestedContextUpdate.next(response.updatedContexts);
        }
      }),
      map(
        (response: SaveContextResponse) =>
          new PositionerResponse(
            response.calculation,
            this.vehicleFactory,
            this.loadFactory
          )
      )
    );
  }

  public removeLoads(
    removeLoads: Load[],
    context: VehicleContext
  ): Observable<PositionerResponse> {
    const request = new RemoveLoadsRequest(removeLoads, context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/remove-loads', request)
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
  }

  /**
   * Aktualizacja ładunków wymagająca przeładunku
   *
   * @param changeLoad Load
   * @param context VehicleContext
   */
  public changeLoadSize(
    changeLoads: Load[],
    context: VehicleContext
  ): Observable<PositionerResponse> {
    const request = new AddLoadListRequest(
      changeLoads,
      context,
      this.loadFactory,
      false
    );
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/change-size', request)
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
  }

  /**
   * Aktualizacja pojedycznego ładunku bez zmiany rozmieszczenia
   *
   * @param changeLoad Load
   * @param context VehicleContext
   */
  public updateLoads(
    changeLoads: Load[],
    context: VehicleContext
  ): Observable<PositionerResponse> {
    const request = new UpdateLoadRequest(changeLoads, context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/update-load', request)
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
  }

  public checkAlternativeCalculations(
    uuid: string
  ): Observable<{ message: string; alternatives: PositionerResponse[] }> {
    const httpCall = this.http
      .get<any>(
        environment.apiUrl + '/database/calculation/alternatives/' + uuid
      )
      .pipe(
        map((response) => {
          const alternatives = (response.alternatives || []).map(
            (r) =>
              new PositionerResponse(r, this.vehicleFactory, this.loadFactory)
          );
          return {
            message: response.message,
            alternatives
          };
        })
      );
    return httpCall;
  }

  public history(
    currentContext: VehicleContext,
    currentTiemstamp: number,
    action: string
  ): Observable<HistoryResponse> {
    const request = new HistoryRequest({
      uuid: currentContext.getUuid(),
      current: currentTiemstamp,
      action
    });
    const httpCall = this.http
      .post<any>(environment.apiUrl + '/database/calculation/history/', request)
      .pipe(map((response) => new HistoryResponse(response)));
    return httpCall;
  }

  public setHistoryHead(
    context: VehicleContext
  ): Observable<PositionerResponse> {
    const httpCall = this.http
      .post<any>(
        environment.apiUrl +
          '/database/calculation/history/set-head/' +
          context.getHistoryUuid(),
        {}
      )
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
  }

  public changeWeight(
    changeLoads: Load[],
    context: VehicleContext
  ): Observable<PositionerResponse> {
    context.calculateStatisticsSimplified();
    return this.saveContext(context);
  }

  public addLoadsFromPendingList(
    list: LoadGroupList,
    context: VehicleContext
  ): Observable<PositionerResponse> {
    const loads = list.loads.flatMap((group) => group.list);
    const request = new AddLoadListRequest(
      loads,
      context,
      this.loadFactory,
      false
    );
    console.log(
      `Adding ${loads.length} loads from pending list on vehicle`,
      context.getVehicle().name
    );
    const httpCall = this.http
      .post<PositionerResponse>(this.positionerUrl + '/add-load', request)
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
    //return this.findPlaceForLoads(request.addedLoads, context, background);
  }

  public addLoads(
    loadBundle: LoadEventObject[],
    context: VehicleContext
  ): Observable<PositionerResponse> {
    const request = new AddLoadsRequest(loadBundle, context, this.loadFactory);

    console.log('addLoads request', request);
    const httpCall = this.http
      .post<PositionerResponse>(this.positionerUrl + '/add-load', request)
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
  }

  public optimizeVehicle(context: VehicleContext) {
    const request = new AddLoadsRequest([], context, this.loadFactory);

    const httpCall = this.http
      .post<PositionerResponse>(
        this.positionerUrl + '/optimize-vehicle',
        request
      )
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
  }

  public reloadLoads(context: VehicleContext): Observable<PositionerResponse> {
    const request = new ReloadLoadsRequest(context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/add-load', request)
      .pipe(
        take(1),
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        )
      );
    return httpCall;
  }

  public shareProject(
    shareProject: ShareProject
  ): Observable<ShareProjectResponse> {
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/share-project', shareProject)
      .pipe(map((response) => new ShareProjectResponse(response)));
    return httpCall;
  }

  public clearLoads(context: VehicleContext): Observable<PositionerResponse> {
    const request = new ClearLoadsRequest(context);
    const httpCall = this.http
      .post<any>(this.positionerUrl + '/clear-loads', request)
      .pipe(
        map((response) => {
          const calculation = new PositionerResponse(
            response,
            this.vehicleFactory,
            this.loadFactory
          );
          return calculation;
        })
      );
    return httpCall;
  }

  public getCalculation(historyUuid: string) {
    const httpCall = this.http
      .get<any>(this.calculationsUrl + '/' + historyUuid)
      .pipe(catchError(this.handleError('getCalculation', [])))
      .pipe(
        map(
          (response) =>
            new PositionerResponse(
              response,
              this.vehicleFactory,
              this.loadFactory
            )
        ),
        share()
      );
    return httpCall;
  }
}
