import { Load } from 'src/app/load/lib/load';
import { VehicleSuggestionType } from 'src/app/suggest-vehicle/lib/suggestion-type';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';

export class VehicleRequest {
  uuid: string; //uuidv4
  loads: Load[];
  matrix: any;
  currentVehicle: Vehicle;
  type: VehicleSuggestionType;
  loadBoundingBoxes: { x: number; y: number; z: number }[] = [];
  constructor(obj?: Partial<VehicleRequest>) {
    Object.assign(this, obj);
  }
}
