import { Load } from 'src/app/load/lib/load';
import { LoadFactory } from 'src/app/load/lib/load-factory';

export type LoadGroupType = new () => LoadGroup;

export class LoadGroup {
  public cnt: number = 0;
  public list: Array<Load>;
  public expanded = false;

  constructor() {
    this.list = new Array();
  }

  public get load(): Load {
    return this.list.length > 0 ? this.list[0] : null;
  }

  public get lastLoad(): Load {
    if (this.list.length > 0) {
      return this.list[this.list.length - 1];
    }
    return null;
  }

  public addLoad(load: Load): LoadGroup {
    this.list.push(load);
    this.cnt = this.list.length;
    return this;
  }

  public removeSingleLoad(load: Load) {
    this.list = this.list.filter((el) => el.uuid !== load.uuid);
    this.cnt = this.list.length;
  }

  /*public updateCount(cnt: number, loadFactory: LoadFactory): LoadGroup {
    if (cnt > this.cnt) {
      let diff = cnt - this.cnt;
      this.cnt = cnt;
      while (diff-- > 0) {
        const load = loadFactory.recreateLoad(this.load);
        load.generateUuid();
        this.list.push(load);
      }
    } else if (cnt < this.cnt) {
      this.cnt = cnt;
      this.list = this.list.slice(0, cnt);
    }
    return this;
  }*/

  public isFloorableBoth() {
    return this.load?.floorableBottom && this.load?.floorableTop;
  }

  public isFloorableOnlyTop() {
    return !this.load?.floorableBottom && this.load?.floorableTop;
  }

  public isFloorableOnlyBottom() {
    return this.load?.floorableBottom && !this.load?.floorableTop;
  }

  public isNonFloorable() {
    return !this.load?.floorableBottom && !this.load?.floorableTop;
  }

  public has(aLoad: Load) {
    const sameBoxes =
      //aLoad.uuid === this.load.uuid &&
      aLoad.name === this.load.name &&
      aLoad.color === this.load.color &&
      aLoad.floorableTop === this.load.floorableTop &&
      aLoad.floorableBottom === this.load.floorableBottom &&
      aLoad.weight === this.load.weight &&
      aLoad.volume === this.load.volume;

    return sameBoxes;
  }

  public hasExact(aLoad: Load) {
    for (const load of this.list) {
      if (aLoad.uuid === load.uuid) {
        return true;
      }
    }
    return false;
  }
}
