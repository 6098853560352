import { Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { GravityCenterMesh } from './gravity-center-mesh';

export class GravityCenter {
  uuid: string;
  position: Vector3;
  weight: number;

  createdAt?: Date;
  updatedAt?: Date;
  _mesh: GravityCenterMesh;

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.uuid = uuidv4();
    }
  }

  get mesh(): GravityCenterMesh {
    if (this._mesh) {
      return this._mesh;
    }
    return new GravityCenterMesh(this);
  }

  public generateUuid() {
    this.uuid = uuidv4();
  }
}
