import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { OrbitControlsService } from './orbit-controls.service';
import { SceneService } from '../scene/scene.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { Load } from '../load/lib/load';
import { Vector3 } from 'three';
import { ColliderDetector } from '../scene/collider-detector';
import { UiService } from '../services/ui.service';

type OrbitMode = 'scene' | 'load' | 'multiload';
@Component({
  selector: 'app-orbit-controls',
  templateUrl: './orbit-controls.component.html',
  styleUrls: ['./orbit-controls.component.less']
})
export class OrbitControlsComponent implements OnInit, OnDestroy {
  protected selectedLoads$: Observable<Load[]>;

  protected mode$: Observable<OrbitMode>;

  protected selectedLoads: Load[] = [];

  protected mode: OrbitMode = 'scene';

  private unsubscribe$ = new Subject<void>();
  private ctrlPressed = false;

  constructor(
    private service: OrbitControlsService,
    private sceneService: SceneService,
    private collider: ColliderDetector,
    private uiService: UiService
  ) {
    this.selectedLoads$ = sceneService.selectedLoads$;
    this.mode$ = this.selectedLoads$.pipe(
      map((loads) =>
        loads.length === 0 ? 'scene' : loads.length === 1 ? 'load' : 'multiload'
      )
    );
  }

  ngOnInit(): void {
    this.selectedLoads$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loads) => {
        console.log('orbit selected loads', loads);
        this.selectedLoads = loads;
        this.selectedLoads.forEach((l) => {
          l.mesh.obj.userData.rotation = l.mesh.obj.rotation.clone();
          l.mesh.obj.userData.position = l.mesh.obj.position.clone();
          l.prevPosition = l.position;
        });
      });
    this.mode$.pipe(takeUntil(this.unsubscribe$)).subscribe((mode) => {
      this.mode = mode;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected restoreState() {
    this.selectedLoads.forEach((l) => {
      l.mesh.obj.rotation.copy(l.mesh.obj.userData.rotation);
      l.mesh.obj.position.copy(l.mesh.obj.userData.position);
      l.updateLoadRotationFromMesh();
      l.position.copy(l.prevPosition);
    });
    this.sceneService.redrawContextWithLabels();
  }

  protected saveChanges() {
    this.selectedLoads.forEach((l) => {
      // this.sceneService.validateLoadPosition(l, null);
    });
    this.uiService.setLoading(true);
    this.sceneService
      .saveContextChanges(this.sceneService.context)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.uiService.setLoading(false);
        this.sceneService.redrawContextWithLabels();
      });
  }

  public move(value: string) {
    switch (this.mode) {
      case 'scene':
        this.service.move(value);
        break;
      case 'load':
      case 'multiload':
        this.selectedLoads.forEach((load) => {
          load.move(this.mapStringDirectionToVector(value));
          if (
            !this.collider.collided(
              load.mesh.obj,
              this.sceneService.context.getAllLoads().map((l) => l.mesh.obj)
            )
          ) {
            load.mesh.obj.userData.validPosition =
              load.mesh.obj.position.clone();
          }
        });
        this.sceneService.redrawContextWithLabels();
        break;
    }
  }

  public rotate(value: string) {
    switch (this.mode) {
      case 'scene':
        this.service.rotate(value);
        break;
      case 'load':
      case 'multiload':
        this.selectedLoads.forEach((load) => {
          const [vector, step] = this.mapStringRotationToVectorStep(value);
          load.rotate(vector, step);
          if (
            !this.collider.collided(
              load.mesh.obj,
              this.sceneService.context.getAllLoads().map((l) => l.mesh.obj)
            )
          ) {
            load.mesh.obj.userData.validRotation =
              load.mesh.obj.rotation.clone();
          }
        });
        this.sceneService.redrawContextWithLabels();
        break;
    }
  }

  public dolly(value: string) {
    this.service.dolly(value);
  }

  /**
   * Zwraca kierunek w postaci wektora. Nazwy kierunków są odwrócone w stosunku do strzałek.
   *
   * @param dir 'up'|'down'|'left'|'right'|'in'|out'
   * @returns Vector3
   */
  private mapStringDirectionToVector(dir: string) {
    const movement = this.ctrlPressed ? 100 : 10;
    switch (dir) {
      case 'right':
        return new Vector3(-1 * movement, 0, 0);
      case 'left':
        return new Vector3(movement, 0, 0);
      case 'up':
        return new Vector3(0, -1 * movement, 0);
      case 'down':
        return new Vector3(0, movement, 0);
      case 'in':
        return new Vector3(0, 0, -1 * movement);
      case 'out':
        return new Vector3(0, 0, movement);
    }
  }

  /**
   * Zwraca obrót w postaci wektora. Nazwy kierunków są odwrócone w stosunku do strzałek.
   *
   * @param dir 'up'|'down'|'left'|'right'|'in'|out'
   * @returns Vector3
   */
  private mapStringRotationToVectorStep(dir: string): [Vector3, number] {
    switch (dir) {
      case 'right':
        return [new Vector3(0, 1, 0), -(2 * Math.PI) / 36];
      case 'left':
        return [new Vector3(0, 1, 0), (2 * Math.PI) / 36];
      case 'up':
        return [new Vector3(1, 0, 0), (2 * Math.PI) / 36];
      case 'down':
        return [new Vector3(1, 0, 0), -(2 * Math.PI) / 36];
      case 'in':
        return [new Vector3(0, 0, 1), (2 * Math.PI) / 36];
      case 'out':
        return [new Vector3(0, 0, 1), -(2 * Math.PI) / 36];
      default:
        throw new Error(`Unknown rotation direction: ${dir}`);
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log('ctrl', event.ctrlKey);
    this.ctrlPressed = event.ctrlKey;
  }
  @HostListener('window:keyup', ['$event'])
  keypEvent(event: KeyboardEvent) {
    console.log('ctrl', event.ctrlKey);
    this.ctrlPressed = event.ctrlKey;
  }
}
