import { Vector3 } from 'three';
import { VectorDimensionLabel } from './VectorDimensionLabel';

export class VectorWidthLabel extends VectorDimensionLabel {
  protected updateLabelPosition(): void {
    const position = new Vector3();
    this.vector.mesh.getWorldPosition(position);
    position.x += this.vector.length / 2 - 30;
    position.y -= this.vector.height / 2;
    this.setPosition(position);
  }
}
