import { Constants } from './constants';
import { Vehicle } from './vehicle';
import { Axle } from '../axles/lib/axle';
import {
  Box3,
  BufferGeometry,
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  Vector3
} from 'three';

export abstract class VehicleMesh {
  public type: string;
  public mesh: Mesh;

  public vehicle: Vehicle;

  public boundingBoxes = new Array<Box3>();

  protected description: string;

  constructor(type?: string, vehicle?: Vehicle) {
    //console.log('vehicleMesh constructor', vehicle.uuid);
    this.mesh = new Mesh();
    this.mesh.name = 'vehicle-mesh';
    this.type = type;
    this.vehicle = vehicle;
    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL,
      Constants.CABIN_MATERIAL,
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL,
      Constants.CYLINDER_MATERIAL,
      Constants.METAL_MATERIAL
    ];
  }

  get position(): Vector3 {
    return this.mesh.position;
  }

  public getGeometry(): BufferGeometry {
    return this.mesh.geometry;
  }
  public getName(): string {
    return 'should be inherited';
  }
  public getDescription(): string {
    return this.description;
  }

  public setDescription(value: string) {
    this.description = value;
  }

  public getType() {
    return this.type;
  }

  public computeBoundingBox(): Box3 {
    this.mesh.geometry.computeBoundingBox();
    const box = this.mesh.geometry.boundingBox;
    return box;
  }

  public updateAxles() {
    this.removeAxles();
    this.addAxles();
  }

  private removeAxles() {
    for (const mesh of this.mesh.children.filter(
      (x) => x.name === 'axle-mesh'
    )) {
      this.mesh.remove(mesh);
    }
  }

  public showAxles() {
    for (const mesh of this.mesh.children.filter(
      (x) => x.name === 'axle-mesh'
    )) {
      mesh.visible = true;
    }
  }

  public hideAxles() {
    for (const mesh of this.mesh.children.filter(
      (x) => x.name === 'axle-mesh'
    )) {
      mesh.visible = false;
    }
  }

  protected addAxles() {
    this.mesh.geometry.computeBoundingBox();
    const offsetY = this.mesh.geometry.boundingBox.min.y;
    const offsetX = this.mesh.geometry.boundingBox.min.x;

    this.vehicle.axles = this.vehicle.axles.map((axle) =>
      axle instanceof Axle ? axle : new Axle(axle)
    );

    for (const axle of this.vehicle.axles) {
      const mesh = axle.mesh;
      const bb = new Box3().setFromObject(mesh);
      const height = bb.max.y - bb.min.y;

      if (mesh !== undefined) {
        mesh.position.y = offsetY - height;
        mesh.position.x = offsetX + axle.offset;
        mesh.position.z =
          this.vehicle.maxWidth / 2 - this.vehicle.cabinWidth / 2;
        this.mesh.add(mesh);
      }
    }
  }

  protected addDebugPoints() {
    this.mesh.geometry.computeBoundingBox();
    const offsetY = this.mesh.geometry.boundingBox.min.y;
    const offsetX = this.mesh.geometry.boundingBox.min.x;
    const offsetZ = this.mesh.geometry.boundingBox.min.z;

    const w = this.vehicle.cabinWidth;
    const h = this.vehicle.cabinHeight;
    const l = this.vehicle.cabinLength;
    const fl = this.vehicle.totalLength;

    const positions = [
      new Vector3(0, 0, 0),
      new Vector3(0, 0, w),
      new Vector3(0, h, 0),
      new Vector3(0, h, w),
      new Vector3(l, 0, 0),
      new Vector3(l, h, 0),
      new Vector3(l, h, w),
      new Vector3(l, 0, w),
      new Vector3(fl, 0, w),
      new Vector3(fl, 0, 0),
      new Vector3(fl, h, 0),
      new Vector3(fl, h, w)
    ];

    const geometry = new SphereGeometry(30, 32, 16);
    const material = new MeshBasicMaterial({ color: 0xffff00 });
    for (const position of positions) {
      const sphere = new Mesh(geometry, material);
      sphere.position.copy(position);
      sphere.position.x += offsetX;
      sphere.position.y += offsetY;
      sphere.position.z += offsetZ;

      this.mesh.add(sphere);
    }
  }

  protected addSpaces(startX?: number, startY?: number) {
    this.mesh.geometry.computeBoundingBox();

    let spaceOffsetX = startX ?? this.mesh.geometry.boundingBox.max.x;

    for (const space of this.vehicle.spaces) {
      const mesh = space.mesh;
      this.mesh.geometry.computeBoundingBox();
      let offsetY = startY ?? this.mesh.geometry.boundingBox.min.y;
      if (offsetY === -Infinity) {
        offsetY = startY;
        if (typeof offsetY === 'undefined') {
          console.log('mesh starting point not set');
        }
      }

      mesh.position.y = offsetY;

      mesh.position.x = spaceOffsetX;

      //console.log('spaceOffsetX', spaceOffsetX);

      mesh.meshObj.geometry.computeBoundingBox();
      this.boundingBoxes.push(mesh.meshObj.geometry.boundingBox);

      this.mesh.add(mesh.meshObj);
      spaceOffsetX += space.length + this.vehicle.trailerSpacing;
    }
  }

  protected buildWireframe() {
    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);
  }
}
