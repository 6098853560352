import { Load } from 'src/app/load/lib/load';
import { v4 as uuidv4 } from 'uuid';

export class Project {
  uuid: string;
  directoryUuid?: string;
  userId: string;
  name?: string;
  seqNumber?: string;
  comment?: string;
  // ID zlecenia oficjalnego
  orderUuid?: string;
  createdAt: Date;
  updatedAt: Date;

  workOrders: string[] = [];
  canMakeOrderTemplate?: boolean;
  orderTemplateId?: string;
  distanceMatching?: number;

  pendingLoads: Load[] = [];

  constructor(obj: Partial<Project>) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.uuid = uuidv4();
    }
    if (this.createdAt) {
      this.createdAt = new Date(this.createdAt);
    }
    if (this.updatedAt) {
      this.updatedAt = new Date(this.updatedAt);
    }

    this.pendingLoads = obj?.pendingLoads || [];
    this.workOrders = obj?.workOrders || [];
  }

  toString() {
    return this.name ?? this.seqNumber ?? '';
  }
}
