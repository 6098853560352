import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Project } from '../../projects/lib/project';
import { ProjectsService } from '../../projects/projects.service';
import { ImportService } from './import.service';
import { OrderService } from '../order.service';
import { DateRange } from 'src/app/dates-filter';

@Component({
  selector: 'app-order-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.less']
})
export class ImportComponent implements OnInit, OnDestroy {
  @Input()
  requiredFileType: string;
  private placeholder = $localize`Załącz pliki tutaj`;
  fileName = this.placeholder;
  uploadProgress: number;
  uploadSub?: Subscription;
  public invalidFormat = false;
  public summary: string;
  public actionType = 'append';
  public advanced = false;
  protected isLoading = false;

  private unsubscribe$ = new Subject<void>();
  private project: Project;
  private file: File;

  constructor(
    private service: ImportService,
    private projectService: ProjectsService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.projectService.currentProject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((project) => (this.project = project));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.service.close();
  }

  downloadTemplate() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '/assets/wzor_importu_zamowien.csv');
    link.setAttribute('download', 'order_template.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.invalidFormat = false;
    }
  }

  async upload() {
    if (this.file) {
      this.fileName = this.file.name;
      this.invalidFormat = false;
      this.isLoading = true;
      this.service
        .uploadFile(this.file, this.project?.uuid, this.actionType)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.isLoading = false;
          if (response.message === 'INVALID_FORMAT') {
            this.invalidFormat = true;
            this.reset();
          } else if (response.message === 'OK') {
            this.invalidFormat = false;
            this.summary = $localize`Zaimportowano`;
            this.updateOrderList(undefined, undefined);
          }
        });
    }
  }

  private updateOrderList(range?: DateRange, search?: string) {
    this.orderService
      .fetchOrders(range, search)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        console.debug('order.component.ts: updateOrderList', list);
      });
  }

  cancelUpload() {
    this.uploadSub?.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = 0;
    this.uploadSub = undefined;
    this.fileName = this.placeholder;
    this.isLoading = false;
  }
}
