import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoadShape } from '../lib/load-shape';
import { LoadEventObject } from '../lib/load-event-object';
import { UiMode, UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-load-form',
  templateUrl: './form.component.html',
  styleUrls: ['./../load.component.less', './form.component.less']
})
export class FormComponent {
  @Input() events: Observable<void>;
  @Input() shape: LoadShape;
  @Output() addLoadEvent = new EventEmitter<LoadEventObject[]>();

  eventsSubject: Subject<void> = new Subject<void>();

  public uiMode$: Observable<UiMode>;

  constructor(uiService: UiService) {
    this.uiMode$ = uiService.getUiMode();

    //console.error("app-form: shape: ", this.shape);
  }

  addLoad(eventObject: LoadEventObject[]) {
    this.addLoadEvent.emit(eventObject);
  }
}
