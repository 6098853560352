import { Order } from './order';
import { OrderGroup } from './order-group';

export class GroupedOrderList {
  public constructor(
    private _groups: OrderGroup[],
    private _singleItems: Order[],
    private _total: number = 0,
    private _page: number = 0, // material paginator is 0-based
    private _pageSize: number = 50
  ) {}

  get currentPage() {
    return this._page;
  }

  get totalOrders() {
    return this._total;
  }

  get groups() {
    return this._groups;
  }

  get single() {
    return this._singleItems;
  }

  get pageSize() {
    return this._pageSize;
  }

  get type() {
    return 'groups';
  }

  get isEmpty() {
    return this.groups.length === 0 && this.single.length === 0;
  }
}
