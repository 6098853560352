<div class="orbit-controls" [class]="'mode-' + (mode$ | async)">
  <div class="rotate text-center">
    <div>
      <ng-container *ngIf="(mode$ | async) === 'scene'">
        <button mat-raised-button color="default" (click)="move('down')">
          <mat-icon class="center-vertical">keyboard_arrow_up</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="move('down')">
          <mat-icon class="center-vertical">move_up</mat-icon>
        </button>
      </ng-container>
    </div>

    <div class="row">
      <ng-container *ngIf="(mode$ | async) === 'scene'">
        <button mat-raised-button color="default" (click)="move('right')">
          <mat-icon class="center-vertical">keyboard_arrow_left</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="move('right')">
          <mat-icon class="center-vertical">keyboard_arrow_left</mat-icon>
        </button>
      </ng-container>

      <div class="col" *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="move('in')">
          <mat-icon class="center-vertical">keyboard_arrow_up</mat-icon>
        </button>
        <button mat-raised-button color="default" (click)="move('out')">
          <mat-icon class="center-vertical">keyboard_arrow_down</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="(mode$ | async) === 'scene'">
        <button mat-raised-button color="default" (click)="move('left')">
          <mat-icon class="center-vertical">keyboard_arrow_right</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="move('left')">
          <mat-icon class="center-vertical">keyboard_arrow_right</mat-icon>
        </button>
      </ng-container>
    </div>

    <div>
      <div>
        <ng-container *ngIf="(mode$ | async) === 'scene'">
          <button mat-raised-button color="default" (click)="move('up')">
            <mat-icon class="center-vertical">keyboard_arrow_down</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="(mode$ | async) !== 'scene'">
          <button mat-raised-button color="default" (click)="move('up')">
            <mat-icon class="center-vertical">move_down</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>

    <div class="zoom">
      <div>
        <button
          mat-raised-button
          color="default"
          (click)="dolly('in')"
          class="no-load-mode"
        >
          <mat-icon class="center-vertical">add</mat-icon>
        </button>
      </div>
      <div>
        <button
          mat-raised-button
          color="default"
          (click)="dolly('out')"
          class="no-load-mode"
        >
          <mat-icon class="center-vertical">remove</mat-icon>
        </button>
      </div>
    </div>

    <div>
      <ng-container *ngIf="(mode$ | async) === 'scene'">
        <button mat-raised-button color="default" (click)="rotate('up')">
          <mat-icon class="rotate-up">360</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="rotate('up')">
          <mat-icon class="rotate-up">360</mat-icon>
        </button>
      </ng-container>
    </div>

    <div class="row">
      <ng-container *ngIf="(mode$ | async) === 'scene'">
        <button mat-raised-button color="default" (click)="rotate('left')">
          <mat-icon class="rotate-left">360</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="rotate('left')">
          <mat-icon class="rotate-left">360</mat-icon>
        </button>
      </ng-container>

      <div class="col" *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="rotate('in')">
          <mat-icon class="rotate-in">360</mat-icon>
        </button>
        <button mat-raised-button color="default" (click)="rotate('out')">
          <mat-icon class="rotate-out">360</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="(mode$ | async) === 'scene'">
        <button mat-raised-button color="default" (click)="rotate('left')">
          <mat-icon class="rotate-right">360</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="rotate('right')">
          <mat-icon class="rotate-right">360</mat-icon>
        </button>
      </ng-container>
    </div>

    <div>
      <ng-container *ngIf="(mode$ | async) === 'scene'">
        <button mat-raised-button color="default" (click)="rotate('down')">
          <mat-icon class="rotate-down">360</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) !== 'scene'">
        <button mat-raised-button color="default" (click)="rotate('down')">
          <mat-icon class="rotate-down">360</mat-icon>
        </button>
      </ng-container>
    </div>
    <div class="row center" *ngIf="(mode$ | async) !== 'scene'">
      <button
        mat-raised-button
        color="default"
        (click)="restoreState()"
        i18n-matTooltip
        matTooltip="Odrzuć niezapisane transformacje"
        [matTooltipPosition]="'left'"
        [matTooltipShowDelay]="2000"
        #t23="matTooltip"
      >
        <mat-icon>history</mat-icon>
      </button>
      <button
        mat-raised-button
        color="default"
        (click)="saveChanges()"
        i18n-matTooltip
        matTooltip="Zapisz transformacje zaznaczonych ładunków"
        [matTooltipPosition]="'left'"
        [matTooltipShowDelay]="2000"
        #t24="matTooltip"
      >
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</div>
