import { Injectable } from '@angular/core';

import { Camera, Object3D } from 'three';
import { DragControls } from '../../lib/vendor/three/controls/DragControls';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class DragControlsModel {
  object: Object3D;
}

export class DragControlsMoveModel {
  object: Object3D;
}

@Injectable({
  providedIn: 'root'
})
export class DragControlsService {
  private dragControls: DragControls;
  private camera: Camera;
  private domElement: HTMLElement;
  private loadedMeshes: Object3D[];
  private model = new Subject<DragControlsModel>();
  private movement = new Subject<DragControlsMoveModel>();

  private _isDragging$ = new BehaviorSubject<boolean>(false);

  public initDragControls(
    camera: Camera,
    domElement: HTMLElement,
    loaded: Object3D[]
  ) {
    this.camera = camera;
    this.domElement = domElement;
    this.loadedMeshes = loaded;
    if (this.dragControls) {
      this.dragControls.deactivate();
    }
    this.dragControls = new DragControls(loaded, camera, domElement);
    this.dragControls.transformGroup = true;
    this.dragControls.getRaycaster().layers.set(1);
    this.dragControls.addEventListener('dragstart', (event) => {
      //this.controls.enabled = false;
      console.log('update valid position', event.object.position.clone());
      event.object.userData.validPosition = event.object.position.clone();
      this._isDragging$.next(true);
    });
    this.dragControls.addEventListener('dragend', (event) => {
      //this.controls.enabled = true;
      this.movement.next({
        object: event.object
      });
      this._isDragging$.next(false);
    });

    this.dragControls.addEventListener('drag', (event) => {
      //this.controls.enabled = true;
      this.onDragMove(event);
    });
  }

  get isDragging$(): Observable<boolean> {
    return this._isDragging$.asObservable();
  }

  public setLoadedMeshes(loads: Object3D[]) {
    this.initDragControls(this.camera, this.domElement, loads);
  }

  public modelChanged(): Observable<DragControlsModel> {
    return this.model.asObservable();
  }

  public dragEnd(): Observable<DragControlsModel> {
    return this.movement.asObservable();
  }

  private onDragMove(event) {
    if (!event.object) {
      return;
    }
    this.model.next({
      object: event.object
    });
  }
}
