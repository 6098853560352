import { Injectable } from '@angular/core';

import {
  Box3,
  BoxGeometry,
  BufferGeometry,
  Color,
  DoubleSide,
  Euler,
  FrontSide,
  Group,
  Material,
  Mesh,
  MeshLambertMaterial,
  MeshPhongMaterial,
  Object3D,
  Vector3
} from 'three';
import { GLTFLoader } from '../../lib/vendor/three/loader/GLTFLoader';
import { OBB } from '../../lib/vendor/three/OBB';
import { v4 as uuid } from 'uuid';
import { ModelDetailsFactoryService } from './model-details/model-details-factory.service';
import { DRACOLoader } from 'src/app/lib/vendor/three/loader/DRACOLoader';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelLoaderService {
  private _cache = new Map<string, Object3D>();
  private gltfLoader: GLTFLoader;
  public modelDirectory = environment.assetsUrl + '/'; //'assets/glbs/decimated-0.05/0.05/';

  public constructor(private modelDetailsFactory: ModelDetailsFactoryService) {
    this.gltfLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/assets/lib/draco/');
    this.gltfLoader.setDRACOLoader(dracoLoader);
  }

  private randomColor(): Color {
    const color = new Color(0xffffff);
    color.setHex(Math.random() * 0xffffff);
    return color;
  }

  private getMaterial(): MeshLambertMaterial {
    return new MeshLambertMaterial({
      color: this.randomColor(),
      transparent: true,
      depthWrite: false,
      depthTest: false,
      opacity: 0.35,
      side: FrontSide
    });
  }

  private getWireframeMaterial(): MeshPhongMaterial {
    return new MeshPhongMaterial({
      color: this.randomColor(),
      transparent: false,
      depthWrite: true,
      opacity: 1,
      visible: false,
      wireframe: true,
      side: DoubleSide
    });
  }

  private getObbWireframeMaterial(): MeshPhongMaterial {
    return new MeshPhongMaterial({
      color: 0xff0000,
      transparent: false,
      depthWrite: true,
      opacity: 1,
      visible: false,
      wireframe: true
    });
  }
  private processModel(
    modelName: string,
    model: Object3D,
    initialLoad: boolean = true
  ): Object3D {
    if (!initialLoad) {
      model.traverse((child) => {
        if (!(child instanceof Mesh) || !child.userData.obb) {
          return;
        }
        const obb = new OBB();
        obb.copy(child.geometry.userData.obb);
        child.geometry.userData.obb = obb;
        child.userData.obb = new OBB();

        if (child.userData.obbExp && child.geometry.userData.obbExp) {
          const obbExp = new OBB();
          obbExp.copy(child.geometry.userData.obbExp);
          child.geometry.userData.obbExp = obbExp;
          child.userData.obbExp = new OBB();
        }
      });
      return model;
    }
    const wireframeMaterial = this.getWireframeMaterial();
    const obbWireframeMaterial = this.getObbWireframeMaterial();
    const geometry = new BoxGeometry(1, 1, 1);
    let mesh = new Mesh(geometry, wireframeMaterial);
    const modelMaterial = this.getMaterial();
    const wholeBox = new Box3();
    const wholeBoxExpanded = new Box3();
    const modelDetails = this.modelDetailsFactory.getModelDetails(modelName);

    model.traverse((child) => {
      if (!(child instanceof Mesh) || child.name.endsWith('-wireframe')) {
        return;
      }

      const c = new Mesh(child.geometry.clone(), modelMaterial);
      c.name = 'comp-' + child.name;
      c.layers.set(1);
      const spacing = 0;

      if (child.name.endsWith('-bb')) {
        const scaled = new Mesh(child.geometry.clone(), modelMaterial);
        scaled.geometry.computeBoundingBox();
        const bb: Box3 = scaled.geometry.boundingBox!.clone();

        const geometry = new BoxGeometry(
          bb.max.x - bb.min.x,
          bb.max.y - bb.min.y,
          bb.max.z - bb.min.z
        );

        const center = bb.getCenter(new Vector3());
        const c1 = new Mesh(geometry, wireframeMaterial);
        c1.position.copy(center);
        c1.name = child.name + '-bounding-box-wireframe';
        c1.userData.obb = new OBB();
        c1.geometry.userData.obb = new OBB();

        c1.geometry.userData.obb.halfSize
          .copy(
            new Vector3(
              bb.max.x - bb.min.x,
              bb.max.y - bb.min.y,
              bb.max.z - bb.min.z
            )
          )
          .multiplyScalar(0.5);

        //bb.expandByVector(new Vector3(spacing, 1, spacing));
        c1.userData.obbExp = new OBB();
        c1.geometry.userData.obbExp = new OBB();
        c1.geometry.userData.obbExp.halfSize
          .copy(
            new Vector3(
              bb.max.x - bb.min.x,
              bb.max.y - bb.min.y,
              bb.max.z - bb.min.z
            )
          )
          .multiplyScalar(0.5);

        c.attach(c1);
        wholeBox.expandByObject(c1);
        wholeBoxExpanded.union(bb);
      } else if (child.name.endsWith('-obb')) {
        const scaled = new Mesh(child.geometry.clone(), modelMaterial);
        scaled.geometry.computeBoundingBox();
        const bb: Box3 = scaled.geometry.boundingBox!;

        let bbLength = bb.max.x - bb.min.x;
        let bbWidth = bb.max.z - bb.min.z;
        let bbHeight = bb.max.y - bb.min.y;

        let rotation = new Euler();
        const specificBBDimension = modelDetails?.getPartDimensions(child.name);
        const specificRotation = modelDetails?.getPartRotation(child.name);
        if (specificBBDimension?.x) {
          bbLength = specificBBDimension.x;
        }
        if (specificBBDimension?.y) {
          bbHeight = specificBBDimension.y;
        }
        if (specificBBDimension?.z) {
          bbWidth = specificBBDimension.z;
        }
        if (specificRotation) {
          rotation = specificRotation;
        }

        const geometry = new BoxGeometry(bbLength, bbHeight, bbWidth);

        const center = bb.getCenter(new Vector3());
        const c1 = new Mesh(geometry, obbWireframeMaterial);

        c1.rotation.copy(rotation);

        c1.position.copy(center);
        c1.name = child.name + '-oriented-bounding-box-wireframe';
        c1.userData.obb = new OBB();
        c1.geometry.userData.obb = new OBB();

        const bbSize = new Vector3(
          bbLength, //bb.max.x - bb.min.x,
          bbHeight, // bb.max.y - bb.min.y,
          bbWidth //bb.max.z - bb.min.z
        );

        c1.geometry.userData.obb.halfSize.copy(bbSize).multiplyScalar(0.5);

        c1.userData.obbExp = new OBB();
        c1.geometry.userData.obbExp = new OBB();
        c1.geometry.userData.obbExp.halfSize
          .copy(
            new Vector3(bbLength + spacing * 2, bbHeight, bbWidth + spacing * 2)
          )
          .multiplyScalar(0.5);

        c.attach(c1);
        wholeBox.expandByObject(c1);
        wholeBoxExpanded.union(c1.geometry.userData.obbExp.getAabb());
      }
      mesh.add(c);
    });
    const wholeSize = wholeBox.getSize(new Vector3());
    const wholeSizeExpanded = wholeBoxExpanded.getSize(new Vector3());
    mesh.userData.size = wholeSize;
    mesh.userData.halfSize = wholeSize.clone().multiplyScalar(0.5);
    mesh.userData.sizeExp = wholeSizeExpanded;
    mesh.userData.halfSizeExp = wholeSizeExpanded.clone().multiplyScalar(0.5);
    console.log(
      'whole model size',
      mesh.userData.size,
      mesh.userData.halfSize,
      mesh.userData.sizeExp,
      mesh.userData.halfSizeExp
    );
    return mesh;
  }

  private deepCopy(obj: Mesh) {
    const copy = obj.clone(true);
    copy.traverse((child: Object3D) => {
      if (
        !(child instanceof Mesh) ||
        !child.userData.obb ||
        !child.geometry.userData.obb
      ) {
        return;
      }
      const geoObb = new OBB();
      geoObb.copy(child.geometry.userData.obb);
      child.geometry.userData.obb = geoObb;
      child.userData.obb = new OBB();
    });
  }

  public cloneWithMaterialAndGeometry(obj: Mesh) {
    const clone = obj.clone(true);
    if (obj.material instanceof Material) {
      clone.material = obj.material.clone();
    } else if (Array.isArray(obj.material)) {
      clone.material = obj.material.map((m) => m.clone());
    }
    if (clone.geometry) {
      clone.geometry = obj.geometry.clone();
    }
    clone.traverse((child: Object3D) => {
      if (!(child instanceof Mesh)) {
        return;
      }
      if (child.geometry) {
        child.geometry = child.geometry.clone();
      }
      if (child.material instanceof Material) {
        child.material = child.material.clone();
      } else if (Array.isArray(child.material)) {
        child.material = child.material.map((m) => m.clone());
      }
    });
    clone.userData.size = new Vector3(
      obj.userData?.size?.x,
      obj.userData?.size?.y,
      obj.userData?.size?.z
    );
    clone.userData.halfSize = new Vector3(
      obj.userData?.halfSize?.x,
      obj.userData?.halfSize?.y,
      obj.userData?.halfSize?.z
    );
    clone.userData.sizeExp = new Vector3(
      obj.userData?.sizeExp?.x,
      obj.userData?.sizeExp?.y,
      obj.userData?.sizeExp?.z
    );
    clone.userData.halfSizeExp = new Vector3(
      obj.userData?.halfSizeExp?.x,
      obj.userData?.halfSizeExp?.y,
      obj.userData?.halfSizeExp?.z
    );
    return clone;
  }

  public loadGlbModel(key: string): Promise<Group> {
    const group = new Group();
    group.name = 'load-group';
    group.userData.uuid = uuid();
    if (typeof this._cache[key] !== 'undefined') {
      return new Promise((resolve, _) => {
        const model = this.cloneWithMaterialAndGeometry(this._cache[key]);
        const mesh = this.processModel(key, model, false);
        mesh.name = key;
        //console.log('resolved mesh', mesh);
        group.add(mesh);
        group.userData.size = mesh.userData.size.clone();
        group.userData.halfSize = mesh.userData.halfSize.clone();
        resolve(group);
      });
    }

    return new Promise((resolve, reject) => {
      this.gltfLoader.load(
        this.modelDirectory + key + '.glb',
        (gltf) => {
          gltf.scene.name = 'model';
          const mesh = this.processModel(key, gltf.scene, true);
          mesh.name = key;
          this._cache[key] = this.cloneWithMaterialAndGeometry(mesh as Mesh);
          group.add(mesh);
          if (mesh.userData.size) {
            group.userData.size = mesh.userData.size.clone();
            group.userData.halfSize = mesh.userData.halfSize.clone();
          }
          resolve(group);
        },
        (xhr) => {
          console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          console.log('ERROR READ GLB', error);
          reject(error);
        }
      );
    });
  }
}
