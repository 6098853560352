import { Component } from '@angular/core';

@Component({
  selector: 'app-dealers-panel',
  templateUrl: './dealers-panel.component.html',
  styleUrls: ['./dealers-panel.component.less']
})
export class DealersPanelComponent {

}
