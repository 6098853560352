import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Truck } from './truck';
import { BufferGeometry, Float32BufferAttribute } from 'three';

export class TruckMesh extends VehicleMesh {
  private truck: Truck;

  private indices = [];
  private vertices = [];

  private length: number;
  private height: number;
  private width: number;

  public constructor(truck: Truck) {
    super('truck', truck);
    this.truck = truck;
    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getCapacity(): number {
    return this.width * this.length;
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const y0 = 0;
    const z0 = this.vehicle.maxWidth / 2 - this.vehicle.cabinWidth / 2;
    const x1 = x0 + this.vehicle.cabinLength;
    const y1 = y0 + this.vehicle.cabinHeight;
    const z1 = z0 + this.vehicle.cabinWidth;
    let groupStart = 0;
    let groupCount = 0;

    // cabin
    this.vertices.push(x0, y0, z1); // 4 left bottom front
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z1); // 5 left top front
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z0); // 6 left top back
    this.vertices.push(x0, y0, z0); // 7 left bottom back
    this.vertices.push(x1, y0, z1); // 12 cabin front bottom left
    this.vertices.push(x1, y0, z0); // 13 cabin back bottom left
    this.vertices.push(x1, y1, z0); // 14 cabin back top left
    this.vertices.push(x1, y1, z1); // 15 cabin front top left
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z1); // 16 cabin back top left
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z0); // 17 cabin front top left

    this.indices.push(0, 3, 5, 0, 5, 4); // cabin bottom faces
    this.indices.push(0, 4, 7, 0, 7, 8, 1, 8, 7); // cabin front faces
    this.indices.push(3, 5, 9, 5, 6, 9, 2, 9, 6); // cabin back faces
    this.indices.push(4, 6, 7, 4, 5, 6); // cabin left bottom faces
    this.indices.push(1, 2, 7, 2, 6, 7); // cabin left top faces

    groupStart += groupCount;
    groupCount = 6 * 3 + 9 * 2;
    this.mesh.geometry.addGroup(groupStart, groupCount, 2);

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();

    this.addSpaces();
    this.addAxles();
  }
}
