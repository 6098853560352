import { Component, OnInit } from '@angular/core';
import { DirectoryListItem, Project, ProjectList, ProjectsService } from '../';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoadComponentService } from '../../services/load-component.service';
import { ProjectCreateComponent } from '../project-create/project-create.component';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from '../../confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { DateRange } from '../../dates-filter/lib/date-range';
import { DirectoryCreateComponent } from '../directory-create/directory-create.component';
import { FilterType } from '../../dates-filter/lib/filter-type';
import { FilterChangeEvent } from '../../dates-filter/lib/filter-change.event';

@Component({
  selector: 'app-project-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent implements OnInit {
  protected list$: Observable<ProjectList>;
  protected isLoading$: Observable<boolean>;
  search: UntypedFormControl;
  projectNames$: Observable<string[]>;
  selectedDatesFilter = FilterType.month;

  private unsubscribe$ = new Subject<void>();

  constructor(
    protected service: ProjectsService,
    private loadComponentService: LoadComponentService,
    private dialog: MatDialog
  ) {
    console.log('projects/list.component.ts constructor');
    this.list$ = service.list$;
    this.isLoading$ = this.list$.pipe(
      map(() => false),
      startWith(true)
    );
    this.search = new UntypedFormControl(service.lastSearchQuery, []);
    this.projectNames$ = this.search.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) =>
        this.service
          .fetchProjectNames()
          .pipe(
            map((response) =>
              response.filter((name) =>
                name.toLowerCase().includes((value ?? '').toLowerCase())
              )
            )
          )
      )
    );
  }

  ngOnInit(): void {
    console.log('projects/list.component.ts init');

    // update project list
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((searchQuery) => {
        this.selectedDatesFilter = FilterType.none;
        if (searchQuery) {
          this.service
            .fetchProjects(new DateRange(), searchQuery)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((list) => this.service.updateProjectList(list));
        } else {
          this.service
            .fetchProjects(undefined, '')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((list) => this.service.updateProjectList(list));
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected openProject(project: Project) {
    // console.log('projects.component.ts: project selected', project);
    this.service.currentProject = project;
  }

  protected editProject(project: Project) {
    const component = this.loadComponentService.add(ProjectCreateComponent);
    component.instance.project = project;
  }

  protected deleteProject(project: Project) {
    // console.log('projects.component.ts: project delete clicked', project);
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: { action: ConfirmationDialogAction.deleteProject }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => this.service.deleteProject(project)),
        switchMap(() => this.service.fetchProjects()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((response) => {
        this.service.updateProjectList(response);
        this.service.clearNamesCache();
      });
  }

  newProject() {
    // console.log('projects.component.ts: new project clicked');
    this.loadComponentService.clear('projects/list.component.ts');
    this.loadComponentService.add(ProjectCreateComponent);
  }

  newDirectory() {
    this.loadComponentService.clear('projects/list.component.ts');
    this.loadComponentService.add(DirectoryCreateComponent);
  }

  protected editDirectory(listItem: DirectoryListItem) {
    const component = this.loadComponentService.add(DirectoryCreateComponent);
    component.instance.directory = listItem.directory;
  }

  protected deleteDirectory(listItem: DirectoryListItem) {
    // console.log('projects.component.ts: project delete clicked', project);
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: { action: ConfirmationDialogAction.deleteDirectory }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => this.service.deleteDirectory(listItem.directory)),
        switchMap(() => this.service.fetchProjects()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((response) => {
        this.service.updateProjectList(response);
        this.service.clearNamesCache();
      });
  }

  changeFilters(event: FilterChangeEvent) {
    console.log('projects/list.component.ts: changed filters', event);
    this.selectedDatesFilter = event.filterType;
    this.updateProjectList(event.range, this.search.value);
  }

  private updateProjectList(range?: DateRange, search?: string) {
    this.service
      .fetchProjects(range, search)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        console.debug('projects/list.component.ts: updateProjectList', list);
        this.service.updateProjectList(list);
      });
  }
}
