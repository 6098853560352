import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concatAll,
  delay,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { CalculationService } from '../api/calculation.service';
import { HistoryService } from '../history/lib/history.service';

import { HistoryResponse } from '../lib/communication/history.response';
import { Vector } from '../lib/communication/vector';

import {
  ChangeFlooring,
  ChangeLoadWeight,
  ContextMenuActionType,
  ContextMenuService,
  ContextMenuServiceCommunicationModel
} from '../context-menu';
import { LoadGroupList } from '../lib/model/load-group/load-group-list';
import { Profile } from '../lib/model/profile';
import { VehicleContext } from '../lib/model/vehicle-context';
import { MessageService } from '../messenger/message.service';
import { OrbitControlsService } from '../orbit-controls/orbit-controls.service';
import {
  TransformControlsService,
  TransformControlsServiceCommunicationModel
} from '../services/transform-controls.service';
import { LabelService } from '../label/label.service';
import { LoadComponentService } from '../services/load-component.service';
import { SuggestVehicleComponent } from '../suggest-vehicle/suggest-vehicle.component';
import { ContextService } from '../vehicle/context/context.service';

import { MeshPositionerValidator } from './mesh.positioner.validator';

import { Load } from '../load/lib/load';
import { LoadEventObject } from '../load/lib/load-event-object';
import { LoadFactory } from '../load/lib/load-factory';

import { CalculationSummaryComponent } from '../calculation-summary/calculation-summary.component';
import { LabelComponentModel } from '../label/label.component.model';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { StatusResponse } from '../lib/communication/status.response';
import { LoadGroup } from '../lib/model/load-group/load-group';
import { ScaledImage } from '../lib/model/scaled-image';
import { SceneImage } from '../lib/model/scene-image';
import { OrbitControls } from '../lib/vendor/three/OrbitControls';
import { Cuboid } from '../load/type/cuboid/lib/cuboid';
import { ProfileService } from '../services/profile.service';

import { Camera, Spherical, Vector3, WebGLRenderer } from 'three';
import { Vehicle } from '../vehicle/lib/vehicle';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { VehicleService } from '../vehicle/vehicle.service';

import { ReportsService } from '../reports/reports.service';
import { Space } from '../vehicle/space/lib/space';
import { MyScene } from './lib/MyScene';
import { FormService as AxlesFormService } from '../vehicle/axles/form/form.service';
import { ContextFactory } from '../vehicle/context/lib/context-factory';
import { DebugPointsService } from '../lib/debug/debug-points.service';
import { ColliderDetector } from './collider-detector';
import { FlooringLevelSettingsService } from '../settings-modal/flooring-level/flooring-level-settings.service';
import { UiService } from '../services/ui.service';
import { VehicleSuggestionType } from '../suggest-vehicle/lib/suggestion-type';
import { VehicleToLoadTransformerService } from '../services/vehicle-to-load-transformer.service';
import {
  DragControlsModel,
  DragControlsService
} from '../raycasting/lib/drag-controls.service';
import { Order } from '../order/lib/order';
import { TrailerPositioner } from '../raycasting/lib/trailer-positioner';
import { PendingLoadsService } from '../loadings/lib/pending-loads.service';
import { CarTrailerGroup } from '../lib/model/load-group/car-trailer-group';
import { Project, ProjectsService } from '../projects';
import { EditComponent as EditLoadedGroupComponent } from '../load/form/edit/edit.component';

type ContextMenuAction = (
  loads: Load[],
  value: any
) => Observable<PositionerResponse | Project | void>;
type ContextMenuActionDispatch = {
  [key in ContextMenuActionType]?: ContextMenuAction;
};

@Injectable({
  providedIn: 'root'
})
export class SceneService {
  public loads: Load[];
  public profile$: Observable<Profile>;
  public updateAxles$: Observable<void>;

  private shouldRefresh = new BehaviorSubject<boolean>(null);
  public updateFlooringLevelInView$: Subject<void>;
  public isInLockedMode = false;
  private contextMenuActionHandlers: ContextMenuActionDispatch = {};
  private highlightLDM = new Subject<Space>();

  private sceneImgRequest$ = new Subject<void>();
  private sceneImgResponse$ = new Subject<SceneImage>();

  private generatePlacementFromOrders = new ReplaySubject<Order[]>(1);

  public get context(): VehicleContext {
    return this.contextService.getCurrentContext();
  }

  public readonly selectedLoads$: Observable<Load[]>;

  constructor(
    private calculationService: CalculationService,
    private profileService: ProfileService,
    private messenger: MessageService,
    private historyService: HistoryService,
    private orbitControlsService: OrbitControlsService,
    private contextMenuService: ContextMenuService,
    private vehicleService: VehicleService,
    // private spaceService: SpaceService,

    private labelService: LabelService,
    private transformControlsService: TransformControlsService,
    private loadComponentService: LoadComponentService,
    private contextService: ContextService,
    private reportsService: ReportsService,
    axlesService: AxlesFormService,
    private flooringLevelSettingsService: FlooringLevelSettingsService,
    private vehicleFactory: VehicleFactory,
    private contextFactory: ContextFactory,
    private loadFactory: LoadFactory,
    private uiService: UiService,
    private colliderDetector: ColliderDetector,
    private vehicleToLoadTransformer: VehicleToLoadTransformerService,
    private dragControlsService: DragControlsService,
    private trailerPositioner: TrailerPositioner,
    private pendingLoads: PendingLoadsService,
    private projectsService: ProjectsService
  ) {
    this.profile$ = profileService.getProfile();
    this.selectedLoads$ = transformControlsService.getSelectedLoads();

    this.updateAxles$ = axlesService.updateAxles$;
    this.updateFlooringLevelInView$ =
      flooringLevelSettingsService.updateFlooringLevelInView$;
  }

  public addSelectedLoad(load: Load) {
    this.transformControlsService.toggleSelectedLoad(load, true);
  }

  public removeSelectedLoad(load: Load) {
    this.transformControlsService.toggleSelectedLoad(load, false);
  }

  public isLoadSelected(load: Load) {
    this.transformControlsService.isSelected(load);
  }

  public showFlooringLevelSettings() {
    this.flooringLevelSettingsService.show();
  }

  showVehicleSettings() {
    this.vehicleService.showSettings();
  }

  showGridSettings() {
    this.vehicleService.showGridSettings();
  }

  onVehicleChanged(): Observable<VehicleContext> {
    return this.vehicleService.vehicleChanged().pipe(
      filter((vehicle) => !!vehicle),
      switchMap((vehicle) => {
        const context = this.contextFactory.createForVehicle(vehicle);
        return this.addVehicleContextAndSwitch(context);
      })
    );
  }

  updateProfile(profile: Profile) {
    this.profileService.updateProfile(profile);
  }

  containsAnyContext(): boolean {
    return this.contextService.contexts.value.length !== 0;
  }

  hasMoreThanOneContext(): boolean {
    return this.contextService.contexts.value.length > 1;
  }

  setLabelsConfiguration(config: LabelComponentModel) {
    this.labelService.initModel(config);
  }

  public clearLoads(): Observable<PositionerResponse> {
    return this.calculationService
      .clearLoads(this.context)
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public saveContextChanges(
    context: VehicleContext
  ): Observable<PositionerResponse> {
    context.calculateStatisticsSimplified();
    return this.calculationService
      .saveContext(context)
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public updateContext(context: VehicleContext) {
    if (!context) {
      return;
    }
    if (context.getMessage() === 'space_not_found') {
      this.suggestVehicles(context);
    } else if (context.getMessage() === 'weight_limit') {
      this.suggestVehicles(context, 'weight');
    } else {
      this.loadComponentService.remove(SuggestVehicleComponent);
    }

    //this.historyService.setUndoButtonActive(true);
    //this.historyService.setRedoButtonActive(true);

    // if (this.context.getUuid() !== context.getUuid()) {
    this.orbitControlsService.restore(context);
    // }
  }

  public showAxlesSettings() {
    this.vehicleService.showAxlesSettings(this.context);
  }

  public vehicleContextDrawn(context: VehicleContext) {
    //this.initHistory(context);

    this.bindDragControlsLoads(context.getAllLoads());
    this.orbitControlsService.enable();
    this.labelService.setData(
      context,
      context.getAllLoads(),
      this.orbitControlsService.getControls().getSpherical().radius
    );
    // this.addTroikaLabelService();
    // this.setLabelsData();

    // this.initFlooringLevel(ctx);

    //this.orbitControlsService.dolly('in');
    //this.orbitControlsService.dolly('in');
    // this.orbitControlsService.dolly('in');

    this.contextService.contextDrawn(context);
  }

  public refreshSummary() {
    // console.log('refreshSummary called');
  }

  public refreshView() {
    this.shouldRefresh.next(true);
  }

  public shouldRefreshInvoked(): Observable<boolean> {
    return this.shouldRefresh.asObservable();
  }

  public suggestVehicles(
    context: VehicleContext,
    type: VehicleSuggestionType = 'size'
  ) {
    this.loadComponentService.clear('scene.service.ts - suggestVehicls');
    const cr = this.loadComponentService.add(
      SuggestVehicleComponent,
      '#scene-container'
    );
    cr.instance.context = context;
    cr.instance.type = type;
  }

  public cloneContext(): Observable<VehicleContext> {
    return this.contextService.cloneContext(this.context);
  }

  public addVehicleContextAndSwitch(
    context?: VehicleContext
  ): Observable<VehicleContext> {
    if (context === null || context === undefined) {
      this.orbitControlsService.reset();
    }

    return this.contextService.createContext(context);
  }

  public getVehicleContext(): Observable<VehicleContext> {
    return this.contextService.contextChanged();
  }

  // public getFlooringLevel(): Observable<FlooringLevel> {
  // return this.loadingSpaceService.flooringLevel$;
  // }

  public getGroupedLoads(): LoadGroupList {
    if (this.context) {
      return this.context.groupLoads();
    } else {
      return new LoadGroupList(LoadGroup);
    }
  }

  public removeAllContexts(): Observable<StatusResponse<void>> {
    return this.contextService.removeAllContexts();
  }
  public removeCurrentContext(): Observable<VehicleContext> {
    return this.contextService.removeCurrentContext();
  }

  public removeContextFromView(context: VehicleContext) {
    this.contextService.removeContextFromView(context);
  }

  public removeAllContextsFromView() {
    this.contextService.removeAllContextsFromView();
  }

  /**
   * Wszystko co nie wymaga zmiany rozmieszczenia ładunków,
   * może być aktualizowane tą metodą
   *
   * @param load Load
   */
  public updateLoads(loads: Load[]): Observable<PositionerResponse> {
    return this.calculationService.updateLoads(loads, this.context).pipe(
      tap((response) => {
        this.handlePositionerResponse(response);
        this.unselectLoads();
      })
    );
  }

  public changeLoadCount(
    group: LoadGroup,
    cnt: number
  ): Observable<PositionerResponse | Project> {
    // console.log('scene.service.ts: changeLoadCount', group, cnt);
    const currentCount = group.cnt;
    if (cnt >= currentCount) {
      const toAdd = cnt - currentCount;
      const cuboidCnt = new LoadEventObject({ load: group.load, cnt: toAdd });
      return this.addLoadAmount([cuboidCnt]);
    } else if (cnt < currentCount) {
      const toRemove = cnt - currentCount;
      const uuids = group.list
        .sort((a, b) => (a.idx < b.idx ? -1 : 1))
        .slice(toRemove)
        .map((l) => l.uuid);
      const loads = this.context
        .getAllLoads()
        .filter((l) => uuids.includes(l.uuid))
        .sort((a, b) => (a.idx < b.idx ? -1 : 1));
      return this.removeLoads(loads);
    }
  }

  public changeWeight(loads: Load[]): Observable<PositionerResponse> {
    return this.calculationService.changeWeight(loads, this.context).pipe(
      tap((response) => {
        this.handlePositionerResponse(response);
        this.unselectLoads();
      })
    );
  }

  public changeFlooring(loads: Load[]): Observable<PositionerResponse> {
    console.log('change flooring');
    return this.calculationService.changeLoadSize(loads, this.context).pipe(
      tap((response) => this.handlePositionerResponse(response)),
      switchMap((response) => {
        const currenPending = this.pendingLoads.getCurrentLoads();
        loads.forEach((l) => currenPending.addLoad(l));
        const newPending = currenPending.removeSpecificLoads(
          response.getLoadedLoads()
        );
        const updateProject = this.pendingLoads.replaceList(newPending);
        return combineLatest([updateProject]).pipe(
          map(([project]) => response)
        );
      })
    );
  }

  public copyLoad(
    load: Load,
    data: {
      cnt: number;
    }
  ): Observable<PositionerResponse> {
    const cuboidCnt = new LoadEventObject({
      load,
      cnt: data.cnt
    });
    return this.addLoadAmount([cuboidCnt]);
  }

  /*public initHistory(context: VehicleContext) {
    this.historyService.setContext(context);
  }*/

  // public historyBack(currentTimestamp: number) {

  // }
  // public historyForward(currentTimestamp: number) {

  // }

  public removeLoads(
    loads: Array<Load>
  ): Observable<PositionerResponse | Project> {
    if (this.pendingLoads.areAllLoadsFromPending(loads)) {
      const pending = this.pendingLoads.getCurrentLoads();
      pending.removeSpecificLoads(loads);
      return this.pendingLoads.replaceList(pending);
    }
    return this.calculationService.removeLoads(loads, this.context).pipe(
      tap((response: PositionerResponse) => {
        this.handlePositionerResponse(response);
        this.unselectLoads();
      })
    );
  }

  public select(loads: Array<Load>) {
    console.log('select inside method', loads[0]);
    const load = loads[0];

    //this.unselectLoads();
    //load.select();

    this.transformControlsService.selectOneLoad(load);
  }

  public addLoadAmount(
    loadAmount: LoadEventObject[]
  ): Observable<PositionerResponse> {
    const context = this.contextService.getCurrentContext();
    const results: Observable<PositionerResponse>[] = [];
    results.push(this.calculationService.addLoads(loadAmount, context));
    console.log('calculationService.addLoads context', context);
    return of(...results)
      .pipe(concatAll())
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public addPendingLoads(
    list: LoadGroupList,
    context: VehicleContext = null
  ): Observable<VehicleContext> {
    const useContext = context
      ? context
      : this.contextService.getCurrentContext() ||
        this.contextService.createEmptyContext();
    return this.calculationService
      .addLoadsFromPendingList(list, useContext)
      .pipe(map((response) => this.handlePositionerResponse(response)));
  }

  public optimizeVehicle(context: VehicleContext): Observable<VehicleContext> {
    return this.calculationService
      .optimizeVehicle(context)
      .pipe(map((response) => this.handlePositionerResponse(response)));
  }

  public reloadLoads(): Observable<Project> {
    const context = this.contextService.getCurrentContext();
    context.setLoads(this.pendingLoads.getLoadList());
    const results: Observable<PositionerResponse>[] = [];
    results.push(this.calculationService.reloadLoads(context));
    console.log('calculationService.reloadLoads context', context);
    return of(...results)
      .pipe(concatAll())
      .pipe(tap((response) => this.handlePositionerResponse(response)))
      .pipe(
        switchMap((response) => {
          const newPendingList = this.pendingLoads.getCurrentLoads();
          console.log(
            'new pending',
            newPendingList.loads.flatMap((l) => l.list).map((l) => l.uuid),
            this.context.getLoadedLoads().map((l) => l.uuid)
          );

          newPendingList.removeSpecificLoads(this.context.getLoadedLoads());
          response.notLoadedLoads.forEach((l) => newPendingList.addLoad(l));
          response.notLoadedLoads = [];
          return this.pendingLoads.replaceList(newPendingList);
        })
      );
  }

  public alternativeCalculationsPoll(
    context: VehicleContext
  ): Observable<{ message: string; alternatives: VehicleContext[] }> {
    return this.calculationService
      .checkAlternativeCalculations(context.getUuid())
      .pipe(
        map((result) => {
          if (result.message == 'found') {
          }
          const alternativContexts = result.alternatives.map((r) =>
            this.contextFactory.fromPositionerResponse(r)
          );
          return { message: result.message, alternatives: alternativContexts };
        })
      );
  }

  public lockContext(lock: boolean) {
    this.isInLockedMode = lock;
  }

  public init(camera: Camera, scene: MyScene, canvas: HTMLCanvasElement) {
    this.registerContextMenuActionHandlers();
    this.initLabels(canvas, scene, camera);
  }

  public bindDragControlsLoads(loads: Load[]) {
    this.transformControlsService.bindLoads(loads);
    //this.dragControlsService.setLoadedMeshes(loads.map((l) => l.mesh.obj));
  }

  public getCurrentSphericalRadius(): number {
    return this.orbitControlsService.getControls().getSpherical().radius;
  }
  public getCurrentSpherical(): Spherical {
    return this.orbitControlsService.getControls().getSpherical();
  }

  // public initFlooringLevel(context: VehicleContext) {
  // this.flooringLevelService.setLevel(
  // context,
  // context?.getVehicle()?.flooringLevel || 100
  // );/
  // this.flooringLevelService.drawLabel();
  // }

  public setLabelRendererSize(width: number, height: number) {
    this.labelService.setSize(width, height);
  }

  public renderLabels() {
    this.labelService.render();
  }

  public handlePositionerResponse(
    response: PositionerResponse
  ): VehicleContext {
    this.historyService.initIfEmpty(
      this.contextFactory.clone(this.context, true)
    );
    const newContext = this.contextService.handleResponse(response);
    this.historyService.pushState(this.contextFactory.clone(newContext, true));
    return newContext;
  }

  public dragEnable(value: boolean) {
    if (this.context !== null) {
      if (value === true) {
        this.orbitControlsService.disableAllButZoom();
      } else {
        this.context.getAllLoads().forEach((x) => x.unhover());
        this.refreshView();
        this.orbitControlsService.enable();
      }
    }
  }

  public dragHoverChange(load: Load) {
    //console.log('dravHoverChange', load);
    try {
      if (load !== null && typeof load !== 'undefined') {
        this.context?.getAllLoads().forEach((x) => x.unhover());
        load.hover();
        this.refreshView();
      } //else {
      //this.context?.getAllLoads().forEach((x) => x.unhover());
      //this.refreshView();
      //}
    } catch (e) {
      console.error('TRY Dragcontrols', e);
    }
  }

  public dragModelChange(
    model: TransformControlsServiceCommunicationModel | DragControlsModel
  ) {
    //console.log('scene.service.ts: dragModelChange', model);
    // const event = model.event;
    if (model instanceof TransformControlsServiceCommunicationModel) {
      const load = model.load;
      this.colliderDetector.detect(
        load,
        this.context.getVehicle(),
        this.context.getAllLoads()
      );
    } else {
      //TODO !!!!
      /*const load = this.findLoadByMeshUuid(model.object.userData.uuid);
      this.colliderDetector.detect(
        load,
        this.context.getVehicle(),
        this.context.getAllLoads()
      );*/
    }

    this.labelService.updateLabels();
    this.refreshView();
  }

  public validateLoadPosition(load: Load, movement: Vector) {
    let space: Space = undefined;
    let spaceOffset: Vector3 = undefined;
    if (load.spaceUuid) {
      space = this.context.findSpace(load.spaceUuid);
      spaceOffset = space.mesh?.meshObj.getWorldPosition(new Vector3());
      this.trailerPositioner.setSpace(space);
    }

    const validator = new MeshPositionerValidator(
      this.context.getVehicle(),
      this.context.getAllLoads(),
      load,
      movement,
      this.trailerPositioner,
      this.colliderDetector
    );

    validator.validate(); //  validate może zmienić spaceUuid
    this.updateLoadSpaces();
    if (load.spaceUuid) {
      space = this.context.findSpace(load.spaceUuid);
      spaceOffset = space.mesh?.meshObj.getWorldPosition(new Vector3());
      this.trailerPositioner.setSpace(space);
    } else {
      space = undefined;
      spaceOffset = undefined;
    }
    load.position.x = load.mesh.obj.position.x - (spaceOffset?.x || 0);
    load.position.y = load.mesh.obj.position.y - (spaceOffset?.y || 0);
    load.position.z = load.mesh.obj.position.z - (spaceOffset?.z || 0);
    this.labelService.updateLabels();
  }

  private updateLoadSpaces() {
    for (const load of this.context.getLoads()) {
      if (load.loaded && load.spaceUuid) {
        const space = this.context.findSpace(load.spaceUuid);
        space.loads.push(load);
      }
    }
    this.context.setLoads(
      this.context.getLoads().filter((l) => !l.loaded || !l.spaceUuid)
    );
    for (const space of this.context.getVehicle().spaces) {
      for (const load of space.loads) {
        if (load.spaceUuid !== space.uuid) {
          if (load.spaceUuid) {
            load.loaded = true;
            const loadSpace = this.context.findSpace(load.spaceUuid);
            loadSpace.loads.push(load);
          } else {
            load.loaded = false;
            this.context.getLoads().push(load);
          }
        }
      }
      space.loads = space.loads.filter((l) => l.spaceUuid === space.uuid);
    }
  }

  public dragEnd(
    model: TransformControlsServiceCommunicationModel | DragControlsModel
  ): Observable<PositionerResponse> {
    console.log('dragEnd called', model);
    let load: Load;
    if (model instanceof TransformControlsServiceCommunicationModel) {
      load = model.load;
      load.position.x += model.movement.x;
      load.position.z += model.movement.z;
      load.position.y += model.movement.y;
      this.validateLoadPosition(load, model.movement);
      return this.saveContextChanges(this.context);
    } else {
      const load = this.findLoadByMeshUuid(model.object.userData.uuid);
      load.position.x = model.object.position.x;
      load.position.y = model.object.position.y;
      load.position.z = model.object.position.z;
      this.labelService.updateLabels();
      return this.saveContextChanges(this.context);
    }
  }

  /**
   * Wykonanie akcji z menu kontekstowego wg zarejestrowanych funkcji do obsługi
   *
   * @param model ContextMenuServiceCommunicationModel
   */
  public executeContextAction(
    model: ContextMenuServiceCommunicationModel
  ): Observable<PositionerResponse | Project | void> {
    if (
      model &&
      typeof this.contextMenuActionHandlers[model.action] !== 'undefined'
    ) {
      const loads = model.selectedLoads;
      const value = model.value;

      this.refreshView();
      return this.contextMenuActionHandlers[model.action](loads, value);
    } else {
      // console.log(
      // 'scene.service.ts: executeContextAction, uundefined action or model',
      // model
      // );
    }
    return EMPTY;
  }

  public initOrbitControls(
    camera: Camera,
    renderer: WebGLRenderer
  ): Observable<OrbitControls> {
    this.orbitControlsService.init(camera, renderer.domElement);

    return this.orbitControlsService.modelChanged();
  }

  public redrawContextWithLabels() {
    this.contextMenuService.close();

    if (this.context !== null) {
      this.labelService.setData(
        this.context,
        this.context.getExistingLoads(),
        this.orbitControlsService.getControls().getSpherical().radius
      );
      this.context.setOrbitControlsState(
        this.orbitControlsService.getCurrentControlsState()
      );
    }
    this.refreshView();
  }

  public addLDMHighlight(space?: Space) {
    this.highlightLDM.next(space);
  }

  get freeFloorHighlight$() {
    return this.highlightLDM.asObservable();
  }

  public requestSceneImg() {
    this.sceneImgRequest$.next();
  }

  public onSceneImgRequest(): Observable<void> {
    return this.sceneImgRequest$.asObservable();
  }

  public returnSceneImg(imgData: SceneImage) {
    this.sceneImgResponse$.next(imgData);
  }

  public onSceneImgResponse(): Observable<SceneImage> {
    return this.sceneImgResponse$.asObservable();
  }

  public exportCurrentContext() {
    this.contextService.startExport([this.context]);
  }

  public loadPallets(
    pallets: VehicleContext[]
  ): Observable<PositionerResponse> {
    console.debug('scene.service.ts -> loadPallets()', pallets);
    const context = this.contextService.getCurrentContext();

    const loads = pallets
      .map((pallet) =>
        this.vehicleToLoadTransformer.transform(
          pallet.getVehicle(),
          pallet.getUuid()
        )
      )
      .filter((x) => !!x);
    const addEvents = loads.map(
      (load) => new LoadEventObject({ cnt: 1, load })
    );

    return this.calculationService
      .addLoads(addEvents, context)
      .pipe(tap((response) => this.handlePositionerResponse(response)));
  }

  public requestPlacementGenerationForOrders(orders: Order[]) {
    this.generatePlacementFromOrders.next(orders);
  }

  public get generatePlacementForOrders$(): Observable<Order[]> {
    return this.generatePlacementFromOrders.asObservable();
  }

  public getAllLoadsWithPending(): Load[] {
    return (this.context?.getAllLoads() || []).concat(
      this.pendingLoads.getLoadList()
    );
  }
  private initLabels(
    canvas: HTMLCanvasElement,
    scene: MyScene,
    camera: Camera
  ) {
    this.labelService.init(canvas, scene, camera);
  }

  private unselectLoads(): Observable<PositionerResponse> {
    this.context.getAllLoads().forEach((x) => x.unselect());
    this.transformControlsService.deselectAll();
    return EMPTY;
  }

  private selectLoads(loads: Load[]) {
    this.unselectLoads();
    loads.forEach((load) => {
      load.select();
    });
  }

  private showCalculationSummary(summary: PositionerResponse[]) {
    this.loadComponentService.clear(
      'scene.service.ts - showCalculationSummary'
    );
    const cr = this.loadComponentService.add(
      CalculationSummaryComponent,
      '#scene-container'
    );
    cr.instance.items = summary;
  }

  private completeContextMenuAction() {
    const newSub = new BehaviorSubject<void>(undefined);

    return newSub.asObservable();
  }

  /**
   * Definicja funkcji podpiętych pod akcje menu kontekstowego ładunku.
   */
  private registerContextMenuActionHandlers() {
    this.contextMenuActionHandlers.open = () => EMPTY;

    this.contextMenuActionHandlers.select = (loads: Load[], value: number) => {
      this.select(loads);
      return this.completeContextMenuAction();
      //loads.forEach((load) => {
      //load.color = value;
      //});
      //return this.updateLoads(loads);
    };

    this.contextMenuActionHandlers.changeColor = (
      loads: Load[],
      value: number
    ) => {
      loads.forEach((load) => {
        load.color = value;
      });
      return this.updateLoads(loads);
    };

    this.contextMenuActionHandlers.changeWeight = (
      loads: Load[],
      value: ChangeLoadWeight
    ) => {
      const changedLoads = loads.map((l) => {
        l.weight = value.weight;
        return l;
      });
      return this.changeWeight(changedLoads);
    };

    this.contextMenuActionHandlers.changeFlooring = (
      loads: Load[],
      value: ChangeFlooring
    ) => {
      loads.forEach((load) => {
        load.floorableTop = value.floorableTop;
        load.floorableBottom = value.floorableBottom;
      });
      return this.changeFlooring(loads);
    };

    this.contextMenuActionHandlers.copy = (loads: Load[], value: any) =>
      loads.length === 1
        ? this.copyLoad(loads[0], value)
        : this.completeContextMenuAction();

    this.contextMenuActionHandlers.delete = (loads: Load[]) =>
      this.removeLoads(loads);

    this.contextMenuActionHandlers.edit = (loads: Load[]) => {
      const grouped = new CarTrailerGroup();
      loads.forEach((l) => grouped.addLoad(l));
      const component = this.loadComponentService.add(EditLoadedGroupComponent);
      component.setInput('group', grouped);
      return this.completeContextMenuAction();
      //loads.forEach((load) => {
      //load.color = value;
      //});
      //return this.updateLoads(loads);
    };

    this.contextMenuActionHandlers.close = () =>
      /*this.unselectLoads();*/
      EMPTY;
  }

  private findLoadByMeshUuid(uuid: string) {
    return this.context
      .getAllLoads()
      .find((x) => x.mesh.obj.userData.uuid === uuid);
  }
}
