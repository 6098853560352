<div
  *ngIf="listType === 'user' && items.length > 0"
  class="select-all-container"
>
  <mat-checkbox
    (change)="selectAll()"
    [checked]="areAllSelected()"
    i18n-title
    title="Zaznacz wszystkie ładunki"
  ></mat-checkbox>
  <button
    (click)="deleteAll()"
    class="delete-btn"
    i18n-title
    title="Usuń wszystkie ładunki własne"
  >
    ✕
  </button>
</div>
<div *ngIf="items.length === 0" class="help">
  <ng-container i18n
    >Zdefiniuj ładunki, aby dodać je do przestrzeni ładunkowej</ng-container
  >
</div>
<mat-list class="condensed">
  <mat-list-item
    *ngFor="let item of items; let i = index"
    [ngClass]="{ selected: item.selected }"
    [@flashBackground]="item.highlight ? 'show' : ''"
    (@flashBackground.done)="item.highlight = false"
  >
    <div class="load-list-item">
      <span *ngIf="listType === 'user'">
        <mat-checkbox
          (click)="noop()"
          (change)="updateSelection(item, $event)"
          [checked]="item.selected"
        ></mat-checkbox>
      </span>
      <span *ngIf="listType === 'user'">
        <mat-form-field appearance="outline" class="cnt condensed">
          <input
            matInput
            appOnlyNumber="integer"
            [(ngModel)]="item.cnt"
            (input)="cntChanged(item)"
            (focus)="$event.target.select()"
            autocomplete="off"
            name="cnt"
            required
            max="3000"
            [tabindex]="i + 1"
          />
        </mat-form-field>
      </span>
      <span class="name-container" (click)="selectSingle(item)">
        <span class="name">{{ item.load.fullName }}</span>
        <span>
          {{ item.load | dimensions | async }}
          <span *ngIf="item.load.weight">
            |
            {{ item.load.weight | weightUnit | async | number : '0.0-2' }}
            {{ ui.getWeightUnit() | async }}
          </span>
        </span>
      </span>
      <div *ngIf="listType === 'user'" class="actions">
        <button (click)="edit(item)" class="edit-btn">
          <mat-icon>edit</mat-icon>
        </button>
        <button (click)="delete(item)" class="delete-btn">✕</button>
      </div>
    </div>
  </mat-list-item>
</mat-list>
