import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SceneService } from '../scene.service';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { ProfileService } from '../../services/profile.service';
import { Settings } from 'src/app/lib/model/settings';
import { UiService } from 'src/app/services/ui.service';
import { DecimalPipe } from '@angular/common';

interface ActiveSetting {
  on: boolean;
  label: string;
  onClick?: () => void;
}

@Component({
  selector: 'app-scene-active-settings',
  templateUrl: './active-settings.component.html',
  styleUrls: ['./active-settings.component.less']
})
export class ActiveSettingsComponent implements OnInit, OnDestroy {
  @Input() set context(value: VehicleContext) {
    this.currentVehicle = value;
    this.updateDisplayedSettings();
  }

  public currentVehicle: VehicleContext;
  settings: Settings;
  protected activeSettings: ActiveSetting[];

  private unsubscribe$ = new Subject<void>();
  constructor(
    private sceneService: SceneService,
    private profileService: ProfileService,
    protected ui: UiService, // template
    private numbers: DecimalPipe
  ) {
    this.updateDisplayedSettings();
  }

  ngOnInit(): void {
    console.log('active-settings.component init');
    this.profileService
      .getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.settings = profile.settings;
      });

    this.sceneService
      .shouldRefreshInvoked()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateDisplayedSettings());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggle() {
    this.settings.showCriteria = !this.settings.showCriteria;
    this.profileService
      .saveSettings(this.settings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((settings) => {
        this.profileService.updateSettings(settings);
      });
  }

  private updateDisplayedSettings() {
    this.activeSettings = [
      {
        label: $localize`Losowanie kolorów`,
        on: this.currentVehicle?.getSettings()?.randomizeColors ?? false,
        onClick: () => {
          this.sceneService.showVehicleSettings();
        }
      },
      {
        label: $localize`Układaj całymi piętrami`,
        on: this.currentVehicle?.getSettings()?.fullLevels ?? false,
        onClick: () => {
          this.sceneService.showVehicleSettings();
        }
      }
    ];
    const hasFreeRotatedLoads =
      this.currentVehicle
        ?.getAllLoads()
        .findIndex((l) => !l.verticalRotationFrozen) >= 0;
    if (hasFreeRotatedLoads) {
      this.activeSettings.push({
        on: true,
        label: $localize`Obrót dla co najmn. 1 ładunku`
      });
    } else {
      this.activeSettings.push({
        on: false,
        label: $localize`Obrót dla wszystkich ładunków`
      });
    }
    this.currentVehicle
      ?.getVehicle()
      .enabledSpaces.forEach((space, index, spaces) => {
        if (space.settings.maxHeightEnabled) {
          let label = '';
          if (spaces.length > 1) {
            label += '(' + (index + 1) + ') ';
          }
          label += $localize`Piętrowanie do`;
          label +=
            ' - ' +
            this.numbers.transform(
              this.ui.getLengthInCurrentUnit(space.settings.height),
              '0.0-2'
            ) +
            ' ' +
            this.ui.getCurrentLengthUnit();

          this.activeSettings.push({
            label: label,
            on: space.settings.maxHeightEnabled,
            onClick: () => {
              this.sceneService.showFlooringLevelSettings();
            }
          });
        }
        if (space.settings.maxRowsCntEnabled) {
          let label = '';
          if (spaces.length > 1) {
            label += '(' + (index + 1) + ') ';
          }
          label += $localize`Piętrowanie do pięter`;
          label += ' - ' + space.settings.rowsCnt;

          this.activeSettings.push({
            label: label,
            on: space.settings.maxRowsCntEnabled,
            onClick: () => {
              this.sceneService.showFlooringLevelSettings();
            }
          });
        }
      });
  }
}
