<div class="projects-container">
  <div class="filters">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label i18n>Do kraju: </mat-label>
        <mat-select formControlName="toCountry">
          <mat-option
            *ngFor="let loc of orderService.countries"
            [value]="loc.value"
          >
            {{ loc.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!--<mat-form-field class="wide">
        <mat-label i18n>Przyczepa</mat-label>

        <input
          matInput
          type="text"
          placeholder="Przyczepa"
          formControlName="load"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayLoad">
          <mat-option *ngFor="let item of filteredLoads" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    -->
      <mat-form-field appearance="fill">
        <mat-label i18n>Wypełnienie: </mat-label>
        <mat-select formControlName="fillType">
          <mat-option [value]="'LDM'" i18n>LDM</mat-option>
          <mat-option [value]="'volume'" i18n>Objętość</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Cel</mat-label>

        <input
          matInput
          type="number"
          placeholder="Cel %"
          formControlName="target"
        />
        <span matTextSuffix>%</span>
      </mat-form-field>
    </form>
  </div>
</div>

<div class="charts-grid">
  <app-order-reports-chart
    [year]="year1"
    [yearHalf]="yearHalf1"
  ></app-order-reports-chart>
  <app-order-reports-chart
    [year]="year2"
    [yearHalf]="yearHalf2"
  ></app-order-reports-chart>
  <app-order-reports-chart
    [year]="year3"
    [yearHalf]="yearHalf3"
  ></app-order-reports-chart>
  <app-order-reports-chart
    [year]="year4"
    [yearHalf]="yearHalf4"
  ></app-order-reports-chart>
</div>
