<div [formGroup]="form" class="form-control {{ type }}">
  <label class="required" i18n [hidden]="type == 'stacked'">Ile sztuk</label>
  <mat-form-field
    [appearance]="type == 'stacked' ? 'fill' : 'outline'"
    class="cnt"
  >
    <mat-label i18n *ngIf="type == 'stacked'">Ile sztuk</mat-label>
    <input
      matInput
      appOnlyNumber="integer"
      formControlName="cnt"
      autocomplete="off"
      [placeholder]="label"
      name="cnt"
      required
      max="3000"
    />
  </mat-form-field>
  <mat-error *ngIf="form.controls['cnt'].hasError('required')">
    <ng-container i18n>Podaj</ng-container><span>&nbsp;</span>
    <strong><ng-container i18n>liczbę</ng-container></strong>
  </mat-error>
  <mat-error *ngIf="form.controls['cnt'].hasError('max')">
    <ng-container i18n>Max.</ng-container>&nbsp;<strong>3000</strong>&nbsp;
    <ng-container i18n>na raz</ng-container>
  </mat-error>
</div>
