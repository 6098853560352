import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { Load } from '../../lib/load';

@Component({
  selector: 'app-load-disassemble-components',
  templateUrl: './disassemble.component.html',
  styleUrls: ['./disassemble.component.less']
})
export class DisassembleComponent implements OnInit, OnDestroy {
  public opened: boolean;
  @Input() mode: string = 'add';

  @Input() set item(item: Load) {
    this._item = item;
    this.initForm();
  }

  get item(): Load {
    return this._item;
  }

  private _item: Load;

  private unsubscribe$ = new Subject<boolean>();

  public form: UntypedFormGroup;

  private formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

  constructor() {
    this.initForm();
  }

  private initForm() {
    //console.log('disassemble init', this.item?.canDisassemble)
    const elements = {};
    if (this.item) {
      this.item.canDisassemble.forEach((element) => {
        elements[element] = [
          this.item.disassembleComponents.indexOf(element) >= 0,
          Validators.compose([])
        ];
      });
    }
    this.form = this.formBuilder.group(elements);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  change(name: string, event: MatCheckboxChange) {
    const checked = event.checked;
    console.log('disassemble change', name, checked);
  }

  public getValues() {
    const values = {};
    for (const name in this.form.controls) {
      values[name] = this.form.get(name).value;
    }
    return values;
  }

  get valid(): boolean {
    let valid = this.form.valid;

    return valid;
  }
}
