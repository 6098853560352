import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Camera, Vector3 } from 'three';
import { Vector } from '../lib/communication/vector';
import { OrbitControlsState } from '../lib/model/orbit-controls-state';
import { VehicleContext } from '../lib/model/vehicle-context';
import { OrbitControls } from '../lib/vendor/three/OrbitControls';

@Injectable({
  providedIn: 'root'
})
export class OrbitControlsService {
  private model = new Subject<OrbitControls>();
  private object: OrbitControls;
  private initialState: OrbitControlsState;

  constructor() {}

  public reset() {
    this.object.reset();
    this.yield();
  }

  public getCurrentControlsState(): OrbitControlsState {
    const state = new OrbitControlsState();
    const currentPosition = this.object.getCurrentPosition();
    const currentTarget = this.object.getCurrentTarget();
    const currentZoom = this.object.getCurrentZoom();
    //// console.log(currentPosition, currentTarget, currentZoom);
    state.position = new Vector({
      x: currentPosition.x,
      y: currentPosition.y,
      z: currentPosition.z
    });

    state.target = new Vector({
      x: currentTarget.x,
      y: currentTarget.y,
      z: currentTarget.z
    });

    state.zoom = currentZoom;
    return state;
  }

  public restore(context: VehicleContext) {
    if (!this.object) {
      return;
    }

    let state = context.getOrbitControlsState();
    // console.log('orbit-controls.service.ts: restore()', state);
    if (
      state !== undefined &&
      state !== null &&
      state.target &&
      state.position
    ) {
      // // console.log('state !== null', state);
    } else {
      // console.log('orbit controls reset');
      state = this.initialState;
      //this.reset();
    }
    this.object.restore(
      new Vector3(state.target.x, state.target.y, state.target.z),
      new Vector3(state.position.x, state.position.y, state.position.z),
      state.zoom
    );

    // // console.log('current target', this.object.getCurrentTarget());
    // // console.log('current position', this.object.getCurrentPosition());
    // // console.log('current zoom', this.object.getCurrentZoom());
    // this.object.reset();
  }

  public init(camera: Camera, canvas: HTMLCanvasElement) {
    // console.log('init orbit controls');
    this.object = new OrbitControls(camera, canvas);
    this.object.minDistance = 1000;
    this.object.maxDistance = 41000;
    this.object.enableZoom = true;
    this.initialState = this.getCurrentControlsState();
    //this.object.saveState();
    this.object.addEventListener('change', this.yield.bind(this));
  }

  public modelChanged(): Observable<OrbitControls> {
    return this.model.asObservable();
  }

  public getControls() {
    return this.object;
  }

  public move(value: string) {
    this.handleEvent('move-' + value);
  }

  public dolly(value: string) {
    this.handleEvent('dolly-' + value);
  }

  public rotate(value: string) {
    this.handleEvent('rotate-' + value);
  }

  public enable() {
    this.object.enabled = true;
    this.object.onlyZoom = false;
  }

  public disable() {
    this.object.enabled = false;
    this.object.saveState();
    this.object.reset();
  }

  public disableAllButZoom() {
    this.object.enabled = false;
    this.object.onlyZoom = true;
    this.object.saveState();
    this.object.reset();
  }

  private yield() {
    // // console.log('yield');
    this.model.next(this.object);
  }

  private handleEvent(move: string) {
    switch (move) {
      case 'rotate-up':
        this.object.rotateUp(1 / (2 * Math.PI));
        break;
      case 'rotate-down':
        this.object.rotateUp(-1 / (2 * Math.PI));
        break;
      case 'rotate-left':
        this.object.rotateLeft(1 / (2 * Math.PI));
        break;
      case 'rotate-right':
        this.object.rotateLeft(-1 / (2 * Math.PI));
        break;
      case 'move-up':
        this.object.moveUp();
        break;
      case 'move-down':
        this.object.moveDown();
        break;
      case 'move-left':
        this.object.moveLeft();
        break;
      case 'move-right':
        this.object.moveRight();
        break;
      case 'dolly-in':
        this.object.dollyIn(Math.pow(0.95, 5));
        break;
      case 'dolly-out':
        this.object.dollyOut(Math.pow(0.95, 5));
        break;
    }
    this.object.update();
  }
}
