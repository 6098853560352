import { Injectable } from '@angular/core';
import { Space } from '../../vehicle/space/lib/space';
import {
  Box3,
  Box3Helper,
  BoxGeometry,
  Color,
  Euler,
  Group,
  Material,
  Matrix3,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Object3DEventMap,
  Scene,
  SphereGeometry,
  Vector3
} from 'three';
import { OBB } from 'src/app/lib/vendor/three/OBB';
import { ColliderDetector } from 'src/app/scene/collider-detector';
import { Load } from 'src/app/load/lib/load';
import { Positioner } from './positioner';
import { Settings } from 'src/app/vehicle/form/settings/settings';
import { Message } from 'src/app/messenger/message';

type OBBWithName = { name: string; obb: OBB; mesh: Mesh };
type MeshWithObbs = {
  mesh: Mesh;
  obbs: OBBWithName[];
  obbsExp: OBBWithName[];
  load: Load;
  aabb: Box3;
  aabbExp: Box3;
  center: Vector3;
  fixedRotation?: Euler;
  alternateYRotation?: true;
  alternateXRotation?: true;
};

type RotationRangeCache = { [key: string]: number[] };

class Statistics {
  maxX: number;
  maxY: number;
  maxZ: number;

  position: Vector3;
  rotation: Euler;

  constructor(mesh: Object3D, loaded: Object3D[]) {
    const objs: Object3D[] = loaded.map((l) => l);
    objs.push(mesh);

    this.maxX = Math.max(
      ...objs.map((l) => l.position.x + l.userData.halfSize.x)
    );
    this.maxY = Math.max(
      ...objs.map((l) => l.position.y + l.userData.halfSize.y)
    );
    this.maxZ = Math.max(
      ...objs.map((l) => l.position.z + l.userData.halfSize.z)
    );
    this.position = mesh.position.clone();
    this.rotation = mesh.rotation.clone();
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrailerPositioner implements Positioner {
  private space: Space;
  private spaceOffset: Vector3 = new Vector3(-Infinity, 0, -Infinity);
  private minXPositionToCheckForNewStack = -Infinity;
  private verticalTrailersXEnd = -Infinity;
  private scene: Scene;
  private axisY = new Vector3(0, 1, 0);
  private axisX = new Vector3(1, 0, 0);
  private axisZ = new Vector3(0, 0, 1);
  private sceneSettings: Settings;
  private lastIdx = 0;

  private loadedMeshes: MeshWithObbs[] = [];
  private rotationRangeCache: RotationRangeCache = {};

  // reusable
  private rotation = new Euler();
  private centerOffset = new Vector3();
  private spaceBounds: OBB[] = [];

  constructor(private collisionDetector: ColliderDetector) {}

  public setScene(scene: Scene) {
    this.scene = scene;
  }

  public reset() {
    this.minXPositionToCheckForNewStack = -Infinity;
    this.verticalTrailersXEnd = -Infinity;
    this.lastIdx = 0;
    this.loadedMeshes = [];
  }

  public setSpace(space: Space) {
    this.space = space;
    this.space.mesh.meshObj.updateMatrix();
    this.space.mesh.meshObj.updateMatrixWorld();
    this.spaceOffset = this.calculateSpacePosition();
    this.spaceBounds = this.setupSpaceBounds();
    this.minXPositionToCheckForNewStack = this.spaceOffset.x;
    //this.verticalTrailersXEnd = this.spaceOffset.x;
  }

  public setSpaceOffsetManual(v: Vector3) {
    this.spaceOffset = v.clone();
    this.minXPositionToCheckForNewStack = this.spaceOffset.x;
    //this.verticalTrailersXEnd = this.spaceOffset.x;
  }

  private updateExpandedObbs(meshInfo: MeshWithObbs) {
    meshInfo.mesh.updateMatrix();
    meshInfo.mesh.updateMatrixWorld();
    const bb = new Box3();
    const obbs: OBBWithName[] = [];
    for (const obbInfo of meshInfo.obbsExp) {
      obbInfo.mesh.userData.obbExp.copy(obbInfo.mesh.geometry.userData.obbExp);
      obbInfo.mesh.userData.obbExp.applyMatrix4(obbInfo.mesh.matrixWorld);
      obbs.push({
        name: obbInfo.mesh.name,
        obb: obbInfo.mesh.userData.obbExp,
        mesh: obbInfo.mesh
      });
      bb.union(obbInfo.mesh.userData.obbExp.getAabb());
    }
    const size = bb.getSize(new Vector3());
    meshInfo.mesh.userData.sizeExp = size;
    meshInfo.mesh.userData.halfSizeExp = size.clone().multiplyScalar(0.5);
    meshInfo.aabbExp = bb;
    meshInfo.obbsExp = obbs;
  }

  private updateObbs(meshInfo: MeshWithObbs) {
    const bb = new Box3();
    const obbs: OBBWithName[] = [];
    for (const obbInfo of meshInfo.obbs) {
      obbInfo.mesh.userData.obb.copy(obbInfo.mesh.geometry.userData.obb);
      obbInfo.mesh.userData.obb.applyMatrix4(obbInfo.mesh.matrixWorld);
      obbs.push({
        name: obbInfo.mesh.name,
        obb: obbInfo.mesh.userData.obb,
        mesh: obbInfo.mesh
      });
      bb.union(obbInfo.mesh.userData.obb.getAabb());
    }

    const size = bb.getSize(new Vector3());
    const center = bb.getCenter(new Vector3());
    meshInfo.mesh.userData.size = size;
    meshInfo.mesh.userData.halfSize = size.clone().multiplyScalar(0.5);
    meshInfo.aabb = bb;
    meshInfo.obbs = obbs;
    meshInfo.center = center;
  }

  public updatePosition(meshInfo: MeshWithObbs, position: Vector3) {
    meshInfo.mesh.position.copy(position);
    meshInfo.mesh.updateMatrix();
    meshInfo.mesh.updateMatrixWorld();
    this.updateObbs(meshInfo);
  }

  public updateRotationEuler(meshInfo: MeshWithObbs, euler: Euler) {
    meshInfo.mesh.rotation.copy(euler);
    meshInfo.mesh.updateMatrix();
    meshInfo.mesh.updateMatrixWorld(true);
    this.updateObbs(meshInfo);
  }

  private drawDebugDot(position: Vector3, color: number, attachTo: Object3D) {
    const geometry = new SphereGeometry(0.05 * 1000);
    const material = new MeshBasicMaterial({
      color,
      transparent: true,
      depthWrite: false,
      opacity: 0.5
    });
    const mesh = new Mesh(geometry, material);
    mesh.name = 'debug';
    mesh.position.copy(position);
    mesh.layers.set(1);
    attachTo.attach(mesh);
  }

  private calculateSpacePosition(): Vector3 {
    const spaceOnScene =
      this.space.mesh.meshObj.parent?.parent?.type === 'Scene';
    if (spaceOnScene) {
      const spaceOffset = this.space.mesh.meshObj.getWorldPosition(
        new Vector3()
      );
      console.log('space on scene', spaceOffset.x);
      const position = spaceOffset.clone();
      return position;
    } else {
      const position = this.space.mesh.meshObj.position.clone();
      if (this.space.mesh.meshObj.parent) {
        const vehicleSize = new Box3()
          .setFromObject(this.space.mesh.meshObj.parent)
          .getSize(new Vector3());
        position.x -= vehicleSize.x / 2;
        position.z -= vehicleSize.z / 2;
      }
      console.log('space not on scene', position.x);
      return position;
    }
  }

  public inSpaceBounds(mesh: Object3D) {
    const spaceBB = new Box3().setFromObject(this.space.mesh.meshObj);
    spaceBB.expandByScalar(1);

    const spaceOBB = new OBB().fromBox3(spaceBB);

    let isSomethingOutside = false;
    mesh.traverse((child) => {
      if (child instanceof Mesh) {
        if (isSomethingOutside || !child.userData.obb) {
          return;
        }
        //const bb = new Box3().setFromObject(child);

        const obb = child.userData.obb;
        //console.log('spaceOBB', spaceOBB, 'obb', obb);

        //console.log('spaceOBB', spaceOBB, 'meshOBB', obb);
        //this.drawDebugDot(obb.center, 0xff0000, this.scene);

        if (!spaceOBB.intersectsOBB(obb)) {
          console.log('outside space', child.name);
          isSomethingOutside = true;
        }
      }
    });

    return !isSomethingOutside;
  }

  private drawOBB(obb: OBB, attachTo?: Object3D, color?: number) {
    const geometry = new BoxGeometry(
      obb.halfSize.clone().multiplyScalar(2).x,
      obb.halfSize.clone().multiplyScalar(2).y,
      obb.halfSize.clone().multiplyScalar(2).z
    );
    const material = new MeshBasicMaterial({ color: color || 0x00ff00 });
    const cube = new Mesh(geometry, material);
    cube.position.copy(obb.center);
    const rotationMatrix = new Matrix4().setFromMatrix3(obb.rotation);
    cube.rotation.setFromRotationMatrix(rotationMatrix, Euler.DEFAULT_ORDER);
    if (attachTo) {
      attachTo.attach(cube);
    } else {
      this.scene.add(cube);
    }
  }

  private setupSpaceBounds() {
    const spaceBounds: OBB[] = [];
    const halfSize = new Vector3(10000, 10000, 10000);
    const offset = 10005;
    const rotation = new Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1);

    const spaceFront = new OBB(); // przód
    spaceFront.set(
      new Vector3(
        this.spaceOffset.x - offset,
        this.spaceOffset.y + this.space.height / 2,
        this.spaceOffset.z + this.space.width / 2
      ),
      halfSize,
      rotation
    );
    spaceBounds.push(spaceFront);

    //this.drawOBB(spaceFront);

    const spaceBack = new OBB(); // tył
    spaceBack.set(
      new Vector3(
        this.spaceOffset.x + this.space.length + offset,
        this.spaceOffset.y + this.space.height / 2,
        this.spaceOffset.z + this.space.width / 2
      ),
      halfSize,
      rotation
    );
    spaceBounds.push(spaceBack);

    //this.drawOBB(spaceBack);

    const spaceBelow = new OBB(); // dół
    spaceBelow.set(
      new Vector3(
        this.spaceOffset.x + this.space.length / 2,
        this.spaceOffset.y - offset,
        this.spaceOffset.z + this.space.width / 2
      ),
      halfSize,
      rotation
    );
    spaceBounds.push(spaceBelow);

    //this.drawOBB(spaceBelow);

    const spaceAbove = new OBB(); // góra
    spaceAbove.set(
      new Vector3(
        this.spaceOffset.x + this.space.length / 2,
        this.spaceOffset.y + this.space.height + offset,
        this.spaceOffset.z + this.space.width / 2
      ),
      halfSize,
      rotation
    );
    spaceBounds.push(spaceAbove);

    //this.drawOBB(spaceAbove);

    const spaceRight = new OBB(); // prawa
    spaceRight.set(
      new Vector3(
        this.spaceOffset.x + this.space.length / 2,
        this.spaceOffset.y + this.space.height / 2,
        this.spaceOffset.z - offset
      ),
      halfSize,
      rotation
    );
    spaceBounds.push(spaceRight);

    //this.drawOBB(spaceRight);

    const spaceLeft = new OBB(); // lewa
    spaceLeft.set(
      new Vector3(
        this.spaceOffset.x + this.space.length / 2,
        this.spaceOffset.y + this.space.height / 2,
        this.spaceOffset.z + this.space.width + offset
      ),
      halfSize,
      rotation
    );
    spaceBounds.push(spaceLeft);
    //this.drawOBB(spaceLeft);

    return spaceBounds;
  }

  private checkSpaceBoundsDebug(
    meshInfo: MeshWithObbs,
    debug = false,
    useExpandedObbs = false
  ) {
    const mesh = meshInfo.mesh;

    let isSomethingOutside = false;
    //mesh.updateMatrix();
    //mesh.updateMatrixWorld();

    for (const spaceObb of this.spaceBounds) {
      if (
        !spaceObb.intersectsBox3(
          useExpandedObbs ? meshInfo.aabbExp : meshInfo.aabb
        )
      ) {
        continue;
      }
      const obbs = useExpandedObbs ? meshInfo.obbsExp : meshInfo.obbs;
      for (const obbInfo of obbs) {
        if (obbInfo.obb.intersectsOBB(spaceObb, Number.EPSILON)) {
          isSomethingOutside = true;
          debug && console.log('space bound breached', obbInfo.name);
          debug && this.drawOBB(obbInfo.obb, mesh, 0xff0000);
          break;
        }
      }
      if (isSomethingOutside) {
        break;
      }
    }
    //console.log('isSomethingOutside', isSomethingOutside);
    return !isSomethingOutside;
  }

  private drawMesh(load: Load, loads: Array<Load>, scene: Scene) {
    loads.push(load);
    scene.add(load.mesh.obj);
  }

  private randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  private randomGroundedPosition(mesh: Object3D): Vector3 {
    const position = this.spaceOffset;
    const x = this.randomIntFromInterval(
      this.minXPositionToCheckForNewStack + mesh.userData.halfSizeExp.x,
      position.x + this.space.length - mesh.userData.halfSizeExp.x
    );

    const y = position.y + mesh.userData.halfSize.y + 20; // FIXME: CARGO7260x153 z jakiegoś powodu nie mieści się bez dodatku 2mm

    const zMin =
      position.z + mesh.userData.halfSizeExp.z - Math.abs(this.centerOffset.z);
    const zMax =
      position.z +
      this.space.width -
      mesh.userData.halfSizeExp.z +
      Math.abs(this.centerOffset.z);
    /*console.log(
      'Random position possible Z positions',
      Math.ceil(zMax - zMin),
      zMin,
      zMax,
      Math.abs(this.centerOffset.z),
      mesh.rotation.y * (180 / Math.PI),
      this.space.width,
      mesh.userData.size.z
    );*/
    const z = this.randomIntFromInterval(zMin, zMax);

    return new Vector3(x, y, z);
  }

  /**
   * Wrapper dla moveToLowestPossibleDirection
   *
   * @param load Load
   * @param loaded Load[]
   * @param direction Vector3
   * @param debug boolean
   * @returns boolean
   */
  public moveLoadToLowestPossibleDirection(
    load: Load,
    loaded: Load[],
    direction: Vector3,
    debug?: boolean
  ): boolean {
    const meshInfo = this.getObbInfoForLoad(load);
    this.loadedMeshes = this.prepareLoadedMeshes(loaded, false);
    this.calculateMinFreeXPosition();
    return this.moveToLowestPossibleDirection(meshInfo, direction, debug);
  }

  /**
   * Przenosi przyczepę do minimalnej możliwej pozycji w danym kierunku,
   * pozostawiając pozostałe współrzędne niezmienione
   *
   * @param meshInfo MeshWithObbs
   * @param direction Vector3
   * @param debug boolean
   * @returns boolean
   */
  public moveToLowestPossibleDirection(
    meshInfo: MeshWithObbs,
    direction: Vector3,
    debug = false,
    useExpandedObbs = false
  ): boolean {
    const position = meshInfo.mesh.position.clone();
    const originalPosition = position.clone();

    let l = 0;
    let r = 0;
    let dimension = 'x';

    if (direction.x === 1) {
      dimension = 'x';
    } else if (direction.y === 1) {
      dimension = 'y';
    } else if (direction.z === 1) {
      dimension = 'z';
    }

    const halfSize = useExpandedObbs
      ? meshInfo.mesh.userData.halfSizeExp[dimension]
      : meshInfo.mesh.userData.halfSize[dimension];

    const lowest =
      this.spaceOffset[dimension] + halfSize + this.centerOffset[dimension];
    l = lowest;
    r = meshInfo.mesh.position[dimension];

    debug && console.log('TRY move', dimension, l, r);

    let moved = false;
    while (l < r) {
      let m = Math.floor((l + r) / 2);
      if (m !== Math.floor(position[dimension])) {
        moved = true;
      } else {
        break;
      }
      position[dimension] = m;

      this.updatePosition(meshInfo, position);
      if (useExpandedObbs) {
        this.updateExpandedObbs(meshInfo);
      }
      const collision = this.collisionDetector.obbCollided(
        meshInfo,
        this.loadedMeshes,
        useExpandedObbs
      );
      const halfSize = useExpandedObbs
        ? meshInfo.mesh.userData.halfSizeExp[dimension]
        : meshInfo.mesh.userData.halfSize[dimension];
      if (
        collision ||
        !this.checkSpaceBoundsDebug(meshInfo, false, useExpandedObbs) ||
        (dimension === 'x' &&
          meshInfo.center.x - halfSize < this.verticalTrailersXEnd)
      ) {
        debug &&
          console.log(
            'collided, check higher half',
            position[dimension],
            collision,
            l,
            r,
            m
          );
        l = m + 1;
      } else if (r > m) {
        debug &&
          console.log(
            'free space, check lower half',
            position[dimension],
            l,
            r,
            m
          );
        r = m - 1;
      } else {
        debug &&
          console.log('free space in lower half', position[dimension], l, r, m);

        break;
      }
    }

    if (moved) {
      const dilatation = meshInfo.mesh.position.clone();
      dilatation[dimension] += 2;
      this.updatePosition(meshInfo, dilatation);
      return (
        Math.round(
          meshInfo.mesh.position.clone().distanceToSquared(originalPosition)
        ) > 25
      ); // moved more than 5mm any direction
    }
  }

  private tryRandomPosition(meshInfo: MeshWithObbs): boolean {
    let found = false;
    let position = this.randomGroundedPosition(meshInfo.mesh);

    this.updatePosition(meshInfo, position);
    this.updateExpandedObbs(meshInfo);
    if (this.checkSpaceBoundsDebug(meshInfo, false, true)) {
      if (
        !this.collisionDetector.obbCollided(meshInfo, this.loadedMeshes, true)
      ) {
        found = true;
      }
    }

    if (found) {
      this.moveToLowestPossibleDirection(meshInfo, this.axisY, false, true);
      this.moveToLowestPossibleDirection(meshInfo, this.axisX, false, true);
      if (meshInfo.mesh.userData.size.x >= 5500) {
        // drutowanie żeby 5520 stało na środku
        const zCenter = position.clone();
        zCenter.z = 0 + this.centerOffset.z;
        this.updatePosition(meshInfo, zCenter);
        console.log('Long trailer - centering');
      } else {
        const movedZ = this.moveToLowestPossibleDirection(
          meshInfo,
          this.axisZ,
          false,
          true
        );
      }
    }
    //console.log('found place after lowering at', mesh.position.y);
    return found;
  }

  private randomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  private randomAngle(minRadians: number, maxRadians: number) {
    const minDegrees = minRadians * (180 / Math.PI);
    const maxDegrees = maxRadians * (180 / Math.PI);
    const randomDegrees = Math.floor(
      Math.random() * (maxDegrees - minDegrees + 1) + minDegrees
    ); // rounded to 1 degree
    const randomRadians = randomDegrees * (Math.PI / 180);

    return randomRadians;
  }

  private tryRandomRotation(
    meshInfo: MeshWithObbs,
    rotationRange: number[] = []
  ): boolean {
    let found = false;
    let maxRotation = Math.PI * 2;
    if (this.loadedMeshes.length === 0) {
      maxRotation = Math.PI / 2;
    }
    const availableRotation = rotationRange.filter((y) => y <= maxRotation);
    let rotation: number;
    if (meshInfo.mesh.userData.size.x >= 5500) {
      rotation = 0;
    } else {
      rotation =
        availableRotation[Math.floor(Math.random() * availableRotation.length)];
    }
    this.rotation.x = 0;
    this.rotation.y = rotation;
    this.rotation.z = 0;
    this.updateRotationEuler(meshInfo, this.rotation);
    return found;
  }

  private newStackForVertical(meshInfo: MeshWithObbs) {
    console.log('start new vertical stack', meshInfo.mesh.userData.halfSize);
    const maxX = Math.max(
      this.spaceOffset.x,
      ...this.loadedMeshes.map((l) => l.center.x + l.mesh.userData.halfSize.x)
    );
    let xDilatation = this.loadedMeshes.length === 0 ? 0 : 2;
    const x = maxX + meshInfo.mesh.userData.halfSizeExp.x + xDilatation;
    const z = this.spaceOffset.z + meshInfo.mesh.userData.halfSize.z;
    const y = this.spaceOffset.y + meshInfo.mesh.userData.halfSize.y;
    const position = new Vector3(x, y, z);
    this.updatePosition(meshInfo, position);
    const collision = this.collisionDetector.obbCollided(
      meshInfo,
      this.loadedMeshes,
      true
    );
    if (!collision) {
      if (this.checkSpaceBoundsDebug(meshInfo, false, true)) {
        return true;
      } else {
        //console.log('Successfully placed on top but OUT OF THE SPACE BOUNDS');
      }
    } else {
      console.log('collision', collision);
    }
    return false;
  }

  private checkRotationRange(meshInfo: MeshWithObbs): number[] {
    const cacheKey = `${this.space.width}_${meshInfo.load.modelName}`;
    if (typeof this.rotationRangeCache[cacheKey] !== 'undefined') {
      return this.rotationRangeCache[cacheKey];
    }
    meshInfo.load.applyCurrentRotation();
    const accepted: number[] = [];
    for (let rotation = 0; rotation <= 90; rotation++) {
      let radians = rotation * (Math.PI / 180);
      this.rotation.x = 0;
      this.rotation.y = radians;
      this.rotation.z = 0;
      this.updateRotationEuler(meshInfo, this.rotation);
      this.updateExpandedObbs(meshInfo);
      if (meshInfo.mesh.userData.sizeExp.z <= this.space.width) {
        accepted.push(meshInfo.mesh.rotation.y);
      }
    }
    const below180 = accepted.map((y) => Math.PI - y);
    const above180 = accepted.map((y) => Math.PI + y);
    const below360 = accepted.map((y) => 2 * Math.PI - y);
    const range = [...accepted, ...below180, ...above180, ...below360];
    this.rotationRangeCache[cacheKey] = range;
    this.rotation.y = 0;
    return range;
  }

  private initStack(meshInfo: MeshWithObbs): boolean {
    let found = false;
    let steps = 750;
    const availableRotation = this.checkRotationRange(meshInfo);
    console.log(
      'Rotation restricted to ',
      availableRotation.length,
      availableRotation.map((rad) => rad * (180 / Math.PI))
    );

    let bestStats: Statistics;
    meshInfo.load.applyCurrentRotation();

    while (steps-- > 0) {
      if (!meshInfo.fixedRotation) {
        this.tryRandomRotation(meshInfo, availableRotation);
      }
      this.centerOffset.subVectors(meshInfo.mesh.position, meshInfo.center);
      found = this.tryRandomPosition(meshInfo);

      if (found) {
        const stats = new Statistics(
          meshInfo.mesh,
          this.loadedMeshes.map((i) => i.mesh)
        );
        let override = !bestStats;
        if (!override) {
          if (
            this.loadedMeshes.length === 0 &&
            meshInfo.load.modelName.startsWith('CARGO')
          ) {
            if (Math.abs(stats.rotation.y) > Math.abs(bestStats.rotation.y)) {
              console.log(
                'Set best stats to rotation',
                stats.rotation.y,
                Math.abs(stats.rotation.y) - Math.abs(bestStats.rotation.y)
              );
              override = true;
            }
          } else {
            if (Math.round(stats.maxX - bestStats.maxX) === 0) {
              override = stats.maxY < bestStats.maxY;
            } else if (stats.maxX < bestStats.maxX) {
              override = true;
            }
          }
        }
        if (override) {
          bestStats = stats;
        }
      }
    }

    if (bestStats) {
      console.log('found new stack position', bestStats);
      found = true;
      this.updateRotationEuler(meshInfo, bestStats.rotation);
      this.updatePosition(meshInfo, bestStats.position);
      //this.checkSpaceBoundsDebug(meshInfo, true);
    } else {
      found = false;
      console.log('not found');
    }
    /*this.drawDebugDot(meshInfo.mesh.position, 0x0000ff, this.scene);
    this.drawDebugDot(
      meshInfo.aabb.getCenter(new Vector3()),
      0x00ff00,
      this.scene
    );
    const boxHelper = new Box3Helper(meshInfo.aabb);
    this.scene.add(boxHelper);
    for (const obbInfo of meshInfo.obbs) {
      const aabb = obbInfo.mesh.userData.obb.getAabb().clone();
      const h = new Box3Helper(aabb, new Color(0xff0000));
      this.scene.add(h);
    }*/
    this.updateExpandedObbs(meshInfo);
    /*for (const obbInfo of meshInfo.obbsExp) {
      const aabb = obbInfo.obb.getAabb().clone();
      const h = new Box3Helper(aabb, new Color(0x0000ff));
      this.scene.add(h);
    }*/
    /*const boxHelperExp = new Box3Helper(meshInfo.aabbExp, new Color(0x0000ff));
    this.scene.add(boxHelperExp);*/
    console.log(meshInfo.aabb.getSize(new Vector3()));
    return found;
  }

  private tryToPlaceOnTheTop(
    meshInfo: MeshWithObbs,
    bottomMesh: MeshWithObbs
  ): boolean {
    let found = false;
    if (meshInfo.mesh.uuid === bottomMesh.mesh.uuid) return false;
    console.log('put on top', bottomMesh.load.idx);
    //meshInfo.load.applyCurrentRotation();
    //const bottomBB = new Box3().setFromObject(bottomMesh);
    //const bottomMeshSize = bottomBB.getSize(new Vector3());

    const dilatation = 2;
    let position = bottomMesh.mesh.position.clone(); //.add(meshSize.multiplyScalar(0.5));
    position.y +=
      bottomMesh.mesh.userData.halfSize.y +
      meshInfo.mesh.userData.halfSize.y +
      dilatation;

    const endPosition = position.clone();
    if (bottomMesh.mesh.rotation.y > Math.PI / 2) {
      endPosition.x +=
        bottomMesh.mesh.userData.halfSize.x - meshInfo.mesh.userData.halfSize.x; // spróbujmy nie tylko na środku dolnej, ale też pod jej koniec
    } else {
      endPosition.x =
        endPosition.x -
        bottomMesh.mesh.userData.halfSize.x +
        meshInfo.mesh.userData.halfSize.x;
    }

    const candidatePositions: Vector3[] = [position, endPosition]; // endPosition nie działa dla lekko obróconych przyczep - trzeba byłoby przesuwać po ich osi X
    for (const axis of ['x', 'z']) {
      // wartość offsetów trochę magiczna, dla niektórych przypadków działa
      for (const add of [-5, 5]) {
        const offsetPosition = position.clone();
        offsetPosition[axis] += add;
        candidatePositions.push(offsetPosition);
      }
    }

    let rotation = bottomMesh.mesh.rotation.clone();
    if (meshInfo.alternateYRotation) {
      rotation.y = bottomMesh.mesh.rotation.y + Math.PI;
      console.log(
        'alternating Y rotation',
        bottomMesh.mesh.rotation.y,
        rotation.y
      );
    }
    if (meshInfo.alternateXRotation) {
      rotation.x = bottomMesh.mesh.rotation.x + Math.PI;
      console.log(
        'alternating X rotation',
        bottomMesh.mesh.rotation.x,
        rotation.x
      );
    }
    this.updateRotationEuler(meshInfo, rotation);

    //na mniejszej nigdy nie bedzie wieksza stała
    if (
      meshInfo.mesh.userData.size.x * meshInfo.mesh.userData.size.z >
      1.1 * (bottomMesh.mesh.userData.size.x * bottomMesh.mesh.userData.size.z)
    ) {
      console.log(
        'Current trailer is larger more than 10% than other trailers in the stack',
        meshInfo.mesh.userData.size,
        bottomMesh.mesh.userData.size
      );
      //return false;
    }

    for (const position of candidatePositions) {
      this.updatePosition(meshInfo, position);
      //tutaj potrafi wystawac
      const collision = this.collisionDetector.obbCollided(
        meshInfo,
        this.loadedMeshes
      );
      if (!collision) {
        this.moveToLowestPossibleDirection(meshInfo, this.axisY);
        //this.moveToLowestPossibleDirection(meshInfo, this.axisX, true);
        if (this.checkSpaceBoundsDebug(meshInfo)) {
          console.log('Successfully placed on top');
          found = true;
          break;
        } else {
          console.log('Successfully placed on top but OUT OF THE SPACE BOUNDS');
        }
      } else {
        console.log(
          `Collided while trying to place on the top of the other trailer: ${collision.with}`
        );
      }
    }

    return found;
  }

  private tryToPutOnAnyExistingLoad(meshInfo: MeshWithObbs): boolean {
    if (!meshInfo.load.floorableTop || this.loadedMeshes.length === 0) {
      return false;
    }
    let found = false;
    for (let i = this.loadedMeshes.length - 1; i >= 0; i--) {
      const bottomMesh = this.loadedMeshes[i];
      if (!bottomMesh.load.floorableBottom) {
        continue;
      }
      found = this.tryToPlaceOnTheTop(meshInfo, bottomMesh);
      console.log('found', found);
      if (found) {
        break;
      }
    }
    return found;
  }

  private tryToPlaceNextTo(
    meshInfo: MeshWithObbs,
    secondMesh: MeshWithObbs
  ): boolean {
    let found = false;
    if (meshInfo.mesh.uuid === secondMesh.mesh.uuid) return false;
    const dilatation = 1;
    let position = secondMesh.mesh.position.clone(); //.add(meshSize.multiplyScalar(0.5));
    position.z +=
      secondMesh.mesh.userData.halfSize.z +
      meshInfo.mesh.userData.halfSize.z +
      dilatation;
    position.y = this.spaceOffset.y + meshInfo.mesh.userData.halfSize.y;

    const candidatePositions: Vector3[] = [position];
    for (const axis of ['x']) {
      // wartość offsetów trochę magiczna, dla niektórych przypadków działa
      for (const add of [-5, 5]) {
        const offsetPosition = position.clone();
        offsetPosition[axis] += add;
        candidatePositions.push(offsetPosition);
      }
    }

    let rotation = secondMesh.mesh.rotation.clone();
    this.updateRotationEuler(meshInfo, rotation);

    for (const position of candidatePositions) {
      this.updatePosition(meshInfo, position);

      const collision = this.collisionDetector.obbCollided(
        meshInfo,
        this.loadedMeshes
      );
      if (!collision) {
        this.moveToLowestPossibleDirection(meshInfo, this.axisZ);
        if (this.checkSpaceBoundsDebug(meshInfo)) {
          console.log('Successfully placed on next to another');
          found = true;
          break;
        } else {
          console.log(
            'Successfully placed next to another but OUT OF THE SPACE BOUNDS'
          );
        }
      } else {
        console.log(
          `Collided while trying to place next to the other trailer: ${collision.with}`
        );
      }
    }

    return found;
  }

  /**
   * Próbuje ustawić pionową przyczepę obok innej w osi Z
   *
   * @param meshInfo MeshWithObbs
   * @returns boolean
   */
  private tryToPutNextToAnyExistingLoad(meshInfo: MeshWithObbs): boolean {
    if (this.loadedMeshes.length === 0) {
      return false;
    }
    let found = false;
    for (let i = 0; i < this.loadedMeshes.length; i++) {
      const secondMesh = this.loadedMeshes[i];
      found = this.tryToPlaceNextTo(meshInfo, secondMesh);
      console.log('found next to', found);
      if (found) {
        break;
      }
    }
    return found;
  }

  /**
   * Optymalizacja żeby nie trzeba było wywoływać traverse
   *
   * @param load Load
   * @returns MeshWithObbs
   */
  private getObbInfoForLoad(load: Load): MeshWithObbs {
    const obbs: OBBWithName[] = [];
    const obbsExp: OBBWithName[] = [];
    load.mesh.obj.traverse((child) => {
      if (!(child instanceof Mesh) || !child.userData.obb) {
        return;
      }
      obbs.push({ name: child.name, obb: child.userData.obb, mesh: child });
      if (child.userData.obbExp) {
        obbsExp.push({
          name: child.name,
          obb: child.userData.obbExp,
          mesh: child
        });
      }
    });
    const box = load.mesh.computeBoundingBox();
    const boxExp = load.mesh.computeBoundingBox(true);
    const center = box.getCenter(new Vector3());
    return {
      mesh: load.mesh.obj,
      obbs,
      obbsExp,
      load,
      aabb: box,
      aabbExp: boxExp,
      center
    };
  }

  private prepareLoadedMeshes(loaded: Load[], sortByX = true) {
    const loadedMeshes = loaded.map((load) => this.getObbInfoForLoad(load));
    if (sortByX) {
      return loadedMeshes.sort((a, b) =>
        a.mesh.position.x < b.mesh.position.x ? -1 : 1
      );
    } else {
      return loadedMeshes;
    }
  }

  private calculateMinFreeXPosition() {
    this.minXPositionToCheckForNewStack = Math.max(
      this.spaceOffset?.x || -Infinity,
      ...this.loadedMeshes.map((l) => l.aabb.min.x),
      this.verticalTrailersXEnd
    );
    console.log(
      'minFreeXPosition',
      this.minXPositionToCheckForNewStack,
      this.verticalTrailersXEnd
    );
  }

  private onLoadPlaced(meshInfo: MeshWithObbs, loaded: Load[]) {
    meshInfo.mesh.layers.enable(1);
    meshInfo.load.mesh.obj = meshInfo.mesh as Mesh;
    const idx = ++this.lastIdx;
    meshInfo.load.idx = idx;
    meshInfo.load.updateLoadPositionFromMesh();
    meshInfo.load.updateLoadRotationFromMesh();
    loaded.push(meshInfo.load);
    this.loadedMeshes.push(meshInfo);
    console.log(
      'load placed, box size: ',
      meshInfo.load.mesh.obj.userData.size
    );
    return loaded;
  }

  private extractLoadsByCriterion(
    loads: Load[],
    criterion: (load: Load) => boolean
  ): Load[] {
    const extractedLoads = [];
    for (let i = loads.length - 1; i >= 0; i--) {
      if (criterion(loads[i])) {
        extractedLoads.push(loads.splice(i, 1)[0]);
      }
    }
    return extractedLoads;
  }

  /**
   * Sprawdzanie wyjątków do załadunku
   *
   * @param loads Load[]
   * @returns Message | false wiadomość z błędem lub false jeśli nie ma błędów
   */
  public preLoadChecks(loads: Load[]): Message | false {
    const series1000Names = ['1205S', '1150S', '1203S'];
    const series1000Trailers = loads.filter((l) =>
      series1000Names.some((model) => l.name.includes(model))
    );

    const trailer1205SXL = this.extractLoadsByCriterion(
      series1000Trailers,
      (l) => l.name.includes('1205S XL')
    );
    const trailer1205SB = this.extractLoadsByCriterion(
      series1000Trailers,
      (l) => l.name.includes('1205S B')
    );
    const trailer1000TILT = this.extractLoadsByCriterion(
      series1000Trailers,
      (l) => l.name.includes('TILT')
    );

    // 1205 XL po 3 na palecie - wszystkie 1205 XL są też TILT
    if (trailer1205SXL.length % 3 !== 0) {
      return new Message(
        $localize`Zweryfikuj zamówienie`,
        $localize`1205 XL powinno być zamawiane w wielokrotności 3`
      );
    }
    // 1205 B po 4
    if (trailer1205SB.length % 4 !== 0) {
      return new Message(
        $localize`Zweryfikuj zamówienie`,
        $localize`1205 B powinno być zamawiane w wielokrotności 4`
      );
    }
    // pozostałe TILT z serii 1000 po 4 na palecie
    if (trailer1000TILT.length % 4 !== 0) {
      return new Message(
        $localize`Zweryfikuj zamówienie`,
        $localize`Seria 1000 z TILT powinna być zamawiana w wielokrotności 4`
      );
    }
    // pozostałe z serii 1000 po 3 pionowo
    if (series1000Trailers.length % 3 !== 0) {
      return new Message(
        $localize`Zweryfikuj zamówienie`,
        $localize`Seria 1000 bez TILT powinna być zamawiana w wielokrotności 3`
      );
    }
    return false;
  }

  /**
   * Do celów rozmieszczania wystarczy taka uproszczona wersja modeli.
   * Łatwiej to przechowywać w pamięci gdy będzie konieczność tworzenia alternatywnych rozmieszczeń - czyli 1 ładunek będzie musiał istnieć w kilku wersjach.
   * TODO
   * @param loads
   */
  private replaceModelsWithSimplifiedVersions(loads: Load[]) {
    loads.forEach((load) => {
      const g = new Group();
      load.mesh.obj.traverse((child) => {
        if (!child.userData.obb || !(child instanceof Mesh)) {
          return;
        }
        const mesh = new Mesh();
        mesh.name = 'comp-' + child.name;
        const obb = child.geometry.userData.obb.clone();

        const size = obb.getSize(new Vector3());
        mesh.geometry = new BoxGeometry(size.x, size.y, size.z);
        if (child.material instanceof Material) {
          mesh.material = child.material.clone();
          mesh.material.visible = true;
        } else if (Array.isArray(child.material)) {
          mesh.material = child.material.map((m) => m.clone());
        }
        mesh.userData.obb = obb;
        mesh.geometry.userData.obb = obb.clone();
        mesh.geometry.userData.originalOBB = obb.clone();
        mesh.position.copy(child.position);
        mesh.rotation.copy(child.rotation);
        g.add(mesh);
      });
      const mesh = new Mesh();
      mesh.name = 'load';
      mesh.add(g);
      mesh.userData.size = load.mesh.obj.userData.size.clone();
      mesh.userData.halfSize = load.mesh.obj.userData.halfSize.clone();
      load.mesh.obj = mesh;
    });
  }

  public findPlaceForLoads(
    loads: Load[],
    spaces: Space[],
    settings: Settings,
    reset = true
  ) {
    reset && this.reset();
    //this.replaceModelsWithSimplifiedVersions(loads);
    this.sceneSettings = settings;
    const series1000Names = ['1205S', '1150S', '1203S'];
    const trailersWithRampNames = ['MT', '6420', '6520'];
    const seriesCargoNames = ['CARGO'];
    const tiltNames = ['TILT'];
    const noWheelNames = ['2260S B', '2260A B', '2270S XL'];
    const noWheelsAndLightsNames = ['3023GT'];
    const verticalRotation = new Euler(0, Math.PI / 2, Math.PI / 2, 'XYZ');
    const horizontalRotation = new Euler(0, Math.PI / 2, 0, 'XYZ');
    const horizontalRotationUpsideDown = new Euler(
      0,
      Math.PI / 2,
      Math.PI,
      'XYZ'
    );
    const upsideDownRotation = new Euler(Math.PI, 0, 0, 'XYZ');

    const series1000Trailers = this.extractLoadsByCriterion(loads, (l) =>
      series1000Names.some((model) => l.name.includes(model))
    ).sort((a, b) => a.idx - b.idx);

    console.log('TRAILERS Serie 1000', series1000Trailers.length);

    const trailersWithRamp = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(loads, (l) =>
        trailersWithRampNames.some((model) => l.name.includes(model))
      )
        .sort((a, b) => a.idx - b.idx)
        .map((l) => {
          l.disassembleComponents = ['ramp'];
          l.removeDisassembledComponents();
          return l;
        }),
      false
    );

    console.log('TRAILERS Dismantle ramp', trailersWithRamp.length);

    const cargoTrailers = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(loads, (l) =>
        seriesCargoNames.some((model) => l.name.includes(model))
      ).sort((a, b) => a.idx - b.idx),
      false
    );

    console.log('TRAILERS CARGO', cargoTrailers.length);

    const otherTiltTrailers = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(loads, (l) =>
        tiltNames.some((model) => l.name.includes(model))
      )
        .sort((a, b) => a.idx - b.idx)
        .map((l) => {
          l.disassembleComponents = ['shaft', 'jockeywheel', 'wheels'];
          l.removeDisassembledComponents();
          return l;
        }),
      false
    );

    console.log('TRAILERS with TILT, no 1000', otherTiltTrailers);

    const noWheelTrailers = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(loads, (l) =>
        noWheelNames.some((model) => l.name.includes(model))
      )
        .sort((a, b) => a.idx - b.idx)
        .map((l) => {
          l.disassembleComponents = ['wheels', 'jockeywheel'];
          l.removeDisassembledComponents();
          return l;
        }),
      false
    );

    console.log('TRAILERS to dismantle wheels', noWheelTrailers.length);

    const noWheelsAndLightsTrailers = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(loads, (l) =>
        noWheelsAndLightsNames.some((model) => l.name.includes(model))
      )
        .sort((a, b) => a.idx - b.idx)
        .map((l) => {
          l.disassembleComponents = ['wheels', 'jockeywheel', 'lights'];
          l.removeDisassembledComponents();
          return l;
        }),
      false
    );

    console.log(
      'TRAILERS to dismantle wheels and lights',
      noWheelsAndLightsTrailers.length
    );

    const trailer1205SXL = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(series1000Trailers, (l) =>
        l.name.includes('1205S XL')
      ).map((l) => {
        l.disassembleComponents = ['shaft', 'jockeywheel', 'fenders'];
        l.removeDisassembledComponents();
        l.mesh?.applyEulerRotation(horizontalRotationUpsideDown);
        l.updateLoadRotationFromMesh();
        return l;
      }),
      false
    );
    trailer1205SXL.forEach((mesh) => {
      mesh.fixedRotation = horizontalRotationUpsideDown;
    });

    console.log('TRAILERS 1205S XL', trailer1205SXL.length);

    const trailer1205SB = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(series1000Trailers, (l) =>
        l.name.includes('1205S B')
      ).map((l, index) => {
        l.disassembleComponents = ['fenders'];
        l.removeDisassembledComponents();
        //if (index % 2 === 0) {
        console.log('rotate 1205S B', index);
        l.mesh?.applyEulerRotation(upsideDownRotation);
        l.updateLoadRotationFromMesh();
        //}
        return l;
      }),
      false
    );
    trailer1205SB.forEach((l, index) => {
      l.alternateYRotation = true;
      l.alternateXRotation = true;
      l.fixedRotation = upsideDownRotation;
    });
    console.log('TRAILERS 1205 B', trailer1205SB.length);

    const trailer1000TILT = this.prepareLoadedMeshes(
      this.extractLoadsByCriterion(series1000Trailers, (l) =>
        l.name.includes('TILT')
      ).map((l) => {
        l.disassembleComponents = ['shaft', 'jockeywheel', 'fenders'];
        l.removeDisassembledComponents();
        l.mesh?.applyEulerRotation(horizontalRotationUpsideDown);
        l.updateLoadRotationFromMesh();
        return l;
      }),
      false
    );
    trailer1000TILT.forEach((mesh) => {
      mesh.fixedRotation = horizontalRotationUpsideDown;
    });

    console.log('TRAILERS 1000 with TILT', trailer1000TILT.length);

    const remaining1000Loads = this.prepareLoadedMeshes(
      series1000Trailers.map((l) => {
        l.mesh?.applyEulerRotation(verticalRotation);
        l.updateLoadRotationFromMesh();
        return l;
      }),
      false
    );

    remaining1000Loads.forEach((meshInfo) => {
      meshInfo.fixedRotation = verticalRotation;
    });
    console.log('TRAILERS 1000 remaining', remaining1000Loads.length);

    this.placeVerticalTrailers(remaining1000Loads, spaces);

    [...trailer1205SB, ...trailer1205SXL, ...trailer1000TILT].forEach(
      (meshInfo) => {
        console.log(
          '[1000s] finding place for',
          this.lastIdx + 1,
          meshInfo.load.name
        );
        this.findPlaceForMeshInfo(meshInfo, spaces, settings);
      }
    );

    const remainingMeshInfos = this.prepareLoadedMeshes(loads, false);

    const sorted = [
      ...trailersWithRamp,
      ...remainingMeshInfos,
      ...otherTiltTrailers,
      ...noWheelTrailers,
      ...noWheelsAndLightsTrailers
    ].sort((a, b) => {
      const bX = b.mesh.userData.halfSize.x;
      const bZ = b.mesh.userData.halfSize.z;
      const aX = a.mesh.userData.halfSize.x;
      const aZ = a.mesh.userData.halfSize.z;
      const xRatio = bX / aX;
      const zRatio = bZ / aZ;
      const bArea = bX * bZ;
      const aArea = aX * aZ;
      const areaRatio = bArea / aArea;
      if (areaRatio >= 1.0 && areaRatio < 1.1) {
        if (xRatio >= 1.0 && xRatio < 1.1) {
          return bZ - aZ;
        } else if (xRatio > 1.0) {
          return 1;
        }
        return -1;
      } else if (areaRatio >= 1.0) {
        return 1;
      }
      return -1;
    });
    console.log(
      'sorted',
      sorted.map(
        (v) =>
          v.load.name +
          ': ' +
          v.mesh.userData.halfSize.x +
          ', ' +
          v.mesh.userData.halfSize.z
      )
    );

    // drutowanie - cargo zostawiamy jako ostatnie
    [...sorted, ...cargoTrailers].forEach((meshInfo) => {
      console.log('finding place for', this.lastIdx + 1, meshInfo.load.name);
      this.findPlaceForMeshInfo(meshInfo, spaces, settings);
    });

    return true;
  }

  /**
   * Stack pionowych przyczep - ta sama pozycja X i Y, zmiana w Z.
   * W założeniu są to takie same przyczepy, ale nie muszą być.
   * Nie jest tu sprawdzane zachowanie malejącego rozmiaru w osi Z - do przemyślenia czy powinno być.
   *
   * @param meshes MeshWithObbs[]
   * @param spaces Space[]
   */
  private placeVerticalTrailers(meshes: MeshWithObbs[], spaces: Space[]) {
    console.log('Try to make stacks of vertical trailers', meshes.length);
    let maxX = this.minXPositionToCheckForNewStack;
    for (const meshInfo of meshes) {
      let found = false;

      const loadingMethods: (() => boolean)[] = [];
      loadingMethods.push(
        () => {
          console.log('put next to existing');
          return this.tryToPutNextToAnyExistingLoad(meshInfo);
        },
        () => {
          console.log('init new stack for vertical trailers');
          return this.newStackForVertical(meshInfo);
        }
      );
      if (this.sceneSettings.fullLevels) {
        loadingMethods.reverse();
      }

      for (const space of spaces) {
        this.setSpace(space);
        this.loadedMeshes = this.prepareLoadedMeshes(this.space.loads);
        this.calculateMinFreeXPosition();
        for (const method of loadingMethods) {
          found = method();
          if (found) {
            break;
          }
        }
        if (found) {
          break;
        }
      }
      if (found) {
        meshInfo.load.loaded = true;
        meshInfo.load.spaceUuid = this.space.uuid;
        this.onLoadPlaced(meshInfo, this.space.loads);

        maxX = Math.max(
          maxX,
          meshInfo.center.x + meshInfo.mesh.userData.halfSize.x
        );
        this.drawDebugDot(new Vector3(maxX, 0, 0), 0xff0000, meshInfo.mesh);
      }
    }
    this.verticalTrailersXEnd = maxX;
  }

  private findPlaceForMeshInfo(
    meshInfo: MeshWithObbs,
    spaces: Space[],
    settings: Settings
  ) {
    let found = false;
    for (const space of spaces) {
      this.setSpace(space);
      this.loadedMeshes = this.prepareLoadedMeshes(space.loads);
      this.sceneSettings = settings;
      this.calculateMinFreeXPosition();

      found = this.findPlaceForLoadInner(meshInfo);
      if (found) {
        this.onLoadPlaced(meshInfo, this.space.loads);
        break;
      } else {
        //meshInfo.mesh.layers.enable(1);
        //this.onLoadPlaced(meshInfo, this.space.loads);
        //break;
      }
    }
    return found;
  }

  public findPlaceForLoad(load: Load, loaded: Load[], settings: Settings) {
    this.reset();
    const meshInfo = this.getObbInfoForLoad(load);
    this.loadedMeshes = this.prepareLoadedMeshes(loaded);
    this.sceneSettings = settings;
    this.calculateMinFreeXPosition();

    const found = this.findPlaceForLoadInner(meshInfo);
    if (found) {
      this.onLoadPlaced(meshInfo, loaded);
    }
    return found;
  }

  private findPlaceForLoadInner(meshInfo: MeshWithObbs) {
    let found = false;
    const loadingMethods: (() => boolean)[] = [];
    loadingMethods.push(
      () => {
        console.log('put on existing');
        return this.tryToPutOnAnyExistingLoad(meshInfo);
      },
      () => {
        console.log('init new stack');
        return this.initStack(meshInfo);
      }
    );
    if (this.sceneSettings.fullLevels) {
      loadingMethods.reverse();
    }
    for (const method of loadingMethods) {
      if (found) {
        break;
      }
      found = method();
    }
    return found;
  }

  public findPlaceRaycaster(
    load: Load,
    loaded: Array<Load>,
    scene: Scene
  ): boolean {
    console.log('findPlaceRaycaster');
    /*
    const horizontalRotation = new Euler(0, Math.PI / 2, 0, 'XYZ');
    const horizontalRotationUpsideDown = new Euler(
      0,
      Math.PI / 2,
      Math.PI,
      'XYZ'
    );

    load.mesh.applyEulerRotation(horizontalRotationUpsideDown);
    load.updateLoadRotationFromMesh();*/
    const meshInfo = this.getObbInfoForLoad(load);
    //meshInfo.fixedRotation = horizontalRotationUpsideDown;
    this.loadedMeshes = this.prepareLoadedMeshes(loaded);
    this.calculateMinFreeXPosition();
    let found = false;
    if (loaded.length === 0) {
      found = this.initStack(meshInfo);
      //if (found) {
      meshInfo.mesh.layers.enable(1);
      meshInfo.mesh.userData.idx = loaded.length + 1;
      this.drawMesh(load, loaded, scene);
      this.loadedMeshes.push(meshInfo);
      //}
      if (!found) {
        console.error(
          'fitting position not found, displaying on last checked position'
        );
      } else {
        meshInfo.mesh.traverse((child) => {
          if (!child.name.endsWith('-oriented-bounding-box-wireframe')) {
            return;
          }
          console.log('draw obb', child.name);
          //this.drawOBB(child.userData.obb);
        });
      }
      return found;
    }

    found = this.tryToPutOnAnyExistingLoad(meshInfo);

    //let steps = 100;

    if (!found) {
      console.log('init new stack');
      found = this.initStack(meshInfo);
    }

    //if (found) {
    meshInfo.mesh.layers.enable(1);
    meshInfo.mesh.userData.idx = loaded.length + 1;
    this.drawMesh(load, loaded, scene);
    this.loadedMeshes.push(meshInfo);
    //} else {
    if (!found) {
      console.error(
        'fitting position not found, displaying on last checked position'
      );
    } else {
      meshInfo.mesh.traverse((child) => {
        if (!child.name.endsWith('-oriented-bounding-box-wireframe')) {
          return;
        }
        //this.drawOBB(child.userData.obb);
      });
    }

    //}

    /*let position = this.randomPosition(mesh);

    this.setPosition(mesh, position);

    if (!this.collisionDetector.collided(mesh, loaded)) {
      found = true;
    }

    if (found) {
      this.moveToLowestPossibleDirection(mesh, loaded, this.axisY);
      this.moveToLowestPossibleDirection(mesh, loaded, this.axisX);
      this.moveToLowestPossibleDirection(mesh, loaded, this.axisZ);

      mesh.layers.enable(1);
      mesh.userData.idx = loaded.length + 1;
      this.drawMesh(load, mesh, loads, meshes, scene);
    }*/

    return found;
  }
}
