import {
  CustomSpaceType,
  SystemSpaceType
} from 'src/app/fleet-select/form/lib/space-type.enum';
import { LabelComponentModel } from '../../label/label.component.model';

export class Settings {
  lengthUnit? = 'cm';
  weightUnit? = 'kg';
  uiMode? = 'advanced';
  shareLoads = true;
  shareVehicles = true;
  labelConfig?: LabelComponentModel;
  summaryFloorUnit: 'LDM' | 'mm' | 'cm' | 'm' = 'LDM';
  summaryFloorPercentage = false;
  showCriteria = true;
  autoAddCustomLoads = false;
  autoReload = false;
  loadBordersIntensity: number;
  loadTransparency = 0.4;
  usedVehicles?: string[] = [];
  logo?: string;
  loadExtraSpacing: number = 0;
  constructor(obj?: any) {
    Object.assign(this, obj);
    this.labelConfig = new LabelComponentModel(this.labelConfig ?? {});
    if (!this.usedVehicles) {
      this.usedVehicles = [];
    }
  }

  useSystemSpaces(): boolean {
    return (
      this.useVehicles() ||
      this.useContainers() ||
      this.usePallets() ||
      this.useWarehouses()
    );
  }

  useAllSystemSpaces(): boolean {
    return (
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.bus]) &&
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.tandem]) &&
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.mega]) &&
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.solo]) &&
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.standard]) &&
      this.useContainers() &&
      this.usePallets()
    );
  }

  useVehicles(): boolean {
    return (
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.bus]) ||
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.tandem]) ||
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.mega]) ||
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.solo]) ||
      this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.standard])
    );
  }

  useContainers(): boolean {
    return this.usedVehicles.includes(
      SystemSpaceType[SystemSpaceType.container]
    );
  }

  usePallets(): boolean {
    return this.usedVehicles.includes(SystemSpaceType[SystemSpaceType.pallet]);
  }

  useWarehouses(): boolean {
    return this.usedVehicles.includes(
      SystemSpaceType[SystemSpaceType.warehouse]
    );
  }

  useCustomSpaces(): boolean {
    return this.usedVehicles.includes(CustomSpaceType[CustomSpaceType.custom]);
  }
}
