import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { LoadingsComponent } from './loadings/loadings.component';
import { SingleContextComponent } from './single-context/single-context.component';
import { RaycastingComponent } from './raycasting/raycasting.component';

const routes: Routes = [
  {
    path: 'kalkulacja/:id',
    component: SingleContextComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'raycaster',
    component: RaycastingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: LoadingsComponent,
    canActivate: [AuthGuard],
    children: []
  },

  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
