import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { LoadGroup } from '../../../lib/model/load-group/load-group';
import { UiService } from '../../../services/ui.service';
import { Subject, take, takeUntil } from 'rxjs';
import { PendingLoadsService } from '../../lib/pending-loads.service';
import { Color } from 'three';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { ProjectsService } from 'src/app/projects';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { EditComponent as EditLoadedGroupComponent } from '../../../load/form/edit/edit.component';

@Component({
  selector: 'app-pending-load',
  templateUrl: './pending-load.component.html',
  styleUrls: ['./pending-load.component.less']
})
export class PendingLoadComponent implements OnInit, OnDestroy {
  @Input() group: LoadGroup;

  @Input() cnt: number = 1;
  protected fillColor = 'rgb(0, 0, 255)';
  protected readonly maxCount = 3000;

  private unsubscribe$ = new Subject<void>();

  constructor(
    protected ui: UiService,
    private pendingLoadsService: PendingLoadsService,
    private loadFactory: LoadFactory,
    private projectsSerivce: ProjectsService,
    private componentLoader: LoadComponentService
  ) {}

  ngOnInit(): void {
    this.cnt = this.group.cnt;
    this.fillColor = new Color(this.group.load.color).getStyle();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected saveListToProject() {
    const project = this.projectsSerivce.currentProject;
    project.pendingLoads = this.pendingLoadsService.getLoadList();
    this.projectsSerivce
      .updateProject(project, false)
      .pipe(take(1))
      .subscribe((project) => {});
  }

  protected cntChanged(newCount: number) {
    const update = Math.max(0, Math.min(newCount, this.maxCount));
    let diff = update - this.cnt;
    this.cnt = update;
    if (diff < 0) {
      while (diff++ < 0) {
        this.decreaseCount();
      }
    } else {
      while (diff-- > 0) {
        this.increaseCount();
      }
    }
    this.saveListToProject();
  }

  protected increaseCount() {
    this.cnt += 1;
    this.cnt = Math.min(this.cnt, this.maxCount);
    const newLoad = this.loadFactory.recreateLoad(this.group.load);
    newLoad.generateUuid();
    this.group.addLoad(newLoad);
  }
  protected decreaseCount() {
    this.cnt -= 1;
    this.cnt = Math.max(0, Math.min(this.cnt, this.maxCount));
    const lastLoad = this.group.lastLoad;
    if (lastLoad) {
      this.group.removeSingleLoad(lastLoad);
    }
  }

  protected remove() {
    this.pendingLoadsService.remove(this.group.load);
    this.saveListToProject();
  }

  protected showMenu() {
    const component = this.componentLoader.add(EditLoadedGroupComponent);
    component.setInput('group', this.group);
  }
}
