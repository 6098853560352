import { LoadDimensionLabel } from './LoadDimensionLabel';

export class LoadHeightLabel extends LoadDimensionLabel {
  protected updateLabelPosition(): void {
    const position = this.load.mesh.position.clone();
    position.x -= this.load.cuboidHull.length / 2 - 20;
    position.z += this.load.cuboidHull.width / 2 - 20;
    this.setPosition(position);
  }
}
