import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { CarTrailerGroup } from 'src/app/lib/model/load-group/car-trailer-group';
import { LoadGroupList } from 'src/app/lib/model/load-group/load-group-list';
import { Load } from 'src/app/load/lib/load';
import { LoadEventObject } from 'src/app/load/lib/load-event-object';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Project, ProjectsService } from 'src/app/projects';

@Injectable({
  providedIn: 'root'
})
export class PendingLoadsService {
  private isActive = new BehaviorSubject(true);

  private loads = new BehaviorSubject<LoadGroupList>(
    new LoadGroupList(CarTrailerGroup)
  );

  constructor(
    private loadFactory: LoadFactory,
    private projectsService: ProjectsService
  ) {}

  public clear(): LoadGroupList {
    console.log('clear pending load list');
    this.loads.next(new LoadGroupList(CarTrailerGroup));
    return this.loads.value;
  }

  public addLoad(load: Load, cnt: number = 1) {
    console.log('add load to pending load list');
    if (!this.isActive.value) {
      return;
    }
    let nextList = this.loads.value;
    while (cnt-- > 0) {
      const newLoad = this.loadFactory.recreateLoad(load);
      newLoad.generateUuid();
      nextList.addLoad(newLoad);
    }
    this.loads.next(nextList);
    return this.loads$;
  }

  public addLoads(bundles: LoadEventObject[]) {
    console.log('add loads to pending load list');

    let nextList = this.loads.value;
    for (const bundle of bundles) {
      let load = bundle.load;
      let cnt = bundle.cnt;
      while (cnt-- > 0) {
        const newLoad = this.loadFactory.recreateLoad(load);
        newLoad.generateUuid();
        nextList.addLoad(newLoad);
      }
    }

    this.loads.next(nextList);
    return this.loads;
  }

  public replaceList(list: LoadGroupList): Observable<Project> {
    console.log('replace pending load list');
    this.loads.next(list);
    const project = this.projectsService.currentProject;
    project.pendingLoads = this.getLoadList();
    return this.projectsService.updateProject(project, false);
  }

  public newListFromLoads(loads: Load[], newUuid = true): LoadGroupList {
    console.log('new pending load list');
    const newList = new LoadGroupList(CarTrailerGroup);
    loads.forEach((load) => {
      const newLoad = this.loadFactory.recreateLoad(load);
      if (newUuid) {
        newLoad.generateUuid();
      }
      newList.addLoad(newLoad);
    });
    return newList;
  }

  public setNewListFromLoads(
    loads: Load[],
    withNewUuids = true
  ): LoadGroupList {
    const list = this.newListFromLoads(loads, withNewUuids);
    this.loads.next(list);
    return list;
  }

  public remove(load: Load) {
    console.log('remove load from pending');
    this.loads.next(this.loads.value.removeLoad(load));
  }

  public loads$(): Observable<LoadGroupList> {
    return this.loads.asObservable();
  }

  public getCurrentLoads(): LoadGroupList {
    return this.loads.value;
  }

  public getLoadList(): Load[] {
    return this.getCurrentLoads().loads.flatMap((g) => g.list);
  }

  public setIsActive(val: boolean) {
    this.isActive.next(val);
  }

  public isActive$() {
    return this.isActive.asObservable();
  }

  public getIsActive() {
    return this.isActive.value;
  }

  public showList$() {
    return combineLatest([this.isActive$(), this.loads$()]).pipe(
      map(([isActive, list]) => isActive && !list.isEmpty())
    );
  }

  public areAllLoadsFromPending(loads: Load[]) {
    let inPending = 0;
    loads.forEach((l) => {
      if (this.loads.value.hasLoadExact(l)) {
        inPending++;
      }
    });
    return inPending === loads.length;
  }

  public refresh() {
    const loads = this.getLoadList();
    this.setNewListFromLoads(loads, false);
    const project = this.projectsService.currentProject;
    project.pendingLoads = this.getLoadList();
    return this.projectsService.updateProject(project, false);
  }
}
