<div class="projects-container">
  <div class="projects-header">
    <div class="tabs">
      <h2
        i18n
        *ngIf="ui.userSeesProjects(profile$ | async)"
        [class.active]="(activeTab$ | async) == ProjectListMode.PROJECTS"
        (click)="showProjects()"
      >
        Projekty załadunku
      </h2>
      <ng-container *ngIf="ui.userSeesOrders(profile$ | async)">
        <h2
          [class.active]="
            (activeTab$ | async) == ProjectListMode.ORDERS ||
            (activeTab$ | async) == ProjectListMode.OFFICIAL_ORDERS
          "
          [matMenuTriggerFor]="menu"
        >
          <span i18n>Zamówienia</span>
        </h2>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            i18n
            (click)="showOrders()"
            [class.active]="(activeTab$ | async) == ProjectListMode.ORDERS"
          >
            Robocze
          </button>
          <button
            mat-menu-item
            i18n
            (click)="showOfficialOrders()"
            [class.active]="
              (activeTab$ | async) == ProjectListMode.OFFICIAL_ORDERS
            "
          >
            Oficjalne
          </button>
        </mat-menu>
      </ng-container>
      <h2
        i18n
        *ngIf="ui.userSeesReports(profile$ | async)"
        [class.active]="(activeTab$ | async) == ProjectListMode.REPORTS"
        (click)="showReports()"
      >
        Raporty
      </h2>
      <h2 i18n *ngIf="ui.userSeesDealersPanel(profile$ | async)" class="active">
        Panel dealera
      </h2>
    </div>

    <div class="help" *ngIf="showHelp && ui.userSeesProjects(profile$ | async)">
      <ng-container i18n
        >Dodaj nowy projekt lub aby kontynuować pracę, wejdź w szczegóły jednego
        z istniejących</ng-container
      >
      <button
        mat-flat-button
        color="accent"
        class="btn-ok"
        (click)="hideHelp()"
      >
        OK
      </button>
    </div>
  </div>
</div>
<div
  class="projects-container"
  *ngIf="
    ui.userSeesProjects(profile$ | async) &&
    (activeTab$ | async) == ProjectListMode.PROJECTS
  "
>
  <app-project-list></app-project-list>
</div>
<div
  class="projects-container"
  *ngIf="
    ui.userSeesOrders(profile$ | async) &&
    (activeTab$ | async) == ProjectListMode.ORDERS
  "
>
  <app-order-list></app-order-list>
</div>

<div
  class="projects-container"
  *ngIf="(activeTab$ | async) == ProjectListMode.OFFICIAL_ORDERS"
>
  <app-official-order-list></app-official-order-list>
</div>

<app-order-reports
  *ngIf="
    ui.userSeesReports(profile$ | async) &&
    (activeTab$ | async) == ProjectListMode.REPORTS
  "
></app-order-reports>

<div
  class="projects-container"
  *ngIf="ui.userSeesDealersPanel(profile$ | async)"
>
  <app-dealers-panel></app-dealers-panel>
</div>
