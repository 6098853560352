import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Vector } from '../lib/communication/vector';
import { VehicleRequest } from '../lib/communication/vehicle.request';
import { VehicleContext } from '../lib/model/vehicle-context';

import { LoadComponentService } from '../services/load-component.service';
import { environment } from '../../environments/environment';
import { VehicleResponse } from '../lib/communication/vehicle.response';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Load } from '../load/lib/load';
import { HttpService } from '../services/http.service';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { LoadFactory } from '../load/lib/load-factory';
import { VehicleSuggestionType } from './lib/suggestion-type';

@Injectable({
  providedIn: 'root'
})
export class SuggestVehicleService extends HttpService {
  constructor(
    protected http: HttpClient,
    private componentLoader: LoadComponentService,
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory
  ) {
    super(http);
  }

  close() {
    this.componentLoader.clear('suggest-vehicle.service.ts');
  }

  public getVehicles(
    context: VehicleContext,
    matrix: Vector[],
    loads: Load[],
    suggestionType: VehicleSuggestionType
  ): Observable<VehicleResponse> {
    const bbs = context
      .getVehicle()
      .enabledSpaces.filter((s) => s.loads.length > 0)
      .map((s) => {
        const { x, y, z } = s.getLoadsBoundingBoxSize();
        return { x, y, z };
      });
    const request = new VehicleRequest({
      uuid: context.getUuid(),
      loads,
      matrix,
      currentVehicle: context.getVehicle(),
      type: suggestionType,
      loadBoundingBoxes: bbs
    });

    return this.http
      .post<any>(environment.apiUrl + '/database/vehicle/suggest', request)
      .pipe(
        catchError(this.handleError('getVehicles', [])),
        map(
          (response) =>
            new VehicleResponse(response, this.vehicleFactory, this.loadFactory)
        ),
        shareReplay(1)
      );
  }
}
