import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Workbook, Fill } from 'exceljs';
import { LoadGroupList } from '../lib/model/load-group/load-group-list';
import { Settings } from '../lib/model/settings';
import { User } from '../lib/model/user';
import { VehicleContext } from '../lib/model/vehicle-context';
import { Project } from '../projects/lib/project';
import { UiService } from '../services/ui.service';
import { logoBase64 } from './logo';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {
  constructor(private ui: UiService, private numberPipe: DecimalPipe) {}

  public createNewDocument(): Workbook {
    return new Workbook();
  }

  public addSheet(
    workbook: Workbook,
    sheetName: string,
    title: string,
    project: Project,
    context: VehicleContext,
    loads: LoadGroupList,
    images: string[],
    user: User,
    settings: Settings,
    date: string
  ) {
    const sheet = workbook.addWorksheet(sheetName);

    //global
    const accentFill: Fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'CCDCDCDC' }
    };

    // logo
    const logo = workbook.addImage({
      base64: settings?.logo || logoBase64,
      extension: 'png'
    });
    const memoryImg = document.createElement('img');
    memoryImg.src = settings?.logo || logoBase64;
    const height = memoryImg.height | 59;
    const width = memoryImg.width || 239;
    const targetHeight = Math.min(height, 90);
    const scaleFactor = targetHeight / (height || targetHeight);
    const targetWidth = width * scaleFactor;
    let rowIdx = 1;
    let row = sheet.getRow(rowIdx);
    row.height = 100;
    sheet.addImage(logo, {
      tl: { col: 2, row: 0 },
      ext: { width: targetWidth, height: targetHeight }
    });
    sheet.mergeCells(`C${rowIdx}:F${rowIdx}`);
    //sheet.mergeCells('C1:F1');
    // header
    rowIdx++;
    row = sheet.getRow(rowIdx);
    row.values = [title];
    row.font = {
      size: 16,
      bold: true
    };
    row.height = 50;
    row.alignment = { horizontal: 'center' };
    sheet.getCell('A2').alignment = { wrapText: true };
    //    sheet.mergeCells('A2:H2');
    sheet.mergeCells(`A${rowIdx}:H${rowIdx}`);

    if (project?.comment) {
      // 3 pojazd
      row = sheet.addRow([$localize`Uwagi`, project?.comment]);
      rowIdx++;
      sheet.mergeCells(`B${rowIdx}:H${rowIdx}`);
      row.getCell('A').fill = accentFill;
      row.getCell('B').fill = accentFill;
    }

    // 3 pojazd
    row = sheet.addRow([$localize`Pojazd`, context?.getVehicle().fullName]);
    rowIdx++;
    sheet.mergeCells(`B${rowIdx}:H${rowIdx}`);
    row.getCell('A').fill = accentFill;
    row.getCell('B').fill = accentFill;

    // 4 wymiary
    // console.log('TODO 4 wymiary');
    const lengthUnit = this.ui.getCurrentLengthUnit();
    const converter = this.ui.getOutputLengthConverter(lengthUnit);
    let output = '';
    for (const d of context.getVehicle().dimensions) {
      const formattedDimension = this.numberPipe.transform(
        converter * d,
        '0.0-2'
      );
      output += formattedDimension + 'x';
    }
    output = output.substring(0, output.length - 1);
    output += ' ' + lengthUnit;
    output += ' | ';
    const weightUnit = this.ui.getCurrentWeightUnit();
    output += this.ui.getWeightInCurrentUnit(
      context.getVehicle().maxLoadingWeight
    );
    output += ' ' + weightUnit;

    const dimensions =
      output + $localize`[ długość x szerokość x wysokość | ładowność ]`;

    row = sheet.addRow([$localize`Wymiary`, dimensions]);
    rowIdx++;
    sheet.mergeCells(`B${rowIdx}:H${rowIdx}`);
    row.getCell('A').fill = accentFill;
    row.getCell('B').fill = accentFill;

    sheet.addRow([]);
    rowIdx++;

    // 6 ładunki
    let loadsStr = '';
    loads.loads.forEach((group) => {
      let str = group.cnt + ' x ' + group.load.fullName + ' ';
      for (const d of group.load.descriptiveDimensions) {
        str += this.ui.getLengthInCurrentUnit(d) + 'x';
      }
      str = str.substring(0, str.length - 1);
      str += ' ' + lengthUnit;
      if (group.load.weight > 0) {
        str +=
          ' (' +
          group.cnt +
          ' * ' +
          this.numberPipe.transform(
            this.ui.getWeightInCurrentUnit(group.load.weight),
            '0.0-2'
          ) +
          this.ui.getCurrentWeightUnit() +
          ')';
      }

      if (group.isFloorableBoth()) {
        str += ', ' + $localize`piętrowalne`;
      }
      if (group.isFloorableOnlyBottom()) {
        str += ', ' + $localize`piętrowalne aktywnie`;
      }
      if (group.isFloorableOnlyTop()) {
        str += ', ' + $localize`piętrowalne pasywnie`;
      }
      if (group.isNonFloorable()) {
        str += ', ' + $localize`niepiętrowalne`;
      }
      loadsStr += str + '\n';
    });
    row = sheet.addRow([$localize`Ładunki`, loadsStr]);
    rowIdx++;
    row.height = 15 * (loads.loads.length || 1);
    row.alignment = { vertical: 'top' };
    sheet.mergeCells(`B${rowIdx}:H${rowIdx}`);
    row.getCell('A').fill = accentFill;
    row.getCell('B').fill = accentFill;

    //7 scene
    for (let index = 0; index < images.length; index++) {
      const imageData = images[index];
      const scene = workbook.addImage({
        base64: imageData,
        extension: 'jpeg'
      });
      row = sheet.addRow([]);
      rowIdx++;
      row.height = 600;
      sheet.addImage(scene, {
        tl: { col: 0, row: row.number - 1 },
        ext: { width: 1500, height: 750 },
        editAs: undefined
      });
      sheet.mergeCells(`A${rowIdx}:H${rowIdx}`);
    }

    // 8 summary
    row = sheet.addRow([]);
    rowIdx++;
    row.getCell('A').value = context?.getVehicle().fullName;

    row.height = 40;
    row.alignment = { vertical: 'middle', wrapText: true };

    const [mainStats, trailerStats] = context.getEnabledSpacesStatistics(); // FIXME

    const spaceUnit = settings.summaryFloorUnit;
    // summary progress 1
    const usedSpace = mainStats?.getUsedSpace(spaceUnit);
    const totalSpace = mainStats?.getTotalSpace(spaceUnit);
    const usedPercentage = mainStats?.getUsedSpacePercentage(spaceUnit);
    const currentEP = (mainStats?.cnt || 0) + (trailerStats?.cnt || 0);
    row.getCell(
      'C'
    ).value = `${usedPercentage}% (${usedSpace} ${spaceUnit} z ${totalSpace} ${spaceUnit})`;
    row.getCell('C').alignment = { vertical: 'middle' };
    sheet.mergeCells(`C${rowIdx}:E${rowIdx}`);
    row.getCell('F').value = totalSpace + ' ' + spaceUnit;
    row.getCell('F').alignment = { vertical: 'middle' };
    row.getCell('G').value = $localize`Liczba ładunków: ` + currentEP;
    row.getCell('G').alignment = { vertical: 'middle' };
    if (context?.getVehicle()?.enabledSpaces.length > 1) {
      // 9 trailer
      row = sheet.addRow([]);
      rowIdx++;
      const usedTrailerSpace = trailerStats?.getUsedSpace(spaceUnit);
      const totalTrailerSpace = trailerStats?.getTotalSpace(spaceUnit);
      const usedTrailerSpaceProcentage =
        trailerStats?.getUsedSpacePercentage(spaceUnit);

      row.getCell(
        'C'
      ).value = `${usedTrailerSpaceProcentage}% (${usedTrailerSpace} ${spaceUnit} z ${totalTrailerSpace} ${spaceUnit})`;

      row.getCell('F').value = totalTrailerSpace + ' ' + spaceUnit;
      sheet.mergeCells(`A${rowIdx}:B${rowIdx + 1}`);
      sheet.mergeCells(`C${rowIdx}:E${rowIdx + 1}`);
      sheet.mergeCells(`G${rowIdx}:H${rowIdx + 1}`);
      // sheet.mergeCells('A8:B9');
      //sheet.mergeCells('C9:E9');
      //sheet.mergeCells('G8:H9');
    } else {
      sheet.mergeCells(`A${rowIdx}:B${rowIdx}`);
      sheet.mergeCells(`G${rowIdx}:H${rowIdx}`);
      //sheet.mergeCells('A8:B8');
      //sheet.mergeCells('G8:H8');

      sheet.addRow([]);
      rowIdx++;
    }

    // 10 opracował
    row = sheet.addRow([$localize`Opracował/a ` + user?.fullName()]);
    rowIdx++;
    sheet.mergeCells(`A${rowIdx}:H${rowIdx}`);
    //sheet.mergeCells('A10:H10');

    // 11 wygenerowano
    row = sheet.addRow([
      $localize`Wygenerowano dnia ${date} na podstawie realnych danych ładunku i pojazdu`
    ]);
    rowIdx++;
    sheet.mergeCells(`A${rowIdx}:H${rowIdx}`);
    //sheet.mergeCells('A11:H11');

    // 12 Smartload
    row = sheet.addRow(['Smartload `' + new Date().getFullYear()]);
    rowIdx++;
    sheet.mergeCells(`A${rowIdx}:H${rowIdx}`);
    //sheet.mergeCells('A12:H12');
  }

  public async getDocumentData(workbook: Workbook): Promise<Blob> {
    const result = new Promise<Blob>((resolve, reject) => {
      workbook.xlsx
        .writeBuffer()
        .then((data) => {
          const blob = new Blob([data], {
            type: EXCEL_TYPE
          });
          // console.log('xls blob generated');
          resolve(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  }
}
