<ng-container [ngSwitch]="mode">
  <mat-expansion-panel
    *ngSwitchCase="'mat-expansion-panel'"
    #dataPanel
    (opened)="dataPanelOpenState = true"
    (closed)="dataPanelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong i18n>Dostosuj wymiary</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container
      *ngTemplateOutlet="formTemplate; context: { $implicit: form }"
    ></ng-container>
  </mat-expansion-panel>
  <ng-container *ngSwitchCase="'plain-form'">
    <ng-container
      *ngTemplateOutlet="formTemplate; context: { $implicit: form }"
    ></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'inline'">
    <ng-container
      *ngTemplateOutlet="inline; context: { $implicit: form }"
    ></ng-container>
  </ng-container>
</ng-container>

<ng-template #length let-form>
  <mat-form-field [formGroup]="form">
    <input
      matInput
      appOnlyNumber
      required
      i18n-placeholder
      placeholder="Długość ({{ ui.getLengthUnit() | async }})"
      formControlName="length"
      autocomplete="off"
    />
    <mat-error *ngIf="form.get('length').hasError('required')">
      <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
      <strong> <ng-container i18n>wymagane</ng-container></strong>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #width let-form
  ><mat-form-field [formGroup]="form">
    <input
      matInput
      appOnlyNumber
      required
      i18n-placeholder
      placeholder="Szerokość ({{ ui.getLengthUnit() | async }})"
      formControlName="width"
      autocomplete="off"
    />
    <mat-error *ngIf="form.get('width').hasError('required')">
      <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
      <strong> <ng-container i18n>wymagane</ng-container></strong>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #height>
  <mat-form-field [formGroup]="form">
    <input
      matInput
      appOnlyNumber
      required
      i18n-placeholder
      placeholder="Wysokość ({{ ui.getLengthUnit() | async }})"
      formControlName="height"
      autocomplete="off"
    />
    <mat-error *ngIf="form.get('height').hasError('required')">
      <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
      <strong> <ng-container i18n>wymagane</ng-container></strong>
    </mat-error>
  </mat-form-field></ng-template
>

<ng-template #formTemplate let-form>
  <div
    [formGroup]="form"
    novalidate
    (ngSubmit)="onSubmit()"
    class="load-form full-width"
  >
    <div class="row">
      <ng-container
        *ngTemplateOutlet="length; context: { $implicit: form }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="width; context: { $implicit: form }"
      ></ng-container>
    </div>
    <div class="row">
      <ng-container
        *ngTemplateOutlet="height; context: { $implicit: form }"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inline let-form>
  <div
    [formGroup]="form"
    novalidate
    (ngSubmit)="onSubmit()"
    class="load-form full-width"
  >
    <div class="row">
      <ng-container
        *ngTemplateOutlet="length; context: { $implicit: form }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="width; context: { $implicit: form }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="height; context: { $implicit: form }"
      ></ng-container>
    </div>
  </div>
</ng-template>
