import { Vector3 } from 'three';
import { UiService } from '../../services/ui.service';
import { Axle } from '../../vehicle/axles/lib/axle';
import { TextLabel } from './TextLabel';

export abstract class AxleLabel extends TextLabel {
  public constructor(
    axle: Axle,
    xSpaceOffset: number,
    protected uiService: UiService,
    scale = 1.0
  ) {
    super('', new Vector3(), 0, 'axle-box-content', scale);
    this.updateLabelPosition(axle, xSpaceOffset);
    this.updateText(axle);
  }

  protected abstract get axleType(): string;

  protected updateLabelPosition(axle: Axle, xSpaceOffset: number): void {
    const labelPosition = new Vector3(
      xSpaceOffset + axle.mesh.position.x,
      -400,
      axle.mesh.position.z
    );
    this.setPosition(labelPosition);
  }

  protected updateText(axle: Axle) {
    const text =
      this.axleType +
      '\n' +
      'offset: ' +
      this.uiService.getLengthInCurrentUnit(axle.offset) +
      this.uiService.getCurrentLengthUnit() +
      '\n' +
      this.uiService.getWeightInCurrentUnit(axle.pressure) +
      this.uiService.getCurrentWeightUnit();
    this.setText(text);
  }
}
