import { Euler, Vector3 } from 'three';
import { ModelDetails } from './model-details.interface';

export class ModelDetails2300STB implements ModelDetails {
  getPartDimensions(name: string): Vector3 {
    switch (name) {
      case 'wheel-hub-front-right-obb':
      case 'wheel-hub-front-left-obb':
      case 'wheel-hub-rear-left-obb':
      case 'wheel-hub-rear-right-obb':
        return new Vector3(undefined, 60, undefined);
      case 'shaft-brake-handle-obb':
        return new Vector3(undefined, 80, undefined);
      default:
        return new Vector3(undefined, undefined, 30);
    }
  }
  getPartRotation(name: string): Euler {
    switch (name) {
      case 'shaft-left-obb':
        return new Euler(0, -Math.PI / 13, 0);
      case 'shaft-right-obb':
        return new Euler(0, Math.PI / 13, 0);
      case 'wheel-hub-front-right-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-front-left-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rear-right-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rear-left-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'shaft-brake-handle-obb':
        return new Euler(0, 0, Math.PI / 9);
    }
    throw new Error(`2300STB OBB layer for ${name} not supported`);
  }
}
