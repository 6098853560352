import { LoadDisplaySettings } from '../../../lib/load-display-settings';
import { LoadMesh } from '../../../lib/load-mesh';
import {
  Box3,
  BoxGeometry,
  FrontSide,
  Mesh,
  MeshPhongMaterial,
  Vector3
} from 'three';
import { PalletLoad } from './pallet-load';
import { Type } from '@angular/core';
import { VehicleMesh } from '../../../../vehicle/lib/vehicle-mesh';
import {
  EPAL1Mesh,
  EPAL2Mesh,
  EPAL3Mesh,
  EPAL6Mesh,
  EPAL7Mesh,
  EPALCP1Mesh,
  EPALCP2Mesh,
  EPALCP3Mesh,
  EPALCP4Mesh,
  EPALCP5Mesh,
  EPALCP6Mesh,
  EPALCP7Mesh,
  EPALCP8Mesh,
  EPALCP9Mesh
} from '../../../../vehicle/type/other';

export class PalletLoadMesh extends LoadMesh {
  private length: number;
  private height: number;
  private width: number;
  private meshType: string;
  private contentMesh: Mesh;

  private meshTypeMap: { [key: string]: Type<VehicleMesh> } = {
    EPAL1: EPAL1Mesh,
    EPAL2: EPAL2Mesh,
    EPAL3: EPAL3Mesh,
    EPAL6: EPAL6Mesh,
    EPAL7: EPAL7Mesh,
    'EPAL-CP1': EPALCP1Mesh,
    'EPAL-CP2': EPALCP2Mesh,
    'EPAL-CP3': EPALCP3Mesh,
    'EPAL-CP4': EPALCP4Mesh,
    'EPAL-CP5': EPALCP5Mesh,
    'EPAL-CP6': EPALCP6Mesh,
    'EPAL-CP7': EPALCP7Mesh,
    'EPAL-CP8': EPALCP8Mesh,
    'EPAL-CP9': EPALCP9Mesh
  };

  public constructor(pallet: PalletLoad, settings: LoadDisplaySettings) {
    super('rectangular', pallet.color, settings, pallet.uuid);
    this.length = pallet.length;
    this.width = pallet.width;
    this.height = pallet.height;
    this.meshType = pallet.shape;

    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return '(' + this.length + 'x' + this.width + 'x' + this.height + ')';
  }

  public getCapacity(): number {
    return this.width * this.length;
  }

  private init() {
    // const rotation = this.getRotationAngle() / Math.PI / 2;
    // // console.log('rotation', rotation);
    // if (rotation === 0 || rotation === 0.5) {
    const dimensions = {
      length: this.length,
      height: this.height,
      width: this.width
    };
    console.debug('pallet mesh inner dimensions', dimensions);
    const vehicleMesh = new this.meshTypeMap[this.meshType]({
      length: Math.max(this.length, this.width),
      width: Math.min(this.length, this.width),
      height: this.height,
      spaces: []
    });
    const innerBox = new Box3().setFromObject(vehicleMesh.mesh);
    const innerBoxSize = innerBox.getSize(new Vector3());
    //vehicleMesh.mesh.translateX(-this.length / 2);
    vehicleMesh.mesh.translateY(-this.height / 2);
    //vehicleMesh.mesh.translateZ(this.width / 2);
    if (Math.round(this.length - innerBoxSize.x) !== 0) {
      vehicleMesh.mesh.rotation.set(0, Math.PI / 2, 0);
      vehicleMesh.mesh.updateMatrix();
      vehicleMesh.mesh.position.x = -this.length / 2;
      vehicleMesh.mesh.position.z = this.width / 2;
    } else {
      vehicleMesh.mesh.position.x = -this.length / 2;
      vehicleMesh.mesh.position.z = -this.width / 2;
    }

    console.debug('pallet mesh inner dimensions 2:', innerBoxSize);

    this.obj.geometry = new BoxGeometry(
      dimensions.length,
      dimensions.height,
      dimensions.width
    );

    const contentBox = new BoxGeometry(
      dimensions.length,
      dimensions.height - innerBoxSize.y,
      dimensions.width
    );
    this.contentMesh = new Mesh(contentBox, this.obj.material);
    this.contentMesh.translateY(innerBoxSize.y / 2);
    this.obj.add(this.contentMesh);

    this.prepareMesh();

    this.obj.add(vehicleMesh.mesh);
  }

  protected prepareMesh(): void {
    super.prepareMesh();
    this.obj.material = new MeshPhongMaterial({
      color: 0xffffff,
      side: FrontSide,
      transparent: true,
      opacity: 0,
      depthWrite: false
    });
  }

  public select(value = true) {
    this.selected = value;
    const border = this.getBorders();
    border.material = this.createBorderMaterial();
    if (value && !this.obj.userData['selected']) {
      this.obj.userData['originalColor'] = (
        this.contentMesh.material as any
      ).color.getHex();
    }
    this.obj.userData['selected'] = value;
    (this.contentMesh.material as any).color.set(
      value ? 0xff0000 : this.obj.userData['originalColor']
    );
    (this.contentMesh.material as any).emissive.set(value ? 0x111111 : 0x0);
  }

  public hover() {
    (this.contentMesh.material as any).emissive.set(0x333333);
  }

  public unhover() {
    (this.contentMesh.material as any).emissive.set(
      this.obj.userData['selected'] ? 0x111111 : 0x0
    );
  }
}
