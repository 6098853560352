import { Injectable } from '@angular/core';
import { LoadComponentService } from '../services/load-component.service';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(private loadComponentService: LoadComponentService) {}

  public showSettings() {
    this.loadComponentService.clear('header.service.ts showSettings');
    this.loadComponentService.add(
      SettingsModalComponent
      // '#scene-container'
    );
  }
}
