import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';

export class EPAL7Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = 100;
    const plankW2 = 80;
    const plankH = 20;
    const plankL = this.width;

    let currentY = 0;
    const r = 10;

    const allRounded = [r, r, r, r] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    //bottom planks
    const bottomPlanks = [
      new Vector3(x0, currentY, z0),
      new Vector3(xc - plankW / 2, currentY, z0),
      new Vector3(x1 - plankW, currentY, z0)
    ];
    bottomPlanks.forEach((p) => {
      this.plank(p, plankW, plankL, plankH, 1, ...allRounded);
    });

    //boxes
    currentY += plankH;

    const cylinderRadius = 31;
    const cylinderHeight = 100;
    const bracketHeight = 100;
    const bracketWidth = 70;
    const bracketLength = 45;
    const bracketSize = [bracketLength, bracketWidth, bracketHeight] as const;
    const backBrackets = [
      new Vector3(x0 + bracketWidth / 2 + 14, currentY + bracketHeight / 2, z0),
      new Vector3(
        xc - plankW / 2 + bracketWidth / 2 + 14,
        currentY + bracketHeight / 2,
        z0
      ),
      new Vector3(
        x1 - plankW + bracketWidth / 2 + 14,
        currentY + bracketHeight / 2,
        z0
      )
    ];
    backBrackets.forEach((p) => {
      this.angleBracket(p, ...bracketSize, 6, Math.PI / 2);
    });
    const cylinders = [
      new Vector3(x0 + cylinderRadius, currentY + cylinderHeight / 2, zc),
      new Vector3(xc, currentY + cylinderHeight / 2, zc),
      new Vector3(x1 - cylinderRadius, currentY + cylinderHeight / 2, zc)
    ];
    cylinders.forEach((p) => {
      this.cylinder(p, cylinderRadius, cylinderRadius, cylinderHeight, 5);
    });

    const frontBrackets = [
      new Vector3(x0 + bracketWidth / 2 + 14, currentY + bracketHeight / 2, z1),
      new Vector3(
        xc - plankW / 2 + bracketWidth / 2 + 14,
        currentY + bracketHeight / 2,
        z1
      ),
      new Vector3(
        x1 - plankW + bracketWidth / 2 + 14,
        currentY + bracketHeight / 2,
        z1
      )
    ];
    frontBrackets.forEach((p) => {
      this.angleBracket(p, ...bracketSize, 6, -Math.PI / 2);
    });

    //before top planks
    currentY += cylinderHeight;
    this.plank(
      new Vector3(x0, currentY, z0),
      this.length,
      plankW2,
      plankH,
      1,
      ...backExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW2 / 2),
      this.length,
      plankW2,
      plankH,
      1
    );
    this.plank(
      new Vector3(x0, currentY, z1 - plankW2),
      this.length,
      plankW2,
      plankH,
      1,
      ...frontExternalRadiuses
    );

    //top planks
    const plankSize = [plankW2, plankL, plankH] as const;
    currentY += plankH;
    this.plank(
      new Vector3(x0, currentY, z0),
      ...plankSize,
      2,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(x1 - plankW2, currentY, z0),
      ...plankSize,
      2,
      ...rightExternalRadiuses
    );
    const centerTopPlanks = [
      new Vector3(x0 + plankW2 + 40, currentY, z0),
      new Vector3(x0 + plankW2 * 2 + 40 * 2, currentY, z0),
      new Vector3(xc - plankW2 / 2, currentY, z0),
      new Vector3(x1 - plankW2 * 3 - 40 * 2, currentY, z0),
      new Vector3(x1 - plankW2 * 2 - 40, currentY, z0)
    ];
    centerTopPlanks.forEach((p) => {
      this.plank(p, ...plankSize, 2);
    });

    currentY += plankH;
    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    //  scene.add(mesh);

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(0, currentY);
  }
}
