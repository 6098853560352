import { CuboidHull } from 'src/app/load/lib/cuboid-hull';
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { Load } from '../../../lib/load';
import { TShapeMesh } from './t-shape-mesh';

export class TShape extends Load {
  shape = 't-shape';
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  height: number;

  get cuboidHull(): CuboidHull {
    return new CuboidHull({
      width: this.a,
      length: this.b + this.d,
      height: this.height
    });
  }

  get volume(): number {
    return this.area * this.height;
  }
  get area(): number {
    return this.e * this.d + this.b * this.a;
  }

  get fullName(): string {
    return `T ${this.name}`;
  }
  get fullDescription(): string {
    return `T ${this.a}x${this.b}x${this.c}x${this.d}x${this.e}`;
  }

  get descriptiveDimensions(): number[] {
    return [this.a, this.b, this.c, this.d, this.e];
  }

  async createMesh(settings: LoadDisplaySettings): Promise<LoadMesh> {
    this.mesh = new TShapeMesh(this, settings);
    return this.mesh;
  }
}
