import { CuboidHull } from 'src/app/load/lib/cuboid-hull';
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { Load } from '../../../lib/load';
import { HShapeMesh } from './h-shape-mesh';

export class HShape extends Load {
  shape = 'h-shape';
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;
  height: number;

  get cuboidHull(): CuboidHull {
    return new CuboidHull({
      width: this.a + this.b + this.c,
      length: this.d + this.e + this.f,
      height: this.height
    });
  }
  get volume(): number {
    return this.area * this.height;
  }
  get area(): number {
    return (
      this.a * (this.d + this.e + this.f) +
      this.c * (this.d + this.e + this.f) +
      this.b * this.e
    );
  }

  get fullName(): string {
    return `T ${this.name}`;
  }
  get fullDescription(): string {
    return `T ${this.a}x${this.b}x${this.c}x${this.d}`;
  }

  get descriptiveDimensions(): number[] {
    return [this.a, this.b, this.c, this.d];
  }
  async createMesh(settings: LoadDisplaySettings): Promise<LoadMesh> {
    this.mesh = new HShapeMesh(this, settings);
    return this.mesh;
  }
}
