<mat-card appearance="outlined">
  <mat-card-title>
    <ng-container i18n>Lista przyczep</ng-container><span>&nbsp;</span>
  </mat-card-title>
  <ng-container *ngTemplateOutlet="expertForm"></ng-container>
</mat-card>

<ng-template #expertForm>
  <app-cuboid-select-list
    i18n-matTooltip
    matTooltip="Wybierz przyczepę z listy"
    [matTooltipPosition]="'after'"
    [matTooltipShowDelay]="5000"
    #selectLoadTooltip="matTooltip"
  ></app-cuboid-select-list>

  <div [@flashBackground]="showForm === true ? 'show' : ''">
    <div [class.hidden]="showForm === false">
      <div class="amount-row">
        <app-amount #amount [class.hidden]="multiLoadForm"></app-amount>
        <div>
          <app-weight #weight [class.hidden]="multiLoadForm"></app-weight>
        </div>
      </div>

      <app-color *ngIf="!randomizeColors" #color></app-color>
      <app-flooring #flooring></app-flooring>
      <app-load-rotation #rotation></app-load-rotation>
      <app-load-disassemble-components
        #disassemble
      ></app-load-disassemble-components>
    </div>
  </div>

  <div style="clear: both" class="text-center">
    <br />
    <div class="keep-open">
      <mat-checkbox [(ngModel)]="keepOpenAfterSubmit" i18n
        >Nie zamykaj okna po dodaniu ładunku</mat-checkbox
      >
    </div>
    <div class="keep-open">
      <mat-checkbox
        [ngModel]="addLoadsToPendingList$ | async"
        (ngModelChange)="usePendingList($event)"
        i18n
        >Dodawaj ładunki do listy załadunkowej</mat-checkbox
      >
    </div>
    <button
      mat-raised-button
      class="btn-primary"
      (click)="onSubmit()"
      type="submit"
      [disabled]="showForm === false"
      i18n
    >
      Dodaj
    </button>
  </div>
</ng-template>
