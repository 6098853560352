import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  combineLatest,
  concat,
  concatMap,
  distinctUntilChanged,
  EMPTY,
  expand,
  filter,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  toArray,
  withLatestFrom
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Box3, Mesh, Vector3 } from 'three';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from '../confirmation-dialog/confirmation-dialog.component';
import {
  ContextMenuActionType,
  ContextMenuService,
  ContextMenuServiceCommunicationModel
} from '../context-menu';
import { LabelService } from '../label/label.service';

import { User } from '../lib/model/user';
import { VehicleContext } from '../lib/model/vehicle-context';

import { RatingComponent } from '../rating/rating.component';
import { AuthService } from '../services/auth.service';
import { TransformControlsService } from '../services/transform-controls.service';
import { ClickHandlerService } from './click-handler.service';
import { UiMode, UiService } from '../services/ui.service';
import { Vehicle } from '../vehicle/lib/vehicle';
import { VehicleService } from '../vehicle/vehicle.service';
import { VehicleService as VehicleLoader } from '../vehicle/lib/vehicle.service';
import { SceneService } from './scene.service';
import { MenuService } from '../menu/menu.service';
import { LoadComponentService } from '../services/load-component.service';
import { ExportListComponent } from '../pdf-export/export-list/export-list.component';
import { SceneImage } from '../lib/model/scene-image';
import { GravityCenterMesh } from '../lib/gravity-center/gravity-center-mesh';
import { GravityCenter } from '../lib/gravity-center/gravity-center';
import { EmptyVehicle } from '../vehicle/type/empty-vehicle/lib/empty-vehicle';
import { CSS2DToCanvasConverterService } from '../label/css2d-to-canvas-converter.service';
import { ContextFactory } from '../vehicle/context/lib/context-factory';
import { DebugPointsService } from '../lib/debug/debug-points.service';
import { ProfileService } from '../services/profile.service';
import { SceneDirector } from './lib/SceneDirector';
import { FreeSpaceService } from '../vehicle/space/lib/free-space.service';
import { ContextService } from '../vehicle/context/context.service';
import { PalletsLoadingModalComponent } from '../pallets-loading-modal/pallets-loading-modal.component';
import { MassDistributionChartComponent } from '../mass-distribution-chart/mass-distribution-chart.component';
import { ModelLoaderService } from '../raycasting/lib/model-loader.service';
import { DragControlsService } from '../raycasting/lib/drag-controls.service';
import { LoadService } from '../load/lib/load.service';
import { LoadEventObject } from '../load/lib/load-event-object';
import { PendingLoadsService } from '../loadings/lib/pending-loads.service';
import { LoadFactory } from '../load/lib/load-factory';
import { ProjectsService } from '../projects';
import { OrderService } from '../order/order.service';
import { MessageService } from '../messenger/message.service';
import { Message } from '../messenger/message';

@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.less']
})
export class SceneComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('canvas')
  private canvasRef: ElementRef;

  protected uiMode$: Observable<UiMode>;
  protected user$: Observable<User>;
  protected upgradePlanUrl: string;
  protected massDistributionEnabled: boolean;
  protected palletsAsLoadEnabled: boolean;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private director: SceneDirector;

  context: VehicleContext;

  private unsubscribe$ = new Subject<boolean>();

  constructor(
    authService: AuthService,
    private sceneService: SceneService,
    private contextService: ContextService,
    private contextMenuService: ContextMenuService,
    private transformControlsService: TransformControlsService,
    private clickHandlerService: ClickHandlerService,
    private labelService: LabelService,
    private uiService: UiService,
    private vehicleService: VehicleService,
    private vehicleLoader: VehicleLoader,
    public exportDialog: MatDialog,
    private dialog: MatDialog,
    private menuService: MenuService,
    private loadComponentService: LoadComponentService,
    private labelConverter: CSS2DToCanvasConverterService,
    private contextFactory: ContextFactory,
    private debugPointsService: DebugPointsService,
    private profileService: ProfileService,
    private freeSpaceService: FreeSpaceService,
    private dragControlsService: DragControlsService,
    private loadListService: LoadService,
    private pendingLoadsService: PendingLoadsService,
    private loadFactory: LoadFactory,
    private projectsService: ProjectsService,
    private orderService: OrderService,
    private messageService: MessageService,
    private modelLoader: ModelLoaderService
  ) {
    this.uiMode$ = uiService.getUiMode();
    this.user$ = authService.getUser();
    this.director = new SceneDirector(modelLoader);

    this.upgradePlanUrl = environment.upgradePlanUrl;
    this.massDistributionEnabled = uiService.massDistributionEnabled;
    this.palletsAsLoadEnabled = uiService.palletsAsLoadEnabled;
  }

  public ngAfterViewInit() {
    this.director.setupMainScene(new Vector3(0, 1250, 16000), this.canvas);

    this.sceneService.init(
      this.director.camera,
      this.director.scene,
      this.canvas
    );

    this.sceneService
      .initOrbitControls(this.director.camera, this.director.renderer)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.sceneService.redrawContextWithLabels();
      });
    this.initDragControls();
    this.initClickHandler();
  }

  public ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy(): void {
    console.log('scene.component.ts: destroy, unsubscribe');
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.clickHandlerService.deactivate();
    this.freeSpaceService.stop();
    this.vehicleService.resetVehicle();
  }

  //#region  ----------- UI ACTIONS ---------------
  protected containsAnyContext(): boolean {
    return this.sceneService.containsAnyContext();
  }

  protected isNotEmptyContext() {
    return (
      this.context &&
      this.context.getVehicle() &&
      this.context.getVehicle().type !== 'empty'
    );
  }

  //usuń ładunek z pojazdu
  protected clearLoad() {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: { action: ConfirmationDialogAction.deleteAllLoads }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => {
          this.uiService.setLoading(true);
          return this.sceneService.clearLoads();
        })
      )
      .subscribe(() => {
        this.uiService.setLoading(false);
      });
  }

  protected clearVehicleAndLoad() {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: { action: ConfirmationDialogAction.deleteVehicle }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => {
          this.uiService.setLoading(true);
          return this.sceneService.removeCurrentContext();
        })
      )
      .subscribe((removedContext) => {
        this.sceneService.removeContextFromView(removedContext);
        this.uiService.setLoading(false);
      });
  }

  protected clearAll() {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: { action: ConfirmationDialogAction.deleteAll }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => {
          this.uiService.setLoading(true);
          return this.sceneService.removeAllContexts();
        })
      )
      .subscribe(() => {
        this.sceneService.removeAllContextsFromView();
        this.uiService.setLoading(false);
      });
  }

  protected optimizeVehicle() {
    //this.sceneService.suggestVehicles(this.context, 'get-smaller');
    if (this.pendingLoadsService.getLoadList().length > 0) {
      this.messageService.snackInfo(
        new Message(
          $localize`Optymalizacja dostępna tylko po załadowaniu wszystkich przyczep`
        ),
        10
      );
      return;
    }
    this.uiService.setLoadingNow(true);
    const currentVehicleUuid = this.context.getVehicle().uuid;
    this.sceneService
      .optimizeVehicle(this.context)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        console.log('Optimize vehicle result', context);
        this.uiService.setLoadingNow(false);
        if (context.getVehicle().uuid !== currentVehicleUuid) {
          console.log(
            'Optimize vehicle: switched to new placement',
            context.getVehicle().name
          );
          this.messageService.snackSuccess(
            new Message($localize`Załadowano przyczepy na mniejszy pojazd`)
          );
        } else {
          this.messageService.snackInfo(
            new Message(
              $localize`Nie znaleziono lepszego pojazdu`,
              undefined,
              'OK'
            )
          );
        }
      });
  }

  /**
   * Funkcja "Przeładuj"
   */
  protected reloadLoads() {
    this.uiService.setLoadingNow(true);
    const loads = this.context
      .getAllLoads()
      .concat(
        this.pendingLoadsService.getCurrentLoads().loads.flatMap((g) => g.list)
      );
    this.context.getVehicle().enabledSpaces.forEach((space) => {
      space.loads = [];
    });
    this.context.setAddedLoads([]);
    this.context.setExistingLoads([]);
    this.context.setLoads([]);
    const newPendingList = this.pendingLoadsService.clear();
    loads.forEach((l) => {
      l.spaceUuid = null;
      l.loaded = null;
      l.rotationX = 0;
      l.rotationY = 0;
      l.rotationZ = 0;
      //l.disassembleComponents = [];

      newPendingList.addLoad(l);
    });
    this.sceneService
      .addPendingLoads(newPendingList)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.profileService.fetchProfile()),
        switchMap((profile) => {
          this.profileService.updateProfile(profile);
          newPendingList.removeSpecificLoads(
            this.sceneService.context.getLoadedLoads()
          );
          return this.pendingLoadsService.replaceList(newPendingList);
        })
      )
      .subscribe((project) => {
        this.uiService.setLoadingNow(false);
      });
  }

  protected exportProject() {
    if (this.sceneService.hasMoreThanOneContext()) {
      this.loadComponentService.add(ExportListComponent);
    } else {
      this.sceneService.exportCurrentContext();
    }
  }

  protected loadPallets() {
    console.debug('scene.component.ts -> loadPallets()');
    this.loadComponentService.add(PalletsLoadingModalComponent);
  }

  protected showMassDistribution() {
    this.loadComponentService.add(MassDistributionChartComponent);
  }

  protected canLoadAnyContextOnThisVehicle(): boolean {
    return (
      this.palletsAsLoadEnabled &&
      this.context?.canLoadOtherVehiclesOnThis() &&
      this.contextService.canLoadAnyContextOnVehicle()
    );
  }

  protected showRatingDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    //dialogConfig.height = '50%';
    dialogConfig.width = '30%';
    dialogConfig.data = {
      calculationId: this.context.getUuid(),
      historyId: this.context.getHistoryUuid()
    };
    this.exportDialog.open(RatingComponent, dialogConfig);
    return;
  }

  protected loadsExist(): boolean {
    if (this.context) {
      return !this.context.isEmpty();
    }
    return false;
  }

  //#endregion ----------- UI ACTIONS ---------------

  protected onResize() {
    this.resizeCanvasToDisplaySize();
    this.renderOnRequest();
  }

  private getCanvasAsImg(canvas: HTMLCanvasElement) {
    return canvas.toDataURL('image/jpeg', 1.0);
  }

  private subscribe() {
    this.sceneService
      .onVehicleChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        // console.log('addvehicleContextandswitch', context);
        // this.sceneService.zoomIn();
      });

    this.sceneService
      .getVehicleContext()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        if (!context) {
          return;
        }
        console.log(
          'scene component getVehicleContext subscribe',
          context.getAllLoads().map((l) => l.mesh)
        );
        this.sceneService.updateContext(context);
        this.updateContext(context);
        if (this.profileService.currentProfile.hasAttribute('debug')) {
          console.log('DEBUG: draw matrix debug vectors');
          this.drawMatrixDebugVectors();
        }
      });

    this.sceneService
      .shouldRefreshInvoked()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.renderOnRequest();
      });

    this.sceneService.updateAxles$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        const vehicle = this.context.getVehicle();
        this.updateAxles(vehicle);
        this.sceneService.redrawContextWithLabels();
        //console.log('updateAxles in scene component.ts');
      });

    this.sceneService.updateFlooringLevelInView$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        const vehicle = this.context.getVehicle();
        this.drawFlooringLevels(vehicle);
        this.sceneService.redrawContextWithLabels(); // update labels
        //console.log('updateFlooring level in scene component.ts');
      });

    this.contextMenuService
      .modelChanged()
      .pipe(
        switchMap((model) => {
          if (
            model.action !== ContextMenuActionType.close &&
            model.action !== ContextMenuActionType.open
          ) {
            this.uiService.setLoading(true);
          }
          return this.sceneService.executeContextAction(model);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.uiService.setLoading(false);
      });

    this.sceneService.profile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.sceneService.setLabelsConfiguration(profile.settings.labelConfig);
        if (this.context) {
          console.log('should redraw loads');

          this.updateContext(this.context);
        }
      });

    this.labelService
      .shouldRefreshInvoked()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        //console.log('should refresh invoked');
        this.sceneService.refreshView();
      });

    merge(
      this.uiService.getLengthUnit(),
      this.uiService.getWeightUnit(),
      this.uiService.getLabelFontModifier()
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.labelService.updateLabels();
        this.sceneService.refreshView();
      });

    this.labelService.getModel().subscribe((model) => {
      // console.log('show Gravity center PAWEŁ', model.showGravityCenter);
      if (
        this.context &&
        !(this.context.getVehicle() instanceof EmptyVehicle)
      ) {
        this.director.scene.drawGravityCenters(
          this.context.getVehicle(),
          this.context.getAllLoads(),
          model.showGravityCenter
        );
      }
    });

    this.sceneService.freeFloorHighlight$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((space) => {
        this.director.scene.addFreeFloorHighlight(
          space,
          space
            ? this.context
                .getStatistics()
                .find((s) => s.spaceUuid === space.uuid)
            : null
        );
        this.renderOnRequest();
      });

    this.sceneService
      .onSceneImgRequest()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => {
        console.log('scene.component.ts: requested img');
        await new Promise((f) => setTimeout(f, 10));
        const labelCanvas = this.labelConverter.addLabelsToCanvas(
          this.labelService.getAllLabels(),
          this.canvas,
          this.director.camera,
          this.uiService.getCurrentLabelFontModifierAsNumber()
        );

        const img = this.getCanvasAsImg(labelCanvas);
        this.cleanupExport();
        this.sceneService.returnSceneImg(
          new SceneImage(img, this.director.getAspectRatio())
        );
      });

    this.freeSpaceService.hovered$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vectorMesh) => {
        console.debug('scene.component.ts: hovered space change', vectorMesh);
        this.labelService.showMatrixLabels(vectorMesh);
        this.sceneService.redrawContextWithLabels();
      });

    this.freeSpaceService.measurementEnabled$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((enabled) => {
        if (enabled) {
          this.director.scene?.showMatrixVectors();
        } else {
          this.director.scene?.hideMatrixVectors();
        }
      });

    this.listenToPlacementGenerationForOrder();
  }

  private listenToPlacementGenerationForOrder() {
    this.sceneService.generatePlacementForOrders$
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter((orders) => orders && orders.length > 0))
      .pipe(
        switchMap((orders) => {
          this.uiService.setLoadingNow(true);
          return combineLatest([this.loadListService.loads$]).pipe(
            map(([loads]) => {
              return { orders, loads };
            })
          );
        }),
        take(1)
      )
      .pipe(
        switchMap(({ orders, loads }) => {
          console.log('generate load list from orders. Load list loaded');
          const loadsForOrders = orders
            .map((order) => {
              const load = loads.find((l) => l.uuid === order.ItemId);
              if (!load) {
                return null;
              }
              load.floorableAll = true;
              return new LoadEventObject({
                load: this.loadFactory.recreateLoad({
                  ...load,
                  floorableTop: true,
                  floorableBottom: true,
                  color: null,
                  orderNumber: order.Uuid
                }),
                cnt: order.Qty
              });
            })
            .filter((l) => !!l);
          this.pendingLoadsService.clear();
          this.pendingLoadsService.setIsActive(loadsForOrders.length > 0);
          this.pendingLoadsService.addLoads(loadsForOrders);
          return this.pendingLoadsService.isActive$();
        }),
        switchMap(() => {
          console.log('listenToPlacementGenerationForOrder: loadModels');
          return from(this.pendingLoadsService.getLoadList()).pipe(
            toArray(),
            switchMap((loads) => {
              const project = this.projectsService.currentProject;
              project.pendingLoads = loads;
              this.orderService.updatePendingOrdersList([]);
              return loads;
            }),
            switchMap(() => this.pendingLoadsService.loads$()),
            take(1),
            switchMap((toLoad) => {
              return this.sceneService.addPendingLoads(toLoad);
            }),
            switchMap(() => {
              const newPending = this.pendingLoadsService
                .getCurrentLoads()
                .removeSpecificLoads(
                  this.sceneService.context.getLoadedLoads()
                );
              const updatedPendingProcess =
                this.pendingLoadsService.replaceList(newPending);
              return updatedPendingProcess;
            })
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        console.log('scene.component - placement for orders generated');
        this.uiService.setLoadingNow(false);
      });
  }

  private cleanupExport() {
    document
      .querySelectorAll('.label-canvas')
      .forEach((canvas) => canvas.remove());
  }

  private initDragControls() {
    // transform controls

    this.transformControlsService.init(
      this.director.camera,
      this.director.renderer.domElement
    );

    this.transformControlsService
      .modelChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((model) => this.sceneService.dragModelChange(model));

    this.transformControlsService
      .dragEnd()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((model) => {
          this.uiService.setLoading(true);
          return this.sceneService.dragEnd(model);
        })
      )
      .subscribe(() => {
        this.uiService.setLoading(false);
      });

    this.transformControlsService
      .getEnabled()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        this.director.scene.add(this.transformControlsService.getControls());
        this.sceneService.dragEnable(val);
      });

    this.transformControlsService
      .getHoveredLoad()
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((load) => {
        this.sceneService.dragHoverChange(load);
      });

    this.transformControlsService
      .getSelectedLoads()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loads) => {
        this.renderOnRequest();
      });
  }

  private updateContext(context: VehicleContext) {
    if (!this.director.hasScene()) {
      return;
    }
    console.log(
      'scene component updateContext',
      context.getAllLoads().map((l) => l.mesh)
    );
    this.director.scene
      .setContext(
        context,
        this.profileService.currentSettings,
        () => {
          this.menuService.openVehicles();
        },
        this.clickHandlerService
      )
      .then(() => {
        console.log('scene component context async set');
        this.context = context;
        this.sceneService.vehicleContextDrawn(context);
        this.freeSpaceService.listen(
          this.director.scene.getMatrixVectors(),
          this.canvas,
          this.director.camera
        );
        if (this.freeSpaceService.currentMeasurementEnabledValue) {
          this.director.scene.showMatrixVectors();
        }
        /*
        const geometry = new SphereGeometry(50, 32, 16);
        const redMaterial = new MeshBasicMaterial({ color: 0xff0000 });
        const mesh = new Mesh(geometry, redMaterial);
        this.director.scene.add(mesh);
        */
        this.renderOnRequest();
      });
  }

  // ?
  private drawVehicleGravityCenters(vehicle: Vehicle) {
    const vehicleMesh = this.director.scene.getObjectByName(
      'vehicle-mesh'
    ) as Mesh;
    if (!vehicleMesh) {
      console.log('no vehicle mesh');
      return;
    }

    const vehicleFront = new GravityCenterMesh(
      new GravityCenter({
        weight: 300
      })
    );

    for (const space of vehicle.spaces) {
      const spaceMesh = vehicleMesh.children
        .filter((x) => x.name === 'space-mesh')
        .find((x) => (x as Mesh).userData.uuid === space.uuid);

      const gravityCenterMesh = new GravityCenterMesh(
        new GravityCenter({
          position: new Vector3(0, 0, 0),
          weight: 300
        })
      );
      // gravityCenter.position.x -= vehicle.totalLength / 2;
      this.director.scene.add(gravityCenterMesh);
    }
    this.renderOnRequest();
  }

  private drawFlooringLevels(vehicle: Vehicle) {
    this.director.scene.addFlooringLevels(vehicle);
    this.renderOnRequest();
  }

  private updateAxles(vehicle: Vehicle) {
    vehicle.mesh.updateAxles();
    vehicle.spaces.forEach((x) => x.mesh.updateAxles());
    this.director.scene.drawGravityCenters(
      vehicle,
      this.context?.getAllLoads(),
      this.profileService.currentSettings.labelConfig?.showGravityCenter
    );
    this.renderOnRequest();
  }

  private initClickHandler() {
    this.clickHandlerService.init(this.director.camera, this.canvas);
    this.clickHandlerService.activate([]);
  }

  private resizeCanvasToDisplaySize() {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;

    if (this.canvas.width !== width || this.canvas.height !== height) {
      this.sceneService.setLabelRendererSize(width, height);
      this.director.updateSize(width, height);
    }
  }

  private renderOnRequest() {
    this.director.render();
    this.sceneService.renderLabels();
  }

  private drawMatrixDebugVectors() {
    this.debugPointsService.drawMatrixDebugVectors(
      this.director.scene,
      this.context
    );
    this.renderOnRequest();
  }

  private drawDebugPoints() {
    this.debugPointsService.drawPoints(this.director.scene);
  }

  @HostListener('window:keydown.shift.d', ['$event'])
  onShiftD($event: KeyboardEvent) {
    console.log('====== DEBUG ======');
    const positions = [];
    const rotations = [];
    const names = [];
    this.context
      .getAllLoads()
      .sort((a, b) => a.idx - b.idx)
      .forEach((load) => {
        const mesh = load.mesh.obj;
        console.log('mesh name', mesh.name);
        const loadGroup = mesh.children.find((m) => m.name === 'load');
        positions.push(mesh.position.toArray());
        rotations.push(mesh.rotation.toArray());
        names.push(load.name);
      });
    console.log('Nazwy', names);
    console.log('Pozycje', positions);
    console.log('Rotacje', rotations);
    console.log('====== /DEBUG ======');
  }
}
