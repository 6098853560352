import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { UiMode, UiService } from '../../services/ui.service';
import { ContextService } from './context.service';
import { EditComponent } from './edit/edit.component';

@Component({
  selector: 'app-context',
  templateUrl: './context.component.html',
  styleUrls: ['./context.component.less']
})
export class ContextComponent implements OnInit, AfterViewInit, OnDestroy {
  static readonly SCROLL_STEP = 50;

  @ViewChild('list', { read: ElementRef }) list: ElementRef;
  public contexts: VehicleContext[];
  public uiMode$: Observable<UiMode>;
  showScrollLeft = false;
  showScrollRight = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private contextService: ContextService,
    ui: UiService,
    private componentLoader: LoadComponentService
  ) {
    this.uiMode$ = ui.getUiMode();
  }

  public get currentContext(): VehicleContext {
    return this.contextService.getCurrentContext();
  }

  public changeContext(vehicle: VehicleContext) {
    this.contextService.setContext(vehicle);
  }

  ngOnInit(): void {
    this.contextService

      .getAvailableVehicles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list: VehicleContext[]) => {
        this.contexts = list;
        this.checkListScrollSize();
      });

    // podczas zaznaczania danej zakładki zmienia się szerokość, więc trzeba sprawdzić scroll
    this.contextService.context$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.checkListScrollSize();
      });
  }

  ngAfterViewInit(): void {
    this.checkListScrollSize();
  }

  private checkListScrollSize() {
    if (!this.list) {
      return;
    }
    // nie działa bez timeoutu, elementy nie zdążą się jeszcze zaktualizować
    setTimeout(() => {
      const scrollWidth = this.list.nativeElement.scrollWidth;
      const width = this.list.nativeElement.clientWidth;
      const scroll = this.list.nativeElement.scrollLeft;
      this.showScrollRight = width + scroll < scrollWidth;
      this.showScrollLeft = scroll > 0;
    }, 50);
  }

  scrollLeft() {
    this.scrollList(0 - ContextComponent.SCROLL_STEP);
  }

  scrollRight() {
    this.scrollList(ContextComponent.SCROLL_STEP);
  }

  onScroll(event) {
    this.checkListScrollSize();
  }

  private scrollList(px: number) {
    if (!this.list) {
      return;
    }
    this.list.nativeElement.scrollLeft += px;
  }

  protected editContext(context: VehicleContext) {
    const component = this.componentLoader.add(EditComponent);
    component.instance.context = context;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
