import { Vector } from '../communication/vector';

export class OrbitControlsState {
  public zoom: number = 1.0;
  public target: Vector;
  public position: Vector;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
