import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { DateRange, FilterChangeEvent, FilterType } from 'src/app/dates-filter';
import { OrderService } from '../order.service';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { ProjectsService } from 'src/app/projects';
import { PageEvent } from '@angular/material/paginator';
import { OfficialOrder } from '../lib/official-order';
import { OfficialOrderList } from '../lib/official-order-list';
import { DetailsComponent } from './details/details.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { UiService } from 'src/app/services/ui.service';
import { MessageService } from 'src/app/messenger/message.service';

@Component({
  selector: 'app-official-order-list',
  templateUrl: './official-list.component.html',
  styleUrls: ['./official-list.component.less']
})
export class OfficialListComponent implements OnInit, OnDestroy {
  protected list$: Observable<OfficialOrderList>;
  protected isLoading$: Observable<boolean>;

  protected currentLocation?: string = '';
  protected currentSorting?: string = '';
  protected showDetails = false;

  search: UntypedFormControl;
  selectedDatesFilter = FilterType.month;

  private unsubscribe$ = new Subject<void>();

  constructor(
    protected service: OrderService,
    private loadComponentService: LoadComponentService,
    private projectService: ProjectsService,
    private dialog: MatDialog,
    protected ui: UiService,
    private messageService: MessageService
  ) {
    this.list$ = service.officialOrders$;
    this.isLoading$ = this.list$.pipe(
      map(() => false),
      startWith(true)
    );
    this.search = new UntypedFormControl(service.lastOfficialSearchQuery, []);
    this.currentLocation = service.lastLocation;
    this.currentSorting = service.lastOfficialSorting;
  }

  ngOnInit(): void {
    // update order list
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((searchQuery) => {
        this.service
          .fetchOfficialOrders(undefined, searchQuery)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeFilters(event: FilterChangeEvent) {
    console.log('official-list.component.ts: changed filters', event);
    this.selectedDatesFilter = event.filterType;
    this.updateOrderList(event.range, this.search.value, 0);
  }

  private updateOrderList(
    range?: DateRange,
    search?: string,
    page?: number,
    location?: string,
    sorting?: string
  ) {
    this.service
      .fetchOfficialOrders(range, search, page, location, sorting)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        console.debug('official-list.component.ts: updateOrderList', list);
      });
  }

  protected goToDetails(order: OfficialOrder) {
    this.loadComponentService.clear('official-list.component.ts goToDetails');
    const component = this.loadComponentService.add(DetailsComponent);
    component.instance.order = order;
  }

  protected removeOrder(order: OfficialOrder) {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          action: ConfirmationDialogAction.deleteOfficialOrder
        }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => this.service.removeOfficialOrder(order.Uuid))
      )
      .subscribe(() => {});
  }

  protected onPageChange(e: PageEvent) {
    this.updateOrderList(undefined, undefined, e.pageIndex);
  }

  protected changeLocation(event: any) {
    console.log('change location', this.currentLocation);
    this.updateOrderList(undefined, undefined, 0, this.currentLocation);
  }

  protected changeSorting(event: any) {
    console.log('change sorting', this.currentSorting);
    this.updateOrderList(
      undefined,
      undefined,
      0,
      undefined,
      this.currentSorting
    );
  }

  protected openProject(order: OfficialOrder) {
    this.projectService
      .find(order.ProjectId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((project) => {
        if (project) {
          this.projectService.currentProject = project;
        }
      });
  }

  protected createTemplate(order: OfficialOrder) {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          action: ConfirmationDialogAction.createOrderTemplate
        }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => this.service.createOrderTemplate(order.Uuid)),
        tap(({ newOrders, error }) => {
          if (newOrders > 0) {
            this.messageService.snackSuccess({
              title: $localize`Dodano zamówienia oficjalne na podstawie szablonu: ${newOrders}`,
              actionText: 'Ok'
            });
          }
          if (error === 'calculation_not_present') {
            this.messageService.snackError({
              title: $localize`Brak rozmieszczenia dla zamówienia`,
              actionText: 'Ok'
            });
          }
        }),
        switchMap(() => this.service.fetchOfficialOrders())
      )
      .subscribe(() => {});
  }

  protected getLastPartOfUuid(uuid: string): string {
    const parts = uuid.split('-');
    return parts[parts.length - 1];
  }
}
