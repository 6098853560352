import { FlooringLabel } from './FlooringLabel';

export class ProtrusionBottomLabel extends FlooringLabel {
  updateText(): void {
    const text =
      $localize`dł: ` +
      this.load.protrusionLength +
      '%' +
      $localize` sz: ` +
      this.load.protrusionWidth +
      '%';
    this.setText(text);
  }

  updateLabelPosition(): void {
    const position = this.load.mesh.position.clone();
    position.y -= this.load.cuboidHull.height / 2 - 65;
    this.setPosition(position);
  }

  updateCssClass(): void {}
}
