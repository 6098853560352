import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooringLevelComponent } from './flooring-level/form/flooring-level.component';
import { FlooringLevelModalComponent } from './flooring-level/flooring-level-modal.component';
import { SettingsModalComponent } from './settings-modal.component';
import { FleetSelectComponent } from '../fleet-select/fleet-select.component';
import { FleetSelectFormComponent } from '../fleet-select/form/fleet-select-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { DirectivesModule } from '../directives/directives.module';
import { ReportsComponent } from '../reports/reports.component';
import { MatSliderModule } from '@angular/material/slider';
import { MaterialFileInputModule } from 'ngx-material-file-input';

@NgModule({
  declarations: [
    FlooringLevelComponent,
    FlooringLevelModalComponent,
    SettingsModalComponent,
    FleetSelectComponent,
    FleetSelectFormComponent,
    ReportsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DirectivesModule,
    MatSliderModule,
    MaterialFileInputModule
  ],
  exports: [
    FlooringLevelComponent,
    SettingsModalComponent,
    FlooringLevelModalComponent,
    FleetSelectComponent,
    FleetSelectFormComponent,
    ReportsComponent
  ]
})
export class SettingsModule {}
