<div class="modal-box order-details-box" #box>
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Szczegóły zamówienia {{ order.Number }}</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <div *ngIf="order">
        <table class="details">
          <tbody>
            <tr>
              <th>Reference</th>
              <td>{{ order.Reference }}</td>
            </tr>
            <tr>
              <th>Number</th>
              <td>{{ order.Number }}</td>
            </tr>
            <tr>
              <th>CustAccount/ToWH</th>
              <td>{{ order.CustAccount }}</td>
            </tr>
            <tr>
              <th>Delivery Name</th>
              <td>{{ order.DeliveryName }}</td>
            </tr>
            <tr>
              <th>Delivery City</th>
              <td>{{ order.DeliveryCity }}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{{ order.State }}</td>
            </tr>
            <tr>
              <th>Zip Code</th>
              <td>{{ order.ZipCode }}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{{ order.Country }}</td>
            </tr>
            <tr>
              <th>ItemGrp</th>
              <td>{{ order.ItemGrp }}</td>
            </tr>
            <tr>
              <th>ItemId</th>
              <td>{{ order.ItemId }}</td>
            </tr>
            <tr>
              <th>Item name</th>
              <td>{{ order.ItemName }}</td>
            </tr>
            <tr>
              <th>Qty</th>
              <td>{{ order.Qty }}</td>
            </tr>
            <tr>
              <th>AvailableStock</th>
              <td>{{ order.AvailableStock }}</td>
            </tr>
            <tr>
              <th>DlvMode</th>
              <td>{{ order.DlvMode }}</td>
            </tr>
            <tr>
              <th>Shipping Date</th>
              <td>{{ order.ShippingDateConfirmed | date : 'dd.MM.YYYY' }}</td>
            </tr>
            <tr>
              <th>Volume</th>
              <td>{{ order.Volume }}</td>
            </tr>
            <tr>
              <th>InventRefType</th>
              <td>{{ order.InventRefType }}</td>
            </tr>
            <tr>
              <th>InventRefNo</th>
              <td>{{ order.InventRefNo }}</td>
            </tr>
            <tr>
              <th>ProdStatus</th>
              <td>{{ order.ProdStatus }}</td>
            </tr>
            <tr>
              <th>LatestSchedDate</th>
              <td>{{ order.LatestSchedDate }}</td>
            </tr>
            <tr>
              <th>ShipmentId</th>
              <td>{{ order.ShipmentId }}</td>
            </tr>
            <tr>
              <th>TruckArrival</th>
              <td>{{ order.TruckArrival }}</td>
            </tr>
            <tr>
              <th>Note</th>
              <td>{{ order.Note }}</td>
            </tr>
            <tr>
              <th>Site</th>
              <td>{{ order.Site }}</td>
            </tr>
            <tr>
              <th>Pool</th>
              <td>{{ order.Pool }}</td>
            </tr>
            <tr>
              <th>ItemGrpId</th>
              <td>{{ order.ItemGrpId }}</td>
            </tr>
            <tr>
              <th>ByerGroup</th>
              <td>{{ order.ByerGroup }}</td>
            </tr>
            <tr>
              <th>Warehouse</th>
              <td>{{ order.Warehouse }}</td>
            </tr>
            <tr>
              <th>Na pojeździe</th>
              <td>{{ order.VehicleName || '' }}</td>
            </tr>
            <tr>
              <th>Wolne LDM</th>
              <td>{{ order.FreeLDM / 1000 | number : '0.0-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="loader" *ngIf="isLoading">
        <mat-icon>
          <mat-spinner class="progress" color="accent" diameter="24">
          </mat-spinner>
        </mat-icon>
      </div>
    </div>
  </div>
</div>
