import { Injectable } from '@angular/core';
import { MyScene } from 'src/app/scene/lib/MyScene';
import { SpaceMesh } from 'src/app/vehicle/space/lib/space-mesh';
import { DebugVectorMesh } from 'src/app/vehicle/space/lib/debug-vector-mesh';
import { Mesh, MeshBasicMaterial, Scene, SphereGeometry, Vector3 } from 'three';
import { VehicleContext } from '../model/vehicle-context';

@Injectable({
  providedIn: 'root'
})
export class DebugPointsService {
  redPoints: Array<Vector3>;
  bluePoints: Array<Vector3>;
  greenPoints: Array<Vector3>;

  constructor() {
    this.redPoints = new Array<Vector3>();
    this.bluePoints = new Array<Vector3>();
    this.greenPoints = new Array<Vector3>();
  }

  public addRedPoint(vec: Vector3) {
    this.redPoints.push(vec);
  }

  public addBluePoint(vec: Vector3) {
    this.bluePoints.push(vec);
  }

  public addGreenPoint(vec: Vector3) {
    this.greenPoints.push(vec);
  }

  public drawPoints(scene: Scene) {
    const redPositions = this.redPoints.concat([
      // new Vector3(0, 0, 0),
      //new Vector3(-2600, 1000, 0)
      // new Vector3(2600, 150, 0),
      // new Vector3(-2600, 1000, 0)
    ]);
    const bluePositions = this.bluePoints.concat([new Vector3(0, 0, 0)]);
    const greenPositions = this.greenPoints.concat([
      // new Vector3(-2100, 50, -1100)
      // new Vector3(-500, -1000, -750),
      // new Vector3(-500, -1000, 750),
      // new Vector3(-500, 1000, -750),
      // new Vector3(-500, 1000, 750),
      // new Vector3(500, 1000, 750),
      // new Vector3(500, 1000, -750),
      // new Vector3(500, -1000, -750),
      // new Vector3(500, -1000, 750)
    ]);

    const geometry = new SphereGeometry(50, 32, 16);
    const redMaterial = new MeshBasicMaterial({ color: 0xff0000 });
    const blueMaterial = new MeshBasicMaterial({ color: 0x0000ff });
    const greenMaterial = new MeshBasicMaterial({ color: 0x00ff00 });
    for (const position of redPositions) {
      const sphere = new Mesh(geometry, redMaterial);
      sphere.position.copy(position);
      scene.add(sphere);
    }

    for (const position of bluePositions) {
      const sphere = new Mesh(geometry, blueMaterial);
      sphere.position.copy(position);
      scene.add(sphere);
    }

    for (const position of greenPositions) {
      const sphere = new Mesh(geometry, greenMaterial);
      sphere.position.copy(position);
      scene.add(sphere);
    }
  }

  private removeMatrixDebugVectors(scene: MyScene) {
    scene.removeObjectsByName('debug-vector');
  }

  public drawMatrixDebugVectors(scene: MyScene, context: VehicleContext) {
    if (!scene || !context) {
      return;
    }
    this.removeMatrixDebugVectors(scene);
    const vehicle = context.getVehicle();
    if (!vehicle) {
      return;
    }
    const vehicleMesh = scene.getObjectByName('vehicle-mesh') as Mesh;
    if (!vehicleMesh) {
      console.debug('debug-points.service: no vehicle mesh');
      return;
    }
    vehicle.spaces.forEach((space) => {
      const spaceMesh = vehicleMesh.children
        .filter((x) => x.name === 'space-mesh')
        .find((x) => (x as Mesh).userData.uuid === space.uuid);
      if (!spaceMesh) {
        return;
      }
      space.matrix.forEach((vector) => {
        //console.debug('DEBUG: draw matrix vector', vector);
        const vectorMesh = new DebugVectorMesh(vector);
        spaceMesh.add(vectorMesh.mesh);
      });
    });
  }
}
