import { CarTrailerType1 } from 'src/app/load/type';
import { Load } from '../../../load/lib/load';
import { LoadGroup } from './load-group';
export class CarTrailerGroup extends LoadGroup {
  public has(aLoad: Load) {
    if (
      !(aLoad instanceof CarTrailerType1) ||
      !(this.load instanceof CarTrailerType1)
    ) {
      return super.has(aLoad);
    }
    const sameTrailers =
      aLoad.name === this.load.name &&
      aLoad.modelName === this.load.modelName &&
      aLoad.weight === this.load.weight &&
      aLoad.floorableTop === this.load.floorableTop &&
      aLoad.floorableBottom === this.load.floorableBottom &&
      aLoad.color === this.load.color &&
      this.hasTheSameDisassembledComponents(aLoad);
    return sameTrailers;
  }

  private hasTheSameDisassembledComponents(aLoad: Load): boolean {
    if (
      aLoad.disassembleComponents.length !==
      this.load.disassembleComponents.length
    ) {
      return false;
    }
    const sortedArr1 = [...this.load.disassembleComponents].sort();
    const sortedArr2 = [...aLoad.disassembleComponents].sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }
}
