import {
  Box3,
  Box3Helper,
  BoxGeometry,
  Color,
  EdgesGeometry,
  FrontSide,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshPhongMaterial,
  Object3D,
  Raycaster,
  Vector3
} from 'three';
import { Load } from '../load/lib/load';
import { Vehicle } from '../vehicle/lib/vehicle';
import { Injectable } from '@angular/core';
import { Constants as Config } from '../config/constants';
import { OBB } from '../lib/vendor/three/OBB';

export enum CollisionDirection {
  lower = 'lower',
  greater = 'greater',
  unknown = 'unknown'
}

type CollisionInfo = false | { with: string };

type MeshWithObbs = {
  mesh: Mesh;
  obbs: { name: string; obb: OBB }[];
  obbsExp: { name: string; obb: OBB }[];
  aabb: Box3;
  aabbExp: Box3;
};

type Axis = 'x' | 'y' | 'z';

interface Collisions {
  x?: CollisionDirection;
  y?: CollisionDirection;
  z?: CollisionDirection;
}

interface Intersection {
  uuid?: string;
  size: Vector3;
  checkBox: Box3;
}

interface Jump {
  position: Vector3;
  distance: number;
}

@Injectable({
  providedIn: 'root'
})
export class ColliderDetector {
  private pullAccuracy = -50;
  private vehicleBoundingBoxes: Array<Box3>;
  private loads: Load[] = [];
  private current: Load;
  private vehicle: Vehicle;

  constructor() {}

  public detect(load: Load, vehicle: Vehicle, loads: Load[]) {
    load.mesh.obj.userData.locked = true;
    this.current = load;
    this.vehicle = vehicle;
    this.loads = loads;
    this.vehicleBoundingBoxes = new Array<Box3>();
    for (const space of vehicle.enabledSpaces) {
      const spacePos = space.mesh.meshObj.getWorldPosition(new Vector3());
      //console.log('collider', spacePos);

      this.vehicleBoundingBoxes.push(
        new Box3(
          spacePos.clone(),
          new Vector3(
            spacePos.x + space.length,
            spacePos.y + space.height,
            spacePos.z + space.width
          )
        )
      );
    }
    this.vehicleBoundingBoxes = this.vehicleBoundingBoxes.sort(
      (a, b) => a.min.x - b.min.x
    );
    const otherLoads = loads.filter(
      (x) => x.mesh.obj.userData.uuid !== load.mesh.obj.userData.uuid
    );
    console.log('other loads', otherLoads.length);

    this.setSceneBoundary();

    //this.grounding(load);
    //this.lowerUnsupportedLoad(otherLoads);
    this.lowerAsMuchAsPossible(otherLoads);

    this.collisionDetection(otherLoads);
    load.mesh.obj.userData.locked = false;
  }

  /*
  private grounding(currentLoad: Load) {
    // currentLoad.mesh.position.y = currentLoad.cuboidHull.height / 2;

    this.vehicleBoundingBoxes.forEach((spaceBoundingBox) => {
      this.spaceGrounding(currentLoad, spaceBoundingBox);
    });

    this.loads
      .filter((l) => l.mesh.obj.uuid !== currentLoad.mesh.obj.uuid)
      .forEach((load) => {
        this.loadGrounding(currentLoad, load);
      });
  }
  */

  /**
   * Ustawia maksymalną możliwą pozycję dla ładunków na scenie
   */
  private setSceneBoundary() {
    const box = new Box3().setFromObject(this.current.mesh.obj);
    const boxSize = box.getSize(new Vector3());
    for (const axis of ['x', 'z']) {
      if (box.min[axis] < -Config.GRID_SIZE / 2) {
        this.current.mesh.obj.position[axis] =
          -Config.GRID_SIZE / 2 + boxSize[axis] / 2;
        this.current.mesh.obj.userData.validPosition =
          this.current.mesh.obj.position.clone();
      }
      if (box.max[axis] > Config.GRID_SIZE / 2) {
        this.current.mesh.obj.position[axis] =
          Config.GRID_SIZE / 2 - boxSize[axis] / 2;
        this.current.mesh.obj.userData.validPosition =
          this.current.mesh.obj.position.clone();
      }
    }
  }

  /**
   * Rysuje prostopadłościany - do debugowania
   *
   * @param boxes Box3[]
   */
  private drawBoxes(boxes: Box3[]) {
    let b: Object3D;
    while ((b = this.vehicle.mesh.mesh.parent.getObjectByName('boundary'))) {
      this.vehicle.mesh.mesh.parent.remove(b);
    }
    for (const box of boxes) {
      const center = box.getCenter(new Vector3());
      const mesh = new Mesh();
      mesh.position.copy(center);
      mesh.name = 'boundary';
      mesh.geometry = new BoxGeometry(
        box.max.x - box.min.x,
        box.max.y - box.min.y,
        box.max.z - box.min.z
      );
      mesh.material = new MeshPhongMaterial({
        color: Color.NAMES.gray,
        side: FrontSide,
        transparent: true,
        opacity: 0.2,
        depthWrite: false
      });
      const wireframe = new EdgesGeometry(mesh.geometry);
      const mat = new LineBasicMaterial({
        color: Color.NAMES.steelblue,
        transparent: false,
        opacity: 1,
        linewidth: 1
      });
      const line = new LineSegments(wireframe, mat);
      mesh.add(line);
      this.vehicle.mesh.mesh.parent.add(mesh);
    }
  }

  /**
   * Tworzy boxy wokół przestrzeni.
   * Unikamy w ten sposób ładunków wystających w części z przestrzeni
   * i osiągamy efekt jak w GL - przeskakiwania ładunków nieco poza przestrzeń
   * w momencie ich wyciągania
   *
   * @returns Box3[]
   */
  private getFreeSpaceBoundaries(): Box3[] {
    let first = true;
    const spaceBoundary = 800;
    let spaceXMax: number;
    const boxes: Box3[] = [];
    for (const spaceBox of this.vehicleBoundingBoxes) {
      let xFront = spaceBoundary;
      if (first) {
        xFront = Math.max(this.vehicle.cabinLength, xFront);
      } else if (spaceXMax !== undefined) {
        xFront = spaceBox.min.x - spaceXMax;
      }
      // front
      boxes.push(
        new Box3(
          new Vector3(
            spaceBox.min.x - xFront,
            spaceBox.min.y,
            spaceBox.min.z - spaceBoundary
          ),
          new Vector3(
            spaceBox.min.x,
            spaceBox.max.y,
            spaceBox.max.z + spaceBoundary
          )
        )
      );
      // left
      boxes.push(
        new Box3(
          new Vector3(spaceBox.min.x, spaceBox.min.y, spaceBox.max.z),
          new Vector3(
            spaceBox.max.x,
            spaceBox.max.y,
            spaceBox.max.z + spaceBoundary
          )
        )
      );
      // right
      boxes.push(
        new Box3(
          new Vector3(
            spaceBox.min.x,
            spaceBox.min.y,
            spaceBox.min.z - spaceBoundary
          ),
          new Vector3(spaceBox.max.x, spaceBox.max.y, spaceBox.min.z)
        )
      );

      spaceXMax = spaceBox.max.x;
      first = false;
    }
    const last =
      this.vehicleBoundingBoxes[this.vehicleBoundingBoxes.length - 1];
    boxes.push(
      new Box3(
        new Vector3(last.max.x, last.min.y, last.min.z - spaceBoundary),
        new Vector3(
          last.max.x + spaceBoundary,
          last.max.y,
          last.max.z + spaceBoundary
        )
      )
    );

    return boxes;
  }

  /**
   * Zwraca wszystkie poziomy (w osi Y) na jakich kończą się ładunki,
   * rozpoczynając od najniższego poziomu
   *
   * @param loads Load[]
   */
  private getLoadLevels(loads: Load[]): number[] {
    const levels = [
      ...new Set(
        loads.map((l) => {
          const checkBox = new Box3().setFromObject(l.mesh.obj);
          return checkBox.max.y;
        })
      )
    ].sort((a, b) => a - b);
    return levels;
  }

  private invalidPositionsBoundaries(level: number): Box3[] {
    const loads = this.loads
      .filter(
        (load) =>
          load.mesh.obj.userData.uuid !== this.current.mesh.obj.userData.uuid
      )
      .sort((a, b) => {
        const worldPosA = a.mesh.obj.getWorldPosition(new Vector3());
        const worldPosB = b.mesh.obj.getWorldPosition(new Vector3());
        if (worldPosA.x === worldPosB.x) {
          if (worldPosA.z === worldPosB.z) {
            return worldPosA.y - worldPosB.y;
          }
          return worldPosA.z - worldPosB.z;
        }
        return worldPosA.x - worldPosB.x;
      });
    const boxes: Box3[] = [];
    const raycaster = new Raycaster();

    for (const load of loads) {
      const box = new Box3().setFromObject(load.mesh.obj);
      if (box.max.y !== level) {
        continue;
      }
      const space = this.findVehicleSpaceForBox(box);
      if (!space) {
        continue;
      }
      let intersects: THREE.Intersection[] = [];
      // górny prawy - w stronę tyłu
      let startPoint = new Vector3(box.max.x, box.max.y, box.min.z);
      let target = new Vector3(space.max.x, box.max.y, box.min.z);
      let direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = raycaster.intersectObjects(loads.map((l) => l.mesh.obj));

      // górny lewy - w stronę tyłu
      startPoint = new Vector3(box.max.x, box.max.y, box.max.z);
      target = new Vector3(space.max.x, box.max.y, box.max.z);
      direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = intersects.concat(
        raycaster.intersectObjects(loads.map((l) => l.mesh.obj))
      );
      if (intersects.length === 0) {
        //console.log('intersect ray no intersection to back', level, load.idx);
        const invalidSpace = new Box3(
          new Vector3(box.max.x, box.max.y, box.min.z),
          new Vector3(space.max.x, space.max.y, box.max.z)
        );
        boxes.push(invalidSpace);
      }
      intersects = [];
      // górny przedni - w stronę lewej strony pojazdu
      startPoint = new Vector3(box.min.x, box.max.y, box.max.z);
      target = new Vector3(box.min.x, box.max.y, space.max.z);
      direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = intersects.concat(
        raycaster.intersectObjects(loads.map((l) => l.mesh.obj))
      );
      // górny tylny - w stronę lewej strony pojazdu
      startPoint = new Vector3(box.max.x, box.max.y, box.max.z);
      target = new Vector3(box.max.x, box.max.y, space.max.z);
      direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = intersects.concat(
        raycaster.intersectObjects(loads.map((l) => l.mesh.obj))
      );
      if (intersects.length === 0) {
        //console.log('intersect ray no intersection to left', level, load.idx);
        const invalidSpace = new Box3(
          new Vector3(box.min.x, box.max.y, box.max.z),
          new Vector3(box.max.x, space.max.y, space.max.z)
        );
        boxes.push(invalidSpace);
      }
      intersects = [];
      // górny przedni - w stronę prawej strony pojazdu
      target = new Vector3(box.min.x, box.max.y, box.min.z);
      startPoint = new Vector3(box.min.x, box.max.y, space.min.z);
      direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = intersects.concat(
        raycaster.intersectObjects(loads.map((l) => l.mesh.obj))
      );
      // górny tylny - w stronę prawej strony pojazdu
      target = new Vector3(box.max.x, box.max.y, box.min.z);
      startPoint = new Vector3(box.max.x, box.max.y, space.min.z);
      direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = intersects.concat(
        raycaster.intersectObjects(loads.map((l) => l.mesh.obj))
      );
      if (intersects.length === 0) {
        //console.log('intersect ray no intersection to right', level, load.idx);
        const invalidSpace = new Box3(
          new Vector3(box.min.x, box.max.y, space.min.z),
          new Vector3(box.max.x, space.max.y, box.min.z)
        );
        boxes.push(invalidSpace);
      }

      intersects = [];
      // górny lewy - w stronę przodu pojazdu
      target = new Vector3(box.min.x, box.max.y, box.max.z);
      startPoint = new Vector3(space.min.x, box.max.y, box.max.z);
      direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = intersects.concat(
        raycaster.intersectObjects(loads.map((l) => l.mesh.obj))
      );
      // górny prawy - w stronę przodu pojazdu
      target = new Vector3(box.min.x, box.max.y, box.min.z);
      startPoint = new Vector3(space.min.x, box.max.y, box.min.z);
      direction = new Vector3().subVectors(target, startPoint);

      raycaster.set(startPoint, direction.normalize());
      raycaster.far = direction.length();
      intersects = intersects.concat(
        raycaster.intersectObjects(loads.map((l) => l.mesh.obj))
      );
      if (intersects.length === 0) {
        //console.log('intersect ray no intersection to front', level, load.idx);
        const invalidSpace = new Box3(
          new Vector3(space.min.x, box.max.y, box.min.z),
          new Vector3(box.min.x, space.max.y, box.max.z)
        );
        boxes.push(invalidSpace);
      }
    }

    return boxes;
  }

  public obbCollided(
    meshInfo: MeshWithObbs,
    loaded: MeshWithObbs[],
    useExpandedObbs = false
  ): CollisionInfo {
    if (loaded.length === 0) {
      return false;
    }
    let collidedWith: string | undefined = undefined;
    meshInfo.mesh.updateMatrix();
    meshInfo.mesh.updateMatrixWorld();
    let obbChecked = 0;
    let childrenTested = 0;
    //const meshBox = new Box3().setFromObject(meshInfo.mesh);
    const meshBox = useExpandedObbs ? meshInfo.aabbExp : meshInfo.aabb;
    const obbs = useExpandedObbs ? meshInfo.obbsExp : meshInfo.obbs;
    for (const obbInfo of obbs) {
      childrenTested++;
      for (let j = 0, jl = loaded.length; j < jl; j++) {
        const objectToTest = loaded[j];
        if (objectToTest.mesh.id == meshInfo.mesh.id) {
          continue;
        }
        const objBox = objectToTest.aabb;
        if (!objBox.intersectsBox(meshBox)) {
          // szybkie wyjście jeśli BB całych przyczep się nie przecina
          continue;
        }
        for (const obbToTest of objectToTest.obbs) {
          obbChecked++;
          const epsilon = Number.EPSILON;
          if (obbInfo.obb.intersectsOBB(obbToTest.obb, epsilon) === true) {
            /*console.log(
              'collision between',
              child.name,
              testChild.name,
              obb,
              obbToTest,
              mesh.userData,
              objectToTest.userData
            );*/
            collidedWith = `${obbInfo.name}/${objectToTest.mesh.name}:${objectToTest.mesh.userData.idx}:${obbToTest.name}`;
            return {
              with: collidedWith
            };
          }
        }
      }
    }
    return false;
  }

  public collided(mesh: Object3D, loaded: Object3D[]): CollisionInfo {
    let collided = false;
    let collidedWith: string = undefined;
    mesh.updateMatrix();
    mesh.updateMatrixWorld();
    let obbChecked = 0;
    let childrenTested = 0;
    mesh.traverse((child) => {
      if (collided || !(child instanceof Mesh) || !child.userData.obb) {
        return;
      }
      childrenTested++;

      const obb: OBB = child.userData.obb;
      /*if (child.name == 'jockey-wheel-bb-bounding-box-wireframe') {
        console.log(
          'checking',
          child.name,
          obb.center,
          obb.getSize(new Vector3()),
          obb.rotation
        );
      }*/
      const meshBox = new Box3().setFromObject(mesh);

      for (let j = 0, jl = loaded.length; j < jl; j++) {
        const objectToTest = loaded[j];
        if (objectToTest.id == mesh.id) {
          continue;
        }
        const objBox = new Box3().setFromObject(objectToTest);
        if (!objBox.intersectsBox(meshBox)) {
          // szybkie wyjście jeśli BB całych przyczep się nie przecina
          continue;
        }
        objectToTest.traverse((testChild) => {
          const obbToTest: OBB = testChild.userData.obb;
          if (collided || !(testChild instanceof Mesh) || !obb || !obbToTest) {
            return;
          }
          /*if (
            child.name == 'jockey-wheel-bb-bounding-box-wireframe' &&
            testChild.name == 'jockey-wheel-bb-bounding-box-wireframe'
          ) {
            console.log(
              'checking against',
              testChild.name,
              obbToTest.center,
              obbToTest.getSize(new Vector3()),
              obbToTest.rotation
            );
          }*/
          obbChecked++;
          const epsilon = Number.EPSILON;
          if (obb.intersectsOBB(obbToTest, epsilon) === true) {
            /*console.log(
              'collision between',
              child.name,
              testChild.name,
              obb,
              obbToTest,
              mesh.userData,
              objectToTest.userData
            );*/
            collided = true;
            collidedWith = `${objectToTest.name}:${objectToTest.userData.idx}:${testChild.name}`;
            return;
          }
        });

        if (collided) return;
      }
    });
    //console.log('children tested', childrenTested);
    //console.log('obb checked', obbChecked);
    if (collided) {
      return {
        with: collidedWith
      };
    }
    return collided;
  }

  private lowerAsMuchAsPossible(loads: Load[]) {
    const box = new Box3().setFromObject(this.current.mesh.obj);
    const boxSize = box.getSize(new Vector3());

    const otherMeshes = loads.map((l) => l.mesh.obj);
    const tryLowerByY = boxSize.y / 2;
    const tryCurrent = this.current.mesh.obj;
    const minY =
      Math.min(...this.vehicleBoundingBoxes.map((b) => b.min.y)) +
      boxSize.y / 2;

    const moved = this.lowerBinary(tryCurrent, tryLowerByY, otherMeshes, minY);
    this.updateLoadPosition(moved.position);
    console.log('lowered to ', moved.position);
    this.current.mesh.obj.userData.validPosition = moved.position.clone();
  }

  private lowerBinary(
    mesh: Object3D,
    lowerBy: number,
    otherMeshes: Object3D[],
    minY: number
  ): Object3D {
    const box = new Box3().setFromObject(this.current.mesh.obj);
    const lastValidPosition =
      this.current.mesh.obj.userData.validPosition.clone();
    let tryLowerByY = lowerBy;
    while (tryLowerByY > 0.99) {
      const newPosition = lastValidPosition.clone();
      newPosition.y -= tryLowerByY;
      this.updateLoadYPosition(newPosition.y);
      if (
        mesh.position.y < minY ||
        !this.isWithinSpaceBounds(mesh) ||
        this.collided(mesh, otherMeshes)
      ) {
        console.error(
          'collided on lowering by',
          tryLowerByY,
          this.isWithinSpaceBounds(mesh)
        );
        //console.log('collided lvp', lastValidPosition);
        this.updateLoadYPosition(lastValidPosition.y);
        tryLowerByY /= 2.0;
      } else {
        console.log('lowered by', tryLowerByY, mesh.position.y, minY);
        lastValidPosition.y = newPosition.y;
      }
    }
    if (mesh.position.y < minY) {
      console.log('return to last valid position', lastValidPosition);
      this.updateLoadYPosition(lastValidPosition.y);
    }
    return mesh;
  }

  /**
   * Obniża ładunki, które nie leżą na innych
   *
   * @param loads Load[]
   * @returns void
   */
  private lowerUnsupportedLoad(loads: Load[]) {
    let box = new Box3().setFromObject(this.current.mesh.obj);
    //console.log('box', this.current.idx, box.min, box.max);
    const boxSize = box.getSize(new Vector3());
    const levels = this.getLoadLevels(loads)
      //.filter((l) => l <= box.min.y)
      .reverse();
    if (levels.length === 0) {
      levels.push(0);
    }
    console.log('levels', levels);
    for (const level of levels) {
      //console.log('lower check level', level);
      let minY = 0;
      let canBeFloored = this.current.floorableBottom;
      let totalIntersection: Box3 = new Box3().makeEmpty();
      for (const other of loads) {
        const checkBox = new Box3().setFromObject(other.mesh.obj);
        console.log('checkbox', checkBox.min, checkBox.max, box.min, box.max);
        if (checkBox.max.y !== level) {
          continue;
        }
        minY = Math.max(minY, checkBox.min.y);
        console.log('minY', minY);
        const intersection = this.getTwoBoxesFloorContactSurface(box, checkBox);
        const intersectionSize = intersection.getSize(new Vector3());
        if (intersectionSize.x !== 0 && intersectionSize.z !== 0) {
          //console.log('intersect', level, intersection);
          const area = intersectionSize.x * intersectionSize.z;
          if (area > 0) {
            totalIntersection.union(intersection);
            canBeFloored &&= other.floorableTop;
          }
        }
      }
      const intersectionSize = totalIntersection.getSize(new Vector3());
      console.log('lowerUnsupported', minY, canBeFloored, intersectionSize);

      if (
        intersectionSize.x < boxSize.x / 2 ||
        intersectionSize.z < boxSize.z / 2
      ) {
        this.updateLoadYPosition(minY + boxSize.y / 2);
        box = new Box3().setFromObject(this.current.mesh.obj);
        this.lowerAsMuchAsPossible(loads);
      } else {
        this.lowerAsMuchAsPossible(loads);
        return;
      }
    }
  }

  /**
   * Zwraca nową współrzędną w osi Y jeśli ładunek można umieścić wyżej niż obecnie
   *
   * @param loads Load[]
   * @returns number|false
   */
  private floorSupportedLoad(loads: Load[]): number | false {
    const box = new Box3().setFromObject(this.current.mesh.obj);
    //console.log('floor box', this.current.idx, box.min, box.max);
    const boxSize = box.getSize(new Vector3());

    const levels = this.getLoadLevels(loads)
      .reverse()
      .filter((l) => l > box.min.y);

    for (const level of levels) {
      let canBeFloored = this.current.floorableBottom;
      let totalIntersection: Box3 = new Box3().makeEmpty();

      if (!canBeFloored) {
        return false;
      }
      for (const other of loads) {
        const checkBox = new Box3().setFromObject(other.mesh.obj);
        if (checkBox.max.y !== level) {
          continue;
        }
        const intersection = this.getTwoBoxesFloorContactSurface(
          box,
          checkBox,
          true
        );
        const intersectionSize = intersection.getSize(new Vector3());
        if (intersectionSize.x !== 0 && intersectionSize.z !== 0) {
          //console.log('f intersect', level, intersection);
          const area = intersectionSize.x * intersectionSize.z;
          if (area > 0) {
            totalIntersection.union(intersection);
            canBeFloored &&= other.floorableTop;
          }
        }
      }
      const intersectionSize = totalIntersection.getSize(new Vector3());
      console.log(
        'try floor top',
        intersectionSize.x,
        boxSize.x / 2,
        intersectionSize.z,
        boxSize.z / 2
      );
      if (
        canBeFloored &&
        intersectionSize.x >= boxSize.x / 2 &&
        intersectionSize.z >= boxSize.z / 2
      ) {
        const translation = new Vector3(0, level - box.min.y);
        const movedBox = box.clone().translate(translation);
        const boxMesh = new Box3Helper(movedBox);
        if (this.fitsInCurrentSpace(boxMesh)) {
          return level + boxSize.y / 2;
        }
        return false;
      }
    }
    return false;
  }

  /**
   * Zwraca informacje o przecięciu dwóch obiektów Box3
   * @param box Box3
   * @param checkBox Box3
   * @returns Intersection|null
   */
  private getTwoBoxesIntersection(box: Box3, checkBox: Box3): Intersection {
    const maxX = Math.min(checkBox.max.x, box.max.x);
    const minX = Math.max(checkBox.min.x, box.min.x);
    const intersectXlength = maxX - minX;

    const maxZ = Math.min(checkBox.max.z, box.max.z);
    const minZ = Math.max(checkBox.min.z, box.min.z);
    const intersectZlength = maxZ - minZ;

    const maxY = Math.min(checkBox.max.y, box.max.y);
    const minY = Math.max(checkBox.min.y, box.min.y);
    const intersectYlength = maxY - minY;
    if (intersectXlength > 0 && intersectZlength > 0 && intersectYlength > 0) {
      return {
        size: new Vector3(intersectXlength, intersectYlength, intersectZlength),
        checkBox
      };
    }
    return undefined;
  }

  /**
   * Zwraca informacje o wspólnej częsci "podłogi" dwóch obiektów Box3 stojących bezpośrednio na sobie
   *
   * @param box Box3
   * @param checkBox Box3
   * @param ignoreY: boolean
   * @returns Vector3
   */
  private getTwoBoxesFloorContactSurface(
    box: Box3,
    checkBox: Box3,
    ignoreY = false
  ): Box3 {
    if (
      ignoreY ||
      box.min.y === checkBox.max.y ||
      box.max.y === checkBox.min.y
    ) {
      const maxX = Math.min(checkBox.max.x, box.max.x);
      const minX = Math.max(checkBox.min.x, box.min.x);
      const intersectXlength = maxX - minX;

      const maxZ = Math.min(checkBox.max.z, box.max.z);
      const minZ = Math.max(checkBox.min.z, box.min.z);
      const intersectZlength = maxZ - minZ;
      if (intersectXlength > 0 && intersectZlength > 0) {
        return new Box3(
          new Vector3(minX, box.min.y, minZ),
          new Vector3(maxX, box.min.y + 1, maxZ)
        );
        //return new Vector3(intersectXlength, 0, intersectZlength);
      }
    }
    return new Box3().makeEmpty();
  }

  /**
   * Ustawia nową pozycję ładunku w osi Y.
   * Nowa pozycja ustawiana jest również w dodatkowych atrybutach obiektu,
   * tak żeby TransformControls nie próbowało przywrócić pozycji startowej.
   * TransformControls zostało odpowiednio zmodyfikowane, aby odczytywać ten atrybut.
   *
   * @param load Load
   * @param newY number
   */
  private updateLoadYPosition(newY: number) {
    //console.log('move y', newY);
    const mesh = this.current.mesh.obj;
    mesh.position.y = newY;
    mesh.userData.changedY = newY;
    this.updateLoadPosition(mesh.position.clone());
  }

  private returnToLastValidPosition() {
    const mesh = this.current.mesh.obj;
    const validPosition = mesh.userData.validPosition;
    this.updateLoadPosition(validPosition);
  }

  private updateLoadPosition(position: Vector3) {
    this.current.mesh.updatePosition(position);
  }

  /**
   * Sprawdza czy box mieści się w całości w jednej z przestrzeni
   *
   * @param box Box3
   * @returns boolean
   */
  private fitsInCurrentSpace(box: Object3D): boolean {
    return this.isWithinSpaceBounds(box);
  }

  /**
   * Zwraca przestrzeń, w której mieści się box
   *
   * @param box Box3
   * @returns Box3 | null
   */
  private findVehicleSpaceForBox(box: Box3): Box3 | null {
    for (const checkBox of this.vehicleBoundingBoxes) {
      const maxX = Math.min(checkBox.max.x, box.max.x);
      const minX = Math.max(checkBox.min.x, box.min.x);
      const intersectXlength = maxX - minX;

      const maxZ = Math.min(checkBox.max.z, box.max.z);
      const minZ = Math.max(checkBox.min.z, box.min.z);
      const intersectZlength = maxZ - minZ;

      if (intersectXlength > 0 && intersectZlength > 0) {
        if (box.max.y > checkBox.max.y) {
          return null;
        }
        return checkBox;
      }
    }
    return null;
  }

  private isWithinSpaceBounds(mesh: Object3D) {
    for (let index = 0; index < this.vehicleBoundingBoxes.length; index++) {
      const spaceBB = this.vehicleBoundingBoxes[index].clone();
      spaceBB.expandByScalar(1);
      let isSomethingOutside = false;
      let childrenTested = 0;
      mesh.traverse((child) => {
        if (isSomethingOutside || !child.userData.obb) {
          return;
        }
        const bb = new Box3().setFromObject(child);
        //console.log('testing child bb', bb.min, bb.max);
        childrenTested++;
        if (!spaceBB.containsBox(bb)) {
          //console.log('out of space bounds', child.name, bb, spaceBB);
          isSomethingOutside = true;
        }
      });
      if (!isSomethingOutside) {
        /* console.log(
          'isWithinSpaceBounds',
          spaceBB.min,
          spaceBB.max,
          childrenTested
        ); */
        return true;
      }
    }
    return false;
  }

  /**
   * Realizuje funkcję przyciągania do innych ładunków.
   * Zwraca box z pozycją po przyciągnięciu lub box z niezmienioną pozycją jeśli nie było przyciągania.
   *
   * @param boxes Box3[]
   * @returns Box3
   */
  private pullToLoads(boxes: Box3[]): Box3 {
    const box = new Box3().setFromObject(this.current.mesh.obj);
    const boxSize = box.getSize(new Vector3());
    const newPosition = this.current.mesh.obj.position.clone();

    for (const checkBox of boxes) {
      let maxX = Math.min(checkBox.max.x, box.max.x);
      let minX = Math.max(checkBox.min.x, box.min.x);
      let intersectXlength = maxX - minX;

      let maxZ = Math.min(checkBox.max.z, box.max.z);
      let minZ = Math.max(checkBox.min.z, box.min.z);
      let intersectZlength = maxZ - minZ;

      if (intersectZlength > 0) {
        if (intersectXlength > this.pullAccuracy && intersectXlength < 0) {
          if (box.min.x > checkBox.max.x) {
            newPosition.x = checkBox.max.x + boxSize.x / 2;
          } else {
            newPosition.x = checkBox.min.x - boxSize.x / 2;
          }
        }
      }

      if (intersectXlength > 0) {
        if (intersectZlength > this.pullAccuracy && intersectZlength < 0) {
          if (box.min.z > checkBox.max.z) {
            newPosition.z = checkBox.max.z + boxSize.z / 2;
          } else {
            /*console.log(
              'pull ',
              intersectXlength,
              intersectZlength,
              box.min.z,
              checkBox.min.z
            );*/
            newPosition.z = checkBox.min.z - boxSize.z / 2;
          }
        }
      }
    }
    return box.setFromCenterAndSize(newPosition, boxSize);
  }

  /**
   * Zwraca listę przecięć dwóch obiektów Box3,
   * posortowaną wg rozmiarów w osi X i Z.
   *
   * @param box Box3
   * @param others Box3[]
   * @returns Intersection[]
   */
  private getIntersections(box: Box3, others: Box3[]): Intersection[] {
    const boxSize = new Vector3();
    box.getSize(boxSize);

    const intersections: Intersection[] = [];
    for (const checkBox of others) {
      const intersection = this.getTwoBoxesIntersection(box, checkBox);
      if (intersection) {
        intersections.push(intersection);
      }
    }

    intersections.sort((a, b) => {
      if (a.size.x === b.size.x) {
        return a.size.z - b.size.z;
      }
      return a.size.x - b.size.x;
    });
    return intersections;
  }

  /**
   * Sprawdza gdzie będzie najmniejsze przesunięcie ładunku wg ustalonego kierunku przesuwania.
   * Zwykłe sprawdzanie z boxSize / 2 nie sprawdza się gdy kierunek przeskoku jest już ustalony.
   *
   * @param position Vector3
   * @param boxSize Vector3
   * @param intersection Intersection
   * @param collisionDirection Collisions
   * @returns Axis
   */
  private getLeastJumpAxis(
    position: Vector3,
    boxSize: Vector3,
    intersection: Intersection,
    collisionDirection: Collisions
  ): Axis {
    let xJump = intersection.size.x;
    let zJump = intersection.size.z;
    let targetX: number;
    let targetZ: number;
    if (collisionDirection.x === CollisionDirection.lower) {
      targetX = intersection.checkBox.min.x - boxSize.x / 2;
      xJump = Math.abs(position.x - targetX);
    } else if (collisionDirection.x === CollisionDirection.greater) {
      targetX = intersection.checkBox.max.x + boxSize.x / 2;
      xJump = Math.abs(position.x - targetX);
    }
    if (collisionDirection.z === CollisionDirection.lower) {
      targetZ = intersection.checkBox.min.z - boxSize.z / 2;
      zJump = Math.abs(position.z - targetZ);
    } else if (collisionDirection.z === CollisionDirection.greater) {
      targetZ = intersection.checkBox.max.z + boxSize.z / 2;
      zJump = Math.abs(position.z - targetZ);
    }

    let axis: Axis = 'z';
    if (xJump < zJump) {
      axis = 'x';
    }
    return axis;
  }

  /**
   * Zwraca informację o najmniejszym możliwym przeskoku w celu wyeliminowania kolizji.
   * Wykonuje się rekurencyjnie aż do osiągnięcia pozycji bez kolizji.
   *
   * @param box Box3
   * @param startPosition Vector3
   * @param loads Box3[]
   * @param level number
   * @param collisionDirection CollisionDirection
   * @param jumpHistory Vector3[]
   * @returns Jump
   */
  private findNearestFreeSpace(
    box: Box3,
    startPosition: Vector3,
    loads: Box3[],
    level = 0,
    collisionDirection: Collisions = {},
    jumpHistory: Vector3[] = []
  ): Jump {
    const boxSize = box.getSize(new Vector3());
    const intersections = this.getIntersections(box, loads);
    //console.log('intersections', intersections, level);
    let minJumpPosition: Vector3;
    let minJumpDistance: number;
    if (intersections.length === 0) {
      return {
        position: startPosition,
        distance: 0
      };
    }

    for (const intersection of intersections) {
      const localJumpHistory = [...jumpHistory];
      let position = startPosition.clone();

      const axis = this.getLeastJumpAxis(
        position,
        boxSize,
        intersection,
        collisionDirection
      );
      //for (const axis of ['x', 'z']) {
      if (collisionDirection[axis] === undefined) {
        const cmp =
          startPosition[axis] -
          boxSize[axis] / 2 -
          intersection.checkBox.min[axis];
        if (cmp < 0) {
          collisionDirection[axis] = CollisionDirection.lower;
        } else {
          collisionDirection[axis] = CollisionDirection.greater;
        }
      }
      if (collisionDirection[axis] === CollisionDirection.lower) {
        position[axis] = intersection.checkBox.min[axis] - boxSize[axis] / 2;
      } else {
        position[axis] = intersection.checkBox.max[axis] + boxSize[axis] / 2;
      }
      if (localJumpHistory.find((pos) => pos.equals(position))) {
        continue;
      }
      localJumpHistory.push(position.clone());
      //collisionDirection = {};
      let jumpDistance = startPosition.distanceTo(position);

      let nextBox = new Box3().setFromCenterAndSize(position, boxSize);
      let jumpData = this.findNearestFreeSpace(
        nextBox,
        position,
        loads /*.filter((x) => x.uuid !== intersection.uuid)*/,
        level + 1,
        collisionDirection,
        localJumpHistory
      );
      if (jumpData.position) {
        jumpDistance = startPosition.distanceTo(jumpData.position);
        if (minJumpDistance === undefined || jumpDistance < minJumpDistance) {
          minJumpDistance = jumpDistance;
          minJumpPosition = jumpData.position.clone();
        }
      } else {
        // TODO ? tu raczej zwracać undefined, bo nie doszło do wolnej przestrzeni
        if (minJumpDistance === undefined || jumpDistance < minJumpDistance) {
          minJumpDistance = jumpDistance;
          minJumpPosition = position.clone();
        }
      }

      collisionDirection = {};
      //}
    }

    /*if (minJumpPosition) {
      console.log(
        'jump',
        minJumpPosition.x,
        minJumpPosition.z,
        minJumpPosition.y,
        minJumpDistance,
        level
      );
    }*/
    return {
      position: minJumpPosition,
      distance: minJumpDistance
    };
  }

  /**
   * Detekcja kolizji z innymi ładunkami.
   * Najpierw włącza przyciąganie do innych ładunków,
   * następnie sprawdza możliwość piętrowania ładunku,
   * po czym szuka wolnego miejsca na zadanym poziomie.
   * Ustawia docelową pozycję ładunku wg najmniejszego przeskoku.
   *
   * @param loads Load[]
   */
  private collisionDetection(loads: Load[]) {
    const box = new Box3().setFromObject(this.current.mesh.obj);
    const boxSize = new Vector3();
    box.getSize(boxSize);

    console.log(
      'start valid position',
      this.current.mesh.obj.userData.validPosition
    );

    let boxes = loads.map((l) => new Box3().setFromObject(l.mesh.obj));

    const pulled = this.pullToLoads(boxes);
    if (
      pulled &&
      pulled
        .getCenter(new Vector3())
        .distanceToSquared(this.current.mesh.obj.position) !== 0
    ) {
      console.log('pull active', pulled);
      box.copy(pulled);
      const center = pulled.getCenter(new Vector3());
      this.current.mesh.obj.userData.changedX = center.x;
      this.current.mesh.obj.userData.changedZ = center.z;
      this.updateLoadPosition(center);
      //this.current.mesh.obj.position.copy(center);
    }

    let boundaries = this.getFreeSpaceBoundaries();
    boxes = boxes.concat(...boundaries);
    const changeFloor = this.floorSupportedLoad(loads);
    let floorJump: Jump;
    if (changeFloor !== false) {
      //console.log('change floor', changeFloor);
      const jumpBox = box.clone();
      jumpBox.min.y = changeFloor - boxSize.y / 2;
      jumpBox.max.y = changeFloor + boxSize.y / 2;
      //this.updateLoadYPosition(changeFloor);
      // add invalid boundaries for this level
      const extraInvalidBoundaries = this.invalidPositionsBoundaries(
        jumpBox.min.y
      );
      //boundaries = boundaries.concat(...extraInvalidBoundaries);
      //boxes = boxes.concat(...extraInvalidBoundaries);
      //this.drawBoxes(boundaries);
      floorJump = this.findNearestFreeSpace(
        jumpBox,
        jumpBox.getCenter(new Vector3()),
        boxes
      );
      //console.log('floor jump', floorJump);
      //this.current.mesh.obj.position.copy(floorJump.position);
      //this.current.mesh.obj.userData.validPosition = floorJump.position.clone();
    }

    const jump = this.findNearestFreeSpace(
      box,
      this.current.mesh.obj.position.clone(),
      boxes
    );
    //console.log('results', jump, floorJump);
    if (
      changeFloor !== false &&
      floorJump &&
      floorJump.position &&
      jump.position &&
      floorJump.distance < jump.distance
    ) {
      //console.log('change pos floor jump', floorJump.position);
      //floorJump.position.y = changeFloor;
      if (
        this.current.mesh.obj.position.distanceToSquared(floorJump.position) !==
        0
      ) {
        this.current.mesh.obj.userData.changedX = floorJump.position.x;
        this.current.mesh.obj.userData.changedZ = floorJump.position.z;
      }
      this.updateLoadPosition(floorJump.position);
      this.current.mesh.obj.userData.validPosition = floorJump.position.clone();
      this.lowerAsMuchAsPossible(loads);
    } else if (jump.position) {
      //console.log('change pos jump', jump.position);

      if (
        this.current.mesh.obj.position.distanceToSquared(jump.position) !== 0
      ) {
        this.current.mesh.obj.userData.changedX = jump.position.x;
        this.current.mesh.obj.userData.changedZ = jump.position.z;
      }
      this.updateLoadPosition(jump.position);
      this.current.mesh.obj.userData.validPosition = jump.position.clone();
      this.lowerAsMuchAsPossible(loads);
    } else {
      /* console.log(
        'back to valid position',
        this.current.mesh.obj.userData.validPosition
      ); */

      if (
        this.current.mesh.obj.position.distanceToSquared(
          this.current.mesh.obj.userData.validPosition
        ) !== 0
      ) {
        this.current.mesh.obj.userData.changedX = jump.position.x;
        this.current.mesh.obj.userData.changedZ = jump.position.z;
      }
      this.updateLoadPosition(this.current.mesh.obj.userData.validPosition);
    }
  }
}
