import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Train } from './train';
import { BufferGeometry, Float32BufferAttribute } from 'three';

export class TrainMesh extends VehicleMesh {
  private train: Train;

  private indices = [];
  private vertices = [];

  private length: number;
  private height: number;
  private width: number;

  public constructor(train: Train) {
    super('train', train);
    this.train = train;
    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getCapacity(): number {
    return this.width * this.length;
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    this.length = 1000;
    this.height = 2000;
    this.width = 1500;
    const x0 = this.length / 2;
    const y0 = this.height / 2;
    const z0 = this.width / 2;
    let groupStart = 0;
    let groupCount = 0;

    // cabin
    this.vertices.push(-x0, -y0, z0); // 4 left bottom front
    this.vertices.push(-x0, y0 - this.vehicle.cabinHeight, z0); // 5 left top front
    this.vertices.push(-x0, y0 - this.vehicle.cabinHeight, -z0); // 6 left top back
    this.vertices.push(-x0, -y0, -z0); // 7 left bottom back
    this.vertices.push(x0, -y0, z0); // 12 cabin front bottom left
    this.vertices.push(x0, -y0, -z0); // 13 cabin back bottom left
    this.vertices.push(x0, y0, -z0); // 14 cabin back top left
    this.vertices.push(x0, y0, z0); // 15 cabin front top left
    this.vertices.push(-x0, y0 - this.vehicle.cabinHeight, z0); // 16 cabin back top left
    this.vertices.push(-x0, y0 - this.vehicle.cabinHeight, -z0); // 17 cabin front top left

    this.indices.push(0, 3, 5, 0, 5, 4); // cabin bottom faces
    this.indices.push(0, 4, 7, 0, 7, 8, 1, 8, 7); // cabin front faces
    this.indices.push(3, 5, 9, 5, 6, 9, 2, 9, 6); // cabin back faces
    this.indices.push(4, 6, 7, 4, 5, 6); // cabin left bottom faces
    this.indices.push(1, 2, 7, 2, 6, 7); // cabin left top faces

    groupStart += groupCount;
    groupCount = 6 * 3 + 9 * 2;
    this.mesh.geometry.addGroup(groupStart, groupCount, 2);

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();

    this.addSpaces();
    this.addAxles();
  }
}
