import { Constants } from '../vehicle/lib/constants';
import { Vector } from '../lib/communication/vector';
import { Box3, Vector3 } from 'three';
import { Load } from '../load/lib/load';
import { Vehicle } from '../vehicle/lib/vehicle';
import { Space } from '../vehicle/space/lib/space';
import { Positioner } from '../raycasting/lib/positioner';
import { ColliderDetector } from './collider-detector';
import { CuboidSpace } from '../vehicle/space/lib/type/cuboid-space';
import { Trailer } from '../vehicle/space/type/trailer/lib/trailer';
import { Box } from '../vehicle/space/type/box/lib/box';

export class MeshPositionerValidator {
  private axisY = new Vector3(0, 1, 0);
  private axisX = new Vector3(1, 0, 0);
  private axisZ = new Vector3(0, 0, 1);

  constructor(
    protected vehicle: Vehicle,
    protected loads: Load[],
    protected currentLoad: Load,
    protected movement: Vector,
    protected positioner: Positioner,
    protected collider: ColliderDetector
  ) {}

  //to także powinno zachodzić po stronie usługi
  public validate() {
    this.fixProtrusion();
    this.fixIntersections();
    this.fixGrounding();
  }

  private fixIntersections() {
    const mesh = this.currentLoad.mesh.obj;
    const loaded = this.loads.map((l) => l.mesh.obj);
    let checkSpaceBounds = true;
    this.currentLoad.mesh.updateObbs();
    const space = this.inVehicle();
    if (!space) {
      this.currentLoad.spaceUuid = null;
      this.currentLoad.loaded = false;
      console.log('validator: fixIntersections, outside of space');
      return;
    }
    this.positioner.setSpace(space);
    const spaceOffset = space.mesh.meshObj.getWorldPosition(new Vector3());
    const collided = this.collider.collided(mesh, loaded);
    const isWithinSpaceBounds = this.positioner.inSpaceBounds(mesh);
    if (collided || (checkSpaceBounds && !isWithinSpaceBounds)) {
      if (collided) {
        console.log('validator: load collision');
      } else {
        console.log('validator: outside space bounds');
      }
      if (mesh.userData.validPosition) {
        mesh.position.copy(mesh.userData.validPosition);
      } else if (mesh.userData.position) {
        mesh.position.copy(mesh.userData.position);
      }
      if (mesh.userData.validRotation) {
        mesh.rotation.copy(mesh.userData.validRotation);
      } else if (mesh.userData.rotation) {
        mesh.rotation.copy(mesh.userData.rotation);
      }
      this.currentLoad.updateLoadRotationFromMesh();
      this.currentLoad.updateLoadPositionFromMesh(spaceOffset);
    }
  }

  /**
   * Sprawdza czy ładunek jest przynajmniej w połowie załadowany na jakiejś przestrzeni.
   * Nie można polegać na samym spaceUuid z Load, bo wtedy nie dałoby się wynieść ładunku poza przestrzeń
   *
   * @returns Space|null
   */
  private inVehicle(): Space {
    const bb = new Box3().setFromObject(this.currentLoad.mesh.obj);
    const bbHalfSize = this.currentLoad.mesh.obj.userData.halfSize;

    for (const space of this.vehicle.spaces) {
      const spaceBB = new Box3().setFromObject(space.mesh.meshObj);
      spaceBB.expandByScalar(1);
      const intersection = spaceBB.intersect(bb);
      const intersectionSize = intersection.getSize(new Vector3());
      if (
        intersectionSize.x >= bbHalfSize.x &&
        intersectionSize.z >= bbHalfSize.z
      ) {
        return space;
      }
    }
    return null;
  }

  private getSpace(uuid: string) {
    return this.vehicle.spaces.find((s) => s.uuid === uuid);
  }
  private createVirtualSpaceForLoad(load: Load) {
    const bb = new Box3().setFromObject(load.mesh.obj);
    const size = bb.getSize(new Vector3());
    const pos = bb.min;
    const space = new Box(
      {
        length: size.x + 100,
        width: size.z + 100,
        height:
          load.mesh.obj.position.y + load.mesh.obj.userData.halfSize.y + 100
      },
      undefined
    );
    space.mesh.meshObj.position.copy(pos);
    space.mesh.meshObj.position.y = 0;
    return space;
  }

  /**
   * Przenosi ładunki maksymalnie w dół
   */
  private fixGrounding() {
    const space = this.inVehicle();
    let offset = new Vector3();
    if (space) {
      this.positioner.setSpace(space);
      space.mesh.meshObj.getWorldPosition(offset);
    } else {
      const virtualSpace = this.createVirtualSpaceForLoad(this.currentLoad);
      this.positioner.setSpace(virtualSpace);
    }
    this.positioner.moveLoadToLowestPossibleDirection(
      this.currentLoad,
      this.loads,
      this.axisY
    );
    this.currentLoad.updateLoadRotationFromMesh();
    this.currentLoad.updateLoadPositionFromMesh(offset);
    let moved = true;
    while (moved) {
      moved = false;
      this.loads
        .sort((a, b) => (a.idx > b.idx ? -1 : 1))
        .forEach((l) => {
          const space = this.getSpace(l.spaceUuid);
          let offset = new Vector3();
          if (space) {
            this.positioner.setSpace(space);
            space.mesh.meshObj.getWorldPosition(offset);
          }
          const movedOther = this.positioner.moveLoadToLowestPossibleDirection(
            l,
            this.loads,
            this.axisY
          );
          l.updateLoadRotationFromMesh();
          l.updateLoadPositionFromMesh(offset);
          if (movedOther) {
            moved = true;
          }
        });
    }
  }

  //validator powinien tez byc osobną klasa jak meshpositioner
  //teoretycznie powinno być zbadane dopiero po opuszczeniu
  private fixProtrusion() {
    //jeżeli występuje protrusion to musi sobie z tym poradzic algorytm w services
    const vehiclePosition = this.vehicle.mesh.mesh.position;
    const load = this.currentLoad;
    const position = load.mesh.position;

    const xMinLoad = position.x - load.cuboidHull.length / 2;
    const xMaxLoad = position.x + load.cuboidHull.length / 2;
    const yMinLoad = position.y - load.cuboidHull.height / 2;
    const yMaxLoad = position.y + load.cuboidHull.height / 2;
    const zMinLoad = position.z - load.cuboidHull.width / 2;
    const zMaxLoad = position.z + load.cuboidHull.width / 2;
    const mesh = this.currentLoad.mesh.obj;
    const loaded = this.loads.map((l) => l.mesh.obj);

    for (const space of this.vehicle.spaces) {
      const spaceWorldPosition = space.mesh.meshObj.getWorldPosition(
        new Vector3()
      );
      const xMinSpace = spaceWorldPosition.x;
      const xMaxSpace = xMinSpace + space.length;
      const yMinSpace = spaceWorldPosition.y;
      const yMaxSpace = spaceWorldPosition.y + space.height;
      const zMinSpace = spaceWorldPosition.z;
      const zMaxSpace = spaceWorldPosition.z + space.width;

      const maxX = Math.min(xMaxLoad, xMaxSpace);
      const minX = Math.max(xMinLoad, xMinSpace);
      const intersectXlength = maxX - minX;

      const maxZ = Math.min(zMaxLoad, zMaxSpace);
      const minZ = Math.max(zMinLoad, zMinSpace);
      const intersectZlength = maxZ - minZ;

      const maxY = Math.min(yMaxLoad, yMaxSpace);
      const minY = Math.max(yMinLoad, yMinSpace);
      const intersectYlength = maxY - minY;

      if (
        intersectXlength > 0 &&
        intersectYlength > 0 &&
        intersectZlength > 0
      ) {
        if (
          Math.round(intersectXlength - load.mesh.obj.userData.halfSize.x) <
            0 ||
          Math.round(intersectZlength - load.mesh.obj.userData.halfSize.z) < 0
        ) {
          console.log(
            'Load moved outside of vehicle',
            intersectXlength,
            intersectZlength,
            load.mesh.obj.userData.size.x,
            load.mesh.obj.userData.size.z
          );

          load.mesh.position.z =
            zMaxSpace + load.mesh.obj.userData.halfSize.z + 500;
          load.mesh.obj.updateMatrix();
          load.mesh.obj.updateMatrixWorld();
          load.mesh.updateObbs();
          let collision = this.collider.collided(mesh, loaded);
          while (collision) {
            console.log('protrusion collision', collision);
            load.mesh.position.z += 500;
            load.mesh.obj.updateMatrix();
            load.mesh.obj.updateMatrixWorld();
            load.mesh.updateObbs();
            collision = this.collider.collided(mesh, loaded);
          }
          load.spaceUuid = undefined;
          load.loaded = false;
          break;
        } else {
          console.log('Load moved inside vehicle');
          load.spaceUuid = space.uuid;
          load.loaded = true;
          break;
        }
      }
    }
    return null;
  }
}
