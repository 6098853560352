<div class="global-actions text-right">
  <div class="upload-file text-success" (click)="importFromFile()">
    <span i18n>Import z pliku</span> <mat-icon>upload_file</mat-icon>
  </div>
  <div class="create-order text-success" (click)="showCreateOrderForm()">
    <span i18n>Dodaj zamówienie</span> <mat-icon>add</mat-icon>
  </div>
</div>
<div class="row date-filters-container">
  <div class="date-filters">
    <app-dates-filter
      (datesChangeEvent)="changeFilters($event)"
      [currentType]="selectedDatesFilter"
      [withWeek]="true"
    >
      <span allText i18n>Wszystkie zamówienia</span>
    </app-dates-filter>
  </div>
  <mat-paginator
    hidePageSize="true"
    [length]="(list$ | async)?.totalOrders"
    [pageIndex]="(list$ | async)?.currentPage"
    [pageSize]="(list$ | async)?.pageSize"
    (page)="onPageChange($event)"
  ></mat-paginator>
</div>
<div>
  <mat-form-field class="search" appearance="fill">
    <mat-label i18n>Wyszukaj zamówienie</mat-label>
    <input type="text" matInput [formControl]="search" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<div class="flex-right">
  <mat-form-field appearance="fill">
    <mat-label i18n>Sortuj: </mat-label>
    <mat-select
      [(ngModel)]="currentSorting"
      (selectionChange)="changeSorting($event)"
    >
      <mat-option *ngFor="let sort of service.sortOptions" [value]="sort.value">
        {{ sort.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [(ngModel)]="showDetails" i18n>Pokaż szczegóły</mat-checkbox>
  <mat-form-field appearance="fill">
    <mat-label i18n>Lokalizacja: </mat-label>
    <mat-select
      [(ngModel)]="currentLocation"
      (selectionChange)="changeLocation($event)"
    >
      <mat-option *ngFor="let loc of service.countries" [value]="loc.value">
        {{ loc.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label i18n>FTL / LTL: </mat-label>
    <mat-select [(ngModel)]="ftl" (selectionChange)="changeFtl($event)">
      <mat-option [value]="-1">--</mat-option>
      <mat-option [value]="1">FTL</mat-option>
      <mat-option [value]="0">LTL</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox
    (change)="changeGrouping($event)"
    [(ngModel)]="groupingEnabled"
    i18n
    >MATCH</mat-checkbox
  >
  <mat-form-field class="distanceField" appearance="fill">
    <mat-label i18n>Dystans</mat-label>
    <input
      type="number"
      min="1"
      matInput
      [(ngModel)]="groupingDistance"
      (change)="changeGroupingDistance()"
    />
    <span matSuffix>km</span>
  </mat-form-field>
</div>

<app-pending-orders-list class="pending-orders"></app-pending-orders-list>

<div class="panel">
  <ng-template
    #rowTemplate
    let-order="order"
    let-group="group"
    let-rowType="rowType"
    let-listType="listType"
  >
    <tr
      [class.head]="listType == 'groups' && rowType == 'head'"
      [class.member]="listType == 'groups' && rowType == 'member'"
      [class.single]="listType == 'groups' && rowType == 'single'"
      [class.nomodel]="!order.HasModel"
    >
      <ng-container *ngIf="listType == 'groups'">
        <td *ngIf="rowType == 'head'" class="head-cell">
          <mat-icon>add</mat-icon>
        </td>
        <td *ngIf="rowType == 'member'" class="member-cell"></td>
        <td *ngIf="rowType == 'single'" class="single-cell"></td>
      </ng-container>
      <td *ngIf="showDetails">{{ order.Reference }}</td>
      <td>
        <span class="img-container">
          <ng-container *ngIf="order.HasImage">
            <a href="{{ order.getImageSharedUrl() }}" target="_blank">
              <img src="/assets/images/square.png" alt="" class="img-mark" />
            </a>
          </ng-container>
          {{ order.Number }}
          <ng-container *ngIf="!order.HasModel">
            <mat-icon title="Brak modelu 3D" i18n-title
              >visibility_off</mat-icon
            >
          </ng-container>
        </span>
      </td>
      <td>{{ order.Ftl ? 'FTL' : 'LTL' }}</td>
      <td *ngIf="showDetails">{{ order.CustAccount }}</td>
      <td>{{ order.DeliveryName }}</td>
      <td>{{ order.DeliveryCity }}</td>
      <td *ngIf="showDetails">{{ order.State }}</td>
      <td>{{ order.ZipCode }}</td>
      <td>{{ order.Country }}</td>
      <td *ngIf="showDetails">{{ order.ItemGrp }}</td>
      <td>{{ order.ItemId }}</td>
      <td>{{ order.ItemName }}</td>
      <td>{{ order.Qty }}</td>
      <td *ngIf="showDetails">{{ order.AvailableStock }}</td>

      <td *ngIf="showDetails">{{ order.DlvMode }}</td>
      <td>{{ order.ShippingDateConfirmed | date : 'dd.MM.YYYY' }}</td>
      <td *ngIf="showDetails">{{ order.Volume }}</td>
      <td *ngIf="showDetails">{{ order.InventRefType }}</td>
      <td *ngIf="showDetails">{{ order.InventRefNo }}</td>
      <td *ngIf="showDetails">{{ order.ProdStatus }}</td>
      <td *ngIf="showDetails">{{ order.LatestSchedDate }}</td>
      <td *ngIf="showDetails">{{ order.ShipmentId }}</td>
      <td *ngIf="showDetails">{{ order.TruckArrival }}</td>
      <td *ngIf="showDetails">{{ order.Note }}</td>
      <td *ngIf="showDetails">{{ order.Site }}</td>
      <td *ngIf="showDetails">{{ order.Pool }}</td>
      <td *ngIf="showDetails">{{ order.ItemGrpId }}</td>
      <td *ngIf="showDetails">{{ order.BuyerGroup }}</td>
      <td *ngIf="showDetails">{{ order.Warehouse }}</td>

      <td>
        <div class="actions">
          <button
            type="button"
            mat-icon-button
            color="primary"
            (click)="copyOrder(order)"
            i18n-matTooltip
            matTooltip="Kopiuj zamówienie"
            [matTooltipPosition]="'left'"
            #copyOrderTooltip="matTooltip"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            color="primary"
            (click)="goToDetails(order)"
            i18n-matTooltip
            matTooltip="Szczegóły zamówienia"
            [matTooltipPosition]="'left'"
            #detailsTooltip="matTooltip"
          >
            <mat-icon>info</mat-icon>
          </button>
          <button
            mat-icon-button
            type="button"
            color="accent"
            (click)="generatePlacement(order)"
            i18n-matTooltip
            matTooltip="Generuj nowe rozmieszczenie"
            [matTooltipPosition]="'left'"
            #generatePlacementTooltip="matTooltip"
          >
            <mat-icon>widgets</mat-icon>
          </button>
          <!--<button
            *ngIf="order.ProjectId"
            mat-icon-button
            type="button"
            color="accent"
            (click)="openProject(order)"
            i18n-matTooltip
            matTooltip="Otwórz rozmieszczenie"
            [matTooltipPosition]="'right'"
            #openPlacementTooltip="matTooltip"
          >
            <mat-icon>preview</mat-icon>
          </button>-->
        </div>
      </td>
      <ng-container *ngIf="listType == 'groups' && rowType == 'head'">
        <td [attr.rowspan]="group.members.length + 1">
          <button
            mat-icon-button
            type="button"
            color="accent"
            (click)="generatePlacementForGroup(group)"
            i18n-matTooltip
            matTooltip="Rozmieść grupę zamówień"
            [matTooltipPosition]="'left'"
            #generatePlacementTooltip="matTooltip"
          >
            <mat-icon>widgets</mat-icon>
          </button>
        </td>
      </ng-container>
    </tr>
  </ng-template>
  <table class="table orders-table" [class.details]="showDetails">
    <thead>
      <tr>
        <th *ngIf="(list$ | async)?.type == 'groups'"></th>
        <th *ngIf="showDetails">Reference</th>
        <th>Number</th>
        <th>FTL / LTL</th>
        <th *ngIf="showDetails">CustAccount/ToWH</th>
        <th>Delivery Name</th>
        <th>Delivery City</th>
        <th *ngIf="showDetails">State</th>
        <th>Zip Code</th>
        <th>Country</th>
        <th *ngIf="showDetails">ItemGrp</th>
        <th>ItemId</th>
        <th>Item name</th>
        <th>Qty</th>
        <th *ngIf="showDetails">AvailableStock</th>
        <th *ngIf="showDetails">DlvMode</th>
        <th>Shipping Date</th>
        <th *ngIf="showDetails">Volume</th>
        <th *ngIf="showDetails">InventRefType</th>
        <th *ngIf="showDetails">InventRefNo</th>
        <th *ngIf="showDetails">ProdStatus</th>
        <th *ngIf="showDetails">LatestSchedDate</th>
        <th *ngIf="showDetails">ShipmentId</th>
        <th *ngIf="showDetails">TruckArrival</th>
        <th *ngIf="showDetails">Note</th>
        <th *ngIf="showDetails">Site</th>
        <th *ngIf="showDetails">Pool</th>
        <th *ngIf="showDetails">ItemGrpId</th>
        <th *ngIf="showDetails">ByerGroup</th>
        <th *ngIf="showDetails">Warehouse</th>
        <th></th>
        <th *ngIf="(list$ | async)?.type == 'groups'"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="(list$ | async)?.type == 'single'">
        <ng-container *ngFor="let order of (list$ | async)?.items">
          <ng-container
            *ngTemplateOutlet="
              rowTemplate;
              context: { order: order, rowType: 'single', listType: 'single' }
            "
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(list$ | async)?.type == 'groups'">
        <ng-container *ngFor="let group of (list$ | async)?.groups">
          <ng-container
            *ngTemplateOutlet="
              rowTemplate;
              context: {
                order: group.head,
                group: group,
                rowType: 'head',
                listType: 'groups'
              }
            "
          ></ng-container>
          <ng-container *ngFor="let order of group.members">
            <ng-container
              *ngTemplateOutlet="
                rowTemplate;
                context: { order: order, rowType: 'member', listType: 'groups' }
              "
            ></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let order of (list$ | async)?.single">
          <ng-container
            *ngTemplateOutlet="
              rowTemplate;
              context: { order: order, rowType: 'single', listType: 'groups' }
            "
          ></ng-container>
        </ng-container>
      </ng-container>
      <tr *ngIf="!(isLoading$ | async) && (list$ | async)?.isEmpty">
        <td colspan="29" class="info" i18n>
          Brak zamówień spełniających kryteria
        </td>
      </tr>
      <tr *ngIf="isLoading$ | async" class="info">
        <td colspan="29" i18n>Ładowanie listy zamówień...</td>
      </tr>
    </tbody>
  </table>
</div>
