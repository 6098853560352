import { Vector } from './vector';
import { Statistics } from '../model/statistics';

import { Profile, ProfileInterface } from '../model/profile';
import { Load } from 'src/app/load/lib/load';
import { OrbitControlsState } from '../model/orbit-controls-state';
import { LoadGroupList } from '../model/load-group/load-group-list';
import { LoadGroupCondensed } from '../model/load-group/load-group-condended';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { VehicleFactory } from 'src/app/vehicle/lib/vehicle-factory';
import { Settings } from 'src/app/vehicle/form/settings/settings';

export class PositionerResponse {
  uuid: string;
  historyUuid: string;
  message: string;

  loads: Load[]; //nigdzie nie załadowane ładunki
  notLoadedLoads: Load[]; // niezaładowane, ale nie do wyświetlania - powrót na listę załadunkową

  existingLoads: Load[]; //should not be used
  addedLoads: Load[]; //should not be used
  vehicle: Vehicle;
  matrix: Vector[];
  statistics: Statistics[];
  messages: string[];
  suggestedVehicles: Vehicle[];

  timestamp: number;
  current: boolean;
  createdAt: number;
  initialTimestamp: number;
  profile?: ProfileInterface;
  orbitControlsState: OrbitControlsState;
  projectId: string;
  settings?: Settings;

  backgroundJobId?: string;
  alternatives: PositionerResponse[] = [];

  get allLoads(): Load[] {
    return this.vehicle.enabledSpaces
      .map((space) => space.loads)
      .flat()
      .concat(this.loads);
  }

  get loadsCondensed(): LoadGroupList {
    const list = new LoadGroupList(LoadGroupCondensed);
    this.allLoads.forEach((load) => {
      list.addLoad(load);
    });
    return list;
  }
  constructor(
    obj: any,
    vehicleFactory: VehicleFactory,
    loadFactory: LoadFactory
  ) {
    Object.assign(this, obj);

    if (this.vehicle != null && !(this.vehicle instanceof Vehicle)) {
      this.vehicle = vehicleFactory.recreate(this.vehicle);
    }
    this.statistics = (this.statistics ?? []).map((s) => new Statistics(s));
    if (this.profile != null && !(this.profile instanceof Profile)) {
      this.profile = new Profile(this.profile);
      this.profile.recreate(loadFactory, vehicleFactory);
    }
    this.addedLoads = (this.addedLoads ?? []).map((l) =>
      loadFactory.recreateLoad(l)
    );
    this.existingLoads = (this.existingLoads ?? []).map((l) =>
      loadFactory.recreateLoad(l)
    );
    this.notLoadedLoads = (this.notLoadedLoads ?? []).map((l) =>
      loadFactory.recreateLoad(l)
    );
    this.settings = new Settings(this.settings ?? {});

    this.alternatives = (this.alternatives || []).map(
      (a) => new PositionerResponse(a, vehicleFactory, loadFactory)
    );
  }

  public getLoadedLoads(): Load[] {
    return this.vehicle.spaces.map((space) => space.loads).flat();
  }
}
