export class Position {
  offsetX: number;
  offsetY: number;
  offsetZ: number;
  x: number;
  y: number;
  z: number;

  constructor(obj?: any) {
    this.offsetX = 0;
    this.offsetY = 0;
    this.offsetZ = 0;
    Object.assign(this, obj);
  }

  clone() {
    return new Position(this);
  }

  copy(a: Position) {
    Object.assign(this, a);
  }
}
