import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  debounceTime,
  delay,
  distinctUntilChanged,
  iif,
  merge,
  of,
  startWith,
  switchMap,
  take
} from 'rxjs';
import { ScaledImage } from '../lib/model/scaled-image';
import { environment } from 'src/environments/environment';
import { Profile, ProfileInterface } from '../lib/model/profile';
import { ProfileService } from './profile.service';

export enum UiMode {
  STANDARD = 'standard',
  ADVANCED = 'advanced'
}

export enum ProjectListMode {
  PROJECTS = 'projects',
  ORDERS = 'orders',
  OFFICIAL_ORDERS = 'official-orders',
  REPORTS = 'reports'
}

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public uiMode = new BehaviorSubject<UiMode>(UiMode.ADVANCED);
  public lengthUnit = new BehaviorSubject<string>('cm');
  public weightUnit = new BehaviorSubject<string>('kg');
  public labelFontModifier = new BehaviorSubject<string>('0px');

  public loadMultiSelectEnabled = true;

  private projectListMode = new BehaviorSubject<ProjectListMode>(
    ProjectListMode.PROJECTS
  );

  public constructor(private profileService: ProfileService) {}

  public get massDistributionEnabled(): boolean {
    return !(environment.disableMassDistribution || false);
  }

  public get warehousesEnabled(): boolean {
    return !(environment.disableWarehouses || false);
  }

  public get palletsAsLoadEnabled(): boolean {
    return !(environment.disablePalletsAsLoad || false);
  }

  private lengthOutputConverters = {
    mm: 1,
    cm: 0.1,
    m: 0.001,
    in: 1 / 25.3995,
    ft: 1 / 304.8
  };

  private weightOutputConverters = {
    g: 1,
    kg: 0.001,
    t: 0.00001,
    lb: 1 / 453.59237,
    ts: 1 / 1016047,
    ta: 1 / 907184.74
  };

  private loaderVisible$ = new ReplaySubject<boolean>(1);

  private delayedLoader = this.loaderVisible$.pipe(
    startWith(false),
    switchMap((loading) =>
      iif(() => loading, of(true).pipe(delay(1000)), of(false))
    )
  );

  private immediateLoader = new ReplaySubject<boolean>(1);

  private immediateLoader$ = this.immediateLoader.pipe(startWith(false));

  setUiMode(mode: UiMode) {
    this.uiMode.next(mode);
  }

  getUiMode(): Observable<UiMode> {
    return this.uiMode.asObservable();
  }

  getLengthUnit(): Observable<string> {
    return this.lengthUnit.asObservable();
  }

  setLengthUnit(unit: string) {
    this.lengthUnit.next(unit);
  }

  getWeightUnit(): Observable<string> {
    return this.weightUnit.asObservable();
  }

  getCurrentWeightUnit(): string {
    return this.weightUnit.getValue();
  }

  getCurrentLengthUnit(): string {
    return this.lengthUnit.getValue();
  }

  setWeightUnit(unit: string) {
    this.weightUnit.next(unit);
  }

  setLabelFontModifier(val: number) {
    this.labelFontModifier.next(val + 'px');
  }

  getLabelFontModifier(): Observable<string> {
    return this.labelFontModifier.asObservable();
  }

  getCurrentLabelFontModifierAsNumber(): number {
    return parseInt(
      (this.labelFontModifier.getValue() + '').replace('px', ''),
      10
    );
  }

  getOutputLengthConverter(toUnit: string) {
    if (typeof this.lengthOutputConverters[toUnit] === 'undefined') {
      throw new Error(`Unit ${toUnit} is not supported`);
    }
    return this.lengthOutputConverters[toUnit];
  }

  getOutputWeightConverter(toUnit: string) {
    if (typeof this.weightOutputConverters[toUnit] === 'undefined') {
      throw new Error(`Unit ${toUnit} is not supported`);
    }
    return this.weightOutputConverters[toUnit];
  }

  getLengthInCurrentUnit(mmValue: number, roundTo = 3) {
    const unit = this.getCurrentLengthUnit();
    return this.convertLengthToUnit(mmValue, unit, roundTo);
  }

  getLengthInDefaultUnit(value: number) {
    if (value === undefined) {
      return value;
    }
    const unit = this.getCurrentLengthUnit();
    const converter = 1 / this.getOutputLengthConverter(unit);
    return this.roundTo(value * converter, 2);
  }

  convertLengthToDefaultUnit(value: number, fromUnit: string) {
    const converter = 1 / this.getOutputLengthConverter(fromUnit);
    return this.roundTo(value * converter, 2);
  }

  convertLengthToUnit(mmValue: number, unit: string, roundTo = 3) {
    return this.roundTo(mmValue * this.getOutputLengthConverter(unit), roundTo);
  }

  getWeightInCurrentUnit(gValue: number, roundTo = 2) {
    const unit = this.getCurrentWeightUnit();
    return this.convertWeightToUnit(gValue, unit, roundTo);
  }

  getWeightInDefaultUnit(value: number) {
    const unit = this.getCurrentWeightUnit();
    const converter = 1 / this.getOutputWeightConverter(unit);
    return this.roundTo(value * converter, 2);
  }

  convertWeightToUnit(gValue: number, unit: string, roundTo = 2) {
    return this.roundTo(
      (gValue || 0) * this.getOutputWeightConverter(unit),
      roundTo
    );
  }

  scaleImage(
    source: string,
    width: number,
    height: number
  ): Observable<ScaledImage> {
    const result$ = new Subject<ScaledImage>();

    const img = new Image();
    img.src = source;
    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = width;
      elem.height = height;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      const data = ctx.canvas.toDataURL();
      result$.next(new ScaledImage(data, null));
    };
    img.onerror = (error: string) => result$.next(new ScaledImage('', error));
    return result$.pipe(take(1));
  }

  setLoading(val: boolean) {
    console.log('Show loading screen', val);
    this.loaderVisible$.next(val);
    if (!val) {
      this.immediateLoader.next(val);
    }
  }

  setLoadingNow(val: boolean) {
    /*if (val) {
      window.app.showLoader();
    } else {
      window.app.hideLoader();
    }*/
    console.log('Show loading screen now', val);
    this.immediateLoader.next(val);
    this.loaderVisible$.next(val);
    /*if (!val) {
      this.loaderVisible$.next(val);
    }*/
  }

  get showLoader$(): Observable<boolean> {
    return merge(this.delayedLoader, this.immediateLoader$).pipe(
      distinctUntilChanged()
    );
    //debounceTime(5)
  }

  private roundTo(num: number, precision: number) {
    const multiplier = Math.pow(10, precision);
    return Math.round(num * multiplier + Number.EPSILON) / multiplier;
  }

  public get projectListMode$() {
    return this.projectListMode.asObservable();
  }

  public setProjectListMode(mode: ProjectListMode) {
    this.projectListMode.next(mode);
  }

  public getUserMainRole(user: ProfileInterface) {
    if (user?.hasRole('ROLE_ADMIN')) {
      return $localize`Administrator`;
    }
    if (user?.hasRole('ROLE_MA')) {
      return $localize`Manager`;
    }
    if (user?.hasRole('ROLE_ZA')) {
      return $localize`Zarząd`;
    }
    if (user?.hasRole('ROLE_DE')) {
      return $localize`Dealer`;
    }
    if (user?.hasRole('ROLE_HA')) {
      return $localize`Handlowiec`;
    }
    if (user?.hasRole('ROLE_DY')) {
      return $localize`Dystrybucja`;
    }
    return $localize`Użytkownik`;
  }

  public userSeesProjects(user: ProfileInterface) {
    return !user?.hasRole('ROLE_DE');
  }

  public userSeesDealersPanel(user: ProfileInterface) {
    return user?.hasRole('ROLE_DE');
  }

  public userSeesOrders(user: ProfileInterface) {
    return !user?.hasRole('ROLE_DE');
  }

  public userSeesReports(user: ProfileInterface) {
    return (
      user?.hasRole('ROLE_ZA') ||
      user?.hasRole('ROLE_MA') ||
      user?.hasRole('ROLE_ADMIN')
    );
  }

  public userSeesOrderTemplates(user?: ProfileInterface) {
    return (user || this.profileService.currentProfile)?.hasRole('ROLE_ADMIN');
  }
}
