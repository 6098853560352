import {
  Box3,
  Box3Helper,
  Color,
  EdgesGeometry,
  Euler,
  LineBasicMaterial,
  LineBasicMaterialParameters,
  LineSegments,
  Mesh,
  Object3D,
  Vector3
} from 'three';
import { LoadDisplaySettings } from './load-display-settings';
import { loadMaterial } from '../../lib/helper/load-material';
import { ModelLoaderService } from 'src/app/raycasting/lib/model-loader.service';
import { OBB } from 'src/app/lib/vendor/three/OBB';

export abstract class LoadMesh {
  public type: string;

  // protected forcedRotation = false;
  protected description: string;
  protected selected = false;
  public obj: Mesh;

  private rotationX: number;
  private rotationY: number;
  private rotationZ: number;

  private floorable: boolean;
  public modelLoaded = false;

  constructor(
    type: string,
    protected color: number,
    public readonly settings: LoadDisplaySettings,
    uuid: string,
    protected modelLoader?: ModelLoaderService
  ) {
    this.obj = new Mesh();
    this.obj.userData.uuid = uuid;
    // this.rotationX = obj.rotationX;
    // this.rotationY = obj.rotationY;
    // this.rotationZ = obj.rotationZ;
    this.type = type;
    // console.log('color constructor', color);
    this.obj.material = loadMaterial(color, settings);
    this.obj.name = 'load';
    //this.obj.userData.uuid = load.

    /*this.material.depthTest = true;
    this.material.depthWrite = true;

    this.renderOrder = 1;

    this.onBeforeRender = (renderer) => {
      renderer.clearDepth();
    };*/
  }

  get position(): Vector3 {
    return this.obj.position;
  }

  get rotation(): Euler {
    return this.obj.rotation;
  }

  public getWorldPosition(target: Vector3) {
    return this.obj.getWorldPosition(target);
  }

  public abstract getName(): string;

  public getDescription(): string {
    return this.description;
  }

  public setDescription(value: string) {
    this.description = value;
  }

  public setFloorable(value: boolean) {
    this.floorable = value;
  }
  public getFloorable(): boolean {
    return this.floorable;
  }

  // public getForcedRotation(): boolean {
  // return this.forcedRotation;
  // }
  // public setForcedRotation(value: boolean) {
  // this.forcedRotation = value;
  // }

  public getType() {
    return this.type;
  }

  // public getMesh(): THREE.Mesh {
  // return this.mesh;
  // }
  //public get mesh: THREE.Mesh{
  // return this.mesh;
  // }

  public getMaterial() {
    return this.obj.material;
  }

  protected getBorderColor(): string | number | Color {
    return this.selected ? Color.NAMES.firebrick : Color.NAMES.black;
  }

  protected getLineSettings(): LineBasicMaterialParameters {
    const settings = {
      color: this.getBorderColor(),
      transparent: this.settings.loadBordersIntensity < 10,
      opacity: this.settings.loadBordersIntensity / 10,
      linewidth: 2 // i tak w webgl linie są rysowane w grubości 1
    };
    return settings;
  }

  protected prepareMesh() {
    // this.mesh = new THREE.Mesh(this.object, super.getMaterial());
    //const wireframe = new THREE.WireframeGeometry(this.mesh.geometry);
    const wireframe = new EdgesGeometry(this.obj.geometry);
    const mat = new LineBasicMaterial(this.getLineSettings());
    const line = new LineSegments(wireframe, mat);
    line.name = 'border';
    this.obj.add(line);
  }

  protected createBorderMaterial() {
    return new LineBasicMaterial(this.getLineSettings());
  }

  protected getBorders() {
    return this.obj.children.find((x) => x.name === 'border') as LineSegments;
  }

  public move(vector: Vector3) {
    console.log('move called');
    this.updatePosition(this.position.clone().add(vector));
  }

  public rotate(vector: Vector3, angle: number) {
    console.log('move called');
    this.updateRotation(vector, angle);
  }

  public select(value = true) {
    this.selected = value;
    //console.log('load-mesh.ts select');
    const border = this.getBorders();
    if (border) {
      border.material = this.createBorderMaterial();
    }
    if (value && !this.obj.userData['selected']) {
      this.obj.userData['originalColor'] = (
        this.obj.material as any
      ).color.getHex();
    }
    this.obj.userData['selected'] = value;
    //console.log("this.obj.userData['selected']", this.obj.userData['selected']);

    this.obj.children.forEach((child) => {
      if (child.name === 'load' || child.name == 'load-group') {
        child.children.forEach((item) => {
          //za duzo zaglebien .....
          if (item.children.length > 1) {
            item.children.forEach((i) => {
              //console.log('i.name', i);
              if (i instanceof Mesh) {
                i.material.color.set(
                  value ? 0xff0000 : this.obj.userData['originalColor']
                );
                (i.material as any).emissive.set(value ? 0x111111 : 0x0);
              }

              /*              (this.obj.material as any).color.set(
                value ? 0xff0000 : this.obj.userData['originalColor']
              );
              (this.obj.material as any).emissive.set(value ? 0x111111 : 0x0);*/
            });
          }
        });
      }
    });

    /*(this.obj.material as any).color.set(
      value ? 0xff0000 : this.obj.userData['originalColor']
    );
    (this.obj.material as any).emissive.set(value ? 0x111111 : 0x0);*/
  }

  public updatePosition(position: Vector3) {
    const mesh = this.obj;
    mesh.position.copy(position.clone());
    mesh.updateMatrix();
    mesh.updateMatrixWorld();
    mesh.traverse((child: Object3D) => {
      if (
        !(child instanceof Mesh) ||
        !child.userData.obb ||
        !child.geometry.userData.obb
      ) {
        return;
      }
      child.userData.obb.copy(child.geometry.userData.obb);
      child.userData.obb.applyMatrix4(child.matrixWorld);
    });
  }

  // FIXME - jeśli najpierw zrobimy obrót, a potem usuniemy części, to to się rozjeżdża
  public recenter(newCenter: Vector3) {
    const mesh = this.obj;
    const diff = new Vector3().subVectors(newCenter, mesh.position);
    //console.error('recenter', diff);
    //mesh.position.copy(newCenter.clone());
    //mesh.updateMatrix();
    //mesh.updateMatrixWorld();
    mesh.traverse((child: Object3D) => {
      if (!child.name.startsWith('comp-')) {
        return;
      }
      const childPos = new Vector3().subVectors(
        child.position.clone(),
        diff.clone()
      );
      child.position.copy(childPos);
      //console.log('recenter', child.name);
      if (
        !(child instanceof Mesh) ||
        !child.userData.obb ||
        !child.geometry.userData.obb
      ) {
        return;
      }
      if (!child.geometry.userData.originalOBB) {
        child.geometry.userData.originalOBB =
          child.geometry.userData.obb.clone();
      }
      const obb = new OBB();
      obb.copy(child.geometry.userData.originalOBB);
      //obb.applyMatrix4(child.matrixWorld);
      child.geometry.userData.obb = obb;
    });
  }

  public updateRotation(vector: Vector3, step: number) {
    const mesh = this.obj;
    mesh.rotateOnAxis(vector, step);
    mesh.updateMatrix();
    mesh.updateMatrixWorld();
    const box = this.computeBoundingBox();
    const center = box.getCenter(new Vector3());
    this.recenter(center);
    this.updateObbs();
  }

  public updateObbs() {
    const bb = new Box3();
    this.obj.traverse((child: Object3D) => {
      if (
        !(child instanceof Mesh) ||
        !child.userData.obb ||
        !child.geometry.userData.obb
      ) {
        return;
      }
      if (!child.geometry.userData.originalOBB) {
        child.geometry.userData.originalOBB =
          child.geometry.userData.obb.clone();
      }
      child.userData.obb.copy(child.geometry.userData.obb);
      child.userData.obb.applyMatrix4(child.matrixWorld);
      //bb.expandByObject(child, true);
      bb.union(child.userData.obb.getAabb());
    });
    const size = bb.getSize(new Vector3());
    this.obj.userData.size = size;
    this.obj.userData.halfSize = size.clone().multiplyScalar(0.5);
  }

  public computeBoundingBox(expanded = false) {
    const bb = new Box3();
    this.obj.traverse((child: Object3D) => {
      if (
        !(child instanceof Mesh) ||
        !child.userData.obb ||
        !child.geometry.userData.obb
      ) {
        return;
      }
      //bb.expandByObject(child, true);
      bb.union(child.userData.obb.getAabb());
      if (expanded && child.userData.obbExp) {
        bb.union(child.userData.obbExp.getAabb());
      }
    });
    const size = bb.getSize(new Vector3());
    if (expanded) {
      this.obj.userData.sizeExp = size;
      this.obj.userData.halfSizeExp = size.clone().multiplyScalar(0.5);
    } else {
      this.obj.userData.size = size;
      this.obj.userData.halfSize = size.clone().multiplyScalar(0.5);
    }
    //console.error('new bounding box', size);
    return bb;
  }

  public applyEulerRotation(euler: Euler) {
    const mesh = this.obj;
    mesh.rotation.copy(euler);
    mesh.updateMatrix();
    mesh.updateMatrixWorld(true);
    const box = this.computeBoundingBox();
    const center = box.getCenter(new Vector3());
    //this.recenter(center); // FIXME - to psuje pozycję OBB
    this.updateObbs();
  }

  public removeComponents(names: string[]) {
    const toRemove = [];
    this.obj.traverse((child) => {
      if (names.includes('shaft') && child.name.includes('shaft')) {
        toRemove.push(child);
      }
      if (
        names.includes('wheels') &&
        (child.name.includes('tire') ||
          child.name.includes('fender') ||
          child.name.includes('mudguard') ||
          (child.name.includes('wheel') &&
            !child.name.includes('jockey') &&
            !child.name.includes('mount') &&
            !child.name.includes('hub')))
      ) {
        toRemove.push(child);
      }
      if (names.includes('jockeywheel') && child.name.includes('jockey')) {
        toRemove.push(child);
      }
      if (
        names.includes('fenders') &&
        (child.name.includes('fender') || child.name.includes('mudguard'))
      ) {
        toRemove.push(child);
      }

      if (names.includes('ramp') && child.name.includes('ramp')) {
        toRemove.push(child);
      }
      if (names.includes('lights') && child.name.includes('light')) {
        toRemove.push(child);
      }
    });
    for (const child of toRemove) {
      child.parent?.remove(child);
    }
    if (toRemove.length > 0) {
      this.obj.updateMatrix();
      this.obj.updateMatrixWorld();
      const box = this.computeBoundingBox();
      const center = box.getCenter(new Vector3());
      //this.recenter(center);
      this.updateObbs();
      //this.updatePosition(center);
    }
  }

  public hover() {
    (this.obj.material as any).emissive.set(0x333333);

    //workaround TODO
    // pierwsze children to jest load a dopiero drugie to sa dzieci
    this.obj.children.forEach((x) => {
      if (x.name === 'load') {
        x.children.forEach((y) => {
          console.log('hovering child', y.name);
          if (y instanceof Mesh) {
            (y.material as any).emissive.set(0x333333);
          }
        });
      }
    });
  }

  public unhover() {
    (this.obj.material as any).emissive.set(
      this.obj.userData['selected'] ? 0x111111 : 0x0
    );
  }

  // protected setMesh(mesh: THREE.Mesh) {
  // this.mesh = mesh;
  // }

  /*public computePosition(xIdx, zIdx, yIdx, minX, minZ, minY) {
    this.getMesh().geometry.computeBoundingBox();
    this.getMesh().position.x = xIdx + minX + this.getXLength() / 2;
    this.getMesh().position.z = zIdx + minZ + this.getZLength() / 2;
    this.getMesh().position.y =
      yIdx +
      minY +
      (this.getMesh().geometry.boundingBox.max.y -
        this.getMesh().geometry.boundingBox.min.y) /
        2; //wysokością na razie się nie zajmujemy
  }*/

  /**
   * Update the velocity, position and orientation for a
   * given timestep.
   *
   * NOTE: this is extremely hot code (4000 arrows: ~240k
   * calls/second). No allocations and preventable calculations
   * here.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public update(dt) {
    // console.log('UPDATE DT', dt);
    // update velocity from 'gravity' towards origin
    /*v3.copy(this.position)
      .multiplyScalar(-Math.PI / this.position.lengthSq());
    this.velocity.add(v3);

    // update position from velocity
    v3.copy(this.velocity).multiplyScalar(dt);
    this.position.add(v3);

    // update rotation from direction of velocity
    v3.copy(this.velocity).normalize();
    this.rotation.setFromUnitVectors(ARROW_FORWARD, v3);

    // write udpated values into attribute-buffers
    this.position.toArray(
        this.buffers.position, this.offsets.position);
    this.rotation.toArray(
        this.buffers.rotation, this.offsets.rotation);*/
  }

  // setRotation() {
  // this.object.rotateX(MathUtils.degToRad(this.rotationX));
  // this.object.rotateY(MathUtils.degToRad(this.rotationY));
  // this.object.rotateZ(MathUtils.degToRad(this.rotationZ));
  // // console.log('setRotation', this.mesh.position);

  // const pivot = new THREE.Group();
  // pivot.position.set(0.0, 0.0, 0);
  // this.mesh.add(pivot);

  // const pivot = new THREE.Object3D();
  // pivot.add(this.mesh);

  // this.mesh.position.set(1000, 0, 0);

  // pivot.setRotationFromEuler(
  // new Euler(
  // MathUtils.degToRad(this.rotationX),
  // MathUtils.degToRad(this.rotationY),
  // /        MathUtils.degToRad(this.rotationZ),
  // 'XYZ'
  // /  )
  // );
  // pivot.rotateX()
  // pivot.add(mesh2);
  // const position = this.mesh.position.clone();
  // this.mesh.position.sub(position);
  // this.mesh.updateMatrix();
  // this.object.translate(0, 0, 0);
  // this.object.center();
  /*this.mesh.setRotationFromEuler(
      new Euler(
        MathUtils.degToRad(this.rotationX),
        MathUtils.degToRad(this.rotationY),
        MathUtils.degToRad(this.rotationZ),
        'XYZ'
      )
    );*/
  // let position = new THREE.Vector3();
  // this.mesh.getWorldPosition(position);
  // // console.log('world position', position);

  // this.mesh.position.sub(position);
  // this.mesh.position.sub(this.mesh.position);
  // this.mesh.rotation.set(
  // MathUtils.degToRad(this.rotationX),
  // MathUtils.degToRad(this.rotationY),
  // MathUtils.degToRad(this.rotationZ),
  // 'XYZ'
  // );

  // this.object.applyMatrix4
  // this.mesh.applyQuaternion(new THREE.Quaternion())

  // var box = new THREE.Box3().setFromObject(this.mesh);
  // box.getCenter(this.mesh.position); // this re-sets the mesh position
  // this.mesh.position.multiplyScalar(-1);

  // const quaternion = new THREE.Quaternion();
  // quaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);

  // this.mesh.applyQuaternion(quaternion);
  // const vector = new THREE.Vector3(1, 0, 0);
  // vector.applyQuaternion(quaternion);

  // this.mesh.position.add(position);
  // }
}
