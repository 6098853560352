<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'load-form'" [ngTemplateOutlet]="loadForm">
  </ng-container>
  <ng-container *ngSwitchCase="'modal'" [ngTemplateOutlet]="modal">
  </ng-container>
  <ng-container *ngSwitchDefault>
    ng-template *ngSwitchDefault does not exist
  </ng-container>
</ng-container>

<ng-template #formTemplate>
  <div *ngIf="form?.Spaces.length === 0" class="text-primary text-center">
    <ng-container i18n
      >Aby określić wysokość piętrowania najpierw wybierz przestrzeń, a potem
      ładunki</ng-container
    >
  </div>
  <div *ngFor="let spaceForm of form?.Spaces; let idx = index">
    <div [formGroup]="spaceForm">
      <h3 *ngIf="form?.Spaces.length > 1">
        <ng-container i18n>Naczepa</ng-container> {{ idx + 1 }}
      </h3>

      <div class="row">
        <mat-slide-toggle formControlName="maxHeightEnabled" i18n
          >Maksymalna wysokość</mat-slide-toggle
        >

        <mat-form-field>
          <input
            matInput
            appOnlyNumber
            formControlName="height"
            autocomplete="off"
            placeholder="{{ uiService.getLengthUnit() | async }}"
            name="height"
          />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-slide-toggle formControlName="maxRowsCntEnabled" i18n
          >Maksymalna liczba pięter</mat-slide-toggle
        >
        <mat-form-field>
          <input
            matInput
            appOnlyNumber
            formControlName="rowsCnt"
            autocomplete="off"
            i18n-placeholder
            placeholder="liczba"
            name="rowsCnt"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modal>
  <ng-template [ngTemplateOutlet]="formTemplate"></ng-template>
</ng-template>
<ng-template #loadForm>
  <mat-expansion-panel (opened)="opened = true" (closed)="opened = false">
    <mat-expansion-panel-header>
      <mat-panel-title i18n>Wysokość piętrowania</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template [ngTemplateOutlet]="formTemplate"></ng-template>
  </mat-expansion-panel>
</ng-template>
