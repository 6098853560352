import { Load } from '../../../lib/load';
import { BrenderupMesh } from './brenderup-mesh';
import { Vector } from '../../../../lib/communication/vector';
import { CuboidHull } from '../../../lib/cuboid-hull';
import { LoadDisplaySettings } from '../../../lib/load-display-settings';
import { LoadMesh } from '../../../lib/load-mesh';
import { ModelLoaderService } from 'src/app/raycasting/lib/model-loader.service';

export class CarTrailerType1 extends Load {
  shape = 'car-trailer-1';
  components: Vector[];
  width: number;
  length: number;
  height: number;
  displayWidth: number;
  displayHeight: number;
  displayLength: number;

  public constructor(obj: any, public settings: LoadDisplaySettings) {
    super(obj, settings);
    this.components = (obj.components || []).map(
      (v: Partial<Vector>) => new Vector(v)
    );
    this.mesh = obj?.mesh;
  }

  get cuboidHull(): CuboidHull {
    if (this.mesh instanceof BrenderupMesh) {
      return new CuboidHull({
        length: this.mesh.getMaxX(),
        height: this.mesh.getMaxY(),
        width: this.mesh.getMaxZ()
      });
    }
    const minX = Math.min(...this.components.map((v) => v.x));
    const maxX = Math.max(...this.components.map((v) => v.front));
    const minY = Math.min(...this.components.map((v) => v.y));
    const maxY = Math.max(...this.components.map((v) => v.top));
    const minZ = Math.min(...this.components.map((v) => v.z));
    const maxZ = Math.max(...this.components.map((v) => v.zEnd));
    const width = maxZ - minZ;
    const height = maxY - minY;
    const length = maxX - minX;
    return new CuboidHull({
      width,
      length,
      height
    });
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get volume(): number {
    //return this.components.reduce((prev, current) => prev + current.volume, 0);
    return (this.mesh as BrenderupMesh)?.calculateModelVolume() || 0;
  }
  get area(): number {
    return this.components.reduce((prev, current) => prev + current.area, 0);
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }

  get descriptiveDimensions(): number[] {
    return [this.displayLength, this.displayWidth, this.displayHeight];
  }

  async createMesh(
    settings: LoadDisplaySettings,
    modelLoader: ModelLoaderService
  ): Promise<LoadMesh> {
    if (this.mesh) {
      return this.mesh;
    }
    //console.error('create mesh', this.modelName);
    this.mesh = new BrenderupMesh(this, settings, modelLoader);
    try {
      await (this.mesh as BrenderupMesh).loadModel();
    } catch (error) {
      console.error(`Model ${this.name} not loaded`);
    }
    this.removeDisassembledComponents();
    (this.mesh as BrenderupMesh).updateColor(this.color);
    this.mesh.obj.userData.idx = this.idx;
    /*const geometry = new BoxGeometry(this.length, this.height, this.width);
    const material = new MeshBasicMaterial({ color: 0xff0000 });
    const wireframe = new WireframeGeometry(geometry);

    const line = new LineSegments(wireframe, material);

    this.mesh.obj.add(line);*/

    return this.mesh;
  }

  get isEmpty(): boolean {
    return false;
  }
}
