import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';

export class EPALCP8Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = 100;
    const plankW2 = 80;
    const plankW3 = 120;
    const plankH = 18;
    const plankH2 = 22;
    const plankL = this.length;

    let currentY = 0;
    const r = 14;

    const allRounded = [r, r, r, r] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    //bottom planks
    const bottomPlankCutoff = 100;
    this.plank(
      new Vector3(x0, currentY, z0),
      plankL,
      plankW,
      plankH,
      1,
      ...backExternalRadiuses
    );
    this.plank(new Vector3(x0, currentY, z0 + 320), plankL, plankW, plankH, 1);
    this.plank(
      new Vector3(x0, currentY, z1 - 320 - plankW),
      plankL,
      plankW,
      plankH,
      1
    );
    this.plank(
      new Vector3(x0, currentY, z1 - plankW),
      plankL,
      plankW,
      plankH,
      1,
      ...frontExternalRadiuses
    );

    const bottomSmallPlanksOffset = 20;
    this.plank(
      new Vector3(x0 + bottomSmallPlanksOffset, currentY, z0 + plankW),
      plankW,
      220,
      plankH,
      1
    );
    this.plank(
      new Vector3(x0 + bottomSmallPlanksOffset, currentY, z0 + 320 + plankW),
      plankW,
      300,
      plankH,
      1
    );
    this.plank(
      new Vector3(x0 + bottomSmallPlanksOffset, currentY, z1 - plankW - 220),
      plankW,
      220,
      plankH,
      1
    );

    this.plank(
      new Vector3(x1 - plankW - bottomSmallPlanksOffset, currentY, z0 + plankW),
      plankW,
      220,
      plankH,
      1
    );
    this.plank(
      new Vector3(
        x1 - plankW - bottomSmallPlanksOffset,
        currentY,
        z0 + 320 + plankW
      ),
      plankW,
      300,
      plankH,
      1
    );
    this.plank(
      new Vector3(
        x1 - plankW - bottomSmallPlanksOffset,
        currentY,
        z1 - plankW - 220
      ),
      plankW,
      220,
      plankH,
      1
    );

    //after bottom planks
    currentY += plankH;

    this.plank(
      new Vector3(x0 + bottomSmallPlanksOffset, currentY, z0),
      plankW,
      this.width,
      plankH,
      1
    );
    this.plank(
      new Vector3(xc - plankW / 2, currentY, z0),
      plankW,
      420,
      plankH,
      1
    );
    this.plank(
      new Vector3(xc - plankW / 2, currentY, z1 - 420),
      plankW,
      420,
      plankH,
      1
    );
    this.plank(
      new Vector3(x1 - plankW - bottomSmallPlanksOffset, currentY, z0),
      plankW,
      this.width,
      plankH,
      1
    );

    //boxes
    currentY += plankH;
    const boxH = 80;
    const boxOffset = 20;
    const boxSize = [plankW, plankW2, boxH] as const;
    const leftBoxes = [
      new Vector3(x0 + boxOffset, currentY, z0 + boxOffset),
      new Vector3(x0 + boxOffset, currentY, z0 + boxOffset + 320),
      new Vector3(x0 + boxOffset, currentY, z1 - plankW2 - 320 - boxOffset),
      new Vector3(x0 + boxOffset, currentY, z1 - plankW2 - boxOffset)
    ];
    leftBoxes.map((p) => {
      this.plank(p, ...boxSize, 2, ...leftExternalRadiuses);
    });
    const centerBoxes = [
      new Vector3(xc - plankW / 2, currentY, z0 + boxOffset),
      new Vector3(xc - plankW / 2, currentY, z0 + boxOffset + 320),
      new Vector3(xc - plankW / 2, currentY, z1 - plankW2 - 320 - boxOffset),
      new Vector3(xc - plankW / 2, currentY, z1 - plankW2 - boxOffset)
    ];
    centerBoxes.map((p) => {
      this.plank(p, ...boxSize, 2);
    });
    const rightBoxes = [
      new Vector3(x1 - plankW - boxOffset, currentY, z0 + boxOffset),
      new Vector3(x1 - plankW - boxOffset, currentY, z0 + boxOffset + 320),
      new Vector3(
        x1 - plankW - boxOffset,
        currentY,
        z1 - plankW2 - 320 - boxOffset
      ),
      new Vector3(x1 - plankW - boxOffset, currentY, z1 - plankW2 - boxOffset)
    ];
    rightBoxes.map((p) => {
      this.plank(p, ...boxSize, 2, ...rightExternalRadiuses);
    });

    //before top planks
    currentY += boxH;
    const beforeTopPlanks = [
      new Vector3(x0, currentY, z0 + bottomSmallPlanksOffset),
      new Vector3(x0, currentY, z0 + bottomSmallPlanksOffset + 320),
      new Vector3(x0, currentY, z1 - plankW2 - bottomSmallPlanksOffset - 320),
      new Vector3(x0, currentY, z1 - plankW2 - bottomSmallPlanksOffset)
    ];
    beforeTopPlanks.forEach((p) =>
      this.plank(p, this.length, plankW2, plankH2, 1)
    );

    //top planks
    currentY += plankH2;
    this.plank(
      new Vector3(x0, currentY, z0),
      plankW3,
      this.width,
      plankH,
      2,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(x1 - plankW3, currentY, z0),
      plankW3,
      this.width,
      plankH,
      2,
      ...rightExternalRadiuses
    );
    const spacing = 36;
    const middlePlanks = [
      new Vector3(x0 + plankW3 + spacing, currentY, z0),
      new Vector3(x0 + plankW3 + plankW2 + spacing * 2, currentY, z0),
      new Vector3(x0 + plankW3 + plankW2 * 2 + spacing * 2, currentY, z0),

      new Vector3(x1 - plankW3 - spacing - plankW2, currentY, z0),
      new Vector3(x1 - plankW3 - plankW2 * 2 - spacing * 2, currentY, z0),
      new Vector3(x1 - plankW3 - plankW2 * 3 - spacing * 2, currentY, z0)
    ];
    middlePlanks.forEach((p, index) => {
      this.plank(p, plankW2, this.width, plankH, 2);
    });

    this.plank(
      new Vector3(xc - plankW / 2, currentY, z0),
      plankW,
      420,
      plankH,
      2
    );
    this.plank(
      new Vector3(xc - plankW / 2, currentY, z1 - 420),
      plankW,
      420,
      plankH,
      2
    );

    currentY += plankH;
    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(0, currentY);
  }
}
