import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { LoadService } from '../../../lib/load.service';
import { LoadService as LoadUiService } from '../../../load.service';
import { UiService } from 'src/app/services/ui.service';
import { LoadListItem } from '../../../lib/load-list-item';
import { flashBackground } from 'animations/animations';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-cuboid-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.less'],
  animations: [flashBackground]
})
export class SelectListComponent implements OnInit, OnDestroy {
  protected items: LoadListItem[] = [];
  protected filteredItems: LoadListItem[] = [];
  protected lastLoads: LoadListItem[] = [];

  protected searchControl = new FormControl();

  get selected(): LoadListItem[] {
    return this.items.filter((item) => item.selected);
  }
  public searchText: string;
  protected loadShape = 'cuboid';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: LoadService,
    private loadUiService: LoadUiService,
    private profileService: ProfileService,
    public ui: UiService
  ) {
    combineLatest([profileService.getProfile(), this.loadUiService.list$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([profile, items]) => {
        if (!profile || !items) {
          return;
        }
        const lastLoads = items.filter((l) =>
          (profile.lastLoads || []).some(
            (latest) => latest.name === l.load.name
          )
        );
        lastLoads.sort((a, b) => {
          const timestampA =
            (profile.lastLoads || []).find(
              (latest) => latest.name === a.load.name
            )?.timestamp || 0;
          const timestampB =
            (profile.lastLoads || []).find(
              (latest) => latest.name === b.load.name
            )?.timestamp || 0;
          return timestampB - timestampA;
        });
        this.lastLoads = lastLoads;
      });
  }

  ngOnInit(): void {
    console.log('cuboid/select-list/select-list.component.ts: init');
    //this.select(null);

    this.service.loads$ //.getByShape(this.loadShape)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        console.log('load list loaded');
        const newList = list.map((load) => new LoadListItem(load));
        this.loadUiService.updateList(newList);
      });
    this.loadUiService.list$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.items = list;
        this.filteredItems = list.slice();
      });

    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filterItems(value))
      )
      .subscribe((filteredItems) => {
        this.filteredItems = filteredItems;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _filterItems(value: string | LoadListItem): LoadListItem[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.items.filter(
      (item) =>
        item.load.fullName.toLowerCase().includes(filterValue) ||
        item.load.uuid.toLowerCase().includes(filterValue)
    );
  }

  protected displayFn(item: LoadListItem): string {
    return item && item.load.fullName ? item.load.fullName : '';
  }

  protected selectSingle(item: LoadListItem): void {
    this.items.forEach(
      (l) => (l.selected = item && item.load.uuid === l.load.uuid)
    );
    this.loadUiService.updateList(this.items);
    this.loadUiService.updateSelected(this.selected);
    this.loadUiService.setSingleItemForScroll(item);
  }

  protected onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedItem: LoadListItem = event.option.value;
    this.selectSingle(selectedItem);
  }
}
