<!--<div
  class="tool"
  *ngIf="isNotEmptyContext()"
  [class.enabled]="tools.measurement === true"
  i18n-matTooltip
  matTooltip="Zmierz wolną przestrzeń"
  [matTooltipPosition]="'right'"
  [matTooltipShowDelay]="250"
  #measurementTooltip="matTooltip"
>
  <button mat-button (click)="toggleMeasurementTool()">
    <mat-icon>straighten</mat-icon>
  </button>
</div>-->
<div class="tool" *ngIf="canMakeAnOfficialOrder()">
  <button
    mat-button
    [color]="'primary'"
    i18n-matTooltip
    matTooltip="Akceptuj - wystaw zamówienie do oficjalnych"
    [matTooltipPosition]="'right'"
    [matTooltipShowDelay]="250"
    #officialOrderTooltip="matTooltip"
    (click)="moveOrdersToOfficial()"
  >
    <mat-icon>done_outline</mat-icon>
  </button>
</div>
<div class="tool" *ngIf="canMakeOrderTemplate()">
  <button
    mat-button
    [color]="'primary'"
    i18n-matTooltip
    matTooltip="Utwórz szablon z zamówienia"
    [matTooltipPosition]="'right'"
    [matTooltipShowDelay]="250"
    #createTemplateTooltip="matTooltip"
    (click)="createTemplate()"
  >
    <mat-icon>format_paint</mat-icon>
  </button>
</div>

<div
  class="tool"
  *ngIf="searchingAlternatives()"
  i18n-matTooltip
  matTooltip="Szukam alternatywnych rozmieszczeń"
  [matTooltipPosition]="'right'"
  [matTooltipShowDelay]="250"
  #alternativeSearchTooltip="matTooltip"
>
  <mat-icon class="alternative-search">alt_route</mat-icon>
</div>
<div
  class="tool"
  *ngIf="alternativesAvailable()"
  [class.enabled]="tools.alternatives === true"
>
  <button
    mat-button
    i18n-matTooltip
    matTooltip="Pokaż alternatywne rozmieszczenia"
    [matTooltipPosition]="'right'"
    [matTooltipShowDelay]="250"
    #measurementTooltip="matTooltip"
    (click)="toggleAlternatives()"
  >
    <mat-icon>alt_route</mat-icon>
  </button>
  <div class="alternative-list" *ngIf="tools.alternatives === true">
    <button
      i18n
      (click)="selectAlternative(baseContext)"
      [class.selected]="
        !selectedAlternative || selectedAlternative == baseContext
      "
    >
      Najlepsze rozmieszczenie
    </button>
    <p><b i18n>Alternatywne rozmieszczenia:</b></p>
    <ul>
      <li
        *ngFor="let alternative of baseContext.alternatives; let i = index"
        (click)="selectAlternative(alternative)"
        [class.selected]="alternative == selectedAlternative"
      >
        <span i18n>Alternatywne rozmieszczenie #{{ i + 1 }}</span>
      </li>
    </ul>
    <div class="buttons">
      <button
        mat-raised-button
        class="btn-outlined btn-secondary save"
        (click)="confirmAlternative()"
      >
        <span i18n>Zatwierdź</span>
      </button>
      <button
        mat-raised-button
        class="btn-secondary mat-accent btn-outlined"
        (click)="cancelAlternative()"
      >
        <span i18n>Anuluj</span>
      </button>
    </div>
  </div>
</div>
