import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Bus } from './bus';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { Constants } from '../../../lib/constants';

export class BusMesh extends VehicleMesh {
  private indices = [];
  private vertices = [];

  public constructor(bus: Bus) {
    super('bus', bus);
    this.init();
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    // this.length = 1000;
    // this.height = 2000;
    // this.width = 1500;
    const x0 = 0;
    const y0 = 0;
    const z0 = this.vehicle.maxWidth / 2 - this.vehicle.cabinWidth / 2;
    const x1 = x0 + this.vehicle.cabinLength;
    const y1 = y0 + this.vehicle.cabinHeight;
    const z1 = z0 + this.vehicle.cabinWidth;

    //const x0 = this.vehicle.cabinLength / 2;
    //const y0 = this.vehicle.cabinHeight / 2;
    //const z0 = this.vehicle.cabinWidth / 2;
    let groupStart = 0;
    let groupCount = 0;

    // cabin
    this.vertices.push(x0, y0, z1); // 4 left bottom front
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z1); // 5 left top front
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z0); // 6 left top back
    this.vertices.push(x0, y0, z0); // 7 left bottom back
    this.vertices.push(x1, y0, z1); // 12 cabin front bottom left
    this.vertices.push(x1, y0, z0); // 13 cabin back bottom left
    this.vertices.push(x1, y1, z0); // 14 cabin back top left
    this.vertices.push(x1, y1, z1); // 15 cabin front top left
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z1); // 16 cabin back top left
    this.vertices.push(x0, y1 - this.vehicle.cabinRoofHeight, z0); // 17 cabin front top left

    this.indices.push(0, 3, 5, 0, 5, 4); // cabin bottom faces
    this.indices.push(0, 4, 7, 0, 7, 8, 1, 8, 7); // cabin front faces
    this.indices.push(3, 5, 9, 5, 6, 9, 2, 9, 6); // cabin back faces
    this.indices.push(4, 6, 7, 4, 5, 6); // cabin left bottom faces
    this.indices.push(1, 2, 7, 2, 6, 7); // cabin left top faces

    groupStart += groupCount;
    groupCount = 6 * 3 + 9 * 2;
    const geometry = this.getGeometry();
    geometry.addGroup(groupStart, groupCount, 2);

    geometry.setIndex(this.indices);
    geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    geometry.computeVertexNormals();

    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL,
      Constants.CABIN_MATERIAL,
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL
    ];

    this.buildWireframe();

    this.addSpaces();
    this.addAxles();
  }
}
