<div class="modal-box settings-modal">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Ustawienia przestrzeni ładunkowej</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
          <mat-checkbox formControlName="randomizeColors" i18n
            >Losuj kolory ładunków</mat-checkbox
          >
          <div class="help" i18n>
            Zaznacz tę opcję przed ładowaniem, aby każdy ładunek miał inny
            kolor.
          </div>
        </div>
        <div>
          <mat-checkbox formControlName="fullLevels" i18n
            >Układaj całymi piętrami</mat-checkbox
          >
          <div class="help">
            <ng-container i18n
              >Zaznacz tę opcję przed ładowaniem, aby system piętrując
              dopełniał</ng-container
            >
            <br />
            <ng-container i18n>
              najpierw całe piętro w poziomie, aby zacząć kolejne
              piętro</ng-container
            >
            <div i18n class="text-orange">
              Uwaga: działa, gdy włączone jest piętrowanie ładunku podczas jego
              dodawania
            </div>
          </div>
        </div>

        <div class="buttons">
          <button
            mat-raised-button
            type="submit"
            [disabled]="!form.valid"
            class="btn-primary mt-2"
          >
            <span i18n>Zapisz </span>
            <mat-icon *ngIf="isLoading">
              <mat-spinner class="progress" color="accent" diameter="24">
              </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
