import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  confirmed: boolean;
  load: string;
  action: ConfirmationDialogAction;
  message: string;
}

export enum ConfirmationDialogAction {
  deleteAllLoads,
  deleteLoad,
  deleteVehicle,
  deleteTemplate,
  deleteAll,
  deleteProject,
  deleteAllCustomLoads,
  deleteDirectory,
  deleteOfficialOrder,
  createOrderTemplate
}

@Component({
  selector: 'app-confirmation-dialog',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.less']
})
export class ConfirmationDialogComponent {
  public title: string;
  public content: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data.confirmed = true;
    switch (this.data.action) {
      case ConfirmationDialogAction.deleteAllLoads:
        this.title = $localize`Usunąć wszystkie ładunki?`;
        this.content = $localize`Spowoduje usunięcie wszystkich ładunków z pojazdu`;
        break;
      case ConfirmationDialogAction.deleteAllCustomLoads:
        this.title = $localize`Usuwanie ładunków własnych`;
        this.content = $localize`Czy na pewno usunąć wszystkie ładunki własne?`;
        break;
      case ConfirmationDialogAction.deleteLoad:
        this.title = $localize`Usunąć ładunek (${this.data.load})?`;
        this.content = $localize`Spowoduje usunięcie ładunku ze sceny`;
        break;
      case ConfirmationDialogAction.deleteVehicle:
        this.title = $localize`Usunąć pojazd?`;
        this.content = $localize`Spowoduje permanentne usunięcie wszystkich ładunków oraz pojazdu ze sceny.`;
        break;
      case ConfirmationDialogAction.deleteTemplate:
        this.title = $localize`Usuwanie szablonu`;
        this.content = $localize`Czy na pewno chcesz usunąć układ ${this.data.message}?`;
        break;

      case ConfirmationDialogAction.deleteAll:
        this.title = $localize`Usuwanie wszytkich ułożeń`;
        this.content = $localize`Czy na pewno chcesz permanentnie usunąć WSZYSTKIE ułożenia z tego projektu?`;
        break;
      case ConfirmationDialogAction.deleteProject:
        this.title = $localize`Usuwanie projektu`;
        this.content = $localize`Ta akcja usunie bezpowrotnie projekt i wszystkie jego rozmieszczenia - czy kontynuować?`;
        break;
      case ConfirmationDialogAction.deleteDirectory:
        this.title = $localize`Usuwanie folderu`;
        this.content = $localize`Ta akcja usunie bezpowrotnie folder wraz z projektami i wszystkimi rozmieszczeniami wewnątrz - czy kontynuować?`;
        break;
      case ConfirmationDialogAction.deleteOfficialOrder:
        this.title = $localize`Usuwanie zamówienia`;
        this.content = $localize`Ta akcja usunie zamówienie z listy zamówień oficjalnych. Wszystkie pozycje powrócą do zamówień roboczych. Czy kontynuować?`;
        break;
      case ConfirmationDialogAction.createOrderTemplate:
        this.title = $localize`Szablon zamówienia`;
        this.content = $localize`Z zamówienia zostanie utworzony szablon. W przyszłości zamówienia z takim samym ładunkiem automatycznie trafią do oficjalnych. Czy kontynuować?`;
        break;
      default:
        console.error('unsupported ConfirmationDialogAction');
        break;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
