import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SceneService } from 'src/app/scene/scene.service';
import { Settings } from './settings';
import { SettingsService } from './settings.service';
import { UiService } from 'src/app/services/ui.service';

//TODO: to jest settings bardziej kalkulacji niż pojazdu
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit, OnDestroy {
  settings: Settings;
  // flooringLevelActive = false;
  // flooringLevel: number;
  isLoading = false;

  public form = this.fb.group({
    randomizeColors: [
      this.service.context.getSettings().randomizeColors,
      Validators.compose([])
    ],
    fullLevels: [
      this.service.context.getSettings().fullLevels,
      Validators.compose([])
    ]
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: SettingsService,
    private sceneService: SceneService,
    private fb: UntypedFormBuilder,
    private ui: UiService
  ) {}

  ngOnInit(): void {
    console.log('onInit');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    this.isLoading = true;

    const settings = this.service.context.getSettings() ?? new Settings();
    settings.randomizeColors = this.form.controls.randomizeColors.value;
    settings.fullLevels = this.form.controls.fullLevels.value;

    this.ui.setLoading(true);
    this.service
      .saveSettings(settings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        console.log('settings to store in vehicle calculation', x);
        this.service.context.setSettings(settings);
        this.sceneService.reloadLoads().subscribe(() => {
          this.ui.setLoading(false);
          this.close();
        });
      });
  }

  close(): void {
    this.service.close();
  }
}
