import { Euler, Vector3 } from 'three';
import { ModelDetails } from './model-details.interface';

export class ModelDetailsUNI2503UC implements ModelDetails {
  getPartDimensions(name: string): Vector3 {
    switch (name) {
      case 'front-bar-slanted-1-obb':
        return new Vector3(630, 50, undefined);
      case 'wheel-hub-fr-obb':
      case 'wheel-hub-fl-obb':
      case 'wheel-hub-rl-obb':
      case 'wheel-hub-rr-obb':
        return new Vector3(undefined, 60, undefined);
    }
    return new Vector3(undefined, undefined, 50);
  }
  getPartRotation(name: string): Euler {
    switch (name) {
      case 'shaft-left-obb':
        return new Euler(0, -Math.PI / 12, 0);
      case 'shaft-right-obb':
        return new Euler(0, Math.PI / 12, 0);
      case 'floor-bar-slanted-2-obb':
        return new Euler(0, -Math.PI / 19, 0);
      case 'floor-bar-slanted-1-obb':
        return new Euler(0, Math.PI / 19, 0);
      case 'front-bar-slanted-1-obb':
        return new Euler(0, 0, Math.PI / 4.45);
      case 'wheel-hub-fr-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-fl-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rr-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rl-obb':
        return new Euler(0, 0, Math.PI / 7);
    }
    throw new Error(`UNI2503UC OBB layer for ${name} not supported`);
  }
}
