import { v4 as uuidv4 } from 'uuid';

import { LoadMesh } from './load-mesh';
import { Position } from 'src/app/lib/positioner/position';
import { CuboidHull } from './cuboid-hull';
import { LoadDisplaySettings } from './load-display-settings';
import { Point } from 'src/app/lib/point';
import { ModelLoaderService } from 'src/app/raycasting/lib/model-loader.service';
import { Euler, EulerOrder, Vector3 } from 'three';

export abstract class Load {
  uuid: string;
  spaceUuid: string;
  modelName?: string;
  maxLoadingWeight?: number;
  position: Position;
  name: string;
  color: number;
  floorableTop: boolean = false;
  floorableBottom: boolean = false;
  weight: number;
  idx: number;
  initialTimestamp: number;
  // fixedHorizontalRotation: boolean; //obrócono ręcznie w poziomie
  // fixedVerticalRotation: boolean; // obrócono ręcznie w pionie

  horizontalRotationFrozen: boolean = false; //brak możliwości obrotu w poziomie // dla algorytmów
  verticalRotationFrozen: boolean = true; // brak możliwości obrotu w pionie // dla algorytmów

  fixedPosition: boolean; // ręcznie przeniesiono ładunek

  canDisassemble: string[] = [
    'wheels',
    'shaft',
    'jockeywheel',
    'fenders',
    'ramp',
    'lights'
  ];
  disassembleComponents: string[] = [];
  rotations: string[] = [];
  rotationX: number = 0;
  rotationY: number = 0;
  rotationZ: number = 0;
  rotationOrder: EulerOrder = 'XYZ';
  loaded: boolean;
  type: string;
  shape: string;
  userDefined = false;
  projectId?: string;
  createdAt?: Date;
  updatedAt?: Date;

  protrusionLength: number = 0; //dopuszczalny % wystawania w długości - default >=99%
  protrusionWidth: number = 0; //dopuszczalny % wystawania w szerokości - default >=99%
  selected: boolean;

  markedForReload: boolean = false;

  displayOrder?: number;

  contactPoints: Point[] = [];

  // uuid zamówienia roboczego
  orderNumber?: string;

  #mesh: LoadMesh;
  #prevPosition: Position;

  constructor(obj: any, public settings: LoadDisplaySettings) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.generateUuid();
    }
    this.rotations = [...(this.rotations || [])];

    //this.createMesh(settings);
  }

  abstract get cuboidHull(): CuboidHull;
  abstract get fullName(): string;
  abstract get volume(): number;
  abstract get area(): number;
  abstract get fullDescription(): string;

  abstract get descriptiveDimensions(): number[];

  abstract createMesh(
    settings?: LoadDisplaySettings,
    modelLoader?: ModelLoaderService
  ): Promise<LoadMesh>;

  get mesh(): LoadMesh {
    return this.#mesh;
  }

  set mesh(mesh: LoadMesh) {
    this.#mesh = mesh;
  }

  get prevPosition() {
    return this.#prevPosition;
  }

  set prevPosition(p: Position) {
    this.#prevPosition = p.clone();
  }

  get floorableAll(): boolean {
    return this.floorableTop && this.floorableBottom;
  }

  set floorableAll(val: boolean) {
    this.floorableTop = val;
    this.floorableBottom = val;
  }

  public is(cls: typeof Load) {
    return this instanceof cls;
  }

  public generateUuid() {
    this.uuid = uuidv4();
  }

  public select() {
    this.selected = true;
    this.mesh?.select();
  }

  public move(vector: Vector3) {
    this.mesh?.move(vector);
    this.position.x += vector.x;
    this.position.y += vector.y;
    this.position.z += vector.z;
    //this.updateLoadPositionFromMesh();
  }

  public rotate(vector: Vector3, angle: number) {
    this.mesh?.rotate(vector, angle);
    this.updateLoadRotationFromMesh();
  }

  public applyCurrentRotation() {
    const euler = new Euler(
      this.rotationX,
      this.rotationY,
      this.rotationZ,
      this.rotationOrder
    );
    this.mesh?.applyEulerRotation(euler);
  }
  public updateLoadRotationFromMesh() {
    this.rotationX = this.mesh?.obj.rotation.x;
    this.rotationY = this.mesh?.obj.rotation.y;
    this.rotationZ = this.mesh?.obj.rotation.z;
    this.rotationOrder = this.mesh?.obj.rotation.order;
  }

  public updateLoadPositionFromMesh(offset?: Vector3) {
    this.position = new Position({
      x: this.mesh?.obj.position.x - (offset?.x || 0),
      y: this.mesh?.obj.position.y - (offset?.y || 0),
      z: this.mesh?.obj.position.z - (offset?.z || 0)
    });
  }

  public removeDisassembledComponents() {
    this.mesh?.removeComponents(this.disassembleComponents);
  }

  public unselect() {
    this.selected = false;
    this.mesh?.select(false);
  }

  public hover() {
    this.mesh?.hover();
  }

  public unhover() {
    this.mesh?.unhover();
  }

  public updateSettings(settings: LoadDisplaySettings) {
    if (this.displaySettingsChanged(settings)) {
      this.createMesh(settings);
    }
  }

  public requireReloadWhenChangedTo(updated: Load): boolean {
    return (
      this.cuboidHull.length !== updated.cuboidHull.length ||
      this.cuboidHull.width !== updated.cuboidHull.width ||
      this.cuboidHull.height !== updated.cuboidHull.height ||
      this.floorableTop !== updated.floorableTop ||
      this.floorableBottom !== updated.floorableBottom ||
      this.protrusionLength !== updated.protrusionLength ||
      this.protrusionWidth !== updated.protrusionWidth ||
      this.verticalRotationFrozen !== updated.verticalRotationFrozen ||
      this.horizontalRotationFrozen !== updated.horizontalRotationFrozen ||
      this.disassembleComponents.length !==
        updated.disassembleComponents.length ||
      this.disassembleComponents.sort().join(',') !==
        updated.disassembleComponents.sort().join(',')
    );
  }

  public markForReload(val: boolean = true) {
    this.markedForReload = val;
  }

  private displaySettingsChanged(newSettings: LoadDisplaySettings) {
    return (
      newSettings.loadBordersIntensity !== this.settings.loadBordersIntensity ||
      newSettings.loadTransparency !== this.settings.loadTransparency
    );
  }
}
