import { LoadFactory } from 'src/app/load/lib/load-factory';
import { CuboidSpace } from '../../../lib/type/cuboid-space';
import { BoxMesh } from './box-mesh';

export class Box extends CuboidSpace {
  type = 'box';

  constructor(obj: Partial<Box>, loadFactory: LoadFactory) {
    super(obj, loadFactory);
    console.log('box constructor called');

    this.mesh = new BoxMesh(this);
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get volume(): number {
    return this.area * this.height;
  }

  get area(): number {
    return this.width * this.length;
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }

  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }
}
