import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { VehicleContext } from '../lib/model/vehicle-context';
import { SceneService } from '../scene/scene.service';

import { UiService } from '../services/ui.service';
import { Vehicle } from '../vehicle/lib/vehicle';
import { VehicleSuggestionType } from './lib/suggestion-type';
import { SuggestVehicleService } from './suggest-vehicle.service';
import { ProjectsService } from '../projects';

type Status = 'new' | 'found' | 'not-found';

@Component({
  selector: 'app-suggest-vehicle',
  templateUrl: './suggest-vehicle.component.html',
  styleUrls: ['./suggest-vehicle.component.less']
})
export class SuggestVehicleComponent implements OnInit, OnDestroy {
  @Input() context: VehicleContext;
  @Input() type: VehicleSuggestionType = 'size';

  protected vehicles$: Observable<Vehicle[]>;
  protected status$: Observable<Status>;
  protected currentVehicle: Vehicle;

  protected loadedCnt: number;
  protected notLoadedCnt: number;
  protected loadedPercentage: number;
  protected notLoadedPercentage: number;

  protected loadsWeight: number;
  protected weightLimitExceededBy: number;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: SuggestVehicleService,
    private sceneService: SceneService,
    protected ui: UiService,
    protected projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.currentVehicle = this.context.getVehicle();
    const currentVehicleUuid = this.currentVehicle.uuid;
    this.loadedCnt = this.context.getLoadedLoads().length;
    this.notLoadedCnt = this.context.getLoads().length;
    this.loadedPercentage = Math.round(
      (this.loadedCnt / (this.loadedCnt + this.notLoadedCnt)) * 100
    );
    this.notLoadedPercentage = 100 - this.loadedPercentage;
    if (this.type === 'weight') {
      this.loadsWeight = this.context.getAllLoadsWeight();
      this.weightLimitExceededBy = Math.max(
        0,
        this.loadsWeight - this.context.getVehicle().maxLoadingWeight
      );
    }
    this.vehicles$ = this.service
      .getVehicles(
        this.context,
        this.context.getMatrix(),
        this.context.getAllLoads(),
        this.type
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        map((response) =>
          response.vehicles
            .filter((v) => v.uuid !== currentVehicleUuid)
            .slice(0, 10)
        )
      );

    this.status$ = this.vehicles$.pipe(
      map((vehicles) => (vehicles.length === 0 ? 'not-found' : 'found')),
      startWith<Status>('new')
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.service.close();
  }

  vehicleChosen(vehicle: Vehicle): void {
    throw new Error('Not implemented');
  }

  multiply(): void {
    throw new Error('Not implemented');
  }

  autoPlacement() {
    throw new Error('Not implemented');
  }
}
