import { Injectable } from '@angular/core';
import { TransformControls } from '../lib/vendor/three/controls/TransformControls';

import {
  Box3,
  Object3D,
  PerspectiveCamera,
  Plane,
  Raycaster,
  Vector2,
  Vector3
} from 'three';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ContextMenuService } from '../context-menu/context-menu.service';
import { Vector } from '../lib/communication/vector';
import { Load } from '../load/lib/load';
import { UiService } from './ui.service';

interface TransformEventListeners {
  [key: string]: (event: THREE.Event) => void;
}

interface DocumentEventListeners {
  [key: string]: (event: Event | MouseEvent | KeyboardEvent) => void;
}

export class TransformControlsServiceCommunicationModel {
  load: Load;
  movement?: Vector;
}

@Injectable({
  providedIn: 'root'
})
export class TransformControlsService {
  private controls: TransformControls;
  private loads: Load[];
  private meshMap: Object3D[];
  private camera: PerspectiveCamera;
  private canvas: HTMLCanvasElement;
  private selectedLoad?: Load;
  //private selectedLoads: Load[];
  private startPosition: Vector3;

  private mouse: Vector2;

  private model = new Subject<TransformControlsServiceCommunicationModel>();
  private movement = new Subject<TransformControlsServiceCommunicationModel>();

  private enabled = new BehaviorSubject<boolean>(false);
  private hoveredLoad = new BehaviorSubject<Load | undefined>(undefined);
  private selectedLoads = new BehaviorSubject<Load[]>([]);

  private raycaster = new Raycaster();
  // private plane = new Plane();
  private plane = new Plane(new Vector3(0, 1, 0), 0);
  private pNormal = new Vector3(0, 1, 0); // plane's normal
  private planeIntersect = new Vector3(); // point of intersection with the plane
  private pIntersect = new Vector3(); // point of intersection with an object (plane's point)
  private shift = new Vector3(); // distance between position of an object and points of intersection with the object

  private dragPid: number = 0;

  private eventListeners: TransformEventListeners = {};
  private documentListeners: DocumentEventListeners = {};

  constructor(
    // private orbitControlsService: OrbitControlsService,
    private contextMenuService: ContextMenuService,
    private ui: UiService
  ) {
    //this.selectedLoads = [];
    this.mouse = new Vector2();
  }

  public getEnabled(): Observable<boolean> {
    return this.enabled.asObservable();
  }

  public getHoveredLoad(): Observable<Load | undefined> {
    return this.hoveredLoad.asObservable();
  }

  public getSelectedLoads(): Observable<Load[]> {
    return this.selectedLoads.asObservable();
  }

  public init(camera: PerspectiveCamera, canvas: HTMLCanvasElement) {
    this.camera = camera;
    this.canvas = canvas;
    this.controls = new TransformControls(this.camera, this.canvas);
    this.controls.axis = 'XZ';
    this.controls.enabled = true;
    this.controls.showY = false;
    this.controls.showX = true;
    this.controls.showZ = true;

    //this.controls.setMode('translate');
  }

  public bindLoads(loads: Load[]) {
    this.loads = loads;
    this.meshMap = loads.map((x) => x.mesh.obj);
    this.unbindEvents();
    this.bindEvents();
    //this.controls.enableMultiMode(this.ui.loadMultiSelectEnabled);
  }

  public modelChanged(): Observable<TransformControlsServiceCommunicationModel> {
    return this.model.asObservable();
  }

  public dragEnd(): Observable<TransformControlsServiceCommunicationModel> {
    return this.movement.asObservable();
  }

  private findLoadByMeshId(id: number) {
    /*console.log(
      'findLoadByMeshId',
      id,
      'ids',
      this.loads.map((x) => x.mesh.obj.id),
      'names',
      this.loads.map((x) => x.mesh.obj.name)
    );*/
    return this.loads.find((x) => x.mesh.obj.id === id);
  }

  /**
   * Wykrywanie najechania myszką na ładunek / zjechania myszką z ładunku
   *
   * @param event
   */
  private onPointerMove(event: PointerEvent) {
    if (this.controls.dragging) {
      return;
    }
    var rect = this.canvas.getBoundingClientRect();

    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);

    this.raycaster.layers.set(1);

    const intersects = this.raycaster.intersectObjects(this.meshMap, true);

    if (intersects.length > 0) {
      let object = intersects[0].object;
      //console.log('intersects.length > 0', object);

      const maxStepUp = 5;
      let stepUp = 0;
      //console.log('BEFORE WHILE object.name', object.name);
      while (object && object.name !== 'load' && stepUp < maxStepUp) {
        //console.log('object.name', object.name, stepUp, object.id);
        object = object.parent;
        stepUp++;
      }

      //workaround na load  parent load - gdzies dla brenderupmesh dodawane sa 2x obiekty o nazwie 'load'
      if (object.parent && object.parent.name === 'load') {
        object = object.parent;
      }
      //console.log('load ', object);

      /*if (!this.canDrag(object)) {
        console.log('cantdrag');
        this.hoveredLoad.next(undefined);
        this.setCursor('auto');
        this.controls.detach(object);
        return;
      }*/
      this.controls.enabled = true;
      this.controls.axis = 'XZ';

      if (object !== this.controls.object) {
        //console.log('TransformControls attach', object, this.controls.enabled);
        this.controls.attach(object);
        //object.userData.moveStartPoint = null;
      }
      const selectedLoad = this.findLoadByMeshId(object.id);

      this.hoveredLoad.next(selectedLoad);
      if (!event.ctrlKey) {
        this.setCursor('pointer');
      }
    } else {
      if (this.controls.object !== undefined) {
        //this.enable(false);
        this.hoveredLoad.next(undefined);
        //this.controls.detach(this.controls.object);
        this.setCursor('auto');
        /*console.log(
          'TransformControls detach',
          this.controls.object,
          this.controls.object.position,
          this.controls.enabled
        );*/

        this.enable(false);
        this.controls.detach();
      }
    }
  }

  private canDrag(object: Object3D) {
    const box = new Box3().setFromObject(object);
    const otherLoads = this.loads.filter(
      (l) => l.mesh.obj.uuid !== object.uuid
    );
    for (const other of otherLoads) {
      const checkBox = new Box3().setFromObject(other.mesh.obj);
      const maxX = Math.min(checkBox.max.x, box.max.x);
      const minX = Math.max(checkBox.min.x, box.min.x);
      const intersectXlength = maxX - minX;

      const maxZ = Math.min(checkBox.max.z, box.max.z);
      const minZ = Math.max(checkBox.min.z, box.min.z);
      const intersectZlength = maxZ - minZ;
      if (
        intersectXlength > 0 &&
        intersectZlength > 0 &&
        checkBox.min.y >= box.max.y
      ) {
        return false;
      }
    }
    return true;
  }

  private setCursor(
    type: 'pointer' | 'auto' | 'move' | 'magnet' | 'magnet-minus'
  ) {
    ['pointer', 'move', 'magnet', 'magnet-minus'].forEach((t) => {
      type !== t && this.canvas.classList.remove('cursor-' + t);
    });
    this.canvas.classList.add('cursor-' + type);
  }

  public getControls() {
    return this.controls;
  }

  private enable(val: boolean) {
    this.enabled.next(val);
    //this.controls.enabled = true;
  }

  private onDragMove(force = false) {
    if (!this.controls.object) {
      return;
    }
    //console.log('pos', this.controls.object.position);
    const load = this.findLoadByMeshId(this.controls.object.id);
    let movementVector: Vector = null;
    let distance: number = 0;
    if (this.controls.object.userData.moveStartPoint) {
      const moveEnd: Vector3 = this.controls.object.position.clone();
      movementVector = new Vector(
        moveEnd
          .clone()
          .sub(this.controls.object.userData.moveStartPoint)
          .normalize()
      );
      distance =
        this.controls.object.userData.moveStartPoint.distanceTo(moveEnd);
      if (distance !== 0) {
        this.controls.object.userData.moveStartPoint = moveEnd;
      }
    }
    if (force || distance !== 0) {
      this.model.next({
        load: load,
        movement: movementVector
      });
    }
  }

  private bindEvents() {
    this.eventListeners.change = (event) => {
      //console.log('TransformControls change', event, this.controls.enabled);
      this.onDragMove();
    };
    this.eventListeners['dragging-changed'] = (event) => {
      /*console.log(
        'TransformControls dragging-changed',
        event.value,
        this.controls.enabled
      );*/
    };
    this.eventListeners.mouseDown = (event: any) => {
      //console.log('TransformControls mouseDown', event);
      const ctrl = event.pointer.mouseEvent.ctrlKey || false;
      const selectedLoad = this.findLoadByMeshId(this.controls.object.id);
      if (event.pointer.button === 0) {
        // left
        const position = this.controls.object.position.clone();
        this.controls.object.userData.moveStartPoint = position.clone();
        this.controls.object.userData.start = position.clone();
        this.controls.object.userData.validPosition = position.clone();
        this.enable(true);
        if (!ctrl) {
          this.setCursor('move');
        }

        this.dragPid++;
        if (!this.ui.loadMultiSelectEnabled) {
          // ?
        } else if (ctrl && !selectedLoad?.selected) {
          this.controls.object.userData['drag'] = this.dragPid;
          this.toggleSelectedLoad(selectedLoad!!);
        } else if (!ctrl) {
          if (!this.isSelected(selectedLoad!!)) {
            this.deselectAll();
            // this.selectOneLoad(selectedLoad);
          }
        }
      } else if (event.pointer.button === 2) {
        // right
        let selectedLoads: Load[] = [];
        //console.log('right mouse button', ctrl);
        if (
          this.ui.loadMultiSelectEnabled &&
          (ctrl || selectedLoad?.selected)
        ) {
          //console.log('right selected', ctrl, selectedLoad.selected);
          this.toggleSelectedLoad(selectedLoad!!, true);
          selectedLoads = this.selectedLoads.value;
        } else {
          //this.selectOneLoad(selectedLoad);
          selectedLoads = selectedLoad ? [selectedLoad] : [];
        }
        this.contextMenuService.open(selectedLoads, event.pointer.mouseEvent);
      }
    };
    this.eventListeners.mouseUp = (event: any) => {
      //console.log('TransformControls mouseup', event);
      this.enable(false);
      this.onDragMove(true);
      const object = this.controls.object;
      this.setCursor(this.hoveredLoad.value ? 'pointer' : 'auto');
      if (object !== undefined) {
        const ctrl = event.pointer.mouseEvent.ctrlKey;
        const start = object.userData.start;
        object.userData.start = null;
        const end = object.position.clone();
        const movement = new Vector({
          x: end.x - start.x,
          z: end.z - start.z,
          y: end.y - start.y
        });
        const load = this.findLoadByMeshId(object.id);
        if (!load) {
          return;
        }

        if (
          Math.abs(movement.x) < 10 &&
          Math.abs(movement.y) < 10 &&
          Math.abs(movement.z) < 10
        ) {
          // odznaczamy tylko jeśli nie było ruchu i zaznaczenie nastąpiło w wyniku wcześniejszego kliknięcia niż bieżące
          // lub jeśli zaznaczony był pojedynczy ładunek
          if (
            ctrl &&
            object.userData['drag'] !== this.dragPid &&
            this.isSelected(load)
          ) {
            this.toggleSelectedLoad(load, false);
          }
        } else {
          if (this.selectedLoads.value.length > 1) {
            return;
          }
          const model = new TransformControlsServiceCommunicationModel();
          model.load = load;
          model.movement = movement;
          this.movement.next(model);
          //this.deselectAll();
        }
        this.controls.detach();
      }
    };
    this.eventListeners.objectChange = (event) => {};
    for (const event in this.eventListeners) {
      this.controls?.addEventListener(event, this.eventListeners[event]);
    }

    this.documentListeners['pointermove'] = (event) =>
      this.onPointerMove(event as PointerEvent);

    this.documentListeners['keydown'] = (event: KeyboardEvent) => {
      if (this.ui.loadMultiSelectEnabled && event.ctrlKey) {
        //if (event.shiftKey) {
        //  setCursor('magnet-minus');
        //} else {
        this.setCursor('magnet');
        //}
      } else {
        this.setCursor('auto');
      }

      const selectedLoads = this.selectedLoads.value;
      if (selectedLoads.length > 0) {
        const selectedLoad = selectedLoads[0]; //only first

        console.log('selected load', selectedLoad);

        switch (event.code) {
          case 'KeyY':
            if (event.shiftKey) {
              selectedLoad.move(new Vector3(0, -10, 0));
            } else {
              selectedLoad.move(new Vector3(0, 10, 0));
            }
            break;
          case 'KeyX':
            if (event.shiftKey) {
              selectedLoad.move(new Vector3(-10, 0, 0));
            } else {
              selectedLoad.move(new Vector3(10, 0, 0));
            }
            break;
          case 'KeyZ':
            if (event.shiftKey) {
              selectedLoad.move(new Vector3(0, 0, -10));
            } else {
              selectedLoad.move(new Vector3(0, 0, 10));
            }
            break;

          case 'KeyP':
            if (event.shiftKey) {
              selectedLoad.rotate(new Vector3(1, 0, 0), Math.PI / 2);
            } else {
              selectedLoad.rotate(new Vector3(1, 0, 0), -Math.PI / 2);
            }

            break;
          case 'KeyL':
            if (event.shiftKey) {
              selectedLoad.rotate(new Vector3(0, 1, 0), Math.PI / 2);
            } else {
              selectedLoad.rotate(new Vector3(0, 1, 0), -Math.PI / 2);
            }
            break;
          case 'KeyM':
            if (event.shiftKey) {
              selectedLoad.rotate(new Vector3(0, 0, 1), Math.PI / 2);
            } else {
              selectedLoad.rotate(new Vector3(0, 0, 1), -Math.PI / 2);
            }
            break;
        }

        //refresh view nothing more
        this.model.next({
          load: selectedLoad,
          movement: new Vector()
        });
      }
    };
    this.documentListeners['keyup'] = (event) => {
      this.setCursor('auto');
    };
    for (const event in this.documentListeners) {
      document.addEventListener(event, this.documentListeners[event]);
    }
  }

  public isSelected(load: Load) {
    return this.selectedLoads.value.findIndex((l) => l.uuid === load.uuid) >= 0;
  }

  public toggleSelectedLoad(load: Load, state?: boolean) {
    const idx = this.selectedLoads.value.findIndex((l) => l.uuid === load.uuid);
    if (idx < 0 || state !== false) {
      if (!this.isSelected(load)) {
        this.selectedLoads.next([...this.selectedLoads.value, load]);
      }
      load.select();
    } else if (!state && idx >= 0) {
      const loads = this.selectedLoads.value;
      loads.splice(idx, 1);
      this.selectedLoads.next(loads);
      load.unselect();
    }
    //this.controls.enableMove(this.selectedLoads.value.length <= 1);
    this.contextMenuService.close();
  }

  public selectOneLoad(load: Load) {
    this.selectedLoads.value.forEach((l) => {
      l.unselect();
    });
    load.select();
    this.selectedLoads.next([load]);
    //this.controls.enableMove(true);
    this.contextMenuService.close();
  }

  public deselectAll() {
    this.selectedLoads.value.forEach((load) => {
      load.unselect();
    });
    this.selectedLoads.next([]);
    this.contextMenuService.close();
  }

  private unbindEvents() {
    if (!this.controls) {
      return;
    }
    for (const event in this.eventListeners) {
      this.controls.removeEventListener(event, this.eventListeners[event]);
    }
    for (const event in this.documentListeners) {
      document.removeEventListener(event, this.documentListeners[event]);
    }
    this.eventListeners = {};
    this.documentListeners = {};
    //this.controls.dispose();
  }
}
