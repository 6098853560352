import { environment } from 'src/environments/environment';

export class Order {
  public Uuid?: string;
  public Reference?: string;
  public Number?: string;
  public DlvMode?: string;
  public ShippingDateConfirmed?: string;
  public CustAccount?: string;
  public DeliveryName?: string;
  public DeliveryCity?: string;
  public DeliveryAddress?: string;
  public State?: string;
  public ZipCode?: string;
  public Country?: string;
  public ItemGrp?: string;
  public ItemId?: string;
  public ItemName?: string;
  public Qty?: number;
  public AvailableStock?: number;
  public Volume?: number;
  public InventRefType?: string;
  public InventRefNo?: string;
  public ProdStatus?: string;
  public LatestSchedDate?: string;
  public ShipmentId?: string;
  public TruckArrival?: string;
  public Note?: string;
  public Site?: string;
  public Pool?: string;
  public ItemGrpId?: string;
  public BuyerGroup?: string;
  public Warehouse?: string;

  public HasImage?: boolean;
  public HasModel?: boolean;

  public VehicleName?: string;
  public FreeLDM?: number;
  public TotalLDM?: number;
  public FreeCapacity?: number;
  public TotalCapacity?: number;
  public ProjectId?: string;
  public Ftl?: boolean;

  public constructor(obj?: any) {
    Object.assign(this, obj);
  }

  public getImageSharedUrl() {
    return `${environment.apiUrl}/shared/order/${this.Uuid}`;
  }

  public getDeliveryAddress(): string {
    return (
      this.DeliveryAddress ||
      `${this.Country}, ${this.ZipCode} ${this.DeliveryCity}, ${this.State}`
    );
  }
}
