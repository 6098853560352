const serverUrl = 'https://server3d-brenderup.wr.evomedia.pl/';

export const environment = {
  production: false,
  assetsUrl: serverUrl + 'assets',
  apiUrl: serverUrl + 'api/v1',
  authUrl: 'https://wr-accounts.evomedia.pl',
  profileUrl: 'https://wr-smartload.evomedia.pl/moje-konto',
  //upgradePlanUrl: 'https://wr-smartload.evomedia.pl/pl/moje-konto/wybierz-plan',
  upgradePlanUrl: 'https://wr-smartload.evomedia.pl/pl/moje-konto/wybierz-plan',
  subscriptionsUrl: 'https://wr-smartload.evomedia.pl/moje-konto/subskrypcje',
  authRealm: 'smartload',
  authClient: '3d-app-brenderup',
  disableWarehouses: true,
  disablePalletsAsLoad: true,
  disableMassDistribution: true
};
