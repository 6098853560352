import { Sprite, SpriteMaterial, Texture, TextureLoader } from 'three';
import { GravityCenter } from './gravity-center';

export class GravityCenterSprite extends Sprite {
  private gravityCenter: GravityCenter;

  constructor(gravityCenter: GravityCenter) {
    super();
    this.gravityCenter = gravityCenter;

    this.scale.set(200, 200, 1);
    this.name = 'gravity-center';
  }

  public async load(): Promise<void> {
    return new Promise((resolve, reject) => {
      const loader = new TextureLoader();
      loader.load(
        '/assets/images/gravity-center-sprite.png',
        (data: Texture) => {
          this.material = new SpriteMaterial({ map: data });
          resolve();
        },
        (event: ProgressEvent<EventTarget>) => {},
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public getName(): string {
    return (
      '(gravity-center (' +
      this.gravityCenter.position.x +
      ',' +
      this.gravityCenter.position.y +
      ',' +
      this.gravityCenter.position.z +
      ')' +
      this.gravityCenter.weight
    );
  }
}
