import { Injectable } from '@angular/core';
import { ModelDetails } from './model-details.interface';
import * as models from './';

@Injectable({
  providedIn: 'root'
})
export class ModelDetailsFactoryService {
  constructor() {}

  public getModelDetails(name: string): ModelDetails {
    switch (name) {
      case '1150S':
        return new models.ModelDetails1150S();
      case '1205STILT':
        return new models.ModelDetails1205STILT();
      case '1205XLUB':
        return new models.ModelDetails1205XLUB();
      case '2205B':
        return new models.ModelDetails2205B();
      case '2205UB':
        return new models.ModelDetails2205UB();
      case '2260ST':
        return new models.ModelDetails2260ST();
      case '2260UB':
        return new models.ModelDetails2260UB();
      case '2260x153UB':
        return new models.ModelDetails2260x153UB();
      case '2270S':
        return new models.ModelDetails2270S();
      case '2270SXL':
        return new models.ModelDetails2270SXL();
      case '2300SB':
        return new models.ModelDetails2300SB();
      case '2300STB':
        return new models.ModelDetails2300STB();
      case '3150SUB':
        return new models.ModelDetails3150SUB();
      case '3205S':
        return new models.ModelDetails3205S();
      case '3251SB':
        return new models.ModelDetails3251SB();
      case '3251STB':
        return new models.ModelDetails3251STB();
      case '3253S':
        return new models.ModelDetails3253S();
      case '4260SB':
        return new models.ModelDetails4260SB();
      case '4260STB':
        return new models.ModelDetails4260STB();
      case '4310STB':
        return new models.ModelDetails4310STB();
      case '5420W':
        return new models.ModelDetails5420W();
      case '5420':
        return new models.ModelDetails5420();
      case '5325':
        return new models.ModelDetails5325();
      case '5375':
        return new models.ModelDetails5375();
      case '5520':
      case '5520W':
        return new models.ModelDetails5520W();
      case 'ATHB':
        return new models.ModelDetailsATHB();
      case 'BOAT16':
        return new models.ModelDetailsBOAT16();
      case 'BOAT18':
        return new models.ModelDetailsBOAT18();
      case 'BOAT20':
        return new models.ModelDetailsBOAT20();
      case 'BOAT20-6m':
        return new models.ModelDetailsBOAT20_6m();
      case 'BOAT22':
        return new models.ModelDetailsBOAT22();
      case 'BOAT24':
        return new models.ModelDetailsBOAT24();
      case 'BOAT26':
        return new models.ModelDetailsBOAT26();
      case 'BOAT30':
        return new models.ModelDetailsBOAT30();
      case 'CARGO7260x128':
        return new models.ModelDetailsCARGO7260x128();
      case 'CARGO7260x153':
        return new models.ModelDetailsCARGO7260x153();
      case 'CARGO7300':
        return new models.ModelDetailsCARGO7300();
      case 'CARGO7350':
        return new models.ModelDetailsCARGO7350();
      case 'TT5325ATB':
        return new models.ModelDetailsTT5325ATB();
      case 'TT5375ATB':
        return new models.ModelDetailsTT5375ATB();
      case 'BT4260SB':
        return new models.ModelDetailsBT4260SB();
      case 'BT4260STB':
        return new models.ModelDetailsBT4260STB();
      case 'BT4310STB':
        return new models.ModelDetailsBT4310STB();
      case 'MT2600':
        return new models.ModelDetailsMT2600();
      case 'MT3080':
        return new models.ModelDetailsMT3080();
      case 'MT3651':
        return new models.ModelDetailsMT3651();
      case '2513GT':
        return new models.ModelDetails2513GT();
      case '3023GT':
        return new models.ModelDetails3023GT();
      case '6420':
        return new models.ModelDetails6420();
      case '6520':
        return new models.ModelDetails6520();
      case 'UNI2503UB':
        return new models.ModelDetailsUNI2503UB();
      case 'UNI2503UC':
        return new models.ModelDetailsUNI2503UC();

      case 'oven':
        return new models.ModelDetailsOven();
      case 'tomograph':
        return new models.ModelDetailsTomograph();
    }
    console.error(`Model details for ${name} not available`);
    return null;
  }
}
