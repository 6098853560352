import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  filter,
  interval,
  map,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { FreeSpaceService } from '../../vehicle/space/lib/free-space.service';
import { SceneService } from '../scene.service';
import { ContextFactory } from 'src/app/vehicle/context/lib/context-factory';
import { ContextService } from 'src/app/vehicle/context/context.service';
import { OrderService } from 'src/app/order/order.service';
import { OfficialOrder } from 'src/app/order/lib/official-order';
import { MessageService } from 'src/app/messenger/message.service';
import { Message } from 'src/app/messenger/message';
import { ProjectsService } from 'src/app/projects';
import { ProjectListMode, UiService } from 'src/app/services/ui.service';
import { MenuService } from 'src/app/menu/menu.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from 'src/app/confirmation-dialog/confirmation-dialog.component';

type Tools = {
  [key in 'measurement' | 'alternatives']: boolean;
};

@Component({
  selector: 'app-scene-tools',
  templateUrl: './scene-tools.component.html',
  styleUrls: ['./scene-tools.component.less']
})
export class SceneToolsComponent implements OnInit, OnDestroy {
  @Input() context: VehicleContext;

  protected tools: Tools = {
    measurement: false,
    alternatives: false
  };

  private unsubscribe$ = new Subject<void>();
  constructor(
    private sceneService: SceneService,
    private contextFactory: ContextFactory,
    private contextService: ContextService,
    private freeSpaceService: FreeSpaceService,
    private orderService: OrderService,
    private messageService: MessageService,
    private projectService: ProjectsService,
    private uiService: UiService,
    private menuService: MenuService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.sceneService
      .getVehicleContext()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((context) => {
        if (this.baseContext?.getUuid() !== context?.getUuid()) {
          this.tools.alternatives = false;
        }
      });
    this.alternativesPoll();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private alternativesPoll() {
    this.sceneService
      .getVehicleContext()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((context) => {
          if (
            !context ||
            !context.getBackgroundJobId() ||
            (context.getAlternatives() || []).length > 0
          ) {
            return of(null);
          }
          return of(true).pipe(
            switchMap(() => interval(5000)),
            map(() => context)
          );
        }),
        filter(
          (context) =>
            context &&
            !!context.getBackgroundJobId() &&
            (!context.getAlternatives() ||
              context.getAlternatives().length === 0)
        ),
        switchMap((context) => {
          return of(context).pipe(
            switchMap((c) => this.sceneService.alternativeCalculationsPoll(c)),
            map((result) => {
              if (result.message === 'found') {
                // zakończono proces, niekoniecznie znaleziono
                context.setBackgroundJobId(undefined);
              }
              context.setAlternatives(result.alternatives);
              if (result.alternatives.length > 0) {
                this.messageService.snackSuccess(
                  new Message($localize`Znaleziono alternatywne rozmieszczenia`)
                );
              }
              return context;
            })
          );
        })
      )
      .subscribe((context) => {
        console.log(
          'Alternatives search poll...',
          context.getAlternatives().length
        );
        this.context = context;
      });
  }

  protected isNotEmptyContext() {
    return (
      this.context &&
      this.context.getVehicle() &&
      this.context.getVehicle().type !== 'empty'
    );
  }

  protected toggleMeasurementTool() {
    if (this.tools.measurement === false) {
      this.enableOnly('measurement');
      this.freeSpaceService.enableMeasurement();
    } else {
      this.tools.measurement = false;
      this.freeSpaceService.disableMeasurement();
    }
    this.sceneService.redrawContextWithLabels();
  }

  protected alternativesAvailable(): boolean {
    return this.context && this.context.getAlternatives().length > 0;
  }

  protected searchingAlternatives(): boolean {
    return (
      !this.alternativesAvailable() &&
      this.context &&
      !!this.context.getBackgroundJobId()
    );
  }

  protected baseContext: VehicleContext;
  protected selectedAlternative: VehicleContext;

  protected toggleAlternatives() {
    this.tools.alternatives = !this.tools.alternatives;
    if (this.tools.alternatives) {
      this.selectedAlternative = null;
      this.baseContext = this.contextFactory.clone(this.context, true);
      this.baseContext.setAlternatives(this.context.getAlternatives());
    } else {
      this.cancelAlternative();
      this.tools.alternatives = false;
    }
  }

  protected selectAlternative(alternative: VehicleContext) {
    this.selectedAlternative = alternative;
    this.contextFactory.copyLoadData(alternative, this.context);
    this.context.setAlternatives(this.baseContext.getAlternatives());
    this.contextService.setContext(this.context);
    this.sceneService.redrawContextWithLabels();
  }

  protected confirmAlternative() {
    this.uiService.setLoading(true);
    this.sceneService
      .saveContextChanges(this.context)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.uiService.setLoading(false);
        this.baseContext = null;
        this.tools.alternatives = false;
      });
  }

  protected cancelAlternative() {
    if (!this.baseContext) {
      return;
    }
    this.contextFactory.copyLoadData(this.baseContext, this.context);
    this.baseContext = null;
    this.tools.alternatives = false;
    this.selectedAlternative = null;
    this.contextService.setContext(this.context);
    this.sceneService.redrawContextWithLabels();
  }

  private enableOnly(tool: keyof Tools) {
    for (const t in this.tools) {
      this.tools[t] = tool === t;
    }
  }

  protected canMakeAnOfficialOrder() {
    return (
      this.isNotEmptyContext() &&
      !this.projectService.currentProject?.orderUuid &&
      this.sceneService.getAllLoadsWithPending().some((l) => !!l.orderNumber)
    );
  }

  protected moveOrdersToOfficial() {
    const project = this.projectService.currentProject;
    this.orderService
      .moveOrdersToOfficial(project.uuid)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((official) => {
          if (official) {
            const snack = this.messageService.snackSuccess(
              new Message(
                $localize`Zapisano zamówienie oficjalne`,
                '',
                official.Number
              )
            );
            snack
              .onAction()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(() => {
                this.openOfficialOrderList();
              });
          } else {
            this.messageService.snackError({
              title: $localize`Wystąpił błąd podczas tworzenia zamówienia oficjalnego`,
              actionText: 'Ok'
            });
          }
        }),
        switchMap((created) =>
          created ? this.projectService.find(created.ProjectId) : of(null)
        )
      )
      .subscribe((project) => {
        if (project) {
          this.projectService.currentProject.orderUuid = project.orderUuid;
          this.projectService.currentProject.canMakeOrderTemplate =
            project.canMakeOrderTemplate;
          this.projectService.currentProject.orderTemplateId =
            project.orderTemplateId;
          this.changeDetector.detectChanges();
        }
      });
  }

  protected openOfficialOrderList() {
    this.uiService.setProjectListMode(ProjectListMode.OFFICIAL_ORDERS);
    this.menuService.backToProjects();
  }

  protected canMakeOrderTemplate() {
    return (
      this.uiService.userSeesOrderTemplates() &&
      this.projectService.currentProject.orderUuid &&
      this.projectService.currentProject.canMakeOrderTemplate &&
      !this.projectService.currentProject.orderTemplateId
    );
  }

  protected createTemplate() {
    const orderUuid = this.projectService.currentProject.orderUuid;
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          action: ConfirmationDialogAction.createOrderTemplate
        }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((confirmed) => confirmed),
        switchMap(() => this.orderService.createOrderTemplate(orderUuid)),
        tap(({ newOrders, error }) => {
          if (newOrders > 0) {
            this.messageService.snackSuccess({
              title: $localize`Dodano zamówienia oficjalne na podstawie szablonu: ${newOrders}`,
              actionText: 'Ok'
            });
          }
          if (error === 'calculation_not_present') {
            this.messageService.snackError({
              title: $localize`Brak rozmieszczenia dla zamówienia`,
              actionText: 'Ok'
            });
          }
        })
      )
      .subscribe(({ order }) => {
        this.projectService.currentProject.canMakeOrderTemplate = false;
        this.projectService.currentProject.orderTemplateId = order.TemplateId;
      });
  }
}
