import { CuboidHull } from '../../../lib/cuboid-hull';
import { LoadDisplaySettings } from '../../../lib/load-display-settings';
import { LoadMesh } from '../../../lib/load-mesh';
import { Load } from '../../../lib/load';
import { PalletLoadMesh } from './pallet-load-mesh';
import { Cuboid } from '../../cuboid/lib/cuboid';

export class PalletLoad extends Cuboid {
  shape = 'pallet-load';
  fromVehicleContext: string;

  constructor(obj: any, settings: LoadDisplaySettings) {
    super(obj, settings);
    this.shape = obj.shape;
  }

  async createMesh(settings: LoadDisplaySettings): Promise<LoadMesh> {
    //console.error('create mesh');
    this.mesh = new PalletLoadMesh(this, settings);
    return this.mesh;
  }
}
