import { OfficialOrder } from './official-order';

export class OfficialOrderList {
  public constructor(
    private _items: OfficialOrder[],
    private _total: number = 0,
    private _page: number = 0, // material paginator is 0-based
    private _pageSize: number = 50
  ) {}

  get currentPage() {
    return this._page;
  }

  get totalOrders() {
    return this._total;
  }

  get items() {
    return this._items;
  }

  get pageSize() {
    return this._pageSize;
  }

  get isEmpty() {
    return this.items.length === 0;
  }
}
