import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { OfficialOrder } from '../../lib/official-order';
import { Subject, takeUntil } from 'rxjs';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { OrderService } from '../../order.service';

@Component({
  selector: 'app-official-order-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.less']
})
export class DetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() order: OfficialOrder;
  @ViewChild('box') box: ElementRef;

  protected isLoading = true;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private loadComponentService: LoadComponentService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.orderService
      .getOfficialOrderDetails(this.order.Uuid)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((order) => {
        this.order = order;
        this.isLoading = false;
      });
  }

  ngAfterViewInit(): void {
    this.box.nativeElement.style.top = window.scrollY + 'px';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    console.log('order/official-list/details.component.ts destroyed');
  }

  close() {
    this.loadComponentService.clear(
      'order/official-list/details/details.component.ts'
    );
  }
}
