<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h1 mat-dialog-title cdkDragHandle>
    <ng-container i18n>Zmiana rozmiarów</ng-container> {{ uiData.idx }}
  </h1>
  <div mat-dialog-content class="form">
    <mat-form-field appearance="fill">
      <mat-label
        ><ng-container i18n>Waga</ng-container> ({{
          ui.getWeightUnit() | async
        }})</mat-label
      >
      <input matInput [(ngModel)]="uiData.weight" />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" i18n>Anuluj</button>
    <button mat-button [mat-dialog-close]="uiData" cdkFocusInitial i18n>
      Zastosuj
    </button>
  </div>
</div>
