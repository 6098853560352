<div class="row date-filters-container">
  <div class="date-filters">
    <app-dates-filter
      (datesChangeEvent)="changeFilters($event)"
      [currentType]="selectedDatesFilter"
      [withWeek]="true"
    >
      <span allText i18n>Wszystkie zamówienia</span>
    </app-dates-filter>
  </div>
  <mat-paginator
    hidePageSize="true"
    [length]="(list$ | async)?.totalOrders"
    [pageIndex]="(list$ | async)?.currentPage"
    [pageSize]="(list$ | async)?.pageSize"
    (page)="onPageChange($event)"
  ></mat-paginator>
</div>
<div>
  <mat-form-field class="search" appearance="fill">
    <mat-label i18n>Wyszukaj zamówienie oficjalne</mat-label>
    <input type="text" matInput [formControl]="search" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<div class="flex-right">
  <mat-form-field appearance="fill">
    <mat-label i18n>Sortuj: </mat-label>
    <mat-select
      [(ngModel)]="currentSorting"
      (selectionChange)="changeSorting($event)"
    >
      <mat-option
        *ngFor="let sort of service.officialSortOptions"
        [value]="sort.value"
      >
        {{ sort.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [(ngModel)]="showDetails" i18n>Pokaż szczegóły</mat-checkbox>
  <mat-form-field appearance="fill">
    <mat-label i18n>Lokalizacja: </mat-label>
    <mat-select
      [(ngModel)]="currentLocation"
      (selectionChange)="changeLocation($event)"
    >
      <mat-option *ngFor="let loc of service.countries" [value]="loc.value">
        {{ loc.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="panel">
  <ng-template #rowTemplate let-order="order">
    <tr *ngIf="!showDetails">
      <td>
        <div class="img-container">
          <ng-container *ngIf="order.HasImage">
            <a href="{{ order.getImageSharedUrl() }}" target="_blank">
              <img src="/assets/images/square.png" alt="" class="img-mark" />
            </a>
          </ng-container>
          <div>
            {{ order.Number }}<br />
            <small>{{ order.shortPositionNumbers }}</small>
          </div>
          <div
            *ngIf="order.DistanceMatching"
            class="matched-info"
            i18n-title
            title="MATCH w promieniu {{
              order.DistanceMatching / 1000 | number : '0.0'
            }} km"
          >
            <div class="label">km</div>
            <div class="distance">
              {{ order.DistanceMatching / 1000 | number : '0.0' }}
            </div>
          </div>
        </div>
        <div class="icons">
          <mat-icon
            *ngIf="order.TemplateId"
            i18n-matTooltip
            matTooltip="Utworzono szablon"
            [matTooltipPosition]="'right'"
            #templateIconTooltip="matTooltip"
            >format_paint</mat-icon
          >
        </div>
      </td>
      <td>{{ order.createdAt | date : 'dd.MM.YYYY HH:mm' }}</td>
      <td>{{ order.DeliveryName }}</td>
      <td>{{ order.Qty }}</td>
      <td>{{ order.ShippingDateConfirmed | date : 'dd.MM.YYYY' }}</td>
      <td>
        {{ order.VehicleName }} <br />
        <small
          >{{ order.FreeLDM / 1000 | number : '0.0-2' }} /
          {{ order.TotalLDM / 1000 | number : '0.0-2' }} LDM</small
        >
      </td>
      <td>
        <div class="actions">
          <button
            type="button"
            mat-icon-button
            color="primary"
            (click)="goToDetails(order)"
            i18n-matTooltip
            matTooltip="Szczegóły zamówienia"
            [matTooltipPosition]="'left'"
            #detailsTooltip="matTooltip"
          >
            <mat-icon>info</mat-icon>
          </button>
          <button
            *ngIf="order.ProjectId"
            mat-icon-button
            type="button"
            color="accent"
            (click)="openProject(order)"
            i18n-matTooltip
            matTooltip="Otwórz rozmieszczenie"
            [matTooltipPosition]="'right'"
            #openPlacementTooltip="matTooltip"
          >
            <mat-icon>preview</mat-icon>
          </button>
          <button
            mat-icon-button
            type="button"
            color="accent"
            (click)="removeOrder(order)"
            i18n-matTooltip
            matTooltip="Usuń z oficjalnych"
            [matTooltipPosition]="'right'"
            #openPlacementTooltip="matTooltip"
          >
            <mat-icon>delete_forever</mat-icon>
          </button>
          <button
            *ngIf="
              ui.userSeesOrderTemplates() &&
              !order.TemplateId &&
              order.Orders.length === 1
            "
            mat-icon-button
            type="button"
            color="accent"
            (click)="createTemplate(order)"
            i18n-matTooltip
            matTooltip="Utwórz szablon z zamówienia"
            [matTooltipPosition]="'right'"
            #createTemplateTooltip="matTooltip"
          >
            <mat-icon>format_paint</mat-icon>
          </button>
        </div>
      </td>
    </tr>
    <ng-container *ngIf="showDetails">
      <tr class="head">
        <td class="head-cell">
          <mat-icon>add</mat-icon>
        </td>
        <td></td>
        <td>
          <span class="img-container">
            <ng-container *ngIf="order.HasImage">
              <a href="{{ order.getImageSharedUrl() }}" target="_blank">
                <img src="/assets/images/square.png" alt="" class="img-mark" />
              </a>
            </ng-container>
            {{ order.Number }}
          </span>
        </td>
        <td></td>
        <td>{{ order.DeliveryName }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ order.Qty }}</td>
        <td></td>
        <td></td>
        <td>{{ order.ShippingDateConfirmed | date : 'dd.MM.YYYY' }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <div class="actions">
            <button
              type="button"
              mat-icon-button
              color="primary"
              (click)="goToDetails(order)"
              i18n-matTooltip
              matTooltip="Szczegóły zamówienia"
              [matTooltipPosition]="'left'"
              #detailsTooltip2="matTooltip"
            >
              <mat-icon>info</mat-icon>
            </button>
            <button
              *ngIf="order.ProjectId"
              mat-icon-button
              type="button"
              color="accent"
              (click)="openProject(order)"
              i18n-matTooltip
              matTooltip="Otwórz rozmieszczenie"
              [matTooltipPosition]="'right'"
              #openPlacementTooltip2="matTooltip"
            >
              <mat-icon>preview</mat-icon>
            </button>
          </div>
        </td>
      </tr>
      <tr *ngFor="let position of order.Orders" class="member">
        <td class="member-cell"></td>
        <td>{{ position.Reference }}</td>
        <td>{{ position.Number }}</td>
        <td>{{ position.CustAccount }}</td>
        <td>{{ position.DeliveryName }}</td>
        <td>{{ position.getDeliveryAddress() }}</td>
        <td>{{ position.ItemGrp }}</td>
        <td>{{ position.ItemId }}</td>
        <td>{{ position.ItemName }}</td>
        <td>{{ position.Qty }}</td>
        <td>{{ position.AvailableStock }}</td>

        <td>{{ position.DlvMode }}</td>
        <td>{{ position.ShippingDateConfirmed | date : 'dd.MM.YYYY' }}</td>
        <td>{{ position.Volume }}</td>
        <td>{{ position.InventRefType }}</td>
        <td>{{ position.InventRefNo }}</td>
        <td>{{ position.ProdStatus }}</td>
        <td>{{ position.LatestSchedDate }}</td>
        <td>{{ position.ShipmentId }}</td>
        <td>{{ position.TruckArrival }}</td>
        <td>{{ position.Note }}</td>
        <td>{{ position.Site }}</td>
        <td>{{ position.Pool }}</td>
        <td>{{ position.ItemGrpId }}</td>
        <td>{{ position.BuyerGroup }}</td>
        <td>{{ position.Warehouse }}</td>
        <td></td>
      </tr>
    </ng-container>
  </ng-template>
  <table class="table orders-table" [class.details]="showDetails">
    <thead>
      <tr>
        <ng-container *ngIf="!showDetails">
          <th i18n>Numer</th>
          <th i18n>Data utworzenia</th>
          <th i18n>Klient</th>
          <th i18n>Liczba przyczep</th>
          <th i18n>Data dostawy</th>
          <th i18n>Pojazd</th>
          <th></th>
        </ng-container>
        <ng-container *ngIf="showDetails">
          <th></th>
          <th>Reference</th>
          <th>Number</th>
          <th>CustAccount/ToWH</th>
          <th>Delivery Name</th>
          <th>Delivery Address</th>
          <th>ItemGrp</th>
          <th>ItemId</th>
          <th>Item name</th>
          <th>Qty</th>
          <th>AvailableStock</th>
          <th>DlvMode</th>
          <th>Shipping Date</th>
          <th>Volume</th>
          <th>InventRefType</th>
          <th>InventRefNo</th>
          <th>ProdStatus</th>
          <th>LatestSchedDate</th>
          <th>ShipmentId</th>
          <th>TruckArrival</th>
          <th>Note</th>
          <th>Site</th>
          <th>Pool</th>
          <th>ItemGrpId</th>
          <th>ByerGroup</th>
          <th>Warehouse</th>
          <th></th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let order of (list$ | async)?.items">
        <ng-container
          *ngTemplateOutlet="rowTemplate; context: { order: order }"
        ></ng-container>
      </ng-container>
      <tr *ngIf="!(isLoading$ | async) && (list$ | async)?.isEmpty">
        <td colspan="29" class="info" i18n>
          Brak zamówień spełniających kryteria
        </td>
      </tr>
      <tr *ngIf="isLoading$ | async" class="info">
        <td colspan="29" i18n>Ładowanie listy zamówień oficjalnych...</td>
      </tr>
    </tbody>
  </table>
</div>
