import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { SceneService } from '../scene/scene.service';
import { Load } from './lib/load';

import { LoadEventObject } from './lib/load-event-object';
import { LoadShape } from './lib/load-shape';
import { LoadService } from './load.service';
import { ProfileService } from '../services/profile.service';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-load-module',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.less']
})
export class LoadComponent implements OnDestroy {
  eventsSubject: Subject<void> = new Subject<void>();
  public shape: LoadShape;
  public shapes: Array<LoadShape>;
  public saveForm: UntypedFormGroup;
  public fb: UntypedFormBuilder = new UntypedFormBuilder();

  public listType = 'none'; // system | user | none - lista typowe lub własne lub widok wyboru
  // userFormType = 'new'; // new | history - formularz Zdefiniuj nowy | lista Moje poprzednie

  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: LoadService,
    private profileService: ProfileService,
    private ui: UiService
  ) {
    this.saveForm = this.fb.group({
      name: [null, Validators.required]
    });

    this.shapes = this.service.loadShapes;

    //TODO - zmienic by na sztywno nie ustawiac cuboid w typie select listy bo select-list-component.html wtedy nie jest dopasowywane do wybranego typu ladunku
    this.shape = this.shapes.find((x) => x.type === 'cuboid');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public selectedLoadShape(shape: LoadShape) {
    this.shape = shape;
  }

  createLoad(load: Load) {
    // console.log('createLoadEvent', load);
  }
  //addLoad to calculation
  addLoad(eventObject: LoadEventObject[]) {
    this.ui.setLoading(false);
    this.service
      .addLoadAmount(eventObject)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.profileService.fetchProfile())
      )
      .subscribe((profile) => {
        this.ui.setLoading(false);
        this.profileService.updateProfile(profile);
      });
  }
}
