<div class="modal-box order-details-box" #box>
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Szczegóły zamówienia oficjalnego {{ order.Number }}</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <div *ngIf="order">
        <div class="buttons"></div>
        <table class="details">
          <tbody>
            <tr>
              <th>Na pojeździe</th>
              <td>{{ order.VehicleName || '' }}</td>
            </tr>
            <tr>
              <th>Wolne LDM</th>
              <td>{{ order.FreeLDM / 1000 | number : '0.0-2' }}</td>
            </tr>
            <tr>
              <th>Wolna objętość</th>
              <td>
                {{ order.FreeCapacity / 1000 / 1000 / 1000 | number : '0.0-2' }}
              </td>
            </tr>
            <ng-container *ngFor="let position of order.Orders">
              <tr>
                <th colspan="2" class="text-center">{{ position.Number }}</th>
              </tr>
              <tr>
                <th>Reference</th>
                <td>{{ position.Reference }}</td>
              </tr>
              <tr>
                <th>Number</th>
                <td>{{ position.Number }}</td>
              </tr>
              <tr>
                <th>CustAccount/ToWH</th>
                <td>{{ position.CustAccount }}</td>
              </tr>
              <tr>
                <th>Delivery Name</th>
                <td>{{ position.DeliveryName }}</td>
              </tr>
              <tr>
                <th>Delivery City</th>
                <td>{{ position.DeliveryCity }}</td>
              </tr>
              <tr>
                <th>State</th>
                <td>{{ position.State }}</td>
              </tr>
              <tr>
                <th>Zip Code</th>
                <td>{{ position.ZipCode }}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{{ position.Country }}</td>
              </tr>
              <tr>
                <th>ItemGrp</th>
                <td>{{ position.ItemGrp }}</td>
              </tr>
              <tr>
                <th>ItemId</th>
                <td>{{ position.ItemId }}</td>
              </tr>
              <tr>
                <th>Item name</th>
                <td>{{ position.ItemName }}</td>
              </tr>
              <tr>
                <th>Qty</th>
                <td>{{ position.Qty }}</td>
              </tr>
              <tr>
                <th>AvailableStock</th>
                <td>{{ position.AvailableStock }}</td>
              </tr>
              <tr>
                <th>DlvMode</th>
                <td>{{ position.DlvMode }}</td>
              </tr>
              <tr>
                <th>Shipping Date</th>
                <td>
                  {{ position.ShippingDateConfirmed | date : 'dd.MM.YYYY' }}
                </td>
              </tr>
              <tr>
                <th>Volume</th>
                <td>{{ position.Volume }}</td>
              </tr>
              <tr>
                <th>InventRefType</th>
                <td>{{ position.InventRefType }}</td>
              </tr>
              <tr>
                <th>InventRefNo</th>
                <td>{{ position.InventRefNo }}</td>
              </tr>
              <tr>
                <th>ProdStatus</th>
                <td>{{ position.ProdStatus }}</td>
              </tr>
              <tr>
                <th>LatestSchedDate</th>
                <td>{{ position.LatestSchedDate }}</td>
              </tr>
              <tr>
                <th>ShipmentId</th>
                <td>{{ position.ShipmentId }}</td>
              </tr>
              <tr>
                <th>TruckArrival</th>
                <td>{{ position.TruckArrival }}</td>
              </tr>
              <tr>
                <th>Note</th>
                <td>{{ position.Note }}</td>
              </tr>
              <tr>
                <th>Site</th>
                <td>{{ position.Site }}</td>
              </tr>
              <tr>
                <th>Pool</th>
                <td>{{ position.Pool }}</td>
              </tr>
              <tr>
                <th>ItemGrpId</th>
                <td>{{ position.ItemGrpId }}</td>
              </tr>
              <tr>
                <th>ByerGroup</th>
                <td>{{ position.ByerGroup }}</td>
              </tr>
              <tr>
                <th>Warehouse</th>
                <td>{{ position.Warehouse }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div *ngIf="order?.ImageBlob">
        <img src="{{ order.ImageBlob }}" class="image" alt="" />
      </div>
      <div class="loader" *ngIf="isLoading">
        <mat-icon>
          <mat-spinner class="progress" color="accent" diameter="24">
          </mat-spinner>
        </mat-icon>
      </div>
    </div>
  </div>
</div>
