import { Space } from '../../lib/space';
import { Constants } from '../../../lib/constants';
import {
  BoxGeometry,
  BufferGeometry,
  Mesh,
  MeshBasicMaterial,
  Vector3
} from 'three';

export class FlooringLevelMesh {
  protected space: Space;

  private length: number;
  private height: number;
  private width: number;

  public obj: Mesh;

  constructor(space: Space) {
    this.obj = new Mesh();
    this.obj.position.x = 0;
    this.obj.position.z = 0;
    this.obj.position.y = 0;

    this.obj.name = 'space-flooring-level-mesh';
    this.space = space;
    this.obj.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL,
      Constants.CABIN_MATERIAL,
      Constants.TRANSPARENT_MATERIAL,
      Constants.FLOOR_MATERIAL
    ];
    this.init();
  }

  private init() {
    this.length = this.space.length;
    this.width = this.space.width;
    this.height = this.space.settings ? this.space.settings.height : 0;
    console.log('flooring mesh', this.space.mesh.position, this.height);

    this.obj.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;

    const lineWidth = 20;

    const lengthLineGeometry = new BoxGeometry(
      this.length,
      lineWidth,
      lineWidth
    );
    const widthLineGeometry = new BoxGeometry(lineWidth, lineWidth, this.width);

    const material = new MeshBasicMaterial({ color: 0x312fbf });

    const lineLeft = new Mesh(lengthLineGeometry, material);
    lineLeft.position.copy(new Vector3(x0, this.height, z1));
    lineLeft.translateX(this.length / 2);
    this.obj.add(lineLeft);

    const lineRight = new Mesh(lengthLineGeometry, material);
    lineRight.position.copy(new Vector3(x0, this.height, z0));
    lineRight.translateX(this.length / 2);
    this.obj.add(lineRight);

    const lineFront = new Mesh(widthLineGeometry, material);
    lineFront.position.copy(new Vector3(x0, this.height, z0));
    lineFront.translateZ(this.width / 2);
    this.obj.add(lineFront);

    const lineBack = new Mesh(widthLineGeometry, material);
    lineBack.position.copy(new Vector3(x1, this.height, x0));
    lineBack.translateZ(this.width / 2);
    this.obj.add(lineBack);
  }
}
