import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import {
  Box3,
  Box3Helper,
  BoxGeometry,
  Color,
  ConeGeometry,
  CylinderGeometry,
  DirectionalLight,
  DoubleSide,
  Euler,
  GridHelper,
  Group,
  IcosahedronGeometry,
  LineBasicMaterial,
  MathUtils,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshPhongMaterial,
  Object3D,
  PerspectiveCamera,
  Scene,
  SphereGeometry,
  Vector3,
  WebGLRenderer
} from 'three';
import { OrbitControls } from '../lib/vendor/three/controls/OrbitControls';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { Vehicle } from '../vehicle/lib/vehicle';
import { Space } from '../vehicle/space/lib/space';
import { TrailerPositioner } from './lib/trailer-positioner';
import { ModelLoaderService } from './lib/model-loader.service';
import {
  DragControlsModel,
  DragControlsMoveModel,
  DragControlsService
} from './lib/drag-controls.service';
import { Subject, takeUntil } from 'rxjs';
import { Load } from '../load/lib/load';
import { LoadMesh } from '../load/lib/load-mesh';
import { BrenderupMesh } from '../load/type/car-trailer/lib/brenderup-mesh';
import { CarTrailerType1 } from '../load/type';
import { ColliderDetector } from '../scene/collider-detector';

import * as tf from '@tensorflow/tfjs';
import { TransformControlsService } from '../services/transform-controls.service';
import { OBB } from '../lib/vendor/three/OBB';

@Component({
  selector: 'app-raycasting',
  templateUrl: './raycasting.component.html',
  styleUrls: ['./raycasting.component.less']
})
export class RaycastingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('canvas')
  private canvasRef: ElementRef;

  private camera: PerspectiveCamera;

  @Input() public cameraZ: number = 10000;
  @Input() public cameraY: number = 2000;
  @Input() public fieldOfView: number = 45;
  @Input('nearClipping') public nearClippingPlane: number = 1;
  @Input('farClipping') public farClippingPlane: number = 50000;

  @Input() public rotationSpeedX: number = 0.005;
  @Input() public rotationSpeedY: number = 0.001;

  @Input() public size: number = 200;

  private controls: OrbitControls;

  scene!: Scene;
  renderer!: WebGLRenderer;

  private meshes = new Array<Mesh>();
  private loads = new Array<Load>();
  private helpers: Object3D[] = [];

  private vehicle: Vehicle;

  protected showList = true;

  private unsubscribe$ = new Subject<void>();

  private get space(): Space {
    return this.vehicle?.spaces[0];
  }

  private switchToMega() {
    this.vehicle = this.vehicleFactory.recreate({
      uuid: '8079c6f7-dfa7-41bf-a8f3-e51063b6b72d',
      name: 'Mega 2.5',
      type: 'truck',
      subtype: 'mega',
      spaces: [
        {
          uuid: '8079c6f7-dfa7-41bf-a8f3-e51063b6b72c',
          type: 'trailer',
          shape: 'cuboid',
          epCnt: 33,
          maxLoadingWeight: 3500000,
          width: 2500,
          length: 13600,
          height: 3000,
          enabled: true,
          axles: [],
          loads: [],
          matrix: []
        }
      ],
      width: 2500,
      length: 13600,
      height: 3000,
      axles: []
    });
  }

  private switchToStandard() {
    this.vehicle = this.vehicleFactory.recreate({
      cabinLength: 1000,
      cabinHeight: 2000,
      cabinRoofHeight: 500,
      cabinWidth: 1500,
      trailerSpacing: 500,
      spaces: [
        {
          axles: [],
          loads: [],
          enabled: true,
          uuid: '0ac7b276-cb53-47fd-8714-79b6a5ac952c',
          type: 'trailer',
          shape: 'cuboid',
          epCnt: 33,
          maxLoadingWeight: 24000000,
          width: 2480,
          length: 13600,
          height: 2700,
          order: 0,
          matrix: [],
          settings: {
            maxHeightEnabled: false,
            maxRowsCntEnabled: false
          }
        }
      ],
      axles: [],
      gravityCenters: [],
      uuid: '0ac7b276-cb53-47fd-8714-79b6a5ac952f',
      name: 'Standard 2.70',
      type: 'truck',
      subtype: 'standard',
      height: 2700,
      length: 13600,
      width: 2480,
      defaultBaseSize: '13600x2480'
    });
  }

  private switchToZestaw() {
    this.vehicle = this.vehicleFactory.recreate({
      spaces: [
        {
          enabled: true,
          uuid: 'f48a945e-7e24-4ba0-8b3b-60841faed70c',
          type: 'trailer',
          shape: 'cuboid',
          epCnt: 38,
          maxLoadingWeight: 23500000,
          width: 2480,
          length: 7700,
          height: 3000,
          order: 0
        },
        {
          enabled: true,
          uuid: 'f48a945e-7e24-4ba0-8b3b-60841faed7cc',
          type: 'trailer',
          shape: 'cuboid',
          epCnt: 38,
          maxLoadingWeight: 23500000,
          width: 2480,
          length: 7700,
          height: 3000,
          order: 1
        }
      ],
      cabinLength: 1000,
      cabinHeight: 2000,
      cabinRoofHeight: 500,
      cabinWidth: 1500,
      trailerSpacing: 500,
      uuid: 'f48a945e-7e24-4ba0-8b3b-60841faed70a',
      name: 'Zestaw [7,7+7,7]',
      type: 'truck',
      subtype: 'tandem',
      height: 3000,
      length: 15900,
      width: 2480
    });
  }

  constructor(
    private vehicleFactory: VehicleFactory,
    private modelLoader: ModelLoaderService,
    private dragControlsService: DragControlsService,
    private transformControlsService: TransformControlsService,
    private colliderDetector: ColliderDetector,
    private positioner: TrailerPositioner
  ) {
    this.switchToMega();
    console.log('vehicle created', this.vehicle);
  }

  private findLoadByMeshId(uuid: string) {
    return this.loads.find((x) => x.mesh.obj.userData.uuid === uuid);
  }

  private randomColor(): Color {
    const color = new Color(0xffffff);
    color.setHex(Math.random() * 0xffffff);
    return color;
  }

  public reset() {
    for (let mesh of this.meshes) {
      this.scene.remove(mesh);
    }
    for (let mesh of this.helpers) {
      this.scene.remove(mesh);
    }
    this.meshes = [];
    this.loads = [];
    this.helpers = [];
    const toRemove: Object3D[] = [];
    this.scene.traverse((child) => {
      if (child.name.endsWith('debug')) {
        toRemove.push(child);
      }
    });
    toRemove.forEach((child) => {
      child.parent?.remove(child);
    });
    this.positioner.reset();
  }

  private findPlaceWithExceptions(mesh: Group, name: string): boolean {
    let found = false;
    const settings = {
      loadBordersIntensity: 1,
      loadTransparency: 1,
      randomizeColors: false,
      fullLevels: false
    };
    const load = new CarTrailerType1(
      {
        name: name || mesh.name,
        modelName: name || mesh.name,
        floorableBottom: true,
        floorableTop: true
      },
      settings
    );
    const loadMesh = new BrenderupMesh(load, settings, null);
    loadMesh.obj.add(mesh);
    loadMesh.updateObbs();
    load.mesh = loadMesh;
    load.removeDisassembledComponents();
    this.positioner.setScene(this.scene);
    found = this.positioner.findPlaceForLoads(
      [load],
      this.vehicle.spaces,
      settings,
      false
    );
    if (found) {
      this.loads.push(load);
      this.meshes.push(loadMesh.obj);
      this.scene.add(loadMesh.obj);
      //this.drawRedControlDot(loadMesh.obj.position.clone());
    }
    const box = loadMesh.computeBoundingBox();
    const helper = new Box3Helper(box, new Color('red'));
    console.log('placed box size', box.getSize(new Vector3()), mesh);
    //this.scene.add(helper);

    loadMesh.obj.traverse((child) => {
      /*if (!child.name.endsWith('-oriented-bounding-box-wireframe')) {
        return;
      }*/
      if (!child.userData.obb) {
        return;
      }
      console.log('draw obb', child.name);
      //this.drawOBB(child.userData.obb, loadMesh.obj, new Color(0x00ff00));
      if (child.userData.obbExp) {
        //this.drawOBB(child.userData.obbExp, loadMesh.obj, new Color(0xffff00));
      }
    });
    /*found = true;
      this.loads.push(load);
      this.meshes.push(loadMesh.obj);
      this.scene.add(loadMesh.obj);
    }*/
    //loadMesh.obj.userData.uuid = mesh.userData.uuid;

    this.initDragControls();
    return found;
  }

  private findPlace(mesh: Group): boolean {
    let found = false;
    const settings = { loadBordersIntensity: 1, loadTransparency: 1 };
    const load = new CarTrailerType1(
      {
        floorableBottom: true,
        floorableTop: true
      },
      settings
    );
    const loadMesh = new BrenderupMesh(load, settings, null);
    loadMesh.obj.add(mesh);
    loadMesh.updateObbs();
    load.mesh = loadMesh;

    found = this.positioner.findPlaceRaycaster(load, this.loads, this.scene);
    if (found) {
      console.log('load position found', load.mesh?.obj.position.clone());
      //this.loads.push(load);
      this.meshes.push(loadMesh.obj);
      //this.scene.add(loadMesh.obj);
    }
    //loadMesh.obj.userData.uuid = mesh.userData.uuid;

    this.initDragControls();

    // provide optional config object (or undefined). Defaults shown.
    //const config = {
    //      binaryThresh: 0.5,
    //hiddenLayers: [3], // array of ints for the sizes of the hidden layers in the network
    //activation: 'sigmoid', // supported activation types: ['sigmoid', 'relu', 'leaky-relu', 'tanh'],
    //leakyReluAlpha: 0.01 // supported for activation type 'leaky-relu'
    //};

    // create a simple feed forward neural network with backpropagation

    // Define a model for linear regression.
    //const model = tf.sequential();
    //model.add(tf.layers.dense({ units: 1, inputShape: [1] }));

    //model.compile({ loss: 'meanSquaredError', optimizer: 'sgd' });

    // Generate some synthetic data for training.
    //const xs = tf.tensor2d([1, 2, 3, 4], [4, 1]);
    //const ys = tf.tensor2d([1, 3, 5, 7], [4, 1]);

    //(0) + 1150S = mY  1/2
    //(1150S) + 1150S =  mY 1/2, mY 1/2
    //(1150S + 1150S) + 1150S = mY 1/2, mY 1/2, mY 1/2
    //(1150S + 1150S + 1150S) + 1150S = mX 1/2, mY 1/2
    //(1150S + 1150S + 1150S + 1150S) + 1150S = mX 1/2, mY 1/2, mY 1/2

    // Train the model using the data.
    //model.fit(xs, ys, { epochs: 25 }).then(() => {
    // Use the model to do inference on a data point the model hasn't seen before:
    //      (model.predict(tf.tensor2d([5, 8, 9, 11], [4, 1])) as tf.Tensor).print();
    // Open the browser devtools to see the output
    //  });
    return found;
  }

  public loadGlbModel(key: string): Promise<Group> {
    return this.modelLoader.loadGlbModel(key);
  }

  public async add1150S(amt: number) {
    let found = 0;
    let i = amt;
    while (i-- > 0) {
      const model = await this.loadGlbModel('1150S');
      if (this.findPlace(model)) {
        found++;
      }
    }

    //alert('found place for ' + found + ' of ' + amt);
  }

  public async addModelFromName(name: string, trailerName?: string) {
    const model = await this.loadGlbModel(name);
    this.findPlaceWithExceptions(model, trailerName || name);
  }

  private addWithGroup(mesh: Mesh) {
    const group = new Group();
    group.name = 'load';
    group.add(mesh);
    mesh.layers.enable(1);
    this.findPlace(group);
  }

  public addCube() {
    const geometry = new BoxGeometry(1000, 1000, 1000);
    const mesh = new Mesh(geometry, this.getMaterial());
    this.addWithGroup(mesh);
  }

  private getMaterial(): MeshPhongMaterial {
    return new MeshPhongMaterial({
      color: this.randomColor(),
      transparent: true,
      depthWrite: false,
      opacity: 0.1,
      side: DoubleSide
    });
  }

  public addCylinder() {
    const geometry = new CylinderGeometry(
      1.5 * 1000,
      1.5 * 1000,
      1 * 1000,
      32 * 1000
    );
    const mesh = new Mesh(geometry, this.getMaterial());
    this.addWithGroup(mesh);
  }

  public addSphere() {
    const geometry = new SphereGeometry(1.5 * 1000, 32, 32);

    const mesh = new Mesh(geometry, this.getMaterial());
    this.addWithGroup(mesh);
  }

  public addCone() {
    const geometry = new ConeGeometry(1 * 1000, 2 * 1000, 32);
    const mesh = new Mesh(geometry, this.getMaterial());
    this.addWithGroup(mesh);
  }

  public addIcosahedron() {
    const geometry = new IcosahedronGeometry(1.5 * 1000, 0);
    const mesh = new Mesh(geometry, this.getMaterial());
    this.addWithGroup(mesh);
  }

  private getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }
  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private initDragControls() {
    /*this.dragControlsService.initDragControls(
      this.camera,
      this.renderer.domElement,
      this.meshes
    );*/

    this.transformControlsService.init(this.camera, this.renderer.domElement);
    this.transformControlsService.bindLoads(this.loads);
  }

  private initOrbitControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    let controls: OrbitControls = this.controls;
    return controls;
  }

  private addVehicleToScene() {
    const mesh = this.vehicle.mesh.mesh;
    console.log(
      'add vehicle',
      mesh.position,
      -this.vehicle.maxLength / 2,
      -this.vehicle.maxWidth / 2
    );
    mesh.position.y = 0;
    mesh.position.x = -this.vehicle.maxLength / 2;
    mesh.position.z = -this.vehicle.maxWidth / 2;
    mesh.position.copy(mesh.position);
    mesh.updateMatrix();
    mesh.updateMatrixWorld();
    this.scene.add(mesh);

    console.log(
      'space pos',
      this.vehicle.spaces[0].mesh.meshObj.getWorldPosition(new Vector3())
    );
    /*this.drawBlueControlDot(new Vector3(0, 0, 0));
    this.drawRedControlDot(
      this.vehicle.spaces[0].mesh.meshObj.getWorldPosition(new Vector3())
    );*/
  }

  private startRenderingLoop() {
    this.renderer = new WebGLRenderer({ canvas: this.canvas });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    const component: RaycastingComponent = this;
    const controls = this.initOrbitControls();
    this.initDragControls();
    this.addVehicleToScene();

    (function render() {
      requestAnimationFrame(render);
      controls.update();
      component.renderer.render(component.scene, component.camera);
    })();
  }

  private createScene() {
    this.scene = new Scene();

    this.scene.background = new Color(0xffffff);

    let aspectRatio = this.getAspectRatio();
    this.camera = new PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    );

    this.camera.position.z = this.cameraZ;
    this.camera.position.y = this.cameraY;
    //this.camera.layers.disable(2);
    this.camera.layers.enable(1);
    this.camera.layers.disable(2);
    this.camera.layers.disable(3);

    var light = new DirectionalLight(0xffffff);
    light.position.set(1, 1, 1).normalize();
    this.camera.add(light);
    this.scene.add(this.camera);

    const size = 50000;
    const divisions = 100;

    const gridHelper = new GridHelper(size, divisions);
    gridHelper.material = new LineBasicMaterial({
      color: 0xbbbbbb
    });
    this.scene.add(gridHelper);
  }

  ngAfterViewInit(): void {
    this.createScene();
    this.positioner.setScene(this.scene);
    this.positioner.setSpace(this.space);
    this.startRenderingLoop();
  }

  ngOnInit(): void {
    /*this.dragControlsService.isDragging$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isDragging) => {
        if (!this.controls) {
          return;
        }
        this.controls.enabled = !isDragging;
      });

    this.dragControlsService
      .modelChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((model) => this.dragModelChange(model));
    this.dragControlsService
      .dragEnd()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((model) => this.onDragEnd(model));*/

    this.transformControlsService
      .modelChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((model) => {
        const load = model.load;

        this.colliderDetector.detect(load, this.vehicle, this.loads);
      });

    this.transformControlsService
      .getEnabled()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((enabled) => {
        if (!this.controls) {
          return;
        }
        this.scene.remove(this.transformControlsService.getControls());
        this.scene.add(this.transformControlsService.getControls());
        this.controls.enabled = !enabled;
      });

    this.transformControlsService
      .dragEnd()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((model) => {
        console.log('dragend', model);
      });
  }

  private onDragEnd(model: DragControlsMoveModel) {
    console.log('dragend', model);
  }

  public dragModelChange(model: DragControlsModel) {
    //console.log('scene.service.ts: dragModelChange', model);
    // const event = model.event;
    const obj = model.object;
    const load = this.findLoadByMeshId(obj.userData.uuid);

    this.colliderDetector.detect(load, this.vehicle, this.loads);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private drawRedControlDot(position: Vector3) {
    const geometry = new SphereGeometry(0.07 * 1000);
    const material = new MeshBasicMaterial({
      color: 0xff0000,
      transparent: true,
      depthWrite: false,
      opacity: 0.5
    });
    const mesh = new Mesh(geometry, material);
    mesh.position.copy(position);
    this.scene.add(mesh);
  }

  private drawBlueControlDot(position: Vector3) {
    const geometry = new SphereGeometry(0.05 * 1000);
    const material = new MeshBasicMaterial({
      color: 0x0000ff,
      transparent: true,
      depthWrite: false,
      opacity: 0.5
    });
    const mesh = new Mesh(geometry, material);
    mesh.position.copy(position);
    this.scene.add(mesh);
  }

  private drawGreenControlDot(position: Vector3) {
    const geometry = new SphereGeometry(0.09 * 1000);
    const material = new MeshBasicMaterial({
      color: 0x00ff00,
      transparent: true,
      depthWrite: false,
      opacity: 0.3
    });
    const mesh = new Mesh(geometry, material);
    mesh.position.copy(position);
    this.scene.add(mesh);
  }

  private drawYellowControlDot(position: Vector3) {
    const geometry = new SphereGeometry(0.15 * 1000);
    const material = new MeshBasicMaterial({
      color: 0x333333,
      transparent: true,
      depthWrite: false,
      opacity: 0.5
    });
    const mesh = new Mesh(geometry, material);
    mesh.position.copy(position);
    this.scene.add(mesh);
  }
  private drawOBB(obb: OBB, attachTo: Object3D, color: Color) {
    const wireframeMaterial = new MeshPhongMaterial({
      color: 0x000,
      transparent: false,
      depthWrite: true,
      opacity: 1,
      visible: true,
      wireframe: true,
      side: DoubleSide
    });
    const geometry = new BoxGeometry(
      obb.halfSize.clone().multiplyScalar(2).x,
      obb.halfSize.clone().multiplyScalar(2).y,
      obb.halfSize.clone().multiplyScalar(2).z
    );
    const material = new MeshBasicMaterial({ color });
    const cube = new Mesh(geometry, material);
    const wireframe = new Mesh(geometry, wireframeMaterial);
    cube.position.copy(obb.center);
    wireframe.position.copy(obb.center);
    const rotationMatrix = new Matrix4().setFromMatrix3(obb.rotation);
    cube.rotation.setFromRotationMatrix(rotationMatrix, Euler.DEFAULT_ORDER);
    wireframe.rotation.setFromRotationMatrix(
      rotationMatrix,
      Euler.DEFAULT_ORDER
    );
    cube.attach(wireframe);
    if (attachTo) {
      attachTo.attach(cube);
    } else {
      this.scene.add(cube);
    }
  }
}
