import { MatPaginatorIntl as TranslatablePaginator } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class MatPaginatorIntl implements TranslatablePaginator {
  changes = new Subject<void>();

  firstPageLabel = $localize`Pierwsza strona`;
  itemsPerPageLabel = $localize`Rozmiar:`;
  lastPageLabel = $localize`Ostatnia strona`;
  nextPageLabel = $localize`Następna strona`;
  previousPageLabel = $localize`Poprzednia strona`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    const of = $localize`z`;
    if (length === 0 || pageSize === 0) {
      return `0 ${of} ${length}`;
    }

    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
  }
}
