import { Vector3 } from 'three';
import { VectorDimensionLabel } from './VectorDimensionLabel';

export class VectorLengthLabel extends VectorDimensionLabel {
  protected updateLabelPosition(): void {
    const position = new Vector3();
    this.vector.mesh.getWorldPosition(position);
    position.y -= this.vector.height / 2;
    position.z += this.vector.width / 2 - 20;
    this.setPosition(position);
  }
}
