import { Euler, Vector3 } from 'three';
import { ModelDetails } from './model-details.interface';

export class ModelDetailsATHB implements ModelDetails {
  getPartDimensions(name: string): Vector3 {
    switch (name) {
      case 'fender-front-left-obb':
      case 'fender-front-right-obb':
      case 'fender-rear-left-obb':
      case 'fender-rear-right-obb':
        return new Vector3(350, 40, undefined);
      case 'wheel-hub-front-right-obb':
      case 'wheel-hub-front-left-obb':
      case 'wheel-hub-rear-right-obb':
      case 'wheel-hub-rear-left-obb':
        return new Vector3(undefined, 60, undefined);
    }
    return new Vector3(undefined, undefined, 60);
  }
  getPartRotation(name: string): Euler {
    switch (name) {
      case 'shaft-left-obb':
        return new Euler(0, -Math.PI / 15, 0);
      case 'shaft-right-obb':
        return new Euler(0, Math.PI / 15, 0);
      case 'fender-front-left-obb':
        return new Euler(0, 0, -Math.PI / 3.3);
      case 'fender-front-right-obb':
        return new Euler(0, 0, -Math.PI / 3.3);
      case 'fender-rear-left-obb':
        return new Euler(0, 0, Math.PI / 3.3);
      case 'fender-rear-right-obb':
        return new Euler(0, 0, Math.PI / 3.3);
      case 'wheel-hub-front-right-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-front-left-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rear-right-obb':
        return new Euler(0, 0, Math.PI / 7);
      case 'wheel-hub-rear-left-obb':
        return new Euler(0, 0, Math.PI / 7);
    }
    throw new Error(`ATHB OBB layer for ${name} not supported`);
  }
}
